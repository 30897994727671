import React, { useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useFiltered, useSortHandler } from "reactcoregk/hooks";
import { makeStyles } from "@material-ui/styles";
import BaseDialog from "../../../components/Common/BaseDialog";
import Title from "../../../components/Common/Title";
import DefaultTable from "../../../components/Common/DefaultTable";
import { useDefaultTable } from "../../../helpers/hooks";
import SearchBox from "../../../components/Common/SearchBox";

const defaultCells = [
  {
    id: "name",
    numeric: false,
    label: "Product",
  },
];

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});
function ProductsDialog(props) {
  const classes = useStyles();

  const { selectedProducts, handleClose, callback, products } = props;

  const productList = useMemo(() => {
    return products.map((x)=> {
      return { name: x }
    })
  }, [products])

  const [query, setQuery] = useState("");

  const filteredNames = useFiltered(productList, query, ["name"]);
  const sortHandler = useSortHandler();
  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(filteredNames, sortHandler, "name");

  useEffect(() => {
    setSelected(selectedProducts || []);
  }, [selectedProducts, setSelected]);

  const handleConfirm = () => {
    callback(selected);
    handleClose();
  };

  return (
    <BaseDialog
      title={"Products"}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      disableBackdropClick
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>{"Select Products"}</Title>
            </Grid>
            <Grid item>
              <SearchBox
                placeholder={"product"}
                handleChange={(e) => setQuery(e.target.value)}
                query={query}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DefaultTable
            rows={filteredNames}
            isLoading={false}
            headCells={defaultCells}
            renderHeadCell={renderHeadCell}
            renderCheckAllCell={renderCheckAllCell}
            renderDefaultBody={renderDefaultBody}
            selectableRows
          />
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

export default ProductsDialog;
