import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { Publish } from "@material-ui/icons";
import { useTableSearch } from "../../helpers/hooks";
import SearchBox from "../../components/Common/SearchBox";
import FilterBase from "../../components/Common/FilterBase";
import TableFilters from "../../components/Common/TableFilters";
import RankButton from "../../components/Common/Ranking/RankButton";
import { useSelector } from "react-redux";
import { RANK_ENTITY } from "../../constants/enums";
import { createMap } from "reactcoregk/utils";

function getCves(state) {
  return createMap(state.CveDefinition.getAllPageable.all);
}

const Subheader = ({ selected, handleCreateNew }) => {
  const [query, handleChange, syncQuery] = useTableSearch();

  const allAttacks = useSelector(getCves);

  const items = selected.map((id) => ({
    id,
    entityName: RANK_ENTITY.THREATS,
    name: allAttacks.get(id)?.name,
  }));

  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <RankButton
          selected={items}
          entityName={"CVE"}
          rankEntity={RANK_ENTITY.THREATS}
        />
      ),
    },
    {
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },
    {
      customEl: (
        <FilterBase>
          <TableFilters filterOptions={[]} />
        </FilterBase>
      ),
    },

    {
      divider: true,
    },
    {
      title: "Upload definition",
      icon: <Publish color={"primary"} />,
      onClick: handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Subheader;
