import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  fetchData,
  getParameterByName,
  poll,
  updateURLParameter,
} from "./utils";
import { API_URL, AUTH_ENABLED } from "../config";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import { useForm, useToggle } from "reactcoregk/hooks";
import { useHistory, useRouteMatch } from "react-router";
import { useLocation } from "react-router-dom";
import { createMap } from "reactcoregk/utils";
import { jobStatus } from "../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { resetJob } from "../store/job/actions";
import { ApiEndpoint } from "../store/@core/endpoint";
import { EntityType } from "../store/@core/entityType";
import { cloneDeep, uniq } from "lodash";

const useAuthValidation = (loginSuccess, logoutUser) => {
  const [initializing, setInitizalizing] = useState(true);

  const validateUser = useCallback(async () => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      try {
        const appUrl = API_URL + "/api/me";
        await fetchData(appUrl);
        loginSuccess(authUser);
        setInitizalizing(false);
      } catch (e) {
        console.log(e);
        logoutUser();
        setInitizalizing(false);
      }
    } else {
      setInitizalizing(false);
    }
  }, [loginSuccess, logoutUser]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  if (!AUTH_ENABLED) return false;
  return initializing;
};

const useSubheaderLogic = () => {
  const [filters, handleChange] = useForm({ query: "" });
  const [showAdvanced, toggleAdvanced] = useToggle();

  return {
    showAdvanced,
    toggleAdvanced,
    query: filters.query,
    handleChangeQuery: handleChange("query"),
  };
};

const useSortHandler = (by = "", direction = "asc") => {
  const [sort, setSort] = useState({ by, direction });

  const handleSort = useCallback((by) => {
    setSort((prevState) => {
      if (prevState.by === by) {
        return {
          ...prevState,
          direction: prevState.direction === "desc" ? "asc" : "desc",
        };
      }
      return {
        by,
        direction: "asc",
      };
    });
  }, []);

  return {
    sort,
    setSort,
    handleSort,
  };
};

const useSelectedList = (array, identifier = "id") => {
  const [selected, setSelected] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectAllClick = (event) => {
    const newSelectedIds = array.map((n) => n[identifier]);
    if (event.target.checked) {
      const mergedSelectedIds = uniq([...selected, ...newSelectedIds]);
      setSelected(mergedSelectedIds);
      setSelectedRows(
        array.filter((i) => mergedSelectedIds.find((n) => n === i.uuid))
      );
      return;
    } else {
      const previouslySelected = cloneDeep(selected).filter(
        (item) => !newSelectedIds.includes(item)
      );
      setSelectedRows(
        array.filter((i) => newSelectedIds.find((n) => n === i.uuid))
      );
      setSelected(previouslySelected);
    }
  };

  const handleClick = (event, name) => {
    event && event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(array.filter((i) => newSelected.find((n) => n === i.uuid)));
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const numSelected = selected.length;

  return {
    selected,
    selectedRows,
    isSelected,
    numSelected,
    handleClick,
    setSelected,
    handleSelectAllClick,
  };
};

const useDefaultTable = (array, sortHandler, identifier = "id") => {
  const {
    setSelected,
    setSelectedRows,
    handleClick,
    isSelected,
    numSelected,
    selected,
    selectedRows,
    handleSelectAllClick,
  } = useSelectedList(array, identifier);

  const rowCount = array.length;

  const renderHeadCell = (headCell) => {
    return (
      <TableCell
        sortDirection={"asc"}
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        padding={headCell.disablePadding ? "none" : "default"}
      >
        {headCell.isSortable && sortHandler ? (
          <TableSortLabel
            active={sortHandler.sort.by === headCell.id}
            direction={sortHandler.sort.direction}
            onClick={() => sortHandler.handleSort(headCell.id)}
          >
            {headCell.label}
          </TableSortLabel>
        ) : (
          headCell.label
        )}
      </TableCell>
    );
  };

  const renderBodyCell = (label, stylesOverride = {}) => {
    return (
      <TableCell align="left">
        <Typography
          style={{ fontSize: 12, ...stylesOverride }}
          color={"textSecondary"}
        >
          {Array.isArray(label) ? label.join(", ") : label}
        </Typography>
      </TableCell>
    );
  };

  const renderCheckAllCell = () => {
    const indeterminateCalc = () => {
      if (numSelected > 0 && numSelected < rowCount) {
        return (
          array.every((item) => selected.includes(item[identifier])) || true
        );
      } else {
        return false;
      }
    };

    const checkAllChecked = () => {
      if (rowCount > 0) {
        return array.every((item) => selected.includes(item[identifier]));
      } else {
        return false;
      }
    };

    return (
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={indeterminateCalc()}
          checked={checkAllChecked()}
          onChange={handleSelectAllClick}
          inputProps={{ "aria-label": "select all desserts" }}
        />
      </TableCell>
    );
  };

  const renderCheckRowCell = (isItemSelected, labelId, onClick) => {
    return (
      <TableCell padding="checkbox" onClick={onClick}>
        <Checkbox
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>
    );
  };

  const renderDefaultBody = (
    array,
    headerColumns,
    selectableRows,
    onClick,
    styleCell
  ) => {
    return array.map((row, index) => {
      const isItemSelected = isSelected(row[identifier]);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow
          hover
          onClick={(event) => {
            if (onClick) {
              onClick(row);
            } else {
              if (selectableRows) {
                handleClick(event, row[identifier]);
              }
            }
          }}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row[identifier]}
          selected={isItemSelected}
        >
          {selectableRows &&
            renderCheckRowCell(isItemSelected, labelId, (event) =>
              handleClick(event, row[identifier])
            )}
          {headerColumns.map((column) => {
            const styles = styleCell && styleCell(column.id, row[column.id]);
            return renderBodyCell(row[column.id], styles);
          })}
        </TableRow>
      );
    });
  };

  return {
    handleClick,
    handleSelectAllClick,
    isSelected,
    setSelected,
    setSelectedRows,
    renderHeadCell,
    renderBodyCell,
    renderCheckAllCell,
    renderCheckRowCell,
    renderDefaultBody,
    numSelected,
    rowCount,
    selected,
    selectedRows,
  };
};

const usePageableEntity = (context, getAllPageable, rootParams = "") => {
  const entityState = context.getAllPageable;
  const list = entityState.result.content;
  const isLoading = entityState.isLoading;
  const totalPages = entityState.result.totalPages;
  const errorMessage = context.getAllPageable.error;
  const isInitialized = useMemo(
    () => entityState.isFulfilled,
    [entityState.isFulfilled]
  );

  const prevParams = useRef(rootParams);

  useEffect(() => {
    let requestTimeout;
    if (!isInitialized) {
      getAllPageable(`?size=20${rootParams}`);
    } else {
      requestTimeout = setTimeout(() => {
        getAllPageable(`?size=20${rootParams}`);
      }, 100);
    }
    return () => {
      clearTimeout(requestTimeout);
    };
  }, [getAllPageable, isInitialized, rootParams]);

  useEffect(() => {
    prevParams.current = rootParams;
  }, [rootParams]);

  return {
    list,
    isLoading,
    totalPages,
    errorMessage,
  };
};

const useCurrentPageParam = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const currentPage = getParameterByName("page");
  const setCurrentPage = (newPage) => {
    const search = updateURLParameter(location.search, "page", newPage);
    history.push({
      pathname: match.path,
      search: search,
    });
  };
  const safePage = (parseInt(currentPage) || 0) + 1;
  return [safePage, setCurrentPage];
};

export const useSortHandlerPath = (sort, defaultSorting) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (sort.by) {
      const value = sort.by + "," + sort.direction;
      const search = updateURLParameter(location.search, "sort", value);
      history.push({
        pathname: match.path,
        search: search,
      });
    }
  }, [history, location.search, match.path, sort.by, sort.direction]);
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useTableSearch = (queryProp = "s") => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const queryParams = useQuery();
  const search = queryParams.get(queryProp) || "";
  const [query, setQuery] = useState(search);
  const ref = useRef(null);

  const updateUrl = useCallback(
    (query) => {
      const search = updateURLParameter(location.search, queryProp, query);
      history.push({
        pathname: match.path,
        search: search,
      });
    },
    [history, location.search, match.path, queryProp]
  );

  const handleChange = useCallback(
    (e) => {
      clearTimeout(ref.current);
      const value = e.target.value || "";
      setQuery(value);
      ref.current = setTimeout(() => {
        updateUrl(value);
      }, 500);
    },
    [updateUrl]
  );

  const syncQuery = useCallback(() => {
    updateUrl(query);
  }, [query, updateUrl]);

  return [query, handleChange, syncQuery];
};

const useRequest = (initialState) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(initialState);

  const handle = useCallback(async (request, callback) => {
    setLoading(true);
    try {
      setError("");
      const result = await request();
      setResult(result);
      callback && callback(null, result);
    } catch (ex) {
      setError(ex.message);
      callback && callback(ex, null);
    }
    setLoading(false);
  }, []);

  return {
    error,
    loading,
    result,
    handle,
  };
};

const useEntityById = (id, url, initialState, params = "", refreshId) => {
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState("");
  const [entity, setEntity] = useState(initialState);

  useEffect(() => {
    const entityUrl = url + "/" + id + params;
    setBusy(true);
    fetchData(entityUrl)
      .then((data) => {
        setBusy(false);
        setEntity(data);
      })
      .catch((ex) => {
        setBusy(false);
        setError(ex.message);
        setEntity(initialState);
      });
  }, [id, initialState, params, url, refreshId]);

  return {
    busy,
    error,
    entity,
  };
};

const useMap = (array, key) => {
  return useMemo(() => createMap(array, key), [array, key]);
};

export {
  useAuthValidation,
  useSubheaderLogic,
  useDefaultTable,
  useSortHandler,
  usePageableEntity,
  useCurrentPageParam,
  useTableSearch,
  useSelectedList,
  useEntityById,
  useRequest,
  useMap,
};

export const useApi = (endpoint, initialState = {}) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);

  const [refresh, toggleRefresh] = useState(false);

  const refreshRequest = useCallback(() => {
    toggleRefresh((refr) => !refr);
  }, []);

  useEffect(() => {
    if (endpoint) {
      setLoading(true);
      fetchData(`${API_URL}/api${endpoint}`)
        .then((res) => {
          setState(res);
          setLoading(false);
        })
        .catch(() => {
          setState(initialState);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [endpoint, refresh]);

  return [state, loading, refreshRequest];
};

export const useRunningJob = (jobs) => {
  return useMemo(
    () =>
      jobs.find(
        (x) => x.status !== jobStatus.FINISHED && x.status !== jobStatus.ERROR
      ),
    [jobs]
  );
};

export const useRunningJobs = (jobs) => {
  return useMemo(
    () =>
      jobs.filter(
        (x) => x.status !== jobStatus.FINISHED && x.status !== jobStatus.ERROR
      ),
    [jobs]
  );
};

export const useJob = (watchJobId, updateJobLocally) => {
  const pollRef = useRef();

  const watchJob = React.useCallback(
    (jobId) => {
      pollRef.current = poll(async () => {
        const url = `${ApiEndpoint[EntityType.Job]}/${jobId}`;
        try {
          const job = await fetchData(url);
          updateJobLocally(job);
          if (
            job.status === jobStatus.FINISHED ||
            job.status === jobStatus.ERROR
          ) {
            clearInterval(pollRef.current);
          }
        } catch (e) {
          clearInterval(pollRef.current);
        }
      }, 8);
    },
    [updateJobLocally]
  );

  useEffect(() => {
    clearInterval(pollRef.current);
    if (watchJobId) {
      watchJob(watchJobId);
    }
  }, [watchJobId, watchJob]);
};

export const useJobs = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((context) => context.Job.getAll.result);

  const handleReset = useCallback(
    (job) => {
      dispatch(resetJob(job));
    },
    [dispatch]
  );
  const runningJobs = useRunningJobs(jobs);

  // handling jobs progress
  useJob(runningJobs[0]?.uuid ?? "", handleReset);
  useJob(runningJobs[1]?.uuid ?? "", handleReset);
  useJob(runningJobs[2]?.uuid ?? "", handleReset);
  useJob(runningJobs[3]?.uuid ?? "", handleReset);
  useJob(runningJobs[4]?.uuid ?? "", handleReset);
  useJob(runningJobs[5]?.uuid ?? "", handleReset);
  useJob(runningJobs[6]?.uuid ?? "", handleReset);
  useJob(runningJobs[7]?.uuid ?? "", handleReset);
  useJob(runningJobs[8]?.uuid ?? "", handleReset);
  useJob(runningJobs[9]?.uuid ?? "", handleReset);
  useJob(runningJobs[10]?.uuid ?? "", handleReset);
};
