import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { makeStyles } from "@material-ui/styles";
import BaseDialog from "../../../components/Common/BaseDialog";
import Title from "../../../components/Common/Title";
import DefaultTable from "../../../components/Common/DefaultTable";
import { API_URL } from "../../../config";
import { useDefaultTable } from "../../../helpers/hooks";
import SearchBox from "../../../components/Common/SearchBox";
import { fetchData } from "../../../helpers/utils";
import { Paging } from "reactcoregk/models";
import { Pagination } from "@material-ui/lab";
import Box from "@material-ui/core/Box";

const defaultCells = [
  {
    id: "vendor",
    numeric: false,
    label: "Vendor",
  },
];

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});
function VendorsDialog(props) {
  const classes = useStyles();

  const { selectedVendors, handleClose, open, callback, setProducts } = props;
  const [page, setPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const [vendors, setVendors] = useState(new Paging());
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (open) {
      setBusy(true);
      fetchData(`${API_URL}/api/cpes/vendors?page=${page}&size=20&s=${query}`)
        .then((data) => {
          setVendors(data);
          setBusy(false);
        })
        .catch(() => {
          setBusy(false);
        });
    }
  }, [open, page, query]);

  // const filteredNames = useFiltered(vendors.content, query, ["vendor"]);
  const sortHandler = useSortHandler();
  const {
    renderHeadCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(vendors.content, sortHandler, "vendor");

  useEffect(() => {
    setSelected(selectedVendors || []);
  }, [selectedVendors, setSelected]);

  const handleConfirm = () => {
    const item = vendors.content.find((x) => x.vendor === selected[0])
    setProducts(item?.products ?? [])
    callback(selected);
    handleClose();
  };

  const handleEntityClick = (row) => {
    setSelected([row.vendor]);
  };

  return (
    <BaseDialog
      title={"Vendors"}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      disableBackdropClick
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>{"Select Vendor"}</Title>
            </Grid>
            <Grid item>
              <SearchBox
                placeholder={"vendor"}
                handleChange={(e) => setQuery(e.target.value)}
                query={query}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DefaultTable
            rows={vendors.content}
            isLoading={busy}
            headCells={defaultCells}
            renderHeadCell={renderHeadCell}
            renderDefaultBody={renderDefaultBody}
            onRowClick={handleEntityClick}
          />
        </Grid>
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setPage(newPage - 1)}
            page={page + 1}
            count={vendors.totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
      </Grid>
    </BaseDialog>
  );
}

export default VendorsDialog;
