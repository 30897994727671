import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import {
  createProduct,
  updateProduct,
  deleteProduct,
  getAllProducts,
} from "../../store/product/actions";
import Menu from "./Menu";
import {
  useEntityProgress,
  useSortHandler,
} from "reactcoregk/hooks";
import { useCurrentPageParam, usePageableEntity } from "../../helpers/hooks";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Pagination } from "@material-ui/lab";
import ProductsTable from "./ProductsTable";
import { AddProductDialog } from "./AddProductDialog";

const defaultCells = [
  {
    id: "vendor",
    disablePadding: false,
    label: "Vendor",
    default: true,
    isSortable: true,
  },
  {
    id: "products",
    disablePadding: false,
    label: "Products",
    default: true,
  },
  {
    id: "icon",
    disablePadding: false,
    label: "Icon",
    default: true,
  },
];

function Products(props) {
  const { context, getAllProducts } = props;
  const [currentPage, setCurrentPage] = useCurrentPageParam();
  const [openAdd, setOpenAdd] = useState(false);
  const sortHandler = useSortHandler();

  // Refresh table after a successful post
  useEntityProgress(context.Product, () => getAllProducts());

  const { list, totalPages, isLoading } = usePageableEntity(
    context.Product,
    getAllProducts,
    ""
  );

  const products = useMemo(() => {
    return list.map((item) => {
      return {
        ...item,
        products: item?.products ? item.products.join(", ") : "N/A",
      };
    });
  }, [list]);

  const MenuItemsComponent = (
    <Menu handleAddNew={()=>setOpenAdd(true)} />
  );

  const handleRefresh = useCallback(()=>{
    getAllProducts(`?page=${currentPage-1}&size=20`)
  }, [currentPage, getAllProducts])

  return (
    <SubmenuLayout
      MenuItemsComponent={MenuItemsComponent}
      title={"Products"}
      noDivider
    >
      <Container maxWidth={false}>
        <ProductsTable
          headCells={defaultCells}
          isLoading={isLoading}
          products={products}
          sortHandler={sortHandler}
        />
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage - 1)}
            page={currentPage}
            count={totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
      </Container>
      <AddProductDialog
        open={openAdd}
        handleClose={() => setOpenAdd(false)}
        onAdd={handleRefresh}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {
  getAllProducts,
  createProduct,
  updateProduct,
  deleteProduct,
})(Products);
