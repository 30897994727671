import React, { useCallback } from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { HiveIcons } from "../../assets/icons";
import SearchBox from "../../components/Common/SearchBox";
import { useTableSearch } from "../../helpers/hooks";
import FilterBase from "components/Common/FilterBase";
import TableFilters from "components/Common/TableFilters";
import {
  ALERT_CATEGORY,
  THREAT_LEVEL_OPTIONS,
  YES_NO_OPTIONS,
} from "constants/enums";
import { getParameterByName, updateURLParameter } from "helpers/utils";
import { TextField } from "@material-ui/core";

function LastUpdatedDate({ locationSearch, setLocationSearch }) {
  const handleChange = useCallback(
    (value) => {
      const safeValue = value.target.value.toString();
      const searchDate = updateURLParameter(
        locationSearch,
        "lastUpdatedAt",
        safeValue
      );
      setLocationSearch(searchDate);
    },
    [locationSearch, setLocationSearch]
  );

  return (
    <TextField
      variant="standard"
      margin={"normal"}
      type="date"
      fullWidth
      sx={{ width: "300px" }}
      value={getParameterByName("lastUpdatedAt", locationSearch) || ""}
      onChange={handleChange}
      label={"Last Updated Date"}
      color={"primary"}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

const filterOptions = [
  {
    label: "Threat Level",
    value: "threatLevel",
    options: THREAT_LEVEL_OPTIONS,
  },
  {
    label: "Threat Category",
    value: "threatCategory",
    options: ALERT_CATEGORY,
  },
  {
    customEl: (locationSearch, setLocationSearch) => (
      <LastUpdatedDate
        locationSearch={locationSearch}
        setLocationSearch={setLocationSearch}
      />
    ),
  },
  {
    label: "CISA",
    value: "cisaAvailability",
    options: YES_NO_OPTIONS,
  },
];

const AlertSubheader = (props) => {
  const [query, handleChange, syncQuery] = useTableSearch();

  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },
    {
      customEl: (
        <FilterBase>
          <TableFilters filterOptions={filterOptions} />
        </FilterBase>
      ),
    },
    {
      divider: true,
    },
    {
      title: "New alert",
      icon: <HiveIcons.AddOutlined />,
      onClick: props.handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default AlertSubheader;
