import React, { useCallback, useState } from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import {
  useEntityProgress,
  useMasterDetailWithDialog,
  useSortHandler,
} from "reactcoregk/hooks";
import DefinitionsTable from "./definitions-table";
import Subheader from "./subheader";
import {
  useCurrentPageParam,
  useDefaultTable,
  usePageableEntity,
} from "../../helpers/hooks";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import UploadCveDialog from "../Dashboard/UploadCveDialog";
import {
  createCveDefinition,
  getAllCveDefinitions,
  updateCveDefinition,
} from "../../store/cveDefinition/actions";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import EditDialog from "./EditDialog";

const defaultCells = [
  {
    id: "cve",
    numeric: false,
    label: "CVE",
  },
  {
    id: "publishedDate",
    numeric: false,
    label: "Published At",
  },
  {
    id: "lastModifiedDate",
    numeric: false,
    label: "Last Update",
  },
];

function CveDefinitions(props) {
  const {
    context,
    getAllCveDefinitions,
    createCveDefinition,
    updateCveDefinition,
  } = props;

  // Handlers for subheader controls
  // const { query, handleChangeQuery } = useSubheaderLogic();
  const rootParams = props.location.search.replace("?", "&");
  const [currentPage, setCurrentPage] = useCurrentPageParam();

  // Handlers for new or existing entity to show in dialog
  const [openUpload, setOpenUpload] = useState(false);

  const {
    openModal,
    handleCloseModal,
    entity,
    handleEntityClick,
  } = useMasterDetailWithDialog(Schema[EntityType.CveDefinition]);

  // Sort handler used by default table
  const sortHandler = useSortHandler();

  // Callback to trigger on useDependency hook
  const initializeDependency = useCallback(() => {
    getAllCveDefinitions("?page=0&size=20");
  }, [getAllCveDefinitions]);

  // Refresh table after a successful post
  useEntityProgress(context.CveDefinition, initializeDependency);

  // Get state for current entity, and handler to manage paging
  const { list, totalPages, isLoading } = usePageableEntity(
    context.CveDefinition,
    getAllCveDefinitions,
    rootParams
  );

  // Init default table
  const defaultTable = useDefaultTable(list, sortHandler, "cve");

  // Create the subheader options
  const CveMenu = (
    <Subheader
      handleCreateNew={() => setOpenUpload(true)}
      selected={defaultTable.selected}
    />
  );

  return (
    <SubmenuLayout
      noDivider
      MenuItemsComponent={CveMenu}
      title={"CVE Definitions"}
    >
      <Container maxWidth={false}>
        <DefinitionsTable
          headCells={defaultCells}
          isLoading={isLoading}
          definitions={list}
          defaultTable={defaultTable}
          sortHandler={sortHandler}
          handleEntityClick={handleEntityClick}
        />
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage - 1)}
            page={currentPage}
            count={totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
      </Container>
      <UploadCveDialog
        open={openUpload}
        context={context.CveDefinition}
        entityType={EntityType.CveDefinition}
        handleClose={() => setOpenUpload(false)}
        createEntity={createCveDefinition}
      />
      <EditDialog
        entity={entity}
        context={context.CveDefinition}
        updateEntity={updateCveDefinition}
        open={openModal}
        entityType={EntityType.CveDefinition}
        handleClose={handleCloseModal}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      CveDefinition: state.CveDefinition,
    },
  };
};
export default connect(mapStateToProps, {
  getAllCveDefinitions,
  createCveDefinition,
  updateCveDefinition,
})(CveDefinitions);
