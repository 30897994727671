import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "reactcoregk/store/saga";
import {ApiEndpoint} from "../@core/endpoint";
import {EntityType} from "../@core/entityType";
import {fetchData, updateData} from "../../helpers/utils";
import {ActionStates, buildAsyncActionType} from "reactcoregk/store/actions";
import {getAllTechniqueActions, updateTechniqueActions} from "./actions";
import {createMitigations, deleteMitigations, updateMitigations} from "./api";
import {compareProp} from "reactcoregk/utils";

const coreSagas = getCoreSagas(entityType, ApiEndpoint, entityOperations);

function* getAll() {
  try {
    const url1 = ApiEndpoint[EntityType.Technique]
    const url2 = ApiEndpoint[EntityType.Tactic]
    const url3 = ApiEndpoint[EntityType.SubTechnique]
    const techniques = (yield call(fetchData, url1)).sort((a, b) => compareProp(a, b, "name"));
    const tactics = (yield call(fetchData, url2)).sort((a, b) => compareProp(a, b, "name"));
    const subTechniques = (yield call(fetchData, url3)).sort((a, b) => compareProp(a, b, "name"));
    const result = {tactics, techniques, subTechniques}
    yield put(getAllTechniqueActions.success(result));
  } catch (error) {
    yield put(getAllTechniqueActions.failure(error.message));
  }
}

function* update({payload}) {
  try {
    const url = ApiEndpoint[EntityType.Technique]
    const res = yield call(updateData, url, payload);
    const { mitigations} = payload
    const mitigationsToDelete = mitigations.filter(x => x.shouldDelete && x.id)
    const mitigationsToUpdate = mitigations.filter(x => x.shouldUpdate && !x.shouldDelete && x.id)
    const mitigationsToAdd = mitigations.filter(x => !x.id)

    yield call(deleteMitigations, payload.id, mitigationsToDelete)
    yield call(updateMitigations, payload.id, mitigationsToUpdate)
    yield call(createMitigations, payload.id, mitigationsToAdd)

    yield put(updateTechniqueActions.success(res));
  } catch (error) {
    yield put(updateTechniqueActions.failure(error.message));
  }
}

export function* watchGetAll() {
  const actionType = buildAsyncActionType(entityType, "GET_ALL", ActionStates.request);
  yield takeEvery(actionType, getAll);
}

export function* watchUpdate() {
  const actionType = buildAsyncActionType(entityType, "UPDATE", ActionStates.request);
  yield takeEvery(actionType, update);
}

function* sagas() {
  coreSagas.push(fork(watchGetAll))
  coreSagas.push(fork(watchUpdate))
  yield all(coreSagas);
}

export default sagas;
