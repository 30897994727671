import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import RulesSubheader from "./RulesSubheader";
import VulnerabilityClassificationRules from "./Vulnerability";

const TAB_OPTIONS = [
  {
    label: "Vulnerability",
    getComponent: () => <VulnerabilityClassificationRules />,
  },
];

function Rules(props) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = useCallback((_, newTab) => {
    setActiveTab(newTab);
  }, []);

  const RulesMenu = (
    <RulesSubheader
      activeTab={activeTab}
      handleChangeTab={handleChangeTab}
      options={TAB_OPTIONS}
    />
  );
  return (
    <SubmenuLayout noDivider MenuItemsComponent={RulesMenu}>
      {TAB_OPTIONS[activeTab].getComponent()}
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {})(Rules);
