import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Avatar from "@material-ui/core/Avatar";
import { getUsername } from "../../helpers/utils";
import { Box, Button } from "@material-ui/core";
import { postLogout } from "../../store/auth/login/api";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

function UserIcon() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleLogout = () => {
    postLogout();
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        component={Button}
        onClick={handleClick}
        style={{ display: "flex", alignItems: "center", marginLeft: 4 }}
      >
        <Avatar style={{ width: 30, height: 30, marginRight: 4 }}>
          {getUsername().charAt(0).toUpperCase()}
        </Avatar>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClick}
        style={{ marginTop: "40px" }}
      >
        <MenuItem
          component={Link}
          to={"/change-password"}
          onClick={() => setAnchorEl(null)}
        >
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default UserIcon
