import React, { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import { AUTH_ENABLED } from "../config";
import { Auth } from "../models/Auth";
import { authProtectedRoutes } from "./index";

const AppRoute = ({
  path: Path,
  component: Component,
  layout: Layout,
  submenu: SubMenu,
  back,
  title,
  isAuthProtected,
  websocketRef,
  authorities,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && AUTH_ENABLED && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/sign-in", state: { from: props.location } }}
          />
        );
      }

      const userRoles = Auth.getRoles();
      if (authorities && authorities.length !== 0) {
        const isAllowed = authorities.some((r) => userRoles.includes(r));
        if (!isAllowed) {

          const firstAllowed = authProtectedRoutes
            .filter((x) => x.path !== "/")
            .find((x) => x.authorities.some((r) => userRoles.includes(r)));

          const pathname = firstAllowed?.path || "/not-found";

          return (
            <Redirect to={{ pathname, state: { from: props.location } }} />
          );
        }
      }

      const Wrapper = Layout || Fragment;

      return (
        <Wrapper websocketRef={websocketRef} title={title}>
          <Component {...props} />
        </Wrapper>
      );
    }}
  />
);

export default AppRoute;
