import React from "react";
import PropTypes from "prop-types";

import * as colors from "../../constants/colors";

const Background = (props) => {
  // TODO: add bg image implementation
  // TODO: get background color from palette
  return (
    <div
      className={"background-container"}
      style={{
        backgroundColor: props.color,
        backgroundImage: `url(${props.image})`,
        backgroundSize:'cover',
        height: "100vh",
        overflow: "auto",
      }}
    >
      {props.children}
    </div>
  );
};

export default Background;

Background.defaultProps = {
  color: colors.backgroundDark.paper,
};

Background.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
};
