import {CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  loading: false
};

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
        loading: true
      };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
        loading: false
      };
      break;
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default changePassword;
