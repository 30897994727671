import React, { useCallback } from "react";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  useEntityProgress,
  useMasterDetailWithDialog,
  useSortHandler,
} from "reactcoregk/hooks";
import Subheader from "./subheader";
import { useCurrentPageParam, usePageableEntity } from "../../helpers/hooks";
import PatchesTable from "./patches-table";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import {
  createPatch,
  getAllPatches,
  updatePatch,
} from "../../store/patch/actions";
import EditDialog from "./EditDialog";
import UploadPatchDialog from "./UploadPatchDialog";

const defaultCells = [
  {
    id: "bulletinId",
    numeric: false,
    label: "Bulletin ID",
  },
  {
    id: "cve",
    numerice: false,
    label: "Cve",
  },
  {
    id: "name",
    numeric: false,
    label: "Name",
  },
  {
    id: "description",
    numeric: false,
    label: "Description",
  },
  {
    id: "type",
    numeric: false,
    label: "Type",
  },
];

function PatchIntelligence(props) {
  const dispatch = useDispatch();

  const patch = useSelector((state) => state.Patch);

  // Handlers for subheader controls
  const rootParams = props.location.search.replace("?", "&");
  const [currentPage, setCurrentPage] = useCurrentPageParam( );

  // Handlers for new or existing entity to show in dialog
  const {
    openModal: openNewPatchModal,
    handleCloseModal: handleCloseNewPatchModal,
    handleCreateNew: handleCreateNewPatch,
    entity,
  } = useMasterDetailWithDialog(Schema[EntityType.Patch]);

  const {
    openModal: openUploadModal,
    handleCloseModal: handleCloseUploadModal,
    handleCreateNew: handleCreateAddPatch,
  } = useMasterDetailWithDialog(Schema[EntityType.Patch]);

  // Create the subheader options
  const PatchMenu = (
    <Subheader
      handleAddPatch={handleCreateAddPatch}
      handleCreateNew={handleCreateNewPatch}
      {...props}
    />
  );

  // Sort handler used by default table
  const sortHandler = useSortHandler();

  const fetchPatches = useCallback(
    (params) => {
      dispatch(getAllPatches(params));
    },
    [dispatch]
  );

  // Callback to trigger on useDependency hook
  const initializeDependency = useCallback(() => {
    fetchPatches("?page=0&size=20");
  }, [fetchPatches]);

  // Refresh table after a successful post
  useEntityProgress(patch, initializeDependency);

  // Get state for current entity, and handler to manage paging
  const { list, totalPages, isLoading } = usePageableEntity(
    patch,
    fetchPatches,
    rootParams
  );

  return (
    <SubmenuLayout MenuItemsComponent={PatchMenu} title={"Patches"}>
      <Container maxWidth={false}>
        <PatchesTable
          headCells={defaultCells}
          isLoading={isLoading}
          patches={list}
          handleEntityClick={(entity) => {
            props.history.push(`/patches/${entity.surrogateId}`);
          }}
          sortHandler={sortHandler}
        />
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage - 1)}
            page={currentPage}
            count={totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
      </Container>
      <EditDialog
        context={patch}
        open={openNewPatchModal}
        handleClose={handleCloseNewPatchModal}
        entity={entity}
        createEntity={createPatch}
        updateEntity={updatePatch}
        entityType={EntityType.Patch}
      />
      <UploadPatchDialog
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        fetchPatches={fetchPatches}
      />
    </SubmenuLayout>
  );
}
export default PatchIntelligence;
