import { entityType } from "./module";
import {
    createDeleteMethod,
    createGetAllPageableMethod,
    createGetMethod,
    createPostMethod,
    createPutMethod
} from "reactcoregk/store/actions";
import { getDefaultApiHandler } from "../../helpers/utils";

export const getAllSupportTickets = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler())
export const createSupportTicket = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateSupportTicket = (payload) => createPutMethod(entityType, payload, { ...getDefaultApiHandler(), method: "POST" })
export const getSupportTicket = (params) => createGetMethod(entityType, params, getDefaultApiHandler())
export const deleteSupportTicket = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())

