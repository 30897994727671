import React from "react";
import DefaultTable from "../../components/Common/DefaultTable";
import { useHistory } from 'react-router'

function ActorsTable(props) {
  const { actors, headCells, isLoading, defaultTable } = props;
  const history = useHistory()

  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
  } = defaultTable;

  return (
    <>
      <DefaultTable
        headCells={headCells}
        renderCheckAllCell={renderCheckAllCell}
        renderDefaultBody={renderDefaultBody}
        renderHeadCell={renderHeadCell}
        rows={actors}
        onRowClick={row => {
          history.push(`/actors/${row.actorId}`)
        }}
        isLoading={isLoading}
        selectableRows
      />
    </>
  );
}

export default ActorsTable;
