import Entity from "./Entity";

class Alert extends Entity {
  constructor() {
    super();
    this.id = null;
    this.name = "";
    this.shortName = "";
    this.description = "";
    this.threatLevel = "";
    this.cves = [];
    this.ioc = "";
    this.category = "";
    this.hasPdfFile = false;
    this.actorIds = [];
    this.mitreTacticIds = [];
    this.mitreTechniqueIds = [];
    this.mitreSubtechniqueIds = [];
    this.targetedLocations = [];
    this.targetedIndustries = [];
    this.attackIds = [];
    this.attackCollectionIds = [];
    this.recentBreaches = [];
    this.references = [];
    this.patchLinks = [];
    this.tenablePlugins = [];
    this.cisaAvailability = false;
    this.cisaId = "";
    this.recommendations = "";
    this.insights = [""];
    this.attacksExecuted = "";
  }
}
export default Alert;
