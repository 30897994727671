import React, {useEffect, useState} from "react";
import SubmenuLayout from "../../../components/Layouts/SubmenuLayout";
import Container from "@material-ui/core/Container";
import {connect, useDispatch} from "react-redux";
import {deleteData, downloadFile, unwrap} from "../../../helpers/utils";
import Grid from "@material-ui/core/Grid";
import SectionGroupDescription from "../../../components/SectionGroupDescription";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {Button, Collapse, IconButton, Typography} from "@material-ui/core";
import {ChevronLeft, Save} from "@material-ui/icons";
import {HiveIcons} from "../../../assets/icons";
import {useEntityProgress, useForm, useProgress} from "reactcoregk/hooks";
import Patch from "../../../models/Patch";
import {clearPatch, deletePatch, getPatch, updatePatch,} from "../../../store/patch/actions";
import DetailsIcon from "@material-ui/icons/Details";
import UploadDialog from "./UploadDialog";
import FileCard from "../../../components/Common/FileCard";
import {API_URL} from "../../../config";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import BusyIndicator from "../../../components/Common/BusyIndicator";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import {Link} from "react-router-dom";
import PatchForm from "./../PatchForm";

const useStyles = makeStyles({
  paper: {
    padding: 16,
  },
});

const PatchDetails = (props) => {
  const classes = useStyles();
  const {
    context,
    getPatch,
    updatePatch,
    clearPatch,
    deletePatch,
    history,
  } = props;
  const id = props.match.params.id;
  const [form, handleChange, setForm] = useForm(new Patch());
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [busy, error] = useEntityProgress(context.Patch);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [openScript, setOpenScript] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { handleDownloadFile, handleDeleteFile, busyFiles } = useFiles(id);

  useEffect(() => setErrorMessage(error), [error]);
  const patch = context.Patch.get.result;
  const isLoading = context.Patch.get.isLoading;

  const patchDelete = context.Patch.delete;

  useProgress(null, () => history.push("/patches"), null, patchDelete);

  useEffect(() => {
    getPatch(id);
    return () => {
      clearPatch();
    };
  }, [getPatch, id, clearPatch]);

  useEffect(() => {
    if (patch.id) setForm(patch);
  }, [patch, setForm]);

  //   ELENXOS PRIN TO SAVE
  const handleSave = (form) => {
    // handlePatchPreSubmit(form, setErrorMessage, updatePatch);
    updatePatch(form)
  };

  return (
    <SubmenuLayout
      errorMessage={error?.message}
      title={unwrap(patch, "label") || "Patch"}
    >
      <BusyIndicator busy={isLoading} />
      <Container maxWidth={false}>
        <Box mt={2} />
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item>
            <IconButton component={Link} to={"/patches"} size={"small"}>
              <ChevronLeft color={"primary"} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography color={"textPrimary"} style={{ fontWeight: "bold" }}>
              Patch Details
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenDelete(true)}
              style={{ color: "tomato" }}
            >
              Delete Patch
            </Button>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Collapse in={!isLoading && !patch}>
          <Alert severity="error" style={{ marginBottom: 16 }}>
            <AlertTitle>Error</AlertTitle>
            Could not found patch with id <strong>{id}</strong>
          </Alert>
        </Collapse>
        <Collapse in={errorMessage || error}>
          <Alert
            severity="error"
            onClose={() => setErrorMessage(null)}
            style={{ marginBottom: 16 }}
          >
            {errorMessage || error}
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12}>
            <Paper variant={"outlined"} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item style={{ flex: 1 }}>
                  <SectionGroupDescription
                    title={"Details"}
                    IconComponent={DetailsIcon}
                  />
                </Grid>
                <Grid item>
                  <Button
                    style={{ marginLeft: "auto" }}
                    disabled={busy || !patch}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={() => handleSave(form)}
                  >
                    <Save />
                    Update info
                  </Button>
                </Grid>
              </Grid>

              <Box my={3} />
              <PatchForm
                form={form}
                setForm={setForm}
                handleChange={handleChange}
                busy={busy || isLoading}
              />
            </Paper>
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
          >
            <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <Paper variant={"outlined"} className={classes.paper}>
                    <Grid container>
                      <Grid item style={{ flex: 1 }}>
                        <SectionGroupDescription
                          title={"Script"}
                          IconComponent={FolderOpenIcon}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={busy || !patch || isLoading || patch?.script}
                          color={"primary"}
                          variant={"outlined"}
                          onClick={() => setOpenScript(true)}
                        >
                          <HiveIcons.AddOutlined />
                          Add Script
                        </Button>
                      </Grid>
                    </Grid>
                    {patch?.script && (
                      <Grid container>
                        <Grid item sm={4}>
                          <FileCard
                            file="Script"
                            busy={busyFiles["script"]}
                            handleDelete={() => handleDeleteFile("script")}
                            handleDownload={() => handleDownloadFile("script")}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid>

                <Grid item>
                  <Paper variant={"outlined"} className={classes.paper}>
                    <Grid container>
                      <Grid item style={{ flex: 1 }}>
                        <SectionGroupDescription
                          title={"Attachment"}
                          IconComponent={FolderOpenIcon}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={
                            busy || !patch || isLoading || patch?.attachment
                          }
                          color={"primary"}
                          variant={"outlined"}
                          onClick={() => setOpenAttachment(true)}
                        >
                          <HiveIcons.AddOutlined />
                          Add Attachment
                        </Button>
                      </Grid>
                    </Grid>
                    {patch?.attachmentAvailable && (
                      <Grid container>
                        <Grid item sm={4}>
                          <FileCard
                            file="Attachment"
                            busy={busyFiles["attachment"]}
                            handleDelete={() => handleDeleteFile("attachment")}
                            handleDownload={() => handleDownloadFile("attachment")}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Container>
      <UploadDialog
        open={openScript}
        handleClose={() => setOpenScript(false)}
        entity={patch}
        getPatch={getPatch}
        accept={".yaml,.json,.txt"}
        type={"script"}
      />
      <UploadDialog
        open={openAttachment}
        handleClose={() => setOpenAttachment(false)}
        entity={patch}
        getPatch={getPatch}
        accept={""}
        type={"attachment"}
      />
      <DeleteConfirmation
        label={"Patch"}
        open={openDelete}
        handleDelete={() => deletePatch(patch)}
        busy={patchDelete.isLoading}
        errorMessage={patchDelete.error}
        handleClose={() => setOpenDelete(false)}
      />
    </SubmenuLayout>
  );
};

const mapStatetoProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStatetoProps, {
  getPatch,
  updatePatch,
  clearPatch,
  deletePatch,
})(PatchDetails);

const useFiles = (id) => {
  const dispatch = useDispatch();
  const [busyFiles, setBusyFiles] = useState({});

  const toggleLoader = (path, bool) => {
    setBusyFiles((prevState) => ({
      ...prevState,
      [path]: bool,
    }));
  };

  const handleDownloadFile = (type) => {
    toggleLoader(type, true);
    downloadFile(`${API_URL}/api/patches/${id}/${type}`, type, "txt")
      .then(() => toggleLoader(type, false))
      .catch((e) => toggleLoader(type, false));
  };

  const handleDeleteFile = (type) => {
    toggleLoader(type, true);
    deleteData(`${API_URL}/api/patches/${id}/${type}`)
      .then(() => {
        dispatch(getPatch(id));
        toggleLoader(type, false);
      })
      .catch((e) => toggleLoader(type, false));
  };

  return {
    busyFiles,
    handleDeleteFile,
    handleDownloadFile,
  };
};
