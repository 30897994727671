import { Box, Collapse, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BaseDialog from 'components/Common/BaseDialog';
import Input from 'components/Inputs/Input';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useContext } from 'react';
import { useCallback } from 'react';
import { useForm } from 'reactcoregk/hooks';
import { VulnerabilityRulesContext } from '../Vulnerability';
import { postData } from "../../../helpers/utils";
import { API_URL } from "../../../config";

const AddCategoryDialog = ({ isOpen, handleClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { refreshCategories } = useContext(VulnerabilityRulesContext)
    const [errors, setErrors] = useState("")
    const [form, handleChange] = useForm({
        name: "",
        description: ""
    })

    const handleSaveCategory = useCallback(async () => {
        if (!form.name) {
            return setErrors("Name is required")
        }
        if (!form.description) {
            return setErrors("Description is required")
        }
        try {
            await postData(`${API_URL}/api/classifications/categories`, form)
            enqueueSnackbar('New Category created successfully', { variant: 'success' })
            refreshCategories()
            handleClose()
        } catch (err) {
            if (err) {
                enqueueSnackbar(err.message, { variant: 'error' })
            }
        }
    }, [form, enqueueSnackbar, refreshCategories, handleClose])

    return (
        <BaseDialog onClose={handleClose} open={isOpen} zIndex={10000} title='Add New Category' maxWidth='sm' maxContentHeight='70vh' fullWidth primaryAction={handleSaveCategory} primaryActionTitle='Add New Category'>
            {/* Header */}
            <Box display='flex' flexDirection='column' gridRowGap={28}>
                <Typography variant='h5'>Enter details of new category</Typography>

                {/* Category Error */}
                <Collapse in={errors}>
                    <Alert
                        severity="error"
                        style={{ marginBottom: 16 }}
                    >
                        {errors}
                    </Alert>
                </Collapse>


                {/* Description */}
                <Box display='flex' flexDirection='column' gridRowGap='2rem'>
                    <Box>
                        <Typography variant='subtitle2'>Name</Typography>
                        <Input placeholder='Enter Name' onChange={handleChange('name')} value={form.name} />
                    </Box>
                    <Box>
                        <Typography variant='subtitle2'>Add Description</Typography>
                        <Input multiline rows={4} fullWidth placeholder='Enter your Description here...' onChange={handleChange('description')} value={form.description} />
                    </Box>

                </Box>
            </Box>
        </BaseDialog>
    )
}

export default AddCategoryDialog