import {entityType} from "./module";
import {createAsyncAction, createGetAllPageableMethod, createPutMethod} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";
import {API_URL} from "../../config";

const getDefaultApiHandler = () =>
    new ApiHandler(false, false, getDefaultHeaders());

const getPutApiHandler = () => {
    return new ApiHandler(
        false,
        false,
        getDefaultHeaders(),
        "default",
        "",
        `${API_URL}/api/cverisk/threats`
    );
}

export const createCveDefinitionActions = createAsyncAction(entityType, "UPLOAD_CVE")

export const createCveDefinition = (payload) => createCveDefinitionActions.request(payload)
export const updateCveDefinition = (payload) => createPutMethod(entityType, payload, getPutApiHandler())
export const getAllCveDefinitions = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler())
