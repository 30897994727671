import React, { useMemo, useState } from "react";
import BaseDialog from "components/Common/BaseDialog";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { cloneDeep } from "lodash";
import { HiveIcons } from "assets/icons";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
import { RemoveCircleOutline } from "@material-ui/icons";

const AddEditIocDialog = ({
  alertId,
  formIocs = [],
  handleUpdateIoc,
  onClose,
  isEdit = true,
  ...rest
}) => {
  const [iocs, setIocs] = useState(formIocs);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const memoizedIocsView = useMemo(() => {
    return iocs?.map((ioc, index) => {
      return (
        <Grid item container spacing={1} key={index}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="iocType"
              variant="standard"
              fullWidth
              margin="normal"
              placeholder={"Enter IOC Type"}
              value={ioc.iocType}
              onChange={(e) => {
                handleUpdateIOCS(e, index, "iocType");
              }}
              {...(index === 0 ? { label: `Ioc Type` } : {})}
              style={{
                padding: 5,
              }}
            />
            <TextField
              id="iocValue"
              fullWidth
              variant="standard"
              margin="normal"
              placeholder={"Enter IOC Value"}
              value={ioc.iocValue}
              onChange={(e) => {
                handleUpdateIOCS(e, index, "iocValue");
              }}
              {...(index === 0 ? { label: `Ioc Value` } : {})}
              style={{
                padding: 5,
              }}
            />
            {!isEdit && (
              <IconButton
                onClick={() => {
                  handleRemoveIOCs(index);
                }}
              >
                <RemoveCircleOutline color={"error"} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      );
    });
  }, [iocs]);

  const handleUpdateIOCS = (e, index, field) => {
    const clonedIoc = cloneDeep(iocs);
    clonedIoc[index] = {
      ...clonedIoc[index],
      [field]: e.target.value,
      modified: true,
    };
    setIocs(clonedIoc);
  };

  const handleAddIocs = () => {
    const clonedIoc = cloneDeep(iocs);
    clonedIoc.splice(0, 0, {
      ...clonedIoc[0],
      iocType: "",
      iocValue: "",
      uuid: undefined,
    });
    setIocs(clonedIoc);
  };

  const isValid = useMemo(() => {
    let isValid = true;
    if (iocs && iocs.length > 0) {
      for (let index = 0; index < iocs.length; index++) {
        if (isValid) {
          if (
            iocs[index].iocType.length === 0 ||
            iocs[index].iocValue.length === 0
          ) {
            isValid = false;
            break;
          }
        }
      }
    }
    return isValid;
  }, [iocs]);

  const handleRemoveIOCs = (index) => {
    const clonedIoc = cloneDeep(iocs);
    if (clonedIoc.length > 1) {
      clonedIoc.splice(index, 1);
      setIocs(clonedIoc);
    }
  };

  return (
    <>
      <BaseDialog
        {...rest}
        isValid={isValid}
        primaryAction={() => {
          setOpenConfirmation(true);
        }}
        onClose={onClose}
      >
        <Grid item style={{ marginTop: "1rem" }}>
          <Grid container>
            <Grid item style={{ flex: 1, overflowY: "visible" }}>
              <Typography>IOCs</Typography>
              <Grid item>{memoizedIocsView}</Grid>
            </Grid>
            {!isEdit && (
              <Grid item>
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() => {
                    handleAddIocs();
                  }}
                >
                  <HiveIcons.AddOutlined />
                  Add New IOCs
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </BaseDialog>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        title={isEdit ? "Edit IOCs" : "Add IOCs"}
        description={
          isEdit
            ? "Are you sure you want to update these IOCs?"
            : "Are you sure you want to add these IOCs?"
        }
        primaryActionTitle={isEdit ? "Update" : "Add"}
        secondaryActionTitle={"Cancel"}
        primaryAction={() => {
          setOpenConfirmation(false);
          handleUpdateIoc(iocs, []);
          onClose();
        }}
        secondaryAction={() => setOpenConfirmation(false)}
      />
    </>
  );
};

export default AddEditIocDialog;
