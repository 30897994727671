class User {
    constructor() {
        this.id = null;
        this.username = "";
        this.roleIds = [];
        this.firstName = ""
        this.lastName = ""
        this.email = "";
        this.enabled = true;
    }
    initFullUser(object) {
        this.id = object.id;
        this.username = object.username;
        this.firstName = object.firstname;
        this.lastName = object.lastName
        this.email = object.email;
        this.roleIds = object.authorities;
        this.enabled = true;
    }
}
export default User
