import {entityType} from "./module";
import {getCommonState, handleCommonState} from "reactcoregk/store/reducer";
import * as ActionTypes from "reactcoregk/store/actionTypes";
const initialState = getCommonState();


const Country = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0]

  if (actionEntity !== entityType) return state
  const actionType = action.type.split(".")[1]

  switch (actionType) {
    case ActionTypes.CUSTOM:
      switch (action.payload.actionType) {
        case "UPDATE_JOB":
          return {
            ...state,
            getAll: {
              ...state.getAll,
              result: state.getAll.result.map((job) => {
                if (job.uuid === action.payload.result.uuid) {
                  return action.payload.result;
                }
                return job;
              }),
            },
          };
        default:
          break;
      }
      break;
    default:
      break;
  }

  return  handleCommonState(state, actionType, action, "uuid");
};

export default Country;
