import { Box, Button, Card, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useCallback, useState, createContext } from "react";
import useStatusChangeDialogs from "../hooks/useStatusChangeDialogs";
import AddRuleDialog from "./AddRuleDialog";
import RuleDetailsDialog from "./RuleDetailsDialog";
import RuleOverview from "./RuleOverview";
import LoaderSVG from "assets/spinners/general.svg";
import { Skeleton } from "@material-ui/lab";
import EditRuleDialog from "./EditRuleDialog";
import { useVulnerabilityClassificationRules } from "../hooks/useVulnerabilityClassificationRules";
import { useApi } from "../../../helpers/hooks";
import { makeStyles } from "@material-ui/core/styles";

export const VulnerabilityRulesContext = createContext({});
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.type === "dark" ? "#212121" : "#E5E6E9",
  },
}));

const VulnerabilityClassificationRules = () => {
  const classes = useStyles();
  const [rules, isLoading, refreshRules] = useApi(
    "/classifications/rules",
    []
  );
  const [showRuleDetailsDialog, setShowRuleDetailsDialog] = useState(false);
  const [showAddRuleDialog, setShowAddRuleDialog] = useState(false);
  const [showEditRuleDialog, setShowEditRuleDialog] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState("");

  const handleRefreshRules = useCallback(() => {
    setShowEditRuleDialog(false);
    setShowAddRuleDialog(false);
    setSelectedRuleId("");
    setShowRuleDetailsDialog(false);
    refreshRules();
  }, [refreshRules]);

  const ruleHookProps = useVulnerabilityClassificationRules(selectedRuleId);

  const {
    UpdateStatusDialogs,
    handleShowApplyDialog,
    handleShowDisableDialog,
    handleShowDeleteDialog,
  } = useStatusChangeDialogs(ruleHookProps.rule, handleRefreshRules);

  const Loader = () => <Skeleton height="10rem" width="100%" />;

  const handleShowEditRuleDialog = useCallback(() => {
    setShowEditRuleDialog(true);
  }, []);

  const handleCloseEditRuleDialog = useCallback(() => {
    setShowEditRuleDialog(false);
  }, []);

  const handleShowRuleDetailsDialog = useCallback((ruleId) => {
    setSelectedRuleId(ruleId);
    setShowRuleDetailsDialog(true);
  }, []);

  const handleCloseRuleDetailsDialog = useCallback(() => {
    setShowRuleDetailsDialog(false);
    setSelectedRuleId("");
  }, []);

  const handleClearSelectedRule = useCallback(() => {
    setSelectedRuleId("");
  }, []);

  return (
    <VulnerabilityRulesContext.Provider
      value={{
        handleShowEditRuleDialog,
        handleCloseEditRuleDialog,
        handleClearSelectedRule,
        setSelectedRuleId,
        selectedRuleId,
        handleRefreshRules,
        ...ruleHookProps,
      }}
    >
      {/* Default Rules */}
      <Box padding={2}>
        <Box
          item
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">Default Rules</Typography>
          <Button
            onClick={() => setShowAddRuleDialog(true)}
            size="small"
            style={{ borderRadius: "0.15rem" }}
            variant="outlined"
            color="primary"
          >
            <Add fontSize="small" />
            <span>Add Rule</span>
          </Button>
        </Box>
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            marginTop={2}
            display="flex"
            flexDirection="column"
            gridRowGap={8}
          >
            {rules
              .map((rule) => (
                <RuleOverview
                  handleShowApplyDialog={handleShowApplyDialog}
                  handleShowDisableDialog={handleShowDisableDialog}
                  handleShowDeleteDialog={handleShowDeleteDialog}
                  rule={rule}
                  onClick={() => handleShowRuleDetailsDialog(rule.uuid)}
                />
              ))}
            {rules.length === 0 && (
              <Card className={classes.card}>
                <Box
                  padding={2}
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gridRowGap="1rem"
                >
                  <img
                    src={LoaderSVG}
                    width="128"
                    height="128"
                    alt="Circular Lines"
                  />
                  <Typography>No Default Rules Yet</Typography>
                </Box>
              </Card>
            )}
          </Box>
        )}
      </Box>

      {/* Dialogs */}
      <AddRuleDialog
        isOpen={showAddRuleDialog}
        handleClose={() => setShowAddRuleDialog(false)}
      />
      <EditRuleDialog
        isOpen={showEditRuleDialog}
        handleClose={handleCloseEditRuleDialog}
      />

      <RuleDetailsDialog
        isOpen={showRuleDetailsDialog}
        handleClose={handleCloseRuleDetailsDialog}
        selectedRuleId={selectedRuleId}
        setSelectedRuleId={setSelectedRuleId}
        handleShowApplyDialog={handleShowApplyDialog}
        handleShowDisableDialog={handleShowDisableDialog}
      />

      <UpdateStatusDialogs />
    </VulnerabilityRulesContext.Provider>
  );
};

export default VulnerabilityClassificationRules;
