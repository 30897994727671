import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";

const SupportTicketMenu = (props) => {
    const menuItems = [

    ];

    return <SubmenuItems items={menuItems} />;
};

export default SupportTicketMenu;
