import React from "react";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import Container from "@material-ui/core/Container";
import UserListMenu from "./user-list-menu";
import {useDefaultTable, useSortHandler, useSubheaderLogic,} from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import {EntityType} from "../../store/@core/entityType";
import EditDialog from "./EditDialog";
import {connect} from "react-redux";
import {insertUsers} from "../../store/user/actions";
import {createOptions} from "../../helpers/utils";
import {useFiltered, useMasterDetailWithDialog} from "reactcoregk/hooks";
import {Schema} from "../../store/@core/schema";

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "username",
    label: "Username",
  },
  {
    id: "email",
    label: "email",
  },
  {
    id: "status",
    label: "Status",
  }
];

const UserList = (props) => {
  const { insertUsers, context } = props;
  const users = context.User.getAll.result
  const roles = context.Role.getAll.result
  const { query, handleChangeQuery } = useSubheaderLogic();
  const sortHandler = useSortHandler();

  const roleOptions = createOptions(roles, "role")
  const results = useFiltered(users, query, ['username', 'email'])

  const {
    entity,
    handleCloseModal,
    handleCreateNew,
    handleEntityClick,
    openModal,
  } = useMasterDetailWithDialog(Schema[EntityType.User]);

  const MenuItemsComponent = <UserListMenu
      handleCreateNew={handleCreateNew}
      query={query}
      handleChangeQuery={handleChangeQuery}
    />

  const {
    renderCheckAllCell,
    renderHeadCell,
    renderDefaultBody,
  } = useDefaultTable([], sortHandler);

  const formatted = results.map(user => ({
    ...user,
    status: user.enabled ? "Active" : "Deactivated",
  }))

  const styleCell = (colId, value) => {
    if (colId !== "status") return
    return { color: value === "Active" ? "#43DD43" : "#EE2D2A"}
  }

  return (
    <SubmenuLayout noDivider MenuItemsComponent={MenuItemsComponent} title={"Users"}>
      <Container maxWidth={false}>
        <DefaultTable
          renderCheckAllCell={renderCheckAllCell}
          headCells={headCells}
          rows={formatted}
          styleCell={styleCell}
          onRowClick={handleEntityClick}
          renderHeadCell={renderHeadCell}
          renderDefaultBody={renderDefaultBody}
        />
      </Container>
      <EditDialog
        open={openModal}
        handleClose={handleCloseModal}
        entity={entity}
        entityType={EntityType.User}
        createEntity={insertUsers}
        updateEntity={insertUsers}
        context={context.User}
        roleOptions={roleOptions}
      />
    </SubmenuLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  insertUsers,
})(UserList);
