import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
//Account Redux states
import {REGISTER_USER} from './actionTypes';
import {registerUserFailed, registerUserSuccessful} from './actions';
//Include Both Helper File with needed methods
import {postRegister} from '../login/api';

// Is user register successfull then direct plot user in redux.
function* registerUser({payload: {user}}) {
    try {
        const response = yield call(postRegister, '/post-register', user);
        yield put(registerUserSuccessful(response));
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;
