import { Box, Divider, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Clock, Tick } from 'assets/icons/icons';
import BaseDialog from 'components/Common/BaseDialog';
import SectionFlatPanel from 'components/Layouts/SectionFlatPanel';
import React, { useCallback, useContext, useState } from 'react'
import { VulnerabilityRulesContext } from '.';

const RuleDetailsDialog = ({ isOpen, handleClose, handleShowApplyDialog, handleShowDisableDialog }) => {
    const { rule, ruleIsLoading, handleShowEditRuleDialog } = useContext(VulnerabilityRulesContext)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClickMenu = useCallback((event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    }, [])

    const handleCloseMenu = useCallback((event) => {
        event.stopPropagation()
        setAnchorEl(null);
    }, [])

    const handleApplyRule = useCallback(() => {
        handleShowApplyDialog(rule)
        setAnchorEl(null);
    }, [handleShowApplyDialog, rule])

    const handleDisableRule = useCallback(() => {
        handleShowDisableDialog(rule)
        setAnchorEl(null);
    }, [handleShowDisableDialog, rule])

    const DescriptionLoader = () => (
        <>
            <Skeleton variant='text' width='25rem' />
            <Skeleton variant='text' />
            <Skeleton variant='text' />
            <Skeleton variant='text' />
            <Skeleton variant='text' />
        </>
    )

    return (
        <BaseDialog onClose={handleClose} open={isOpen} title={`View ${rule?.enabled ? 'Enabled' : 'Disabled'} Rule`} maxWidth='md' maxContentHeight='70vh' fullWidth primaryAction={() => handleShowEditRuleDialog(true)} primaryActionTitle='Edit Rule'>
            {/* Header */}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h5'>Rule details</Typography>
                <Box display='flex' alignItems='center' gridColumnGap={12}>
                    <Box display='flex' alignItems='center' gridColumnGap={4}>
                        <Typography color='textSecondary'>Parameter Count</Typography>
                        <Typography variant='subtitle1' style={{ marginLeft: '0.25rem' }}>{rule.parameters.length}</Typography>
                    </Box>
                    <Box style={{ height: '1.1rem', width: '1px', backgroundColor: '#666' }} />
                    <Box display='flex' alignItems='center' gridColumnGap={4}>

                        {rule?.enabled ? <Tick color='#43DD43' /> : <Clock />}
                        <Typography variant='subtitle2'>{rule?.enabled ? 'Enabled' : 'Disabled'}</Typography>
                    </Box>
                    <IconButton color='default' onClick={handleClickMenu}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem disabled={rule?.enabled} onClick={handleApplyRule} style={{ width: '10rem' }}>Enable</MenuItem>
                        <Divider />
                        <MenuItem disabled={!rule?.enabled} onClick={handleDisableRule} style={{ width: '10rem' }}>Disable</MenuItem>
                    </Menu>
                </Box>
            </Box>

            {/* Description */}
            <Box marginTop='2rem'>
                {ruleIsLoading && <DescriptionLoader />}
                <Typography variant='subtitle1'>{rule?.name}</Typography>
                <Typography color='textSecondary'>{rule?.description}</Typography>
            </Box>

            {/* Parameters */}
            <Box display='flex' flexDirection='column' gridRowGap={12} marginTop={4}>
                {ruleIsLoading && <Skeleton height='18rem' />}
                {rule.parameters.map((parameter, index) => (
                    <SectionFlatPanel noHeader style={{ borderRadius: '0.2rem' }}>
                        <Box style={{ padding: '1rem 2rem', rowGap: '1rem', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle1'>Parameter {index + 1}</Typography>
                            <Box>
                                <Typography color='textSecondary'>Parameter</Typography>
                                <Typography style={{ marginTop: '0.5rem' }}>{parameter.parameter}</Typography>
                            </Box>
                            <Box display='flex'>
                                <Box flex='1'>
                                    <Typography color='textSecondary'>Condition</Typography>
                                    <Typography style={{ marginTop: '0.5rem' }}>{parameter.condition}</Typography>
                                </Box>
                                <Box flex='1'>
                                    <Typography color='textSecondary'>Value</Typography>
                                    <Typography style={{ marginTop: '0.5rem' }}>{parameter.conditionValue.replaceAll(',', ', ')}</Typography>
                                </Box>
                                <Box flex='1'>
                                    {index !== rule.parameters.length - 1 && (
                                        <>
                                            <Typography color='textSecondary'>Operator</Typography>
                                            <Typography style={{ marginTop: '0.5rem' }}>{parameter.operator}</Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </SectionFlatPanel>
                ))}
            </Box>
        </BaseDialog>
    )
}

export default RuleDetailsDialog