import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { fetchData } from "helpers/utils";

function FetchSelect(props) {
  const {
    dataMap,
    label,
    onChange,
    url,
    setSelectedEntity,
    disabled,
  } = props;
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = useState(options[0]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    fetchData(url + `${query}`)
      .then((data) => {
        setOptions(dataMap(data));
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
      });
  }, [query, url, dataMap]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onChange(newValue?.value);
        setSelectedEntity && setSelectedEntity(newValue);
      }}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      disabled={disabled}
      loading={loading}
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin={props.margin}
          disabled={disabled}
          placeholder={props.placeholder}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default FetchSelect;

FetchSelect.defaultProps = {
  margin: "normal",
};

FetchSelect.propTypes = {
  url: PropTypes.string.isRequired,
  dataMap: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  margin: PropTypes.oneOf(["normal", "none"]),
};
