import Entity from "./Entity";
import moment from "moment";

class Technique extends Entity {
    constructor() {
        super();
        this.id = null
        this.name = ""
        this.description = ""
        this.datasource = ""
        this.platform = ""
        this.techniqueParentId = null
        this.mitreId = ""
        this.mitreCreatedAt = moment().format("YYYY-MM-DDThh:mm:ss")
        this.mitreLastUpdatedAt = moment().format("YYYY-MM-DDThh:mm:ss")
        this.mitigations = []
        this.detection = ""
        this.references = []
    }
}
export default Technique
