import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import { Sort } from "@material-ui/icons";
import RankEditDialog from "./RankEditDialog";
import RankViewDialog from "./RankViewDialog";

function RankButton({ selected, entityName, rankEntity }) {
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const required = 10;
  const diff = Math.abs(required - selected.length);
  const diffTest = selected.length < required ? "more" : "less";

  function renderButton() {
    return selected.length > 0 ? (
      <Button
        color={"primary"}
        style={{ fontSize: 12 }}
        startIcon={<Sort />}
        disabled={selected.length !== required}
        onClick={() => setOpenEdit(true)}
      >
        Rank Selection {diff > 0 && `(${diff} ${diffTest})`}
      </Button>
    ) : (
      <Button
        color={"primary"}
        style={{ fontSize: 12 }}
        startIcon={<Sort />}
        onClick={() => setOpenView(true)}
      >
        Top Ranking
      </Button>
    );
  }

  return (
    <Fragment>
      {renderButton()}
      <RankEditDialog
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        selected={selected}
        entityName={entityName}
      />
      <RankViewDialog
        open={openView}
        handleClose={() => setOpenView(false)}
        selected={selected}
        entityName={entityName}
        rankEntity={rankEntity}
      />
    </Fragment>
  );
}

export default RankButton;
