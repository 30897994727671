export const slideVariants = {
    hidden: {
        opacity: 0,
        marginLeft: 20,
    },
    visible: {
        opacity: 1,
        marginLeft: 0,
        transition: "opacity 500ms, transform 100ms",
    },
    exit: {
        opacity: 1,
        marginLeft: -20,
    },
};
