import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {},

    sectionTitle: {
        fontSize: 16,
        color: theme.palette.text.secondary,
    },
}));

function DetailSection(props) {
    const classes = useStyles();
    const { title, divider, action, actionTitle } = props;
    return (
        <>
            <Grid item>
                <Box>
                    <Box
                        mb={2}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className={classes.sectionTitle}>{title}</div>
                        {action && (
                            <Button color="primary" onClick={action}>
                                {actionTitle}
                            </Button>
                        )}
                    </Box>
                    {props.children}
                </Box>
            </Grid>
            {divider && (
                <Grid item>
                    <Divider />
                </Grid>
            )}
        </>
    );
}

export default DetailSection;
