import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography, Box, Button, IconButton, FormControl, Input, InputAdornment, Avatar, Paper, Container, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import { InsertDriveFile, KeyboardArrowLeft, Send } from "@material-ui/icons";
import { HiveIcons } from "assets/icons";
import { CustomizedTimeline } from "./Timeline";
import { useForm, useProgress } from "reactcoregk/hooks";
import { API_URL } from "config";
import { fetchData, postData, getUserName } from "helpers/utils";
import AttachmentPreview from "./AttachmentPreview";
import BusyIndicator from "components/Common/BusyIndicator";
import { connect } from "react-redux";
import Select from "components/Inputs/Select";
import { SUPPORT_TICKET_PRIORITY, SUPPORT_TICKET_PRIORITY_COLORS, SUPPORT_TICKET_STATE, SUPPORT_TICKET_STATUS } from "constants/enums";
import SubmenuLayout from "components/Layouts/SubmenuLayout";
import SupportTicketMenu from "./supportTickeMenu";
import { updateSupportTicket } from '../../store/supportTicket/actions';
import getMimeTypefromString from "constants/mimeType";

const useStyles = makeStyles((theme) => ({
    button: {
        alignSelf: "center",
        color: "#fff",
        fontSize: 16,
        marginRight: 5,
        "&:hover": {
            backgroundColor: "transparent",
            opacity: 1,
        },
    },
    sectionText: {
        color: '#919299',
        fontSize: 11,
        letterSpacing: 0,
    },
    basicInfo: {
        //marginLeft: -2 + 'vw'
    },
    detailMargin: {
        marginBottom: 10,
        height: 20
    },
    basicText: {
        fontSize: 12,
        color: '#FFFFFF'
    },
    statusSelect: {
        marginTop: '-25px',
        display: 'inline-flex',
        marginLeft: 10
    },
    attachmentIconBox: {
        width: 34,
        borderRadius: '50%',
        backgroundColor: 'rgb(255 255 255 / 0.08)',
        display: 'flex',
        justifyContent: 'center',
        padding: 5
    },
    attachmentLabelText: {
        fontSize: 12,
        color: '#FFFFFF',
        fontWeight: 'bold',
        marginLeft: 12
    },
    attachmentAction: {
        fontSize: 13,
        color: '#18C7B6',
    },
    paper: {
        padding: '13px 15px',
        borderRadius: 6,
        backgroundColor: '#1E1D1D',
        display: 'flex',
        alignItems: 'center',
        height: 60,
        marginBottom: 15,
    },
    commentBox: {
        backgroundColor: '#1E1D1D',
        height: 70,
        width: 100 + '%',
        opacity: 0.6,
        borderRadius: '6px',
        padding: '10px'
    },
    sectionTitle: {
        fontSize: 16,
        color: '#E0E0E0',
    },
    userNameText: {
        fontWeight: 300,
        fontSize: 13,
        color: '#B3B7BD'
    },
    commentText: {
        color: '#7C828B',
        fontSize: 16,
        letterSpacing: 0,
    },
}));

const data = {
    timelineData: [
        {
            title: 'Status changed to Completed',
            createdAt: new Date(),
            color: "#15A1F4",

        },
        {
            title: 'Status changed to Completed',
            createdAt: new Date(),
            color: "#F05E13",

        },
        {
            title: 'Status changed to Processed',
            createdAt: new Date(),
            color: "#9E4BF3",

        },
        {
            title: 'Ticket escalated to Level 2',
            createdAt: new Date(),
            color: "#9E4BF3",

        },
        {
            title: 'Status changed to High Priority',
            createdAt: new Date(),
            color: "#43DD43",

        },
        {
            title: 'Ticket created',
            createdAt: new Date(),
            color: "#15A1F4",

        },
    ],
}


function TicketDetails(props) {
    const { context, history, updateSupportTicket } = props
    const formValue = {
        comment: "",
    }
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [busy, setBusy] = useState(false)
    const [form, handleChange, updateInitial] = useForm(formValue);
    const [entity, setEntity] = useState(null)
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [showAttachment, setShowAttachment] = useState(false)
    const profilePicture = context.Login.profilePicture
    const me = context.User.getAll.result.find(x => x.username === getUserName())



    const statusOptions = React.useMemo(() => {
        const options = []
        for (const key in SUPPORT_TICKET_STATUS) {
            options.push({
                label: SUPPORT_TICKET_STATUS[key],
                value: SUPPORT_TICKET_STATUS[key]
            })
        }
        return options
    }, [])

    // const escalationOptions = React.useMemo(() => {
    //     const options = []
    //     for (const key in SUPPORT_TICKET_ESCALATION) {
    //         options.push({
    //             label: SUPPORT_TICKET_ESCALATION[key],
    //             value: key
    //         })
    //     }
    //     return options
    // }, [])

    const stateOptions = React.useMemo(() => {
        const options = []
        for (const key in SUPPORT_TICKET_STATE) {
            options.push({
                label: SUPPORT_TICKET_STATE[key],
                value: key
            })
        }
        return options
    }, [])


    const priorityColor = _ => {
        if (!entity) return 'inherit'
        switch (entity.priority) {
            case SUPPORT_TICKET_PRIORITY.CRITICAL:
                return SUPPORT_TICKET_PRIORITY_COLORS.CRITICAL
            case SUPPORT_TICKET_PRIORITY.HIGH:
                return SUPPORT_TICKET_PRIORITY_COLORS.HIGH
            case SUPPORT_TICKET_PRIORITY.MEDIUM:
                return SUPPORT_TICKET_PRIORITY_COLORS.MEDIUM
            case SUPPORT_TICKET_PRIORITY.LOW:
                return SUPPORT_TICKET_PRIORITY_COLORS.LOW
            case SUPPORT_TICKET_PRIORITY.INFORMATION:
                return SUPPORT_TICKET_PRIORITY_COLORS.INFORMATION
            default:
                return 'inherit'
        }
    }

    const handleCloseTicket = _ => {
        setEntity(null)
        history.push("/support-tickets")
    }

    useEffect(() => {
        setBusy(true)
        const ticketId = props.match.params.id
        if (ticketId) {
            const url = API_URL + "/api/support-tickets/" + ticketId
            fetchData(url)
                .then((data) => {
                    setBusy(false);
                    setEntity(data)
                })
                .catch((ex) => setBusy(false));
        }
    }, [props.match.params.id])

    const handleMouseDownComment = (event) => {
        event.preventDefault();
    };

    const getNewContent = _ => {
        setIsLoading(true);
        const url = API_URL + "/api/support-tickets/" + entity.id
        fetchData(url)
            .then((data) => {
                setIsLoading(false);
                setEntity(data)
            })
            .catch((ex) => setIsLoading(false));
    }

    const handleSaveComment = async _ => {
        if (!form.comment) return
        setIsLoading(true);
        try {
            const url = API_URL + "/api/support-tickets/comments";
            const payload = {
                comment: form.comment,
                supportTicketId: entity.id,
                commentatorUsername: me.username,
                commentatorId: me.id,
            }
            await postData(url, payload);
            setIsLoading(false);
            getNewContent();
            updateInitial(formValue);
        } catch (e) {
            console.log(e.message);
            setIsLoading(false);

        }
    }

    const handleLoadAttachment = data => event => {
        setSelectedAttachment({ ...data, supportTicketId: entity.id });
        setShowAttachment(true);
    }

    const handleCloseAttachmentDialog = _ => {
        setSelectedAttachment(null);
        setShowAttachment(false);
    }

    const handleDownloadAttachment = async (file) => {
        const temp = file.fileName.split('.')
        const fileType = temp[temp.length - 1]
        const mimeType = getMimeTypefromString(fileType)
        if (!mimeType) return
        const base64Prepends = `data:${mimeType};base64`
        const url = API_URL + `/api/support-tickets/${entity.id}/attachments/${file.id}`;

        setIsLoading(true);
        try {
            const res = await fetchData(url)
            const fileToBeDownloaded = `${base64Prepends},${res}`
            let fileToBeDownloadedURI = encodeURI(fileToBeDownloaded)
            let downloadLink = document.createElement('a')
            downloadLink.href = fileToBeDownloadedURI
            downloadLink.download = `${file.fileName}`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }

    }

    const handleUpdateContent = _ => { setEntity(context.SupportTicket.get.result) }

    //track update, to set new content
    useProgress(
        () => { },
        handleUpdateContent,
        () => { },
        context.SupportTicket.update
    );


    const handleChangeEntity = name => event => {
        const payload = {
            ...entity,
            [name]: event.target.value
        }
        updateSupportTicket(payload)
    }



    const MenuItemsComponent = <SupportTicketMenu />


    return (<>
        <SubmenuLayout MenuItemsComponent={MenuItemsComponent} title={"Support tickets"}>
            <Container maxWidth={false}>
                <BusyIndicator busy={busy} />
                {!busy && entity && <><Grid container direction="row" wrap='nowrap' style={{ height: 'calc(100vh - 96px)', overflow: 'hidden' }}>
                    {/* Left section */}
                    <Grid container item direction="column" sm={9} wrap='nowrap' style={{ padding: '0 2vw' }}>
                        <Grid item container direction="row" alignItems="center" className={classes.basicInfo}>
                            <IconButton
                                aria-describedby={'name'}
                                className={classes.button}
                                disableRipple
                                onClick={handleCloseTicket}
                            >
                                <KeyboardArrowLeft style={{ fontSize: 16, marginRight: 3, color: '#18C7B6' }} />
                                {entity.name}
                            </IconButton>
                            <Button style={{ color: '#18C7B6', backgroundColor: '#1D1F21' }}>
                                Ticket Report
                                <HiveIcons.Download size={"md"} />
                            </Button>
                        </Grid>
                        <Box p={3}>
                            <Grid item >
                                <div className={classes.sectionText}>{entity.name}</div>
                            </Grid>
                            {/* <Grid item >
                                <span className={classes.sectionText}>SLA: </span><span className={classes.basicText}>{"10 hours"}</span>
                            </Grid> */}

                            <Box my={2} >
                                <div className={classes.sectionTitle}>Ticket Details</div>
                            </Box>
                            <Grid item spacing={2} container wrap="nowrap" direction="row" alignItems="flex-start" display="flex" >
                                <Grid item xs={3} style={{ marginRight: '3vw' }}>
                                    <div className={[classes.sectionText, classes.detailMargin].join(' ')}>Created: <span className={classes.basicText}>{`${moment(entity.createdAt).format('Do MMM')}`}</span></div>
                                    <div className={classes.sectionText}>Submitted by: <span className={classes.basicText}>{entity.createdByUsername}</span></div>
                                </Grid>
                                <Grid item xs={3} style={{ marginRight: '3vw' }}>
                                    <div
                                        className={[classes.sectionText, classes.detailMargin].join(' ')}>
                                        Status:
                                        <span className={classes.statusSelect}>
                                            <Select
                                                onChange={handleChangeEntity('status')}
                                                value={entity.status}
                                                options={statusOptions}
                                                controlId={`ticket-${entity.id}`}
                                                margin="none" /></span>
                                        {/* <span style={{ color: `${statusColor()}` }} className={classes.basicText}>{entity.status}</span> */}
                                    </div>
                                    <div className={classes.sectionText}>Last update: <span className={classes.basicText}>{moment(entity.updatedAt).format('Do MMM')}</span></div>
                                </Grid>
                                <Grid item xs={3} style={{ marginRight: '3vw' }}>
                                    <div
                                        className={[classes.sectionText, classes.detailMargin].join(' ')}>
                                        Escalation level:
                                            {/* <span className={classes.statusSelect}>
                                            <Select
                                                onChange={handleChangeEscalation(entity.id)}
                                                value={entity.escalation}
                                                options={escalationOptions}
                                                controlId={`ticket-escalation-${entity.id}`}
                                                margin="none" /></span> */}
                                        <span className={classes.basicText}>{entity.escalation}</span>
                                    </div>
                                    <div className={classes.sectionText}>Last update: <span className={classes.basicText}>{moment(entity.updatedAt).format('Do MMM')}</span></div>
                                </Grid>
                                <Grid item xs={3} >
                                    <div
                                        className={[classes.sectionText, classes.detailMargin].join(' ')}>
                                        State:
                                        <span className={classes.statusSelect}>
                                            <Select
                                                onChange={handleChangeEntity('state')}
                                                value={entity.state}
                                                options={stateOptions}
                                                controlId={`ticket-state-${entity.id}`}
                                                margin="none" /></span>
                                        {/* <span style={{ color: `${statusColor()}` }} className={classes.basicText}>{entity.state}</span> */}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box my={2} >
                            <Divider />
                        </Box>
                        <Box p={3} >
                            <Grid item >
                                <div className={classes.sectionTitle}>Description</div>
                            </Grid>
                            <Box my={2} >
                                <div className={classes.impactDescText}>{entity.description}</div>
                            </Box>
                            <Grid item spacing={2} container direction="row" alignItems="flex-start" >
                                <Grid item style={{ marginRight: '3vw' }}>
                                    <div className={classes.sectionText}>Subject: </div>
                                    <div className={classes.basicText}>{entity.subject}</div>
                                </Grid>
                                <Grid item style={{ marginRight: '3vw' }}>
                                    <div className={classes.sectionText}>Category: </div>
                                    <div className={classes.basicText}>{entity.category}</div>
                                </Grid>
                                <Grid item style={{ marginRight: '3vw' }}>
                                    <div className={classes.sectionText}>Subcategory: </div>
                                    <div className={classes.basicText}>{entity.subcategory}</div>
                                </Grid>
                                <Grid item style={{ marginRight: '3vw' }}>
                                    <div className={classes.sectionText}>Priority: </div>
                                    <div style={{ color: `${priorityColor()}` }} className={classes.basicText}>{entity.priority}</div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.sectionText}>Product version: </div>
                                    <div className={classes.basicText}>{entity.productVersion}</div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Box p={3} >
                            <Box mb={3}>
                                <div className={classes.sectionTitle}>Attachments</div>
                            </Box >
                            {entity.attachments && entity.attachments.map((attachment, index) => {
                                return (<Paper elevation={1} className={classes.paper}>
                                    <div className={classes.attachmentIconBox}>
                                        <InsertDriveFile style={{ width: 18, opacity: 0.3 }} />
                                    </div>
                                    <Typography className={classes.attachmentLabelText}>{attachment.fileName}</Typography>
                                    <div style={{ marginLeft: "auto" }} />
                                    <Button
                                        color='primary'
                                        className={classes.attachmentAction}
                                        onClick={handleLoadAttachment(attachment)}
                                    >View
                    </Button>
                                    <Divider style={{ width: 1, height: 14, margin: '0 15px' }} />
                                    <Button
                                        color='primary'
                                        className={classes.attachmentAction}
                                        onClick={() => handleDownloadAttachment(attachment)}
                                    >Download
                    </Button>
                                </Paper>)
                            })}
                        </Box>

                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Box p={3}>

                            <Box mb={2}>
                                <div className={classes.sectionTitle}>Comments</div>
                            </Box>

                            {entity.comments && entity.comments.map((comment, index) => {
                                return (<Grid item container direction="row" alignItems="flex-start" style={{ margin: '15px 0' }} key={`comment-${comment.id}-${index}`}>
                                    <Grid item>
                                        <Avatar
                                            alt={comment.commentatorUsername}
                                            src={profilePicture}
                                            style={{ width: 45, height: 45, marginRight: 10 }}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <div className={classes.userNameText}>{comment.commentatorUsername}</div>
                                        <div className={classes.commentText}>{comment.comment}</div>
                                    </Grid>
                                </Grid>)
                            })}

                            <Box mt={3} className={classes.commentBox}>
                                <FormControl fullWidth margin={"normal"}>
                                    <Input
                                        disabled={isLoading}
                                        placeholder={"Add a comment"}
                                        id="standard-adornment-comment"
                                        type={"text"}
                                        value={form.comment}
                                        onChange={handleChange("comment")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{ marginBottom: 5 }}
                                                    aria-label="store comment"
                                                    onClick={handleSaveComment}
                                                    onMouseDown={handleMouseDownComment}
                                                >
                                                    <Send style={{ color: '#18C7B6' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Box>
                        </Box>

                    </Grid>
                    {/* Right section */}
                    <Grid container item direction="column" sm={3} >
                        {/* Timeline */}
                        <Box pt={1} px={2} mt={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography className={classes.sectionTitle}>Support timeline</Typography>
                        </Box>
                        <CustomizedTimeline data={data.timelineData} type='contensed' />
                    </Grid>
                </Grid >
                    <AttachmentPreview open={showAttachment} handleClose={handleCloseAttachmentDialog} entity={selectedAttachment} busy={false} />
                </>}
            </Container>
        </SubmenuLayout></>);
}


const mapStateToProps = (state) => {
    return {
        context: state,
    };
};

export default connect(mapStateToProps, { updateSupportTicket })(TicketDetails);





