import React from 'react'
import BaseDialog from 'components/Common/BaseDialog'
import { fetchData } from 'helpers/utils';
import { Grid } from '@material-ui/core';
import { API_URL } from 'config';
import BusyIndicator from 'components/Common/BusyIndicator';

export default function AttachmentPreview(props) {
    const { open, handleClose, entity, busy } = props;
    const [isLoading, setIsloading] = React.useState(false)
    const [media, setMedia] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(null)

    const loadMedia = async (data) => {
        setIsloading(true);
        try {
            const url = API_URL + `/api/support-tickets/${data.supportTicketId}/attachments/${data.id}`;
            const res = await fetchData(url)
            setMedia(res)
            setIsloading(false);
        } catch (e) {
            setErrorMessage(e.message);
            setIsloading(false);
        }
    }

    React.useEffect(() => {
        if (open && entity) {
            loadMedia(entity)
        } else setMedia(null)
    }, [entity, open])

    return (
        <BaseDialog
            open={open}
            onClose={handleClose}
            primaryAction={handleClose}
            inProgress={busy || isLoading}
            fullWidth
            title={"Attachment preview"}
            maxWidth={'lg'}
            errorMessage={errorMessage}
            dismissError={() => setErrorMessage(null)}
            primaryActionTitle="Close"
        >
            <Grid container justify="center">
                <BusyIndicator busy={isLoading} />
                <Grid item style={{ minHeight: '25vh' }}>
                    {media && <img src={`data:image/png;base64,${media}`} alt="" />}
                </Grid>
            </Grid>
        </BaseDialog>
    );
};
