import {entityType} from "./module";
import {createDeleteMethod, createGetAllMethod, createPostMethod, createPutMethod} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

export const getDefaultApiHandler = () => new ApiHandler(false, false, getDefaultHeaders(), "POST");

export const getAllCompanyVerticals = (params) => createGetAllMethod(entityType, params, getDefaultApiHandler())
export const createCompanyVertical = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateCompanyVertical = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const deleteCompanyVertical = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())

