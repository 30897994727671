import React, { useEffect, useState } from "react";
import BaseDialog from "../../../components/Common/BaseDialog";
import ReactJson from "react-json-view";
import { fetchData } from "../../../helpers/utils";
import { API_URL } from "../../../config";
const yaml = require('js-yaml');

function FilePreview(props) {
  const { open, handleClose, preview } = props;
  const {fileUrl, format} = preview
  const [file, setFile] = useState({});
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (fileUrl) {
      setBusy(true);
      setFile({});
      const url = `${API_URL}${fileUrl}`;
      fetchData(url)
        .then((res) => {
          if (format === "yaml") {
            yaml.loadAll(res, function (doc) {
              setFile(doc);
            });
          } else {
            setFile(res);
          }
          setBusy(false);
        })
        .catch((ex) => {
          console.log(ex);
          setBusy(false);
        });
    }
  }, [fileUrl, format]);

  return (
    <BaseDialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      title={"File preview"}
      inProgress={busy}
      showProgress
    >
      <ReactJson src={file} theme={"twilight"} />
    </BaseDialog>
  );
}

export default FilePreview;
