import React from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { useMasterDetailWithDialog, useSortHandler } from "reactcoregk/hooks";
import {useDefaultTable, useQuery} from "../../helpers/hooks";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import EditDialog from "./EditDialog";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import DefaultTable from "../../components/Common/DefaultTable";
import CountriesSubheader from "./countries-subheader";
import {
  createCountry,
  deleteCountry,
  updateCountry,
} from "../../store/country/actions";
import {compareProp} from "reactcoregk/utils";

const defaultCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
];

function Countries(props) {
  const {
    context,
    createCountry,
    updateCountry,
    deleteCountry,
  } = props;

  const query = useQuery()
  const search = query.get("s") || ""

  const { isLoading, result } = context.Country.getAll

  const list = result
    .filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => compareProp(a, b,"name"))

  // Handlers for new or existing entity to show in dialog
  const {
    entity,
    handleEntityClick,
    openModal,
    handleCloseModal,
    handleCreateNew,
  } = useMasterDetailWithDialog(Schema[EntityType.Alert]);

  // Create the subheader options
  const AlertMenu = <CountriesSubheader handleCreateNew={handleCreateNew} />;

  // Sort handler used by default table
  const sortHandler = useSortHandler();
  const { renderHeadCell, renderDefaultBody } = useDefaultTable(list, sortHandler)

  return (
    <SubmenuLayout
      MenuItemsComponent={AlertMenu}
      title={"Countries"}
    >
      <Container maxWidth={false}>
        <DefaultTable 
          headCells={defaultCells} 
          rows={list}
          renderHeadCell={renderHeadCell} 
          renderDefaultBody={renderDefaultBody}
          isLoading={isLoading}
          onRowClick={handleEntityClick}
        />
      </Container>
      <EditDialog
        context={context.Country}
        entity={entity}
        entityType={EntityType.Country}
        open={openModal}
        handleClose={handleCloseModal}
        createEntity={createCountry}
        updateEntity={updateCountry}
        deleteEntity={deleteCountry}
        storedIds={result.map(x => x.id)}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Country: state.Country
    }
  };
};
export default connect(mapStateToProps, {
  createCountry,
  updateCountry,
  deleteCountry,
})(Countries);
