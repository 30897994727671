import React, { useEffect } from "react";
import EntityDialog from "../../components/EntityDialog";
import { EntityType } from "../../store/@core/entityType";
import { useForm } from "reactcoregk/hooks";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Schema } from "../../store/@core/schema";
import Select from "../../components/Inputs/Select";
import { TACTIC_TYPE_OPTIONS } from "../../constants/enums";

function TacticDialog(props) {
  const { entity, open } = props;
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Tactic]);

  useEffect(() => {
    if (entity) setForm(entity);
  }, [entity, setForm, open]);

  return (
    <EntityDialog {...props} form={form} maxWidth={"sm"}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter ID"}
            fullWidth
            multiline
            rowsMax={10}
            value={form.mitreId}
            onChange={handleChange("mitreId")}
            label="Tactic ID"
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter name"}
            fullWidth
            value={form.name}
            onChange={handleChange("name")}
            label="Name"
            autoFocus
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter description"}
            fullWidth
            multiline
            rowsMax={10}
            value={form.description}
            onChange={handleChange("description")}
            label="Description"
          />
        </Grid>
        <Grid item>
          <Select
            options={TACTIC_TYPE_OPTIONS}
            variant="standard"
            margin="none"
            placeholder={"Select type"}
            fullWidth
            value={form.type}
            onChange={handleChange("type")}
            label="Type"
            controlId={"type"}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            type="datetime-local"
            value={form.mitreCreatedAt}
            onChange={handleChange("mitreCreatedAt")}
            label="Created At"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            type="datetime-local"
            value={form.mitreLastUpdatedAt}
            onChange={handleChange("mitreLastUpdatedAt")}
            label="Modified At"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </EntityDialog>
  );
}

export default TacticDialog;
