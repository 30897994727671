import { useAppTheme } from "../../../constants/theme";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Card, Typography } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import React from "react";
import Grid from "@material-ui/core/Grid";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, theme) => ({
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  background: isDragging ? theme.palette.primary.light : "transparent",

  ...draggableStyle,
});

function RankEditList({ items, setItems, entityName }) {
  const theme = useAppTheme();

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setItems((prevState) =>
      reorder(prevState, result.source.index, result.destination.index)
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable
                key={`${item.entityName}-${item.id}`}
                draggableId={`${item.entityName}-${item.id}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <Card
                    variant={"outlined"}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      theme
                    )}
                  >
                    <Grid
                      container
                      spacing={2}
                      justify={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Box
                          py={2}
                          px={2}
                          display={"flex"}
                          style={{
                            minWidth: 58,
                            justifyContent: "center",
                            backgroundColor: theme.palette.primary.dark,
                          }}
                        >
                          <Typography
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            #{index + 1}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography noWrap style={{ maxWidth: "340px" }}>
                          {item.name || `${entityName} - ${item.id}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DragHandle style={{ minWidth: 58 }} />
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default RankEditList;
