import {entityType} from "./module";
import {CLEAR_LICENSES, CREATE_LICENSE, GET_ALL_LICENSES_BY_CLIENT} from "./actionTypes";
import {buildActionType, createAction, createAsyncAction} from "reactcoregk/store/actions";


export const getAllLicensesByClientActions =  createAsyncAction(entityType, GET_ALL_LICENSES_BY_CLIENT)
export const createLicenseActions = createAsyncAction(entityType, CREATE_LICENSE)

export const getAllLicensesByClient = (payload) => getAllLicensesByClientActions.request(payload)
export const createLicense = (payload) => createLicenseActions.request(payload)
export const clearLicenses = () => createAction(buildActionType(entityType, CLEAR_LICENSES))
