import {entityType} from "./module";
import {createGetAllMethod, createPutMethod} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

const getDefaultApiHandler = () =>
    new ApiHandler(false, false, getDefaultHeaders(), "POST");

export const uploadPolicy = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const getAllPolicies = (params) => createGetAllMethod(entityType, params, getDefaultApiHandler())
