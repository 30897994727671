import {entityType} from "./module";
import {
	createDeleteMethod,
	createGetAllPageableMethod,
	createPostMethod,
	createPutMethod
} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

export const getApiHandler = () =>
	new ApiHandler(false, false, getDefaultHeaders(), "POST");

export const getAllActors = (params) => createGetAllPageableMethod(entityType, params, getApiHandler())
export const createActor = (payload) => createPostMethod(entityType, payload, getApiHandler())
export const updateActor = (payload) => createPutMethod(entityType, payload, getApiHandler())
export const deleteActor = (payload) => createDeleteMethod(entityType, payload, getApiHandler())

