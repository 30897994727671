import {entityType} from "./module";
import {
	createDeleteMethod,
	createGetAllPageableMethod,
	createPostMethod,
	createPutMethod
} from "reactcoregk/store/actions";
import { getDefaultApiHandler } from "../../helpers/utils";

export const getAllProducts = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler())
export const createProduct = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateProduct = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const deleteProduct = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())

