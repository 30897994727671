import { Typography } from '@material-ui/core';
import BaseDialog from 'components/Common/BaseDialog';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useCallback } from 'react';
import { deleteData, postData } from "../../../helpers/utils";
import { API_URL } from "../../../config";


const useStatusChangeDialogs = (rule, refreshRules) => {
    const { enqueueSnackbar } = useSnackbar()
    const [showApplyDialog, setShowApplyDialog] = useState(false)
    const [showDisableDialog, setShowDisableDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const handleShowApplyDialog = useCallback(() => {
        setShowApplyDialog(true)
    }, [])

    const handleCloseApplyDialog = useCallback(() => {
        setShowApplyDialog(false)
    }, [])

    const handleShowDisableDialog = useCallback(() => {
        setShowDisableDialog(true)
    }, [])

    const handleCloseDisableDialog = useCallback(() => {
        setShowDisableDialog(false)
    }, [])

    const handleShowDeleteDialog = useCallback(() => {
        setShowDeleteDialog(true)
    }, [])

    const handleCloseDeleteDialog = useCallback(() => {
        setShowDeleteDialog(false)
    }, [])

    const handleApplyRule = useCallback(async () => {
        if (rule) {
            try {
                await postData(`${API_URL}/api/classifications/rules`, { ...rule, enabled: true })
                enqueueSnackbar('Rule Enabled', { variant: 'success' })
                refreshRules()
                handleCloseApplyDialog()
            } catch (err) {
                if (err) {
                    enqueueSnackbar(err.message, { variant: 'error' })
                }
            }
        }
    }, [enqueueSnackbar, handleCloseApplyDialog, refreshRules, rule])

    const handleDisableRule = useCallback(async () => {
        if (rule) {
            try {
                await postData(`${API_URL}/api/classifications/rules`, { ...rule, enabled: false })
                enqueueSnackbar('Rule Disabled', { variant: 'success' })
                refreshRules()
                handleCloseDisableDialog()
            } catch (err) {
                if (err) {
                    enqueueSnackbar(err.message, { variant: 'error' })
                }
            }
        }
    }, [enqueueSnackbar, handleCloseDisableDialog, refreshRules, rule])

    const handleDeleteRule = useCallback(async () => {
        if (rule) {
            try {
                await deleteData(`${API_URL}/api/classifications/rules/${rule.uuid}`, )
                enqueueSnackbar('Rule Deleted', { variant: 'success' })
                refreshRules()
                handleCloseDeleteDialog()
            } catch (err) {
                if (err) {
                    enqueueSnackbar(err.message, { variant: 'error' })
                }
            }
        }
    }, [enqueueSnackbar, handleCloseDeleteDialog, refreshRules, rule])

    const UpdateStatusDialogs = () => (
        <>
            {/* Apply Rule */}
            <BaseDialog
                open={showApplyDialog}
                onClose={handleCloseApplyDialog}
                fullWidth
                title={"Enable Rule"}
                maxWidth={'sm'}
                primaryAction={handleApplyRule}
                primaryActionTitle="Enable"
                secondaryAction={handleCloseApplyDialog}
            >
                <Typography style={{ fontSize: '1.75rem' }}>Are you sure you want to enable the following rule?</Typography>

                <Typography style={{ marginTop: '1.5rem' }}>{rule.name}</Typography>
                <Typography style={{ marginTop: '0.5rem' }} color='textSecondary'>
                    {rule.description}
                </Typography>
            </BaseDialog>

            {/* Disable Rule */}
            <BaseDialog
                open={showDisableDialog}
                onClose={handleCloseDisableDialog}
                fullWidth
                title={"Disable Rule"}
                maxWidth={'sm'}
                primaryAction={handleDisableRule}
                primaryActionTitle="Disable"
                secondaryAction={handleCloseDisableDialog}
            >
                <Typography style={{ fontSize: '1.75rem' }}>Are you sure you want to disable the following rule?</Typography>

                <Typography style={{ marginTop: '1.5rem' }}>{rule.name}</Typography>
                <Typography style={{ marginTop: '0.5rem' }} color='textSecondary'>
                    {rule.description}
                </Typography>
            </BaseDialog>

            {/* Delete Rule */}
            <BaseDialog
              open={showDeleteDialog}
              onClose={handleCloseDeleteDialog}
              fullWidth
              title={"Delete Rule"}
              maxWidth={'sm'}
              primaryAction={handleDeleteRule}
              primaryActionTitle="Delete"
              secondaryAction={handleCloseDeleteDialog}
            >
                <Typography style={{ fontSize: '1.75rem' }}>Are you sure you want to delete the following rule?</Typography>

                <Typography style={{ marginTop: '1.5rem' }}>{rule.name}</Typography>
                <Typography style={{ marginTop: '0.5rem' }} color='textSecondary'>
                    {rule.description}
                </Typography>
            </BaseDialog>
        </>
    )

    return {
        handleShowApplyDialog,
        handleCloseApplyDialog,
        handleShowDisableDialog,
        handleCloseDisableDialog,
        handleShowDeleteDialog,
        handleCloseDeleteDialog,
        UpdateStatusDialogs
    }
}

export default useStatusChangeDialogs