import { entityType } from "./module";
import {
  getCommonState,
  handleAsyncState,
  handleCommonState,
} from "reactcoregk/store/reducer";

const initialState = getCommonState();

const CveDefinition = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0];

  if (actionEntity !== entityType) return state;
  const actionType = action.type.split(".")[1];

  switch (actionType) {
    case "UPLOAD_CVE":
      return handleAsyncState(state, action, "create");
    default:
      break;
  }

  return handleCommonState(state, actionType, action, "cve");
};

export default CveDefinition;
