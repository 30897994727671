import React from "react";
import { useDefaultTable } from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


function ProductsTable(props) {
  const { products, sortHandler, headCells, handleEntityClick, isLoading } =
    props;

  const { renderHeadCell, renderCheckAllCell, renderBodyCell } =
    useDefaultTable(products, sortHandler);

  const renderDefaultBody = () => {
    return products.map((prod) => {
      return (
        <TableRow
          hover
          role="hover"
          onClick={handleEntityClick}
          tabIndex={-1}
          key={prod.id}
        >
          {renderBodyCell(prod.vendor)}
          {renderBodyCell(prod.products)}
          <TableCell>
              <img src={`data:image/png;base64,${prod.icon}`} width={'50px'} height={'50px'} style={{objectFit: "scale-down"}} alt={""}/>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <DefaultTable
      headCells={headCells}
      isLoading={isLoading}
      onRowClick={handleEntityClick}
      renderCheckAllCell={renderCheckAllCell}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={products}
    />
  );
}

export default ProductsTable;
