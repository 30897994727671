import axios from "axios";
import ApiHandler from "reactcoregk/models/apiHandler";
import moment from "moment";

axios.defaults.headers.common = {
  Authorization: "Bearer " + getToken(),
};

export function getDefaultHeaders() {
  return {
    Authorization: "Bearer " + getToken(),
  }
}


export function getAxiosConfig() {
  return {
    headers: getDefaultHeaders(),
  };
}

export const getDefaultApiHandler = () =>
  new ApiHandler(false, true, getDefaultHeaders());

export function getToken() {
  const userAuth = localStorage.getItem("authUser");
  return userAuth ? JSON.parse(userAuth).token : null;
}

export function getUserName() {
  const userAuth = localStorage.getItem("authUser");
  return userAuth ? JSON.parse(userAuth).username : null;
}

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const isAdmin = () => {
  try {
    const decoded = parseJwt(getToken());
    return decoded.authorities.includes("ROLE_ADMIN");
  } catch (ex) {
    return false;
  }
};

function handleError(err) {
  if (err.response) {
    const errorMsg = err.response.data.message;
    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(`Something went wrong. Error code: ${err.request.status}.`);
  }
  throw new Error("Something went wrong.");
}

export const fetchData = (url) =>
  axios
    .get(url)
    .then((res) => res.data)
    .catch(handleError);

export const postData = (url, data) =>
  axios
    .post(url, data)
    .then((res) => res.data)
    .catch(handleError);

export const updateData = (url, data) =>
  axios
    .put(url, data)
    .then((res) => res.data)
    .catch(handleError);

export const deleteData = (url, data) =>
  axios.delete(url, { data }).catch(handleError);

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const createMap = (array, prop = "id") =>
  new Map(array.map((item) => [item[prop], item]));

export const createOptions = (array, labelProp = "name", valueProp = "id") => {
  return array.map((item) => ({
    label: item[labelProp],
    value: item[valueProp],
  })).sort((a, b) => a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1);
};

export function makeid(length = 10) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function sortByPosition(array) {
  return [...array].sort((a, b) => a.position - b.position);
}
export function sortBy(array, prop) {
  return [...array].sort((a, b) => a[prop] - b[prop]);
}

export function compareProp(a, b, prop = "position") {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
}

export const unwrap = (value, prop = "id") => (value && value[prop]) || "";

export const downloadFile = (exportURL, fileName, fileExtension, showTime) => {
  const currentdate = new Date();
  const datetime =
    "Last Sync: " +
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " @ " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  return fetch(exportURL, {
    method: "GET",
    mode: "cors",
    headers: getAxiosConfig().headers,
  })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      var exportURL = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = exportURL;
      a.download =
        fileName + (showTime ? `_${datetime}` : "") + "." + fileExtension;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
};

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export function poll(callBack, seconds) {
  callBack();
  return window.setInterval(callBack, seconds * 1000);
}

export function getUsername() {
  const userAuth = localStorage.getItem("authUser");
  return userAuth ? JSON.parse(userAuth).username : "";
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function updateURLParameter(url, param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function removeParam(url, key) {
  var rtn = url.split("?")[0],
    param,
    params_arr = [],
    queryString = url.indexOf("?") !== -1 ? url.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export function formatDatesOnList(list, dateFormat, ...args) {
  return list.map(item => {
    const res = { ...item }
    args.forEach(arg => {
      res[arg] = res[arg] ? moment(item[arg]).format(dateFormat) : "N/A"
    })
    return res
  })
}

export const convertToLocalTimezone = (
  date,
  format = "yyyy-MM-DD HH:mm:ss"
) => {
  if (!date) {
    return "N/A";
  }
  return moment.utc(date).local().format(format);
};

