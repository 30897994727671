import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

export default withStyles((theme) => ({
	root: {
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[700],
		},
	},
}))(Button);

export const ErrorButtonOutlined = withStyles((theme) => ({
	root: {
		color: red[500],
		borderColor: red[500],
	},
}))(Button);