import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import Title from '../../components/Common/Title';
import { useCommonStyles } from '../../components/Common/styles';
import Background from 'components/Common/Background';
import InputPassword from '../../components/Inputs/InputPassword';
import { connect } from 'react-redux';
import { apiError, loginUser } from '../../store/auth/login/actions';
import { Formik } from 'formik';
import { BackgroundImage } from '../../assets/images';
import {ReactComponent as SignInLogo} from "../../assets/images/loginLogo.svg";
import {Link as RouteLink, Redirect} from "react-router-dom";
import { useSnackbar } from "notistack";

export const lockoutMsg = "Your account has been locked due to bad login attempts, please try after 30 minutes.";

function SignIn(props) {
  const classes = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { apiError, loginUser, error, loading, isAuth } = props;


  useEffect(() => {
    apiError('');
  }, [apiError]);

  useEffect(() => {
    if (error && error === lockoutMsg) {
      enqueueSnackbar(error, { variant: "error" })
    }
  }, [ error, enqueueSnackbar ])

  if (isAuth) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Background image={BackgroundImage}>
      <Container component="main" className={classes.container}>
        <Box mt={'160px'} style={{ textAlign: 'center' }}>
          <SignInLogo />
        </Box>
        <CssBaseline />
        <Paper elevation={20} className={classes.paperForm}>
          <Title>Sign in</Title>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={(values) => loginUser(values, props.history)}
            validate={(values) => {
              const errors = [];
              for (let value in values) {
                if (!values[value]) {
                  errors.push(['Invalid']);
                }
              }
              return errors;
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              /* and other goodies */
            }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    placeholder={'Enter username'}
                    fullWidth
                    id="username"
                    label="Username"
                    error={error}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                  <InputPassword
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={error}
                    value={values.password}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/*<Link href="#" variant="body2">*/}
                    {/*  Forgot password?*/}
                    {/*</Link>*/}
                    {error && error !== lockoutMsg && (
                      <Typography color={'error'}>
                        Invalid username or password
                      </Typography>
                    )}
                  </div>
                  <Box mt={2}>
                    <Typography color={"textSecondary"} variant={"caption"}>
                      Forgot password?{" "}
                      <RouteLink to="/forgot-password" style={{ textDecoration: "none" }}>
                        <Typography color={"primary"} variant={"caption"}>
                          Click here.
                        </Typography>
                      </RouteLink>{" "}
                    </Typography>
                  </Box>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading || errors[0]}
                      className={classes.submit}
                    >
                      Sign In
                  </Button>
                  </div>
                </form>
              )}
          </Formik>
        </Paper>
      </Container>
    </Background>
  );
}

const mapStatetoProps = (state) => {
  const { error, loading, isAuth } = state.Login;
  return { error, loading, isAuth };
};

export default connect(mapStatetoProps, { loginUser, apiError })(SignIn);
