import React, { Fragment, useEffect, useMemo, useState } from "react";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { Client } from "../../models/model";
import { createOptions, unwrap } from "../../helpers/utils";
import { updateClient } from "../../store/client/actions";
import ClientForm from "./ClientForm";
import Grid from "@material-ui/core/Grid";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SecurityIcon from "@material-ui/icons/Security";
import SectionGroupDescription from "../../components/SectionGroupDescription";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import LicenseList from "./license-list";
import { Button } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import CreateLicenseDialog from "./create-license-dialog";
import { createLicense } from "../../store/license/actions";
import { EntityType } from "../../store/@core/entityType";
import { HiveIcons } from "../../assets/icons";
import ErrorBoundary from "../../components/ErrorBoundary";
import {useEntityProgress, useForm} from "reactcoregk/hooks";

const useStyles = makeStyles({
  paper: {
    padding: 16,
  },
});

const ClientView = (props) => {
  const classes = useStyles();
  const { context, updateClient, createLicense } = props;
  const id = props.match.params.id;
  const [form, handleChange, setForm] = useForm(new Client());
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [busy, error] = useEntityProgress(context.Client, () => {});
  const loadingLicense = context.License.create.isLoading;
  const [openLicense, setOpenLicense] = useState(false);

  useEffect(() => setErrorMessage(error), [error]);
  const verticals = context.CompanyVertical.getAll.result;
  const countries = context.Country.getAll.result;
  const versions = context.Version.getAll.result;
  const clients = context.Client.getAll.result;
  const isLoading = context.Client.getAll.isLoading;

  const verticalOptions = createOptions(verticals);
  const countryOptions = createOptions(countries);
  const versionOptions = createOptions(versions);

  const client = useMemo(() => clients.find((x) => x.id === parseInt(id)), [
    clients,
    id,
  ]);

  useEffect(() => {
    if (client) setForm(client);
  }, [client, setForm]);

  return (
    <SubmenuLayout
      MenuItemsComponent={Fragment}
      title={unwrap(client, "name") || "New client"}
      backLink={"/clients"}
    >
      <Container maxWidth={false}>
        {!isLoading && !client && (
          <Alert severity="error" style={{ marginBottom: 16 }}>
            <AlertTitle>Error</AlertTitle>
            Could not found client with id <strong>{id}</strong>
          </Alert>
        )}
        {errorMessage && (
          <Alert
            severity="error"
            onClose={() => setErrorMessage(null)}
            style={{ marginBottom: 16 }}
          >
            {errorMessage}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item md={4} sm={12} xs={12}>
            <Paper variant={"outlined"} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item style={{ flex: 1 }}>
                  <SectionGroupDescription
                    title={"Client info"}
                    IconComponent={AssignmentIndIcon}
                  />
                </Grid>
                <Grid item>
                  <Button
                    style={{ marginLeft: "auto" }}
                    disabled={busy || !client}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={() => updateClient(form)}
                  >
                    <Save />
                    Update info
                  </Button>
                </Grid>
              </Grid>

              <Box my={3} />
              <ClientForm
                disabled={busy || !client}
                form={form}
                handleChange={handleChange}
                verticalOptions={verticalOptions}
                countryOptions={countryOptions}
                versionOptions={versionOptions}
              />
            </Paper>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper variant={"outlined"} className={classes.paper}>
              <Grid container>
                <Grid item style={{ flex: 1 }}>
                  <SectionGroupDescription
                    title={"Licenses"}
                    IconComponent={SecurityIcon}
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={busy || !client || loadingLicense}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={() => setOpenLicense(true)}
                  >
                    <HiveIcons.AddOutlined />
                    Add License
                  </Button>
                </Grid>
              </Grid>
              <Box my={3} />
              <Grid container>
                <ErrorBoundary>
                  <LicenseList client={client} />
                </ErrorBoundary>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <CreateLicenseDialog
        open={openLicense}
        createEntity={createLicense}
        handleClose={() => setOpenLicense(false)}
        context={context.License}
        entityType={EntityType.License}
        clientId={id}
      />
    </SubmenuLayout>
  );
};

const mapStatetoProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStatetoProps, { updateClient, createLicense })(
  ClientView
);
