// Login Method
import {postData} from "../../helpers/utils";
import {ApiEndpoint} from "../@core/endpoint";
import {EntityType} from "../@core/entityType";
import {handleError} from "reactcoregk/store/api";

export const postNotifications = async (ids) => {
  const url = ApiEndpoint[EntityType.Notification];
  const requests = ids.map((id) => postData(`${url}/${id}`, {}));
  return Promise.all(requests).catch(ex => handleError(ex))
};
