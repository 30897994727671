import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { HiveIcons } from "../assets/icons";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  MyButton: {
    color: theme.palette.primary.main,
    border: `0.5px solid ${theme.palette.primary.main}`,
    borderRadius: "25px",
    height: 40,
    padding: "0 40px",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

function NotFound() {
  const classes = useStyles();

  return (
    <Container maxWidth={false} style={{ height: "100vh" }}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item style={{ margin: "5vh 0" }}>
          <HiveIcons.NotFound />
        </Grid>
        <Grid item>
          <Typography
            style={{
              fontSize: 60,
              color: "#FAFAFA",
              fontWeight: 100,
              textAlign: "center",
            }}
          >
            404
          </Typography>
          <Typography style={{ fontSize: 20, color: "#FAFAFA" }}>
            Error: Page not found!
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 8 + "vh" }}>
          <Button
            className={classes.MyButton}
            component={Link}
            variant="outlined"
            to={"/"}
          >
            Back to home page
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFound;
