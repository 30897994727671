import React, { useMemo, useState } from "react";
import TicketsTable from "./TicketsTable";
import HeaderItems from "components/Header/SubmenuItems";
import { HiveIcons } from "assets/icons";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, IconButton, Typography } from "@material-ui/core";
import CreateNewTicketDialog from "./CreateNewTicketDialog";
import { connect } from "react-redux";
import {
  createSupportTicket,
  deleteSupportTicket,
  getAllSupportTickets,
  updateSupportTicket,
} from "../../store/supportTicket/actions";
import { usePageableEntity } from "helpers/hooks";
import BusyIndicator from "components/Common/BusyIndicator";
import SubmenuLayout from "components/Layouts/SubmenuLayout";
import SupportTicketMenu from "./supportTickeMenu";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import {useSortHandler} from "reactcoregk/hooks";

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    color: "#ffffff",
    fontSize: 14,
  },
  button: {
    alignSelf: "center",
    color: "#A7A7A7",
    fontSize: 12,
    "&:hover": {
      color: "#18C7B6",
      backgroundColor: "transparent",
      opacity: 1,
    },
  },
  ticketButton: {
    color: "#18C7B6",
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: 15,
    textAlign: "center",
    height: 30,
    width: "auto",
    borderRadius: 20,
    backgroundColor: "#1D1F21",
  },
});

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Ticket",
  },
  {
    id: "subject",
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "description",
    disablePadding: false,
    label: "Description",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
];

function MyTickets(props) {
  const {
    context,
    updateSupportTicket,
    createSupportTicket,
    getAllSupportTickets,
    history,
  } = props;
  const classes = useStyles();
  const [ticketDialogIsOpen, setTicketDialogIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const rootParams = useMemo(() => `&page=${currentPage}`, [currentPage]);
  // const handleOpenDialog = _ => {
  //     setTicketDialogIsOpen(true)
  // }

  const sortHandler = useSortHandler();

  const handleCloseDialog = (_) => {
    setTicketDialogIsOpen(false);
  };

  const handleEntityClick = (rowData) => {
    history.push(`/support-tickets/${rowData.id}`);
  };

  const { list, isLoading, totalPages } = usePageableEntity(
    context.SupportTicket,
    getAllSupportTickets,
    rootParams
  );

  const MenuItemsComponent = <SupportTicketMenu />;

  const headerItems = [
    {
      customEl: (
        <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
          Support Tickets
        </Typography>
      ),
    },
    {
      pushRight: true,
      customEl: (
        <IconButton
          aria-describedby={"search"}
          className={classes.button}
          disableRipple
          //onClick={handleClick}
        >
          <HiveIcons.SearchIcon style={{ marginRight: 5 }} />
          Search
        </IconButton>
      ),
    },
    {
      customEl: (
        <IconButton
          aria-describedby={"alerts"}
          className={classes.button}
          disableRipple
          //onClick={handleClick}
        >
          <HiveIcons.Network size={"md"} style={{ marginLeft: 9 }} />
          <HiveIcons.ArrowDownFilled size={"md"} style={{ marginLeft: 5 }} />
        </IconButton>
      ),
    },
    // {
    //     customEl: (
    //         <IconButton
    //             aria-describedby={"alerts"}
    //             className={classes.ticketButton}
    //             disableRipple
    //             onClick={handleOpenDialog}
    //         >
    //             <HiveIcons.Plus />
    //             Create ticket
    //         </IconButton>
    //     ),
    // },
  ];

  return (
    <SubmenuLayout
      MenuItemsComponent={MenuItemsComponent}
      title={"Support tickets"}
    >
      <Container maxWidth={false}>
        <BusyIndicator busy={isLoading && list.length === 0} />
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          style={{ borderBottom: "1px solid rgba(24,199,182,0.15)" }}
        >
          <HeaderItems items={headerItems} />
        </Grid>
        <TicketsTable
          tickets={list}
          headCells={headCells}
          handleEntityClick={handleEntityClick}
          isLoading={isLoading}
          sortHandler={sortHandler}
        />
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage - 1)}
            page={currentPage + 1}
            count={totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
        <CreateNewTicketDialog
          style={{ height: "40vh" }}
          context={context}
          open={ticketDialogIsOpen}
          title="Create support Ticket"
          handleClose={handleCloseDialog}
          createEntity={createSupportTicket}
          updateEntity={updateSupportTicket}
        />
      </Container>
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createSupportTicket,
  updateSupportTicket,
  deleteSupportTicket,
  getAllSupportTickets,
})(MyTickets);
