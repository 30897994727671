/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function FreeSolo({ value, onChange, placeholder, label, required, options = [] }) {
  return (
    <Autocomplete
      multiple
      freeSolo
      id="tags-outlined"
      options={options}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
}
