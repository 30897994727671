import {entityType} from "./module";
import {
    createDeleteMethod,
    createGetAllPageableMethod, createGetMethod, createGetSuccessMethod,
    createPostMethod,
    createPutMethod
} from "reactcoregk/store/actions";
import {getDefaultApiHandler} from "../../helpers/utils";
import Attack from "../../models/Attack";

export const getAllAttacks = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler())
export const createAttack = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateAttack = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const deleteAttack = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())
export const getAttack = (params) => createGetMethod(entityType, params, getDefaultApiHandler())
export const clearAttack = () => createGetSuccessMethod(entityType, new Attack())
