import React from "react";
import { useDefaultTable } from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";

function PatchTable(props) {
  const {
    patches,
    sortHandler,
    headCells,
    handleEntityClick,
    isLoading,
  } = props;

  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
  } = useDefaultTable(patches, sortHandler);

  return (
    <DefaultTable
      headCells={headCells}
      isLoading={isLoading}
      onRowClick={handleEntityClick}
      renderCheckAllCell={renderCheckAllCell}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={patches}
    />
  );
}

export default PatchTable;
