import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select as MuiSelect } from "@material-ui/core";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";

const Select = (props) => {
  const {
    onChange,
    options,
    placeholder,
    id,
    label,
    value,
    hasNone,
    controlId,
    fullWidth,
    margin,
    disabled,
    customOption,
    ...other
  } = props;

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      disabled={disabled}
      placeholder={placeholder}
      {...other}
    >
      <InputLabel id={controlId}>{label}</InputLabel>
      <MuiSelect labelId={controlId} id={id} onChange={onChange} value={value}>
        {hasNone && (
          <MenuItem value={0}>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => {
          const Icon = option.icon;
          return (
            <MenuItem
              key={`${controlId}-options-${option.value}`}
              value={option.value}
              selected={false}
              style={{ display: "flex", alignItems: "center" }}
            >
              {Boolean(option.icon) && (
                <Icon style={{ height: 14, width: 14, marginRight: 2 }} />
              )}
              <span>{option.label}</span>
            </MenuItem>
          );
        })}
        {customOption && customOption}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;

Select.defaultProps = {
  fullWidth: true,
  margin: "normal",
};

Select.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  hasNone: PropTypes.bool,
  controlId: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  margin: PropTypes.oneOf(["normal", "dense", "none"]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};
