import React from "react";
import { Box } from "@material-ui/core";
import UserIcon from "./UserIcon";
import PrimaryNavigation from "../Navigation/PrimaryNavigation";
import { useAppTheme } from "../../constants/theme";
import {authProtectedRoutes} from "../../routes";
import {createMap} from "reactcoregk/utils";
import {Auth} from "../../models/Auth";

const menuItems = [
  {
    label: "Dashboard",
    path: "/dashboard",
    divider: true,
  },
  {
    label: "Clients",
    path: "/clients",
    divider: false,
  },
  {
    id: "users",
    label: "Users",
    path: "/users",
    divider: false,
  },
  {
    label: "Alerts",
    path: "/alerts",
    divider: false,
  },
  {
    label: "CVE",
    path: "/cve-definitions",
    divider: false,
  },
  {
    label: "Policies",
    path: "/policies",
    divider: false,
  },
  {
    label: "Attacks",
    path: "/attacks",
    divider: false,
  },
  {
    label: "Collections",
    path: "/collections",
    divider: false,
  },
  {
    label: "Patch Intelligence",
    path: "/patches",
    divider: false,
  },
  {
    label: "Actors",
    path: "/actors",
    divider: false,
  },
  {
    label: "Countries",
    path: "/countries",
    divider: false,
  },
  {
    label: "Industries",
    path: "/industries",
    divider: false,
  },
  {
    label: "Rules",
    path: "/rules",
    divider: false,
  },
  {
    label: "Products",
    path: "/products",
    divider: false,
  },
  {
    customEl: () => <Box ml={"auto"} />,
  },
  {
    label: "Jobs",
    path: "/jobs",
    divider: false,
  },
  {
    label: "Support Tickets",
    path: "/support-tickets",
    divider: true,
  },
  {
    customEl: () => <UserIcon />,
  },
];


const Navigation = () => {
  const theme = useAppTheme();
  const userRoles = Auth.getRoles();
  const routeMap = createMap(authProtectedRoutes, "path")

  const itemsWithAuthorities = menuItems.map(item => {
    return {
      ...item,
      authorities: routeMap.get(item.path)?.authorities
    }
  })

  const filtered = itemsWithAuthorities.filter(item => {
    const {authorities} = item
    if (authorities && authorities.length !== 0) {
      return authorities.some((r) => userRoles.includes(r));
    }
    return true
  })

  return <PrimaryNavigation theme={theme} navigationLinks={filtered} />;
};

export default Navigation;
