import React, { useCallback, useState } from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import {
  useDependency,
  useEntityProgress,
  useMasterDetailWithDialog,
  useSortHandler,
} from "reactcoregk/hooks";
import AttacksTable from "./attacks-table";
import Subheader from "./subheader";
import {
  useCurrentPageParam,
  useDefaultTable,
  usePageableEntity,
  useSortHandlerPath,
} from "../../helpers/hooks";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import {
  createAttack,
  getAllAttacks,
  updateAttack,
} from "../../store/attack/actions";
import EditDialog from "./EditDialog";
import { getAllTechniques } from "../../store/technique/actions";

const defaultCells = [
  {
    id: "id",
    numeric: false,
    label: "ID",
    isSortable: true,
  },
  {
    id: "name",
    numeric: false,
    label: "Name",
  },
  {
    id: "format",
    numeric: false,
    label: "Format",
  },
  {
    id: "type",
    numeric: false,
    label: "Type",
  },
  {
    id: "createdAt",
    numeric: false,
    label: "Created At",
    isSortable: true,
  },
  {
    id: "updatedAt",
    numeric: false,
    label: "Updated At",
    isSortable: true,
  },
  {
    id: "createdByUsername",
    numeric: false,
    label: "Created By",
    isSortable: false,
  },
  {
    id: "updatedByUsername",
    numeric: false,
    label: "Updated By",
    isSortable: false,
  },
];

function Attacks(props) {
  const {
    context,
    getAllAttacks,
    createAttack,
    updateAttack,
    getAllTechniques,
  } = props;

  // Handlers for subheader controls
  const rootParams = props.location.search.replace("?", "&");
  const [currentPage, setCurrentPage] = useCurrentPageParam();
  const [openActors, setOpenActors] = useState(false);
  const [openCves, setOpenCves] = useState(false);

  // Handlers for new or existing entity to show in dialog
  const { openModal, handleCloseModal, handleCreateNew, entity } =
    useMasterDetailWithDialog(Schema[EntityType.Attack]);

  // Sort handler used by default table
  const sortHandler = useSortHandler();

  // Callback to trigger on useDependency hook
  const initializeDependency = useCallback(() => {
    getAllAttacks("?page=0&size=20");
  }, [getAllAttacks]);

  useDependency(context.Technique, getAllTechniques);

  // Refresh table after a successful post
  useEntityProgress(context.Attack, initializeDependency);

  // Get state for current entity, and handler to manage paging
  const { list, totalPages, isLoading } = usePageableEntity(
    context.Attack,
    getAllAttacks,
    rootParams
  );

  useSortHandlerPath(sortHandler.sort);

  // Init default table
  const defaultTable = useDefaultTable(list, sortHandler);

  // Create the subheader options
  const AttackMenu = (
    <Subheader
      handleCreateNew={handleCreateNew}
      selected={defaultTable.selected}
    />
  );

  return (
    <SubmenuLayout MenuItemsComponent={AttackMenu} title={"Attacks"}>
      <Container maxWidth={false}>
        <AttacksTable
          headCells={defaultCells}
          isLoading={isLoading}
          defaultTable={defaultTable}
          attacks={list}
          handleEntityClick={(entity) => {
            props.history.push("/attacks/" + entity.id);
          }}
        />
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage - 1)}
            page={currentPage}
            count={totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
      </Container>
      <EditDialog
        context={context.Attack}
        open={openModal}
        handleClose={handleCloseModal}
        entity={entity}
        techs={context.Technique.getAll.result}
        createEntity={createAttack}
        openActors={openActors}
        setOpenActors={setOpenActors}
        openCves={openCves}
        setOpenCves={setOpenCves}
        updateEntity={updateAttack}
        entityType={EntityType.Attack}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {
  getAllAttacks,
  createAttack,
  updateAttack,
  getAllTechniques,
})(Attacks);
