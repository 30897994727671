import React, { useMemo, useState } from "react";
import {
  getParameterByName,
  removeParam,
  updateURLParameter,
} from "../../helpers/utils";
import { Button, Grid } from "@material-ui/core";
import Select from "../Inputs/Select";
import Chip from "@material-ui/core/Chip";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

const TableFilters = ({ filterOptions, handleDismiss }) => {
  const location = useLocation();
  const history = useHistory();

  const [locationSearch, setLocationSearch] = useState(location.search);

  const handleChange = (prop) => (e) => {
    const value = e.target.value || "";
    let search = "";
    if (e.target.value) {
      search = updateURLParameter(locationSearch, prop, value);
    } else {
      search = removeParam(locationSearch, prop);
    }
    setLocationSearch(search);
  };

  const handleDelete = (prop) => {
    const search = removeParam(locationSearch, prop);
    setLocationSearch(search);
    history.push({
      pathname: location.pathname,
      search: search,
    });
  };

  const handleReset = () => {
    setLocationSearch("");
    history.push({
      pathname: location.pathname,
      search: "",
    });
  };

  const handleApplyFilters = () => {
    const verifyLocationSearch = locationSearch
      .split("&")
      .filter((item) => !item.endsWith("="));
    const finalSearch = verifyLocationSearch.join("&");
    history.push({
      pathname: location.pathname,
      search: finalSearch,
    });
    handleDismiss();
  };

  const extraParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const filtered = [];
    searchParams.forEach(function (value, key) {
      const isExtra =
        !filterOptions.find((z) => z.value === key) &&
        key !== "sort" &&
        key !== "page";
      if (isExtra && value) {
        filtered.push({
          key,
          value,
        });
      }
    });
    return filtered;
  }, [filterOptions, location.search]);

  return (
    <Grid container direction={"column"} style={{ padding: 16 }}>
      <Grid item container spacing={1}>
        {extraParams.map((extraParam) => (
          <Grid item>
            <Chip
              size={"small"}
              label={`${extraParam.key}: ${extraParam.value}`}
              onDelete={() => handleDelete(extraParam.key)}
            />
          </Grid>
        ))}
      </Grid>
      {filterOptions.map(({ value, options, label, customEl }) => (
        <Grid item key={value}>
          {customEl ? (
            customEl(locationSearch, setLocationSearch)
          ) : (
            <Select
              value={getParameterByName(value, locationSearch) || 0}
              onChange={handleChange(value)}
              options={options}
              fullWidth
              controlId={value}
              label={label}
              placeholder={"All"}
              hasNone={true}
              style={{ width: "100%" }}
            />
          )}
        </Grid>
      ))}
      <Grid item style={{ marginTop: 16 }}>
        <Button
          fullWidth
          color={"primary"}
          variant={"outlined"}
          onClick={handleReset}
        >
          Reset Filters
        </Button>
      </Grid>
      <Grid item style={{ marginTop: 16 }}>
        <Button
          fullWidth
          color={"primary"}
          variant={"contained"}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </Grid>
    </Grid>
  );
};

export default TableFilters;
