import React, {useEffect, useState} from "react";
import BaseDialog from "./Common/BaseDialog";
import Grid from "@material-ui/core/Grid";
import {Card, CardActionArea, CardContent, styled} from "@material-ui/core";

function importAll(r) {
  return r.keys().map(r);
}

export const loadProductIconLibrary = () => {
  const images = importAll(require.context("../assets/icons/products", false, /\.(png)$/));
  return images.map((src) => {
    return { src };
  });
};
const images = loadProductIconLibrary();

const StyledCard = styled(Card)((props) => ({
  backgroundColor: props.selected ? props.theme.palette.primary.main : props.theme.palette.background.default
}))

function CardIcon({ image, selected, setSelected }) {
  return (
    <Grid item>
      <StyledCard variant={"outlined"} selected={selected === image.src}>
        <CardActionArea
          onClick={() => setSelected(image.src)}
        >
          <CardContent>
            <img src={image.src} style={{width:120, height:120, objectFit: "scale-down"}} alt={image.src}/>
          </CardContent>
        </CardActionArea>
      </StyledCard>
    </Grid>
  );
}

function ProductIconLibraryDialog({ open, handleClose, onChange, value  }) {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (open) setSelected(value)
  }, [open, value])

  const handleConfirm = () => {
    onChange(selected)
    handleClose()
  }

  return (
    <BaseDialog
      onClose={handleClose}
      open={open}
      title={"Icon Library"}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      primaryActionTitle={"Confirm"}
    >
      <Grid container spacing={2} justify={"flex-start"}>
        {images.map((image, index) => {
          return (
            <CardIcon
              key={index}
              image={image}
              selected={selected}
              setSelected={setSelected}
            />
          );
        })}
      </Grid>
    </BaseDialog>
  );
}

export default ProductIconLibraryDialog;
