import { all } from "redux-saga/effects";
//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/changepwd/saga";
import NotificationSaga from "./notification/saga";
import CompanyVerticalSaga from "./company-vertical/saga";
import RoleSaga from "./role/saga";
import CountrySaga from "./country/saga";
import VersionSaga from "./version/saga";
import UserSaga from "./user/saga";
import ClientSaga from "./client/saga";
import LicenseSaga from "./license/saga";
import CveDefinitionSaga from "./cveDefinition/saga";
import SupportTicket from "./supportTicket/saga";
import AlertSaga from "./alert/saga";
import PolicySaga from "./policy/saga";
import AttackSaga from "./attack/saga";
import TechniqueSaga from "./technique/saga";
import TacticSaga from "./tactic/saga";
import CollectionSaga from "./collection/saga";
import PatchSaga from "./patch/saga";
import ActorSaga from "./actor/saga";
import ProductSaga from "./product/saga";
import JobSaga from "./job/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    // Authenticated
    NotificationSaga(),
    CompanyVerticalSaga(),
    RoleSaga(),
    CountrySaga(),
    VersionSaga(),
    UserSaga(),
    ClientSaga(),
    LicenseSaga(),
    CveDefinitionSaga(),
    SupportTicket(),
    AlertSaga(),
    PolicySaga(),
    AttackSaga(),
    TechniqueSaga(),
    TacticSaga(),
    CollectionSaga(),
    PatchSaga(),
    ActorSaga(),
    ProductSaga(),
    JobSaga(),
  ]);
}
