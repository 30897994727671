import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { useDefaultTable } from "../../helpers/hooks";
import BaseDialog from "../../components/Common/BaseDialog";
import Title from "../../components/Common/Title";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { fetchData } from "../../helpers/utils";
import { Paging } from "reactcoregk/models";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import SearchBox from "../../components/Common/SearchBox";
import { makeStyles } from "@material-ui/styles";
import CveTable from "./CveTable";
import { FormControlLabel, Switch } from "@material-ui/core";

const url = ApiEndpoint[EntityType.CveDefinition];

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});

function ManageCvesDialog(props) {
  const classes = useStyles();

  const { alert, handleClose, open, callback } = props;

  const [page, setPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const [cves, setCves] = useState(new Paging());
  const [errorMessage, setErrorMessage] = useState(null);
  const [query, setQuery] = useState("");
  const [finalQuery, setFinalQuery] = useState("");
  const [cveInputs, setCveInputs] = useState({});
  const [showSelected, setShowSelected] = useState(false);
  const [initialCves, setInitialCves] = useState([]);

  const mapped = useMemo(() => {
    return cves.content.map((row) => {
      return {
        ...row,
        id: row.cve,
      };
    });
  }, [cveInputs, cves.content]);

  useEffect(() => {
    if (open) {
      setBusy(true);
      const APIURL = `${url}?page=${page}&sort=cve,asc&size=20&s=${finalQuery}`;
      const params = showSelected ? `&cves=${selected.join(",")}` : "";
      fetchData(`${APIURL}${params}`)
        .then((data) => {
          if (!showSelected) setCves(data);
          if (showSelected) {
            setSelectedCves(data.content);
            setInitialSelected(selected);
            setInitialCves(data);
          }
          setBusy(false);
        })
        .catch((ex) => setBusy(false));
    }
  }, [open, page, finalQuery, showSelected]);

  const sortHandler = useSortHandler();
  const defaultTable = useDefaultTable(mapped, sortHandler);
  const { selected, setSelected } = defaultTable;

  // For the initial values table
  const [selectedCves, setSelectedCves] = useState([]);

  // Return the mapped values needed for the initial cves table. Any time the user adds or
  // removes a CVE from the main selected CVEs, the mapped objects gets updated.
  const mappedDefaultCves = useMemo(() => {
    return selectedCves
      .filter((cve) => selected.includes(cve.cve))
      .map((row) => {
        return {
          ...row,
          id: row.cve,
        };
      });
  }, [cveInputs, selectedCves, selected]);

  const initialCvesTable = useDefaultTable(mappedDefaultCves, sortHandler);
  const { selected: initialSelected, setSelected: setInitialSelected } =
    initialCvesTable;

  useEffect(() => {
    setSelected(initialSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelected, setSelected]);

  // End of initial values table logic

  useEffect(() => {
    if (open && alert && alert.cves && alert.cves[0]) {
      const map = {};
      const ids = [];
      alert.cves.forEach((cve) => {
        map[cve.cveId] = {
          ...cve,
        };
        ids.push(cve.cveId);
      });
      setCveInputs(map);
      setSelected(ids);
    }
  }, [alert, open, setSelected]);

  const handleSave = () => {
    callback(
      selected.map((cveId) => ({
        ...cveInputs[cveId],
        cveId,
      }))
    );
    handleClose();
    setPage(0);
    setErrorMessage(null);
  };

  return (
    <BaseDialog
      title={"Manage CVES"}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleSave}
      inProgress={busy}
      errorMessage={errorMessage}
      disableBackdropClick
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>Select CVES to add</Title>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showSelected}
                    onChange={() => {
                      setShowSelected((s) => !s);
                      setPage(0);
                    }}
                    name="selected"
                    color="primary"
                  />
                }
                label="Show Selected"
              />
            </Grid>
            <Grid item>
              <SearchBox
                placeholder={"cve, created or last update"}
                handleChange={(e) => setQuery(e.target.value)}
                query={query}
                syncQuery={() => {
                  setPage(0);
                  setFinalQuery(query);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!showSelected && (
          <>
            <Grid item>
              <CveTable
                cves={mapped}
                isLoading={busy}
                defaultTable={defaultTable}
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={cves.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        )}

        {showSelected && (
          <>
            <Grid item>
              <CveTable
                isLoading={busy}
                cves={mappedDefaultCves}
                defaultTable={initialCvesTable}
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={initialCves.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        )}
      </Grid>
    </BaseDialog>
  );
}

export default ManageCvesDialog;
