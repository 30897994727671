import {Operation} from "reactcoregk";
import {EntityType} from "../@core/entityType";

export const entityType = EntityType.Alert;
export const entityOperations = [
  Operation.getAllPageable,
  Operation.get,
  Operation.create,
  Operation.update,
  Operation.delete,
];
