import Entity from "./Entity";

class Alert extends Entity {
	constructor() {
		super();
		this.actorId = null
		this.region = ""
		this.name = ""
		this.profileLink = ""
		this.image = ""
		this.mediaGalleryImageId = ""
		this.description = ""
		this.shortDescription = ""
		this.completeDescription = ""
		this.firstActivityDate = null
		this.lastActivityDate = null
		this.actorType = ""
		this.capability = "AVERAGE"
		this.knownAs = ""
		this.killChain = ""
		this.motivations = []
		this.origins = []
		this.targetCountries = []
		this.targetIndustries = []
		this.cves = []
	}
}
export default Alert
