import React, { useMemo } from "react";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import { connect } from "react-redux";
import { HiveIcons } from "../../assets/icons";
import PopoverContainerBase from "./PopoverContainerBase";
import Badge from "@material-ui/core/Badge";
import { useCommonMenuItemStyles } from "./styles";
import MenuButton from "./MenuButton";
import { useLocation } from "react-router-dom";

function NotificationIcon(props) {
  const classes = useCommonMenuItemStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  const filterCount = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    let count = 0;
    searchParams.forEach(function (value, key) {
      if (value && key !== "sort" && key !== "page") {
        count += 1;
      }
    });
    return count;
  }, [location.search]);

  return (
    <>
      <MenuButton onClick={handleClick}>
        <HiveIcons.Network size={"md"} style={{ marginLeft: 8 }} />
        {open ? (
          <Badge badgeContent={filterCount} color="primary">
            <HiveIcons.ArrowUpFilled size={"md"} />
          </Badge>
        ) : (
          <Badge badgeContent={filterCount} color="primary">
            <HiveIcons.ArrowDownFilled size={"md"} />
          </Badge>
        )}
      </MenuButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
        disablePortal={false}
        style={{ zIndex: 4 }}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            // element: arrowRef,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div className={classes.paper}>
              <PopoverContainerBase
                badge={props.activeFilters}
                title={"Filters"}
                clickAway={false}
                handleClose={() => setAnchorEl(null)}
                open={Boolean(anchorEl)}
              >
                {React.cloneElement(props.children, {
                  handleDismiss: () => setAnchorEl(null),
                })}
              </PopoverContainerBase>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    unreadCount: state.Notification.unreadCount,
  };
};

export default connect(mapStateToProps, {})(NotificationIcon);
