import { makeStyles } from "@material-ui/core/styles";

const useCommonStyles = makeStyles((theme) => ({
  paperForm: {
    marginTop: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    maxWidth: 200,
    display: "block",
    alignSelf: "center",
  },
  container: {
    maxWidth: "676px !Important",
  },
  alert: {
    width: "100%",
    marginTop: 8,
  },
}));

const useCommonWidgetStyles = makeStyles((theme) => ({
  cardActionAreaBg: {
    backgroundColor: theme.palette.type === "dark" ? "#1E1D1D" : "#F7F7F7",
  },
  titleLabel: {
    fontWeight: "normal",
    color: "rgba(150,150,150,1)",
    fontSize: 10,
  },
  titleValue: {
    fontWeight: "bold",
    color: theme.palette.type === "dark" ? "#ff" : "#000",
    fontSize: 11,
    marginLeft: "5px",
  },
  listRow: {
    backgroundColor:
      theme.palette.type === "dark" ? "#1E1D1D" : "rgba(189,189,189,0.12)",
    padding: 8,
    borderRadius: "4px 0 0 4px",
    marginBottom: 8,
    minHeight: "50px",
    borderLeft: `3px solid transparent`,
  },
  borderError: {
    borderLeft: `3px solid #FF2E2D`,
  },
  borderPending: {
    borderLeft: `3px solid #F1C233`,
  },
  borderInitial: {
    borderLeft: `3px solid rgba(31,99,250,1)`,
  },
  colorError: {
    color: "#FF2E2D",
    fontSize: "12px",
  },
  colorPending: {
    color: "#F1C233",
    fontStyle: "italic",
    fontSize: "12px",
  },
  listName: {
    color: theme.palette.type === "dark" ? "#ff" : "#000",
    fontSize: "11px",
  },
  etaTime: {
    color: "#158BD1",
    fontSize: "12px",
  },
  listCaption1: {
    color:
      theme.palette.type === "dark"
        ? "rgba(150,150,150,1)"
        : theme.palette.text.primary,
    fontSize: "12px",
  },
  listCaption2: {
    color: theme.palette.type === "dark" ? "#ff" : "#000",
    fontSize: "12px",
    fontWeight: "bold",
  },
  seeAll: {
    fontSize: "13px",
    color: theme.palette.primary.main,
    textAlign: "right",
    marginBottom: "5px",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  date: {
    fontSize: "12px",
    color:
      theme.palette.type === "dark"
        ? "rgba(150,150,150,1)"
        : theme.palette.text.primary,
  },
}));

const useCommonMenuItemStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  button: {
    alignSelf: "center",
    color: theme.palette.text.secondary,
    fontSize: 12,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      opacity: 1,
    },
  },
}));

const useCommonDetailedPage = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  basicText: {
    fontSize: 13,
    color: theme.palette.text.secondary,
    opacity: theme.palette.type === "dark" ? 1 : 0.6
  },
  riskText: {
    color: theme.palette.text.secondary,
    opacity: theme.palette.type === "dark" ? 1 : 0.6,
    fontSize: 11,
    letterSpacing: 0,
    lineHeight: "13px",
  },
  riskDataText: {
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  highlight: {
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  impactDescText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: "20px",
  },
  impactTargets: {
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "24px",
  },
  impactText: {
    color: "#F05E13",
    fontSize: 45,
    fontWeight: 300,
    lineHeight: "53px",
  },
  link: {
    color:
        theme.palette.type === "dark"
            ? theme.palette.common.white
            : theme.palette.common.black,
  },
  paper: {
    padding: "6px 14px",
    borderRadius: 6,
    height: 40,
    margin: "5px 0",
  },
  labelText: {
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: "14px",
  },
  descTextAlternative: {
    color: theme.palette.text.secondary,
    opacity: theme.palette.type === "dark" ? 1 : 0.6,
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: "14px",
  },
  dateText: {
    color: "#6796FF",
    fontFamily: "Helvetica",
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: "14px",
    fontWeight: "bold",
    marginRight: 10,
  },
  commentBox: {
    height: 70,
    maxWidth: 600,
    width: 100 + "%",
    opacity: 0.6,
    borderRadius: "6px",
    padding: "10px",
    marginTop: 16
  },
  userNameText: {
    fontWeight: 300,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  commentText: {
    color: theme.palette.text.secondary,
    opacity: theme.palette.type === "dark" ? 1 : 0.6,
    fontSize: 16,
    letterSpacing: 0,
  },
}));


export { useCommonStyles, useCommonWidgetStyles, useCommonMenuItemStyles, useCommonDetailedPage };
