import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { HiveIcons } from "../../assets/icons";
import SearchBox from "../../components/Common/SearchBox";
import { useTableSearch } from "../../helpers/hooks";

const CountriesSubheader = (props) => {
  const [query, handleChange, syncQuery] = useTableSearch();

  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },
    {
      divider: true,
    },
    {
      title: "New Country",
      icon: <HiveIcons.AddOutlined />,
      onClick: props.handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default CountriesSubheader;
