import { combineReducers } from "redux";
// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ChangePassword from "./auth/changepwd/reducer";
import Notification from "./notification/reducer";
import Role from "./role/reducer";
import Country from "./country/reducer";
import CompanyVertical from "./company-vertical/reducer";
import Version from "./version/reducer";
import User from "./user/reducer";
import Client from "./client/reducer";
import License from "./license/reducer";
import CveDefinition from "./cveDefinition/reducer";
import SupportTicket from "./supportTicket/reducer";
import Alert from "./alert/reducer";
import Policy from "./policy/reducer";
import Attack from "./attack/reducer";
import Product from "./product/reducer";
import Technique from "./technique/reducer";
import Tactic from "./tactic/reducer";
import Collection from "./collection/reducer";
import Patch from "./patch/reducer";
import Actor from "./actor/reducer";
import Job from "./job/reducer";

const rootReducer = combineReducers({
  // public
  Login,
  Account,
  ChangePassword,
  Notification,
  Role,
  Country,
  CompanyVertical,
  Version,
  User,
  Client,
  License,
  CveDefinition,
  SupportTicket,
  Alert,
  Policy,
  Attack,
  Product,
  Technique,
  Tactic,
  Collection,
  Patch,
  Actor,
  Job
});

export default rootReducer;
