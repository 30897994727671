import React, { useMemo } from "react";
import EntityDialog from "../../components/EntityDialog";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { License } from "../../models/model";
import { useForm } from "reactcoregk/hooks";
import Select from "../../components/Inputs/Select";
import { MULTI_TENANT_MODES, PROXY_MODES } from "../../constants/enums";

const licence = new License();
const singleTenant = MULTI_TENANT_MODES[0].value;
function CreateLicenseDialog(props) {
  const { clientId } = props;
  const [form, handleChange, setForm] = useForm(licence);

  const finalForm = useMemo(() => {
    return {
      ...form,
      clientId: clientId,
      startsAt: moment(form.startsAt).format("YYYY-MM-DDTHH:mm:ss.SSS"),
      expiresAt: moment(form.expiresAt).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    };
  }, [clientId, form]);

  const isValid = form.startsAt && form.expiresAt && form.numberOfTenants > 0;

  const handleChangeTenant = (e) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...form,
      multiTenantMode: value,
      numberOfTenants: value === singleTenant ? 1 : prevState.numberOfTenants,
    }));
  };

  return (
    <EntityDialog
      titleOverride={"Add license"}
      {...props}
      entity={licence}
      form={finalForm}
      isValid={Boolean(isValid)}
      maxWidth={"sm"}
    >
      <form>
        <Select
          variant="standard"
          fullWidth
          margin={"normal"}
          value={form.proxyMode}
          onChange={handleChange("proxyMode")}
          label="Proxy Mode"
          controlId={"proxyMode"}
          options={PROXY_MODES}
        />
        <Select
          variant="standard"
          fullWidth
          margin={"normal"}
          value={form.multiTenantMode}
          onChange={handleChangeTenant}
          label="Tenant Mode"
          controlId={"multiTenantMode"}
          options={MULTI_TENANT_MODES}
        />
        {form.multiTenantMode !== singleTenant && (
          <TextField
            variant="standard"
            fullWidth
            margin={"normal"}
            disabled={form.multiTenantMode === singleTenant}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            value={form.numberOfTenants}
            onChange={handleChange("numberOfTenants")}
            label="Number of Tenants"
          />
        )}
        <TextField
          variant="standard"
          fullWidth
          margin={"normal"}
          type="datetime-local"
          value={form.startsAt}
          onChange={handleChange("startsAt")}
          label="Starts at"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          variant="standard"
          margin={"normal"}
          fullWidth
          type="datetime-local"
          value={form.expiresAt}
          onChange={handleChange("expiresAt")}
          label="Expires at"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </EntityDialog>
  );
}

export default CreateLicenseDialog;
