import React, { useState } from "react";
import Menu from "./Menu";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import UploadCvesDialog from "./UploadCvesDialog";
import DefaultTable from "../../components/Common/DefaultTable";
import Container from "@material-ui/core/Container";
import { useSortHandler } from "reactcoregk/hooks";
import { useDefaultTable, useJobs } from "../../helpers/hooks";
import { connect } from "react-redux";
import { getAllJobs } from "../../store/job/actions";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { convertToLocalTimezone } from "../../helpers/utils";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 450,
  },
}));

const defaultCells = [
  {
    id: "uuid",
    label: "Job ID",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "startedAt",
    label: "Start time",
  },
  {
    id: "finishedAt",
    label: "End time",
  },
  {
    id: "progress",
    label: "Progress percentage",
  },
  {
    id: "message",
    label: "Message",
  },
  {
    id: "totalRows",
    label: "Total rows",
  },
  {
    id: "failedRows",
    label: "Failed rows",
  },
  {
    id: "error",
    label: "Error",
  },
];

const progress = (finished, data) => {
  if (finished) return "-";
  if (!data) return "-";
  if (data.totalRows > 0) {
    if (!data.processedRows) return "0%";
    if (data.processedRows === 0) return "1%";
    return `${Math.round((data.processedRows / data.totalRows) * 100)}%`;
  } else {
    return "100%";
  }
};

function Jobs(props) {
  const { context, getAllJobs } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { isLoading, result } = context.Job.getAll;
  useJobs(result);

  const MenuItemsComponent = <Menu handleAddNew={() => setOpen(true)} />;

  // Sort handler used by default table
  const sortHandler = useSortHandler();
  const { renderHeadCell, renderBodyCell } = useDefaultTable(
    result,
    sortHandler
  );

  const renderDefaultBody = () => {
    return result.map((job) => {
      const percentage = progress(job?.finishedAt, job?.data);
      const displayError =
        job.error && job.error.length > 0 ? (
          <Tooltip
            title={<Typography variant={"caption"}>{job.error.join(" ")}</Typography>}
            placement={"left"}
            arrow
            classes={{ tooltip: classes.customWidth }}
          >
            <VisibilityOutlinedIcon fontSize={"small"} />
          </Tooltip>
        ) : (
          "N/A"
        );
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={job.uuid}>
          {renderBodyCell(job.uuid)}
          {renderBodyCell(job.status)}
          {renderBodyCell(job.startedAt ? convertToLocalTimezone(job.startedAt, "lll") : "N/A")}
          {renderBodyCell(job.finishedAt ? convertToLocalTimezone(job.finishedAt, "lll") : "N/A")}
          {renderBodyCell(percentage)}
          {renderBodyCell(job.data && job.data?.message ? job.data.message : "N/A")}
          {renderBodyCell(job.data && job.data?.totalRows ? job.data.totalRows : "-")}
          {renderBodyCell(job.data && job.data?.failedRows ? job.data.failedRows : "-")}
          {renderBodyCell(displayError)}
        </TableRow>
      );
    });
  };

  return (
    <SubmenuLayout
      MenuItemsComponent={MenuItemsComponent}
      title={"Jobs"}
      noDivider
    >
      <Container maxWidth={false}>
        <DefaultTable
          headCells={defaultCells}
          rows={result}
          renderHeadCell={renderHeadCell}
          renderDefaultBody={renderDefaultBody}
          isLoading={isLoading}
        />
      </Container>
      <UploadCvesDialog
        open={open}
        handleClose={() => setOpen(false)}
        refreshJobs={getAllJobs}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Job: state.Job,
    },
  };
};
export default connect(mapStateToProps, {
  getAllJobs,
})(Jobs);
