import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import getCoreSagas from "reactcoregk/store/saga";
import {ApiEndpoint} from "../@core/endpoint";
import {postData} from "../../helpers/utils";
import {ActionStates, buildAsyncActionType} from "reactcoregk/store/actions";
import {createCveDefinitionActions} from "./actions";
import {EntityType} from "../@core/entityType";

const coreSagas = getCoreSagas(entityType, ApiEndpoint, entityOperations)
function* uploadCve({ payload }) {
    try {

        const url = ApiEndpoint[EntityType.CveDefinition] + "/upload-definitions";
        yield call(postData, url, payload);
        yield put(createCveDefinitionActions.success(payload));
    } catch (error) {
        yield put(createCveDefinitionActions.failure(error.message));
    }
}

export function* watchCveUpload() {
    const actionType = buildAsyncActionType(entityType, "UPLOAD_CVE", ActionStates.request);
    yield takeEvery(actionType, uploadCve);
}
function* sagas() {
    coreSagas.push(fork(watchCveUpload));
    yield all(coreSagas);
}

export default sagas;
