import React, {useEffect, useMemo, useState} from "react";
import EntityDialog from "../../components/EntityDialog";
import { EntityType } from "../../store/@core/entityType";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useForm } from "reactcoregk/hooks";
import { Schema } from "../../store/@core/schema";

function EditDialog(props) {
  const { entity, open } = props;
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Version]);
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (entity) setForm(entity);
  }, [entity, setForm, open]);

  const validate = () => {
    return Boolean(
      form.name && form.date && form.version && form.description && form.changes
    );
  };
  const isValid = validate();

  const finalForm = useMemo(() => {
    return {
      ...form,
      date: moment(form.date).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    };
  }, [form]);

  const hasValidVersion = () => {
    const regex = new RegExp("^(\\d+\\.)(\\d+\\.)(\\d+)$")
    if (regex.test(form.version) === true) {
      return ""
    }
    return "Version format should follow xx.xx.xx"
  }

  const validation = hasValidVersion()

  return (
    <EntityDialog {...props} form={finalForm} maxWidth={"sm"} isValid={isValid}>
      <Grid container direction={"column"}>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter name"}
            fullWidth
            value={form.name}
            onChange={handleChange("name")}
            label="Name"
            autoFocus
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="normal"
              fullWidth
              type="datetime-local"
              value={form.date}
              onChange={handleChange("date")}
              label="Date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="normal"
              placeholder={"Enter version No"}
              fullWidth
              type={"text"}
              error={dirty && !!validation}
              value={form.version}
              helperText={dirty && validation}
              onBlur={() => setDirty(true)}
              onChange={handleChange("version")}
              label="Version No"
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            placeholder={"Enter description"}
            fullWidth
            value={form.description}
            onChange={handleChange("description")}
            label="Description"
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            placeholder={"Enter changes"}
            fullWidth
            multiline
            value={form.changes}
            onChange={handleChange("changes")}
            label="Changes"
          />
        </Grid>
      </Grid>
    </EntityDialog>
  );
}

export default EditDialog;
