import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import {
  useDependency,
  useEntityProgress,
  useMasterDetailWithDialog,
  useSortHandler,
} from "reactcoregk/hooks";
import AlertTable from "./alert-table";
import AlertSubheader from "./alert-subheader";
import {
  useDefaultTable,
  usePageableEntity,
  useQuery,
  useSortHandlerPath,
} from "../../helpers/hooks";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import {
  createAlert,
  deleteAlert,
  getAllAlerts,
  updateAlert,
} from "../../store/alert/actions";
import EditDialog from "./EditDialog";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import { createOptions } from "reactcoregk/utils";
import Grid from "@material-ui/core/Grid";
import { Pagination } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import { getAllTechniques } from "../../store/technique/actions";
import moment from "moment";
const defaultCells = [
  {
    id: "id",
    label: "Alert ID",
    isSortable: true,
  },
  {
    id: "shortName",
    label: "Short Name",
  },
  {
    id: "threatLevel",
    label: "Threat Level",
  },
  {
    id: "lastUpdatedAt",
    label: "Last Update",
    isSortable: true,
  },
  {
    id: "createdByName",
    label: "Created By",
  },
  {
    id: "actions",
    label: "",
  },
];

function Alerts(props) {
  const {
    context,
    getAllAlerts,
    createAlert,
    getAllTechniques,
    updateAlert,
    deleteAlert,
  } = props;

  // Handlers for subheader controls
  // const { query, handleChangeQuery } = useSubheaderLogic();
  const [currentPage, setCurrentPage] = useState(0);
  const query = useQuery();
  const s = query.get("s") || "";
  useEffect(() => {
    if (s) {
      setCurrentPage(0);
    }
  }, [s]);
  const rootParams = useMemo(
    () => `&page=${currentPage}${props.location.search.replace("?", "&")}`,
    [currentPage, s, props.location]
  );

  // Handlers for new or existing entity to show in dialog
  const {
    entity,
    handleEntityClick,
    openModal,
    handleCloseModal,
    handleCreateNew,
  } = useMasterDetailWithDialog(Schema[EntityType.Alert]);

  // Create the subheader options
  const AlertMenu = <AlertSubheader handleCreateNew={handleCreateNew} />;

  // Sort handler used by default table
  const sortHandler = useSortHandler();

  // Callback to trigger on useDependency hook
  const initializeDependency = useCallback(() => {
    getAllAlerts("?page=0&size=20");
  }, [getAllAlerts]);

  // Initialize dependency
  useDependency(context.Technique, getAllTechniques);

  // Refresh alerts after RDU
  useEntityProgress(context.Alert, initializeDependency);

  // Get state for current entity, and handler to manage paging
  const { list, handleLoadMore, totalPages, isLoading } = usePageableEntity(
    context.Alert,
    getAllAlerts,
    rootParams
  );

  useSortHandlerPath(sortHandler.sort);

  const defaultTable = useDefaultTable(list, sortHandler);

  const formatted = list.map((x) => ({
    ...x,
    createdByName: x.createdBy?.username,
    lastUpdatedAt: moment(x.lastUpdatedAt).format("lll"),
  }));

  // Create options
  const countryOptions = createOptions(
    context.Country.getAll.result,
    "name",
    "name"
  );
  const verticalOptions = createOptions(
    context.CompanyVertical.getAll.result,
    "name",
    "name"
  );

  return (
    <SubmenuLayout MenuItemsComponent={AlertMenu} title={"Alerts"}>
      <Container maxWidth={false}>
        <AlertTable
          headCells={defaultCells}
          alerts={formatted}
          defaultTable={defaultTable}
          handleEntityClick={handleEntityClick}
          sortHandler={sortHandler}
          handleLoadMore={handleLoadMore}
          isLoading={isLoading}
        />
        <Box mt={2} component={Grid} container justify={"center"}>
          <Pagination
            onChange={(e, newPage) => setCurrentPage(newPage - 1)}
            page={currentPage + 1}
            count={totalPages}
            showFirstButton
            showLastButton
            size={"small"}
          />
        </Box>
      </Container>
      <EditDialog
        context={context.Alert}
        entity={entity}
        techs={context.Technique.getAll.result}
        entityType={EntityType.Alert}
        open={openModal}
        handleClose={handleCloseModal}
        createEntity={createAlert}
        updateEntity={updateAlert}
        countryOptions={countryOptions}
        verticalOptions={verticalOptions}
        deleteEntity={deleteAlert}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {
  getAllAlerts,
  createAlert,
  getAllTechniques,
  updateAlert,
  deleteAlert,
})(Alerts);
