import React from "react";
import DefaultTable from "../../components/Common/DefaultTable";

function AttacksTable(props) {
  const { attacks, headCells, handleEntityClick, isLoading, defaultTable } = props;

  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
  } = defaultTable

  return (
    <DefaultTable
      headCells={headCells}
      isLoading={isLoading}
      onRowClick={handleEntityClick}
      renderCheckAllCell={renderCheckAllCell}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={attacks}
      selectableRows
    />
  );
}

export default AttacksTable;
