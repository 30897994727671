import {
  Box,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Clock, Tick } from "assets/icons/icons";
import React, { useState } from "react";
import { useContext } from "react";
import { useCallback } from "react";
import { VulnerabilityRulesContext } from ".";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.type === "dark" ? "#212121" : "#E5E6E9",
  },
}));

const RuleOverview = ({
  rule,
  onClick,
  handleShowApplyDialog,
  handleShowDisableDialog,
  handleShowDeleteDialog,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setSelectedRuleId } = useContext(VulnerabilityRulesContext);

  const handleClickMenu = useCallback(
    (event) => {
      event.stopPropagation();
      setSelectedRuleId(rule.uuid);
      setAnchorEl(event.currentTarget);
    },
    [rule, setSelectedRuleId]
  );

  const handleCloseMenu = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleApplyRule = useCallback(
    (event) => {
      event.stopPropagation();
      handleShowApplyDialog();
      setAnchorEl(null);
    },
    [handleShowApplyDialog]
  );

  const handleDisableRule = useCallback(
    (event) => {
      event.stopPropagation();
      handleShowDisableDialog();
      setAnchorEl(null);
    },
    [handleShowDisableDialog]
  );

  const handleDeleteRule = useCallback(
    (event) => {
      event.stopPropagation();
      handleShowDeleteDialog();
      setAnchorEl(null);
    },
    [handleShowDeleteDialog]
  );

  return (
    <Card className={classes.card}>
      <Box
        onClick={onClick}
        padding={1}
        width="100%"
        display="flex"
        justifyContent="space-between"
        gridColumnGap={16}
        style={{ cursor: "pointer" }}
      >
        <Box display="flex" alignItems="center" gridColumnGap={12} width="50%">
          {rule.enabled ? <Tick color="#43DD43" /> : <Clock />}
          <Typography display="inline">{rule.name}</Typography>
        </Box>

        <Box
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="60%"
        >
          <Typography color="textSecondary" style={{ marginRight: "auto" }}>
            {rule.description.length > 300
              ? rule.description.slice?.(0, 400) + "..."
              : rule.description}
          </Typography>

          <IconButton color="default" onClick={handleClickMenu}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              disabled={rule.enabled}
              onClick={handleApplyRule}
              style={{ width: "10rem" }}
            >
              Enable
            </MenuItem>
            <Divider />
            <MenuItem
              disabled={!rule.enabled}
              onClick={handleDisableRule}
              style={{ width: "10rem" }}
            >
              Disable
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleDeleteRule}
              style={{ width: "10rem" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Card>
  );
};

export default RuleOverview;
