import React from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { useMasterDetailWithDialog, useSortHandler } from "reactcoregk/hooks";
import {useDefaultTable, useQuery} from "../../helpers/hooks";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import EditDialog from "./EditDialog";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import DefaultTable from "../../components/Common/DefaultTable";
import { compareProp } from "reactcoregk/utils";
import IndustriesSubheader from "./industries-subheader";
import {
  createCompanyVertical,
  deleteCompanyVertical,
  updateCompanyVertical,
} from "../../store/company-vertical/actions";

const defaultCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
];

function Industries(props) {
  const {
    context,
    createCompanyVertical,
    updateCompanyVertical,
    deleteCompanyVertical,
  } = props;

  const query = useQuery()
  const search = query.get("s") || ""

  const { isLoading, result } = context.CompanyVertical.getAll;

  const list = result
    .filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => compareProp(a, b,"name"))

  // Handlers for new or existing entity to show in dialog
  const {
    entity,
    handleEntityClick,
    openModal,
    handleCloseModal,
    handleCreateNew,
  } = useMasterDetailWithDialog(Schema[EntityType.CompanyVertical]);

  // Create the subheader options
  const AlertMenu = <IndustriesSubheader handleCreateNew={handleCreateNew} />;

  // Sort handler used by default table
  const sortHandler = useSortHandler();
  const { renderHeadCell, renderDefaultBody } = useDefaultTable(list, sortHandler);

  return (
    <SubmenuLayout MenuItemsComponent={AlertMenu} title={"Industries"}>
      <Container maxWidth={false}>
        <DefaultTable
          headCells={defaultCells}
          rows={list}
          renderHeadCell={renderHeadCell}
          renderDefaultBody={renderDefaultBody}
          isLoading={isLoading}
          onRowClick={handleEntityClick}
        />
      </Container>
      <EditDialog
        context={context.CompanyVertical}
        entity={entity}
        entityType={EntityType.CompanyVertical}
        open={openModal}
        handleClose={handleCloseModal}
        createEntity={createCompanyVertical}
        updateEntity={updateCompanyVertical}
        deleteEntity={deleteCompanyVertical}
        storedIds={result.map((x) => x.id)}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      CompanyVertical: state.CompanyVertical,
    },
  };
};
export default connect(mapStateToProps, {
  createCompanyVertical,
  updateCompanyVertical,
  deleteCompanyVertical,
})(Industries);
