import React from "react";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: props => props.color ? props.color : theme.palette.primary.main,
    fontSize: 12,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const ButtonLink = (props) => {
  const classes = useStyles(props);
  return (
    <Button
      className={classes.root}
      component={Link}
      to={props.to}
    >
      {props.children}
    </Button>
  );
};

export default ButtonLink;
