import React, { useCallback, useEffect, useState } from "react";
import EntityDialog from "../../components/EntityDialog";
import { EntityType } from "../../store/@core/entityType";
import { useForm } from "reactcoregk/hooks";
import { Schema } from "../../store/@core/schema";
import AttackForm from "./AttackForm";
import ManageActorsDialog from "./AttackDetails/ManageActorsDialog";
import ManageCvesDialog from "./AttackDetails/ManageCvesDialog";
import Alert from "@material-ui/lab/Alert";
import { Collapse } from "@material-ui/core";
import { handleAttackPreSubmit } from "./logic";

function EditDialog(props) {
  const {
    entity,
    open,
    techs,
    openActors,
    setOpenActors,
    openCves,
    setOpenCves,
    createEntity: createAttack,
  } = props;
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Attack]);
  const [error, setError] = useState("");
  const [attackFormCharConstraint,setAttackFormCharConstraint] = useState(true)
  useEffect(() => {
    if (entity) setForm(entity);
  }, [entity, setForm, open]);

  const onActorsSelect = useCallback(
    (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        actorIds: selected,
      }));
    },
    [setForm]
  );

  const onCvesSelect = useCallback(
    (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        cves: selected,
      }));
    },
    [setForm]
  );

  const createEntity = (form) => {
    handleAttackPreSubmit(form, setError, createAttack);
  };

  return (
    <EntityDialog
      {...props}
      createEntity={createEntity}
      form={form}
      maxWidth={"sm"}
      isValid={attackFormCharConstraint}
    >
      <Collapse in={!!error}>
        <Alert
          severity="error"
          onClose={() => setError("")}
          style={{ marginBottom: 8 }}
        >
          {error}
        </Alert>
      </Collapse>
      <AttackForm
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        techs={techs}
        openActors={() => setOpenActors(true)}
        openCves={() => setOpenCves(true)}
        setAttackFormCharConstraint={setAttackFormCharConstraint}
      />
      <ManageActorsDialog
        open={openActors}
        attack={form}
        callback={onActorsSelect}
        handleClose={() => setOpenActors(false)}
      />
      <ManageCvesDialog
        open={openCves}
        attack={form}
        handleClose={() => setOpenCves(false)}
        callback={onCvesSelect}
      />
    </EntityDialog>
  );
}

export default EditDialog;
