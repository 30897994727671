const primaryLight = {
  main: "#f4d53a",
  contrastText: "#272F3D",
};

const secondaryLight = {
  main: "#2c3249",
  contrastText: "#fff",
};

const errorColor = {
  main: "#FA8072",
};

const primaryDark = {
  main: "#18C7B6",
  // contrastText: "#181D30"
};
const secondaryDark = {
  main: "#18C7B6",
  // contrastText: "#181D30"
};

const backgroundLight = {
  paper: "#F7F7F8",
  default: "#cfd8dc",
};
const backgroundDark = {
  // default: "#384354",
  paper: "#121212",
};

const defaultColorDark = {
  main: "#2c2c2c",
  light: "#535353",
  dark: "#212121",
  contrastText: "#fff",
};
const defaultColorLight = {
  main: "#FCFCFC",
  light: "#ffffff",
  dark: "#ececec",
  contrastText: "#3A415B",
};

export {
  primaryLight,
  secondaryLight,
  primaryDark,
  secondaryDark,
  backgroundDark,
  backgroundLight,
  defaultColorLight,
  defaultColorDark,
  errorColor
};
