export const EntityType = {
  Client: "Client",
  License: "License",
  User: "User",
  Role: "Role",
  CompanyVertical: "CompanyVertical",
  Country: "Country",
  Version: "Version",
  CveDefinition: "CveDefinition",
  SupportTicket: "SupportTicket",
  Alert: "Alert",
  Policy: "Policy",
  Attack: "Attack",
  Technique: "Technique",
  SubTechnique: "SubTechnique",
  Collection: "Collection",
  Tactic: "Tactic",
  Actor: "Actor",
  Patch: "Patch",
  Product: "Product",
  Job: "Job",
};
