import {
    ADD_NOTIFICATION,
    GET_UNREAD_COUNT,
    GET_UNREAD_COUNT_SUCCESS,
    LOAD_NOTIFICATIONS,
    LOAD_NOTIFICATIONS_SUCCESS,
    NOTIFICATION_ERROR,
    READ_NOTIFICATION,
    READ_NOTIFICATION_SUCCESS,
} from "./actionTypes";

export const loadNotifications = (page) => {
  return {
    type: LOAD_NOTIFICATIONS,
    payload: { page },
  };
};

export const loadNotificationsSuccess = (notifications) => {
  return {
    type: LOAD_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};
export const getUnreadNotificationsCount = () => {
  return {
    type: GET_UNREAD_COUNT,
    payload: {},
  };
};

export const getUnreadNotificationsCountSuccess = ({unread}) => {
  return {
    type: GET_UNREAD_COUNT_SUCCESS,
    payload: unread,
  };
};

export const addNotification = (notification) => {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
};

export const readNotifications = (notificationIds) => {
  return {
    type: READ_NOTIFICATION,
    payload: { notificationIds },
  };
};

export const readNotificationsSuccess = (notificationIds) => {
  return {
    type: READ_NOTIFICATION_SUCCESS,
    payload: { notificationIds },
  };
};

export const notificationError = (error) => {
  return {
    type: NOTIFICATION_ERROR,
    payload: error ,
  };
};
