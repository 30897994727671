import React from "react";

import Container from "@material-ui/core/Container";
import {connect} from "react-redux";
import {useEntityProgress, useMasterDetailWithDialog, useSortHandler} from "reactcoregk/hooks";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import ActorsTable from "./ActorsTable";
import {createActor, getAllActors} from "../../store/actor/actions";
import Menu from "./Menu";
import {
  useCurrentPageParam,
  useDefaultTable,
  usePageableEntity,
  useSortHandlerPath,
} from "../../helpers/hooks";
import {Schema} from "../../store/@core/schema";
import {EntityType} from "../../store/@core/entityType";
import EditDialog from "./EditDialog";

const defaultCells = [
  {
    id: "actorId",
    disablePadding: false,
    label: "Actor unique ID",
    default: true,
    isSortable: true,
  },
  {
    id: "name",
    disablePadding: false,
    label: "Name",
    default: true,
  },
  {
    id: "actorType",
    disablePadding: false,
    label: "Type",
    default: true,
  },
];

function Actors(props) {
  const { context, getAllActors, createActor } = props;
  const sortHandler = useSortHandler();

  const errorMessage = context.Actor.getAllPageable.error;
  const verticals = context.CompanyVertical.getAll.result;
  const countries = context.Country.getAll.result;
  // Handle search parameters for pagination
  const rootParams = props.location.search.replace("?", "&");

  const { list, isLoading, totalPages } = usePageableEntity(
    context.Actor,
    getAllActors,
    rootParams
  );

  // Refresh table after a successful post
  useEntityProgress(context.Actor, () => getAllActors());

  const [currentPage, setCurrentPage] = useCurrentPageParam();

  useSortHandlerPath(sortHandler.sort, "id");

  const {
    openModal,
    handleCreateNew,
    handleCloseModal,
    entity,
  } = useMasterDetailWithDialog(Schema[EntityType.Actor]);

  const defaultTable = useDefaultTable(list, sortHandler, "actorId");

  const MenuItemsComponent = <Menu selected={defaultTable.selected} handleCreateNew={handleCreateNew} />;

  return (
    <SubmenuLayout
      noDivider
      MenuItemsComponent={MenuItemsComponent}
      title={"Actors"}
      errorMessage={errorMessage}
    >
      <Container maxWidth={false}>
        <ActorsTable
          actors={list}
          headCells={defaultCells}
          isLoading={isLoading}
          defaultTable={defaultTable}
        />
      </Container>
      <Box mt={2} component={Grid} container justify={"center"}>
        <Pagination
          onChange={(e, newPage) => setCurrentPage(newPage - 1)}
          page={currentPage}
          count={totalPages}
          showFirstButton
          showLastButton
          size={"small"}
        />
      </Box>
      <EditDialog
        entity={entity}
        context={context.Actor}
        createEntity={createActor}
        open={openModal}
        countries={countries}
        verticals={verticals}
        entityType={EntityType.Actor}
        handleClose={handleCloseModal}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Actor: state.Actor,
      Country: state.Country,
      CompanyVertical: state.CompanyVertical
    },
  };
};
export default connect(mapStateToProps, { getAllActors, createActor })(Actors);
