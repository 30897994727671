import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {entityOperations, entityType} from "./module";
import {ApiEndpoint} from "../@core/endpoint";
import {getAllUsers} from "./actions";
import getCoreSagas from "reactcoregk/store/saga";
import {buildActionType, createPostFailureMethod, createPostSuccessMethod} from "reactcoregk/store/actions";
import {postEntity} from "reactcoregk/store/api";
import * as ActionTypes from "reactcoregk/store/actionTypes";
import {getDefaultHeaders} from "../../helpers/utils";

const coreSagas = getCoreSagas(entityType, ApiEndpoint, entityOperations)

const successCreateCallback = (result) => createPostSuccessMethod(entityType, result);
const errorCreateCallback = (result) => createPostFailureMethod(entityType, result);

export function* insertUsers({ payload }) {
    try {
        const url = ApiEndpoint[entityType] + "/save";
        yield call(postEntity, url, payload, getDefaultHeaders());
        yield put(getAllUsers());
        yield put(successCreateCallback());
    } catch (error) {
        yield put(errorCreateCallback(error.message));
    }
}

export function* watchEntityInsert() {
    const actionType = buildActionType(entityType, ActionTypes.POST);
    yield takeEvery(actionType, insertUsers);
}

function* sagas() {
    coreSagas.push(fork(watchEntityInsert));
    yield all(coreSagas);
}

export default sagas;
