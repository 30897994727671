import { entityType } from "./module";
import {
  getCommonState,
  handleAsyncState,
  handleCommonState,
} from "reactcoregk/store/reducer";
import * as ActionTypes from "reactcoregk/store/actionTypes";

const initialState = getCommonState();

const Technique = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0];

  if (actionEntity !== entityType) return state;
  const actionType = action.type.split(".")[1];

  switch (actionType) {
    case "GET_ALL": {
      const newState = handleAsyncState(state, action, "getAll");
      if (newState) return newState;
      break;
    }
    case "UPDATE_SUCCESS": {
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          isLoading: false
        }
      }
    }
    case "POST_SUCCESS": {
      return {
        ...state,
        create: {
          ...state.update,
          error: null,
          isLoading: false
        }
      }
    }
    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          isLoading: false,
          fullFilled: true,
        },
      };
    case ActionTypes.UPDATE:
      return handleAsyncState(state, action, "update")
    default:
      break;
  }

  return handleCommonState(state, actionType, action, "id");
};

export default Technique;
