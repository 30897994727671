import React from "react";
import Container from "@material-ui/core/Container";
import Counters from "./counters";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import {useDefaultTable, useSortHandler, useSubheaderLogic,} from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import {EntityType} from "../../store/@core/entityType";
import Divider from "@material-ui/core/Divider";
import DashboardMenu from "./dashboard-menu";
import {connect} from "react-redux";
import {createVersion, updateVersion} from "../../store/version/actions";
import EditDialog from "./EditDialog";
import SectionGroupDescription from "../../components/SectionGroupDescription";
import PublicIcon from "@material-ui/icons/Public";
import {useMasterDetailWithDialog} from "reactcoregk/hooks";
import {Schema} from "../../store/@core/schema";
import {PRODUCT_NAME} from "../../config";

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "version",
    label: "Version",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "description",
    label: "Description",
  },
];

function Dashboard(props) {
  const { context, createVersion, updateVersion } = props;
  const versions = context.Version.getAll.result;
  const { query, handleChangeQuery } = useSubheaderLogic();


  const {
    openModal,
    handleEntityClick,
    handleCreateNew,
    handleCloseModal,
    entity,
  } = useMasterDetailWithDialog(Schema[EntityType.Version]);

  const sortHandler = useSortHandler();

  const {
    renderCheckAllCell,
    renderHeadCell,
    renderDefaultBody,
  } = useDefaultTable([], sortHandler);

  const MenuItemsComponent = (
    <DashboardMenu
      handleCreateNew={handleCreateNew}
      query={query}
      handleChangeQuery={handleChangeQuery}
    />
  );

  return (
    <SubmenuLayout
      MenuItemsComponent={MenuItemsComponent}
      title={`${PRODUCT_NAME} Cloud`}
    >
      <Container maxWidth={false}>
        <Counters />
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <SectionGroupDescription
          title={`${PRODUCT_NAME} Version list`}
          IconComponent={PublicIcon}
        />
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <DefaultTable
          renderCheckAllCell={renderCheckAllCell}
          headCells={headCells}
          rows={versions}
          onRowClick={handleEntityClick}
          renderHeadCell={renderHeadCell}
          renderDefaultBody={renderDefaultBody}
        />
      </Container>
      <EditDialog
        entity={entity}
        context={context.Version}
        createEntity={createVersion}
        updateEntity={updateVersion}
        open={openModal}
        entityType={EntityType.Version}
        handleClose={handleCloseModal}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createVersion,
  updateVersion,
})(Dashboard);
