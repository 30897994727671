import React from "react";
import DefaultTable from "../../components/Common/DefaultTable";
import {formatDatesOnList} from "../../helpers/utils";

function DefinitionsTable(props) {
  const { definitions, defaultTable, headCells, handleEntityClick, isLoading } = props;

  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
  } = defaultTable

  const formatted = formatDatesOnList(definitions, "LLL", "lastModifiedDate", "publishedDate")

  return (
    <DefaultTable
      headCells={headCells}
      isLoading={isLoading}
      onRowClick={handleEntityClick}
      renderCheckAllCell={renderCheckAllCell}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={formatted}
      selectableRows
    />
  );
}

export default DefinitionsTable;
