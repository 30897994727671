import Entity from "./Entity";

export default class SupportTicket extends Entity {
    constructor() {
        super();
        this.id = null;
        this.name = "";
        this.category = "";
        this.subcategory = "";
        this.subject = "";
        this.sourceChannel = "";
        this.tags = [];
        this.contactType = "";
        this.assignTo = null;
        this.status = null;
        this.state = null;
        this.resolution = null;
        this.priority = "";
        this.escalation = "";
        this.description = "";
        this.productVersion = "";
    }
}
