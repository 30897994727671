import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { createMap } from "../../helpers/utils";
import {ListItemIcon} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    borderRadius: 4,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect(props) {
  const classes = useStyles();
  const {
    labelId,
    fullWidth,
    id,
    label,
    margin,
    onChange,
    options,
    showSelectAll,
    placeholder,
    values,
    required,
    disabled,
    onClose
  } = props;

  const handleChange = (event) => {
    if (event.target.value.includes('all')) {
      if (options.length === event.target.value.length - 1) {
        onChange([])
        return;
      }
      onChange(options.map((x)=>x.value))
      return;
    }
    onChange(event.target.value);
  };

  const handleDelete = (id) => (e) => {
    e.stopPropagation();
    onChange(values.filter((x) => x !== id));
  };

  const selectedMap = createMap(options, "value");

  return (
    <FormControl
      fullWidth={fullWidth}
      placeholder={placeholder}
      margin={margin}
      required={required}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        multiple
        value={values}
        disabled={disabled}
        onChange={handleChange}
        input={<Input />}
        onClose={onClose}
        renderValue={(selected) => (
          <div
            className={classes.chips}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                label={selectedMap.get(value)?.label}
                className={classes.chip}
                clickable
                onDelete={handleDelete(value)}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {Boolean(showSelectAll) && <MenuItem value={'all'}>
          <Checkbox checked={values.length === options.length}/>
          <ListItemText primary={"Select all"} />
        </MenuItem>}
        {options.map((option) => {
          const Icon = option.icon
          return <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={values.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
            {Icon && <ListItemIcon>
              <Icon/>
            </ListItemIcon>}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

MultiSelect.defaultProps = {
  fullWidth: true,
  margin: "normal",
};

MultiSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};
