import React, { useEffect, useState } from "react";
import EntityDialog from "../../components/EntityDialog";
import { EntityType } from "../../store/@core/entityType";
import { useForm } from "reactcoregk/hooks";
import { Schema } from "../../store/@core/schema";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Title from "../../components/Common/Title";
import { HiveIcons } from "assets/icons";

function EditDialog(props) {
  const { entity, open, handleClose } = props;
  const [form, handleChange, setForm] = useForm(
    Schema[EntityType.CveDefinition]
  );

  const onClose = () => {
    setEditMode(false);
    handleClose();
  };

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (entity)
      setForm({
        ...entity,
        id: entity.cve,
        isZeroDay: entity.isZeroDay || false,
      });
  }, [entity, setForm, open]);

  return (
    <EntityDialog
      {...props}
      handleClose={onClose}
      primaryActionTitle={"Update"}
      secondaryActionTitle={"Cancel"}
      secondaryAction={onClose}
      form={form}
      isValid={editMode}
      maxWidth={"sm"}
    >
      <Grid xl container direction={"row"} spacing={8}>
        <Grid item>
          <Title>CVE definition details</Title>
        </Grid>
        <Grid item></Grid>
        <Grid item></Grid>
        <Grid item>
          <Title>
            <HiveIcons.Pen
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setEditMode(!editMode);
              }}
            />
          </Title>
        </Grid>
      </Grid>
      <Grid container direction={"column"} spacing={2} style={{ marginTop: 8 }}>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter Code Name"}
            fullWidth
            value={form.codeName}
            onChange={handleChange("codeName")}
            label="Code Name"
            autoFocus
            disabled={!editMode}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter CVE Name"}
            fullWidth
            value={form.cveName}
            onChange={handleChange("cveName")}
            label="CVE Name"
            autoFocus
            disabled={!editMode}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange("isZeroDay")}
                checked={form.isZeroDay}
                color="primary"
              />
            }
            label={"Is Zero Day"}
            disabled={!editMode}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter PluginId"}
            fullWidth
            value={form.pluginId}
            onChange={handleChange("pluginId")}
            label="Plugin Id"
            autoFocus
            disabled={!editMode}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter Qualys Id"}
            fullWidth
            value={form.qualysId}
            onChange={handleChange("qualysId")}
            label="Qualys Id"
            autoFocus
            disabled={!editMode}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter NVT"}
            fullWidth
            value={form.nvt}
            onChange={handleChange("nvt")}
            label="NVT"
            autoFocus
            disabled={!editMode}
          />
        </Grid>
      </Grid>
    </EntityDialog>
  );
}

export default EditDialog;
