import React, { useCallback, useState } from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import {
  useDependency,
  useEntityProgress,
  useProgress,
} from "reactcoregk/hooks";
import Subheader from "./subheader";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import {
  createTechnique,
  deleteTechnique,
  getAllTechniques,
  updateTechnique,
} from "../../store/technique/actions";
import TechTree from "./TechTree";
import TechniqueDialog from "./TechniqueDialog";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import {
  createTactic,
  deleteTactic,
  updateTactic,
} from "../../store/tactic/actions";
import TacticDialog from "./TacticDialog";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import BusyIndicator from "../../components/Common/BusyIndicator";
import { useSelectedList } from "../../helpers/hooks";
import SortDialog from "./SortDialog";

function Techniques(props) {
  const {
    context,
    getAllTechniques,
    createTechnique,
    updateTechnique,
    deleteTechnique,
    createTactic,
    updateTactic,
    deleteTactic,
  } = props;

  // Handlers for subheader controls
  const [openTech, setOpenTech] = useState(false);
  const [openTact, setOpenTact] = useState(false);
  const [entity, setEntity] = useState(Schema[EntityType.Technique]);
  const [openDeleteTechnique, setOpenDeleteTechnique] = useState(false);
  const [openDeleteTactic, setOpenDeleteTactic] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [sortEntity, setSortEntity] = useState({ children: [] });

  const handleCreateNew = () => {
    setEntity(Schema[EntityType.Tactic]);
    setOpenTact(true);
  };

  const [progressTechniques] = useEntityProgress(
    context.Technique,
    getAllTechniques
  );

  const [progressTactics] = useEntityProgress(context.Tactic, getAllTechniques);

  const isLoading = useDependency(context.Technique, getAllTechniques);

  const handleDeleteTechnique = (entity) => {
    setEntity(entity);
    setOpenDeleteTechnique(true);
  };

  const handleDeleteTactic = (entity) => {
    setEntity(entity);
    setOpenDeleteTactic(true);
  };

  const techniqueDelete = context.Technique.delete;
  const tacticDelete = context.Tactic.delete;

  const afterDelete = useCallback(() => {
    setOpenDeleteTactic(false);
    setOpenDeleteTechnique(false);
    getAllTechniques();
  }, [getAllTechniques]);

  const handleSort = useCallback((entity) => {
    setSortEntity(entity)
    setOpenSort(true)
  }, [])

  useProgress(null, afterDelete, null, techniqueDelete);
  useProgress(null, afterDelete, null, tacticDelete);

  const {
    tactics,
    techniques,
    subTechniques,
  } = context.Technique.getAll.result;

  const {
    handleClick: handleTacticSelect,
    handleSelectAllClick: handleAllTacticSelectAll,
    isSelected: isTacticSelected,
    selected: selectedTactics,
  } = useSelectedList(tactics);

  const {
    handleClick: handleTechSelect,
    isSelected: isTechSelected,
    selected: selectedTechs,
  } = useSelectedList(techniques);

  const {
    handleClick: handleSubTechSelect,
    isSelected: isSubTechSelected,
    selected: selectedSubTechs,
  } = useSelectedList(subTechniques);

  // Create the subheader options
  const TechMenu = (
    <Subheader
      handleCreateNew={handleCreateNew}
      selectedTactics={selectedTactics}
      selectedTechs={selectedTechs}
      selectedSubTechs={selectedSubTechs}
      tactics={tactics || []}
      techniques={techniques || []}
      subTechniques={subTechniques || []}
    />
  );

  return (
    <SubmenuLayout
      MenuItemsComponent={TechMenu}
      title={"Mitre TTP"}
      backLink={"/attacks"}
    >
      <Container maxWidth={false}>
        <TechTree
          isLoading={isLoading}
          techs={context.Technique.getAll.result}
          selectedTactics={selectedTactics}
          handleAllTacticSelectAll={handleAllTacticSelectAll}
          handleSort={handleSort}
          setEntity={setEntity}
          deleteTechnique={handleDeleteTechnique}
          deleteTactic={handleDeleteTactic}
          busy={progressTechniques || progressTactics}
          createTactic={createTactic}
          openTech={() => setOpenTech(true)}
          openTact={() => setOpenTact(true)}
          handleTacticSelect={handleTacticSelect}
          isTacticSelected={isTacticSelected}
          handleTechSelect={handleTechSelect}
          isTechSelected={isTechSelected}
          handleSubTechSelect={handleSubTechSelect}
          isSubTechSelected={isSubTechSelected}
        />
      </Container>
      <TechniqueDialog
        context={context.Technique}
        open={openTech}
        handleClose={() => setOpenTech(false)}
        entity={entity}
        entityType={EntityType.Technique}
        createEntity={createTechnique}
        updateEntity={updateTechnique}
        deleteEntity={deleteTechnique}
      />
      <TacticDialog
        context={context.Tactic}
        open={openTact}
        handleClose={() => setOpenTact(false)}
        entity={entity}
        entityType={EntityType.Tactic}
        createEntity={createTactic}
        updateEntity={updateTactic}
        deleteEntity={deleteTactic}
      />

      <DeleteConfirmation
        label={"Technique"}
        open={openDeleteTechnique}
        handleDelete={() => deleteTechnique(entity)}
        busy={techniqueDelete.isLoading}
        errorMessage={techniqueDelete.error}
        handleClose={() => setOpenDeleteTechnique(false)}
      />
      <DeleteConfirmation
        label={"Tactic"}
        open={openDeleteTactic}
        handleDelete={() => deleteTactic(entity)}
        busy={tacticDelete.isLoading}
        errorMessage={tacticDelete.error}
        handleClose={() => setOpenDeleteTactic(false)}
      />
      <SortDialog
        open={openSort}
        entity={sortEntity}
        getAllTechniques={getAllTechniques}
        handleClose={() => setOpenSort(false)}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {
  getAllTechniques,
  createTechnique,
  updateTechnique,
  deleteTechnique,
  createTactic,
  updateTactic,
  deleteTactic,
})(Techniques);
