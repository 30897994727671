import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { HiveIcons } from "../../assets/icons";
import FilterBase from "../../components/Common/FilterBase";
import TableFilters from "../../components/Common/TableFilters";
import { useTableSearch } from "../../helpers/hooks";
import SearchBox from "../../components/Common/SearchBox";
import RankButton from "components/Common/Ranking/RankButton";
import { RANK_ENTITY } from "constants/enums";

const filterOptions = [];

const Subheader = ({ handleCreateNew, selected }) => {
  const [query, handleChange, syncQuery] = useTableSearch();

  const items = selected.map((collection) => ({
    id: collection.uuid,
    entityName: RANK_ENTITY.BAS_ATTACK_COLLECTIONS,
    name: collection.name,
  }));

  const menuItems = [
    {
      pushRight: true,
      customEl: <RankButton selected={items} entityName={"Collection"} rankEntity={RANK_ENTITY.BAS_ATTACK_COLLECTIONS} />,
    },
    {
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },
    {
      customEl: (
        <FilterBase>
          <TableFilters filterOptions={filterOptions} />
        </FilterBase>
      ),
    },
    { divider: true },
    {
      title: "New Collection",
      icon: <HiveIcons.AddOutlined />,
      onClick: handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Subheader;
