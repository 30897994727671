import React from "react";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import {KeyboardArrowLeft} from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 0,
    backgroundColor: "#FF2E2E",
    color: "white",
    padding: "0 4px",
  },
}))(Badge);

const PopoverTItle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    handleClose,
    badge,
    renderView,
    onBack,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {onBack && (
          <IconButton
            onClick={onBack}
            style={{ width: 24, height: 24, marginRight: 8 }}
          >
            {" "}
            <KeyboardArrowLeft color={"primary"} fontSize={"small"} />
          </IconButton>
        )}
        {children}
        <StyledBadge badgeContent={badge} />
      </Typography>
      <div>{renderView && renderView()}</div>
      <IconButton
        style={{ width: 36, height: 36 }}
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

export default function PopoverContainerBase(props) {
  const { title, autoDismiss, handleClose, badge, onBack } = props;
  return (
    <Paper
      style={{
        minWidth: 300,
        backgroundColor: "#252425",
        borderRadius: 6,
        marginTop: 8,
        paddingBottom: 8,
      }}
    >
      <PopoverTItle handleClose={handleClose} badge={badge} onBack={onBack}>
        {title}
      </PopoverTItle>
      <Divider />
      <div
        style={{
          maxHeight: "calc(100vh - 120px)",
          overflow: "auto",
        }}
        onClick={() => {
          if (autoDismiss) {
            handleClose();
          }
        }}
      >
        {props.children}
      </div>
    </Paper>
  );
}
