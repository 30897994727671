import React, {useCallback, useState} from "react";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import Details from "./details";
import { ChevronLeft } from "@material-ui/icons";
import { Link } from "react-router-dom";
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined";
import { ApiEndpoint } from "../../store/@core/endpoint";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import DetailSection from "../../components/Common/DetailSection";
import {useEntityById, useRequest} from "../../helpers/hooks";
import { EntityType } from "../../store/@core/entityType";
import { Schema } from "../../store/@core/schema";
import ManageAttacksDialog from "./ManageAttacksDialog";
import ArsenalCard from "./ArsenalCard";
import { updateCollection } from "../../store/collection/actions";
import { useEntityProgress } from "reactcoregk/hooks";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import {deleteData} from "../../helpers/utils";
import {ReactComponent as Collection} from 'assets/icons/collection.svg'

function CollectionDetails(props) {
  // get ticket id from url
  const { updateCollection, context, history } = props;
  const id = props.match.params.id;
  const [refreshId, setRefreshId] = useState(0);
  const [openAttacks, setOpenAttacks] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // handling states
  const { entity, busy } = useEntityById(
    id,
    ApiEndpoint[EntityType.Collection],
    Schema[EntityType.Collection],
    "",
    refreshId
  );

  const {handle, loading, error} = useRequest()

  const handleDelete = useCallback(async () => {
    const request = () => deleteData(`${ApiEndpoint[EntityType.Collection]}/${entity.uuid}`)
    await handle(request, (error, data) => {
      if (!error) {
        setOpenDelete(false)
        history.push("/collections")
      }
    })
  }, [entity.uuid, handle, history])

  useEntityProgress(context.Collection, () => setRefreshId((prev) => prev + 1));

  return (
    <SubmenuLayout renderMenuItems={null} title={"Collection details"}>
      <Container maxWidth={false}>
        <Box mt={2} />
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item>
            <IconButton component={Link} to={"/collections"} size={"small"}>
              <ChevronLeft color={"primary"} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography color={"textPrimary"} style={{ fontWeight: "bold" }}>
              Collection Details
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenAttacks(true)}
              color={"primary"}
              endIcon={<SecurityOutlinedIcon />}
            >
              Update attacks
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenDelete(true)}
              style={{color: "tomato"}}
              endIcon={<Collection />}
            >
              Delete Collection
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          <Grid item xs={12}>
            <Grid container direction={"column"} spacing={2}>
              <DetailSection divider>
                <Details collection={entity} busy={busy} />
              </DetailSection>
              <DetailSection divider title={"Description"}>
                <Typography color={"textPrimary"}>
                  {entity?.description}
                </Typography>
              </DetailSection>
              <DetailSection title={"Attacks Added"} divider>
                <Grid container spacing={2}>
                  {entity?.attackIds?.map((id) => (
                    <Grid item sm={3} key={id}>
                      <AttackCard id={id} />
                    </Grid>
                  ))}
                </Grid>
              </DetailSection>
            </Grid>
          </Grid>
          {/* Right section */}
        </Grid>
      </Container>
      <ManageAttacksDialog
        collection={entity}
        open={openAttacks}
        context={context.Collection}
        updateCollection={updateCollection}
        handleClose={() => setOpenAttacks(false)}
      />
      <DeleteConfirmation
        label={"Collection"}
        open={openDelete}
        handleDelete={handleDelete}
        busy={loading}
        errorMessage={error}
        handleClose={() => setOpenDelete(false)}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: {
      Attack: state.Attack,
      Collection: state.Collection,
    },
  };
};
export default connect(mapStateToProps, { updateCollection })(
  CollectionDetails
);

const AttackCard = ({ id, isLoading }) => {
  // handling states
  const { entity, busy } = useEntityById(
    id,
    ApiEndpoint[EntityType.Attack],
    Schema[EntityType.Attack],
    ""
  );

  return <ArsenalCard arsenal={entity} isLoading={busy || isLoading} />;
};
