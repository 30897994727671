import { Box, Button, Checkbox, Collapse, Divider, FormControlLabel, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Add, Clear } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import BaseDialog from 'components/Common/BaseDialog';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react'
import AddCategoryDialog from '../ClassificationCategory/AddCategoryDialog';
import { VulnerabilityRulesContext } from '.';
import { useVulnerabilityClassificationRulesHelpers } from '../hooks/useVulnerabilityClassificationRules';
import { postData } from "../../../helpers/utils";
import { API_URL } from "../../../config";

const EditRuleDialog = ({ isOpen, handleClose }) => {
    const { enqueueSnackbar } = useSnackbar()

    const [showCategoryDialog, setShowCategoryDialog] = useState(false)
    const [toggleEnabledStatus, setToggleEnabledStatus] = useState(false)
    const {
        CATEGORIES_OPTIONS,
        PARAMETERS_OPTIONS,
        PARAMETERS,
        handleAddNewParameter,
        handleChangeRule,
        handleEditParameter,
        handleRemoveParameter,
        parameters,
        rule,
        handleValidateForm,
        errors,
        handleClearSelectedRule,
        handleRefreshRules
    } = useContext(VulnerabilityRulesContext)

    const { getConditionValuesFields, formatPayload } = useVulnerabilityClassificationRulesHelpers()

    const handleCloseDialog = useCallback(() => {
        handleClose()
        setToggleEnabledStatus(false)
    }, [handleClose])

    const handleSaveRule = useCallback(async () => {
        const isValid = handleValidateForm()
        if (isValid) {
            let payload = formatPayload(rule, parameters)
            payload = {
                ...payload,
                enabled: toggleEnabledStatus ? !rule.enabled : rule.enabled
            }
            try {
                await postData(`${API_URL}/api/classifications/rules`, payload)
                enqueueSnackbar('Rule Updated Successfully', { variant: 'success' })
                handleClearSelectedRule()
                handleRefreshRules()
            } catch (err) {
                if (err) {
                    enqueueSnackbar(err.message, { variant: 'error' })
                }
            }
        }
    }, [handleValidateForm, formatPayload, rule, parameters, toggleEnabledStatus, enqueueSnackbar, handleClearSelectedRule, handleRefreshRules])

    const CustomCategoryOption = () => (
        <MenuItem
            selected={false}
            style={{ display: "flex", alignItems: "center", color: '#21C7B6' }}
            onClick={() => setShowCategoryDialog(true)}
        >
            <Add fontSize='small' />
            <span>&nbsp;&nbsp;Add Category</span>
        </MenuItem>
    )

    return (
        <>
            <BaseDialog onClose={handleCloseDialog} open={isOpen} title={`Edit ${rule.enabled ? 'Enabled' : 'Disabled'} Rule`} maxWidth='md' maxContentHeight='70vh' fullWidth primaryAction={handleSaveRule} primaryActionTitle='Save Changes'>
                {/* Header */}
                <Box display='flex' flexDirection='column' gridRowGap={28}>
                    <Box display='flex' justifyContent='space-between' width='100%'>

                        <Typography variant='h5'>Edit details of this rule</Typography>
                        <FormControlLabel
                            style={{ marginLeft: '1.25rem' }}
                            control={
                                <Checkbox
                                    checked={toggleEnabledStatus}
                                    onChange={() => setToggleEnabledStatus(status => !status)}
                                    name="apply-rule"
                                    color="primary"
                                />
                            }
                            label={`${!rule.enabled ? 'Enable' : 'Disable'} Rule`}
                            labelPlacement='start'
                        />
                    </Box>

                    {/* Rule Error */}
                    <Collapse in={errors.ruleErrors}>
                        <Alert
                            severity="error"
                            style={{ marginBottom: 16 }}
                        >
                            {errors.ruleErrors}
                        </Alert>
                    </Collapse>


                    {/* Description */}
                    <Box display='flex' flexDirection='column' gridRowGap='2rem'>
                        <Box>
                            <Typography variant='subtitle2'>Name</Typography>
                            <Input
                                value={rule.name}
                                placeholder='Enter Name'
                                onChange={handleChangeRule('name')}
                            />
                        </Box>
                        <Box>
                            <Typography variant='subtitle2'>Description</Typography>
                            <Input
                                value={rule.description}
                                multiline
                                rows={4}
                                placeholder='Enter your Description here...'
                                onChange={handleChangeRule('description')}
                            />
                        </Box>
                        <Box width='15rem'>
                            <Typography color='textSecondary'>Select Category</Typography>
                            <Select
                                options={CATEGORIES_OPTIONS}
                                value={rule.categoryUuid}
                                margin='none'
                                controlId='categoryUuid'
                                placeholder='Select'
                                label='Select'
                                hideLabel={Boolean(rule.categoryUuid)}
                                shrinkLabel={false}
                                onChange={handleChangeRule('categoryUuid')}
                                customOption={<CustomCategoryOption />}
                            />
                        </Box>
                    </Box>

                    <Divider />

                    {/* Parameters */}
                    <Box display='flex' flexDirection='column' gridRowGap={12}>
                        {parameters?.map((parameter, index) => (
                            <Box key={index}>

                                {/* Parameter Error */}
                                <Collapse in={errors.parameterErrors[index]}>
                                    <Alert
                                        severity="error"
                                        style={{ marginBottom: 16 }}
                                    >
                                        {errors.parameterErrors[index]}
                                    </Alert>
                                </Collapse>

                                <Box style={{ rowGap: '1rem', display: 'flex', flexDirection: 'column' }}>
                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography variant='subtitle1'>Parameter {index + 1}</Typography>
                                        <Tooltip title="Remove Parameter">
                                            <IconButton size='small' onClick={() => handleRemoveParameter(index)}>
                                                <Clear />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box width='15rem'>
                                        <Typography color='textSecondary'>Select Parameter</Typography>
                                        <Select
                                            options={PARAMETERS_OPTIONS}
                                            margin='none'
                                            value={parameter.parameter}
                                            placeholder='Select'
                                            label='Select'
                                            hideLabel={Boolean(parameter.parameter)}
                                            shrinkLabel={false}
                                            controlId={`parameter-${index}`}
                                            onChange={handleEditParameter(index, 'parameter')}
                                        />
                                    </Box>
                                    <Box display='flex' gridColumnGap='3rem' marginTop='1.5rem'>
                                        <Box width='15rem'>
                                            <Typography color='textSecondary'>Select Condition</Typography>
                                            <Select
                                                options={PARAMETERS.find(param => param.name === parameter.parameter)?.supportedConditions.map(param => ({ value: param.name, label: param.label })) || []}
                                                margin='none'
                                                value={parameter.condition}
                                                placeholder='Select'
                                                label='Select'
                                                hideLabel={Boolean(parameter.condition)}
                                                shrinkLabel={false}
                                                controlId={`parameter-condition-${index}`}
                                                onChange={handleEditParameter(index, 'condition')}
                                            />
                                        </Box>
                                        <Box width='15rem'>
                                            <Typography color='textSecondary'>Select Value</Typography>
                                            {getConditionValuesFields(parameter, handleEditParameter(index, 'conditionValue'))}
                                        </Box>
                                        {(index < parameters.length - 1) && (
                                            <Box width='15rem'>
                                                <Typography color='textSecondary'>Select Operator</Typography>
                                                <Select
                                                    options={[{ label: 'OR', value: 'OR' }, { label: 'AND', value: 'AND' }]}
                                                    margin='none'
                                                    value={parameter.operator}
                                                    placeholder='Select'
                                                    label='Select'
                                                    hideLabel={Boolean(parameter.operator)}
                                                    shrinkLabel={false}
                                                    controlId={`parameter-operator-${index}`}
                                                    onChange={handleEditParameter(index, 'operator')}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Divider style={{ margin: '1rem 0' }} />
                            </Box>
                        ))}

                        <Button onClick={() => handleAddNewParameter()} color='primary' variant='outlined' style={{ width: 'max-content', marginTop: '1rem' }}>
                            <Add fontSize='small' />
                            <span>Add Parameter</span>
                        </Button>
                    </Box>
                </Box>
            </BaseDialog>
            <AddCategoryDialog isOpen={showCategoryDialog} handleClose={() => setShowCategoryDialog(false)} />
        </>
    )
}

export default EditRuleDialog