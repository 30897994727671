import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import EntityDialog from "../../components/EntityDialog";
import ErrorBoundary from "../../components/ErrorBoundary";
import { useEntityProgress, useForm } from "reactcoregk/hooks";
import Title from "../../components/Common/Title";

const EditDialog = (props) => {
  const { entity, context, handleClose, storedIds, createEntity } = props;

  const [busy] = useEntityProgress(context, handleClose);
  const [form, handleChange, setForm] = useForm({
    id: "",
    name: "",
  });

  useEffect(() => {
    if (entity?.id) {
      setForm(entity);
    } else {
      setForm({ id: Math.max(...storedIds) + 1, name: "" });
    }
  }, [entity, setForm, storedIds]);

  const restricted = form.id !== entity.id && storedIds.includes(parseInt(form.id))
  
  const isValid = useMemo(() => {
    return Boolean(form.name && form.id && !restricted);
  }, [restricted, form.id, form.name]);

  const handleCreate = () => {
    createEntity(form)
  }

  return (
    <EntityDialog
      form={{...form, id: entity.id }}
      showProgress
      maxWidth={"sm"}
      isValid={isValid}
      titleOverride={entity.id ? "Edit Industry" : "New Industry"}
      primaryActionTitle={entity.id ? "Update" : "Save"}
      inProgress={busy}
      {...props}
      handleCreate={handleCreate}
    >
      <ErrorBoundary>
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Title>Enter Industry details</Title>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              disabled={entity.id}
              margin="none"
              type={"number"}
              error={restricted}
              helperText={restricted && "This ID already exist"}
              placeholder={"Enter Industry ID"}
              fullWidth
              value={form.id}
              onChange={handleChange("id")}
              id="id"
              label="Industry ID"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              margin="none"
              placeholder={"Enter Industry Name"}
              fullWidth
              value={form.name}
              onChange={handleChange("name")}
              id="id"
              label="Industry Name"
            />
          </Grid>
        </Grid>
      </ErrorBoundary>
    </EntityDialog>
  );
};

export default EditDialog;
