import {entityType} from "./module";
import {
    createDeleteMethod,
    createGetAllPageableMethod,
    createGetMethod,
    createPostMethod,
    createPutMethod
} from "reactcoregk/store/actions";
import {getDefaultApiHandler, getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

const getApiHandler = () => new ApiHandler(false, false, getDefaultHeaders())

export const getAllAlerts = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler())
export const getAlert = (params) => createGetMethod(entityType, params, getDefaultApiHandler())
export const createAlert = (payload) => createPostMethod(entityType, payload, getApiHandler())
export const updateAlert = (payload) => createPutMethod(entityType, payload, getApiHandler())
export const deleteAlert = (payload) => createDeleteMethod(entityType, payload, getApiHandler())
