import {CLEAR_LICENSES, CREATE_LICENSE, GET_ALL_LICENSES_BY_CLIENT} from "./actionTypes";
import {getCommonState, handleAsyncState} from "reactcoregk/store/reducer";

const initialState = getCommonState();


const License = (state = initialState, action) => {
  const actionType = action.type.split(".")[1];

  switch (actionType) {
    case CLEAR_LICENSES: {
      return {
        ...state,
        getAll: {
          ...state.getAll,
          result: []
        }
      }
    }
    case GET_ALL_LICENSES_BY_CLIENT: {
      const newState = handleAsyncState(state, action, "getAll");
      if (newState) return newState;
      break;
    }
    case CREATE_LICENSE: {
      const newState = handleAsyncState(state, action, "create");
      if (newState) return newState;
      break;
    }
    default:
      return state
  }

};

export default License;
