import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Schema } from "../../store/@core/schema";
import { EntityType } from "../../store/@core/entityType";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Checkbox, Divider } from "@material-ui/core";

import SvgIcon from "@material-ui/core/SvgIcon";
import Collapse from "@material-ui/core/Collapse";
import { animated, useSpring } from "react-spring/web.cjs";
import { SortByAlpha } from "@material-ui/icons";
import {compareProp} from "reactcoregk/utils";
import {TableLoader} from "../../components/Common/DefaultTable"; // web.cjs is required for IE 11 support

const useTreeItemStyles = makeStyles((theme) => ({
  root: {},
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    fontSize: 14,
  },
}));

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const CustomTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const [showActions, setShowActions] = useState(false);
  const {
    labelText,
    labelInfo,
    color,
    bgColor,
    handleCreate,
    handleSelect,
    isSelected,
    handleEdit,
    handleDelete,
    handleSort,
    busy,
    id,
    ...other
  } = props;

  return (
    <CustomTreeItem
      label={
        <div
          className={classes.labelRoot}
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
        >
          <IconButton
            disabled={busy}
            size={"small"}
            onClick={(e) => handleSelect(e, id)}
            style={{ marginRight: 8 }}
          >
            <Checkbox disabled={busy} checked={isSelected} />
          </IconButton>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {showActions && (
            <Fragment>
              {handleEdit && (
                <IconButton disabled={busy} size={"small"} onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              )}
              {handleSort && (
                <IconButton
                  disabled={busy}
                  size={"small"}
                  onClick={handleSort}
                  style={{ marginLeft: 8 }}
                >
                  <SortByAlpha />
                </IconButton>
              )}
              {handleDelete && (
                <IconButton
                  disabled={busy}
                  size={"small"}
                  onClick={handleDelete}
                  style={{ marginLeft: 8 }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              {handleCreate && (
                <IconButton
                  disabled={busy}
                  size={"small"}
                  onClick={handleCreate}
                  style={{ marginLeft: 8 }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
            </Fragment>
          )}
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
  },
});

export default function TechTree(props) {
  const classes = useStyles();

  const {
    techs,
    openTech,
    openTact,
    setEntity,
    deleteTechnique,
    busy,
    deleteTactic,
    isTacticSelected,
    handleSubTechSelect,
    isTechSelected,
    handleTacticSelect,
    isSubTechSelected,
    handleTechSelect,
    handleSort,
    handleAllTacticSelectAll,
    selectedTactics,
    isLoading
  } = props;

  const data = useTechTree(techs);

  if (isLoading) {
    return <TableLoader />
  }

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={["3"]}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
      <StyledTreeItem
        nodeId={"root"}
        id={"root"}
        handleSelect={handleAllTacticSelectAll}
        isSelected={data.length > 0 && selectedTactics.length === data.length}
        labelText={"Tactics"}
        busy={busy || data.length === 0}
        handleCreate={(e) => {
          e.stopPropagation();
          setEntity(Schema[EntityType.Tactic]);
          openTact();
        }}
        handleSort={(e) => {
          e.stopPropagation();
          handleSort({ children: data });
        }}
        labelIcon={<Fragment />}
      />
      <Divider />
      {data.map((tact) => (
        <StyledTreeItem
          nodeId={`tact_${tact.id}`}
          id={tact.id}
          busy={busy}
          labelText={tact.name}
          handleSelect={handleTacticSelect}
          isSelected={isTacticSelected(tact.id)}
          handleCreate={(e) => {
            e.stopPropagation();
            setEntity({
              ...Schema[EntityType.Technique],
              tacticId: tact.id,
            });
            openTech();
          }}
          handleEdit={(e) => {
            e.stopPropagation();
            setEntity(tact);
            openTact();
          }}
          handleDelete={(e) => {
            e.stopPropagation();
            deleteTactic(tact);
          }}
          handleSort={(e) => {
            e.stopPropagation();
            handleSort(tact);
          }}
          labelIcon={<Fragment />}
        >
          {tact.children.map((tech) => (
            <StyledTreeItem
              nodeId={tech.id}
              id={tech.id}
              handleSelect={handleTechSelect}
              isSelected={isTechSelected(tech.id)}
              labelText={tech.name}
              busy={busy}
              handleCreate={(e) => {
                e.stopPropagation();
                setEntity({
                  ...Schema[EntityType.Technique],
                  platform: tech.platform,
                  datasource: tech.datasource,
                  techniqueParentId: tech.id,
                });
                openTech();
              }}
              handleEdit={(e) => {
                e.stopPropagation();
                setEntity(tech);
                openTech();
              }}
              handleDelete={(e) => {
                e.stopPropagation();
                deleteTechnique(tech);
              }}
              // handleSort={(e) => {
              //   e.stopPropagation();
              //   handleSort(tech);
              // }}
              labelIcon={<Fragment />}
            >
              {tech.children.map((subTech) => (
                <StyledTreeItem
                  nodeId={subTech.id}
                  id={subTech.id}
                  handleSelect={handleSubTechSelect}
                  isSelected={isSubTechSelected(subTech.id)}
                  busy={busy}
                  labelText={subTech.name}
                  handleEdit={(e) => {
                    e.stopPropagation();
                    setEntity(subTech);
                    openTech();
                  }}
                  handleDelete={(e) => {
                    e.stopPropagation();
                    deleteTechnique(subTech);
                  }}
                  labelIcon={<Fragment />}
                />
              ))}
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      ))}
    </TreeView>
  );
}

const useTechTree = (all) => {
  return useMemo(() => {
    const subTechniques = all["subTechniques"] || [];
    const techniques = all["techniques"] || [];
    const tactics = all["tactics"] || [];

    return tactics.map((tact) => ({
      ...tact,
      title: tact.name,
      objectType: "TACTIC",
      children: techniques
        .filter((x) => x.tacticId === tact.id)
        .sort((a, b) => compareProp(a, b, "order"))
        .map((technique) => ({
          ...technique,
          title: technique.name,
          objectType: "TECHNIQUE",
          children: subTechniques
            .filter((x) => x.techniqueParentId === technique.id)
            .sort((a, b) => compareProp(a, b, "order"))
            .map(x => ({ ...x, title: x.name, objectType: "SUB_TECHNIQUE" })),
        })),
    })).sort((a, b) => compareProp(a, b, "order"));
  }, [all]);
};
