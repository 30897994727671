import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "../../components/Inputs/Select";
import ErrorBoundary from "../../components/ErrorBoundary";
import MultiSelect from "components/Inputs/MultiSelect";

function ClientForm(props) {
  const {
    form,
    handleChange,
    verticalOptions,
    countryOptions,
    versionOptions,
    disabled,
  } = props;

  return (
    <ErrorBoundary>
      <Grid container direction={"column"}>
        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter client"}
            fullWidth
            value={form.name}
            disabled={disabled}
            onChange={handleChange("name")}
            label="Name"
            autoFocus
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            placeholder={"Enter contact name"}
            fullWidth
            value={form.contactName}
            disabled={disabled}
            onChange={handleChange("contactName")}
            label="Contact Name"
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            placeholder={"Enter contact email"}
            fullWidth
            type={"email"}
            value={form.contactEmail}
            disabled={disabled}
            onChange={handleChange("contactEmail")}
            label="Contact Email"
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            placeholder={"Enter intent"}
            fullWidth
            value={form.intent}
            disabled={disabled}
            onChange={handleChange("intent")}
            label="Intent"
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            margin="normal"
            placeholder={"Enter compliance"}
            fullWidth
            value={form.compliance}
            disabled={disabled}
            onChange={handleChange("compliance")}
            label="Compliance"
          />
        </Grid>

        <Grid item>
          <MultiSelect
            placeholder={"Select"}
            label={"Company Vertical"}
            controlId={"vertical"}
            onChange={handleChange("vertical")}
            values={form.vertical}
            disabled={disabled}
            options={verticalOptions}
          />
        </Grid>
        <Grid item>
          <MultiSelect
            placeholder={"Select"}
            label={"Country"}
            controlId={"country"}
            onChange={handleChange("country")}
            values={form.country}
            disabled={disabled}
            options={countryOptions}
          />
        </Grid>
        <Grid item>
          <Select
            placeholder={"Select version"}
            label={"Version"}
            controlId={"kronosVersionId"}
            onChange={handleChange("kronosVersionId")}
            value={form.kronosVersionId}
            disabled={disabled}
            options={versionOptions}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
}

export default ClientForm;
