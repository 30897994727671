import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Title from "../../components/Common/Title";
import { Box } from "@material-ui/core";
import { useCommonStyles } from "../../components/Common/styles";
import Background from "components/Common/Background";
import InputPassword from "../../components/Inputs/InputPassword";
import { connect } from "react-redux";
import { userChangePassword } from "../../store/auth/changepwd/actions";
import Alert from "@material-ui/lab/Alert";
import { BackgroundImage } from "../../assets/images";
import { useForm } from "reactcoregk/hooks";
import { ReactComponent as KronosLogo } from "../../assets/images/logo.svg";
import { useAppTheme } from "../../constants/theme";
import { hasCaseSensitive, validateStr } from "reactcoregk/utils";
import PasswordPolicy from "../../components/PasswordPolicy";

function ChangePassword(props) {
  const theme = useAppTheme();
  const logoColor =
    theme.palette.type === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black;
  const classes = useCommonStyles();
  const { userChangePassword, error, loading } = props;

  const [form, handleChange] = useForm({
    newPassword: "",
    confirmNewPassword: "",
    oldPassword: "",
  });

  const validatePassword = () => {
    const avoidUsingTheSame =
      form.newPassword && form.oldPassword !== form.newPassword;
    const passwordsMatch =
      form.newPassword && form.newPassword === form.confirmNewPassword;
    const useChars =
      form.newPassword &&
      form.newPassword.length >= 6 &&
      form.newPassword.length <= 30;
    const includesNumberOrSymbol =
      form.newPassword && validateStr(form.newPassword) === 1;
    const isCaseSensitive =
      form.newPassword && hasCaseSensitive(form.newPassword);
    return {
      useChars: useChars,
      passwordsMatch: passwordsMatch,
      avoidUsingTheSame: avoidUsingTheSame,
      includesNumberOrSymbol: includesNumberOrSymbol,
      isCaseSensitive: isCaseSensitive,
    };
  };

  const validation = validatePassword();

  const isValid = () => {
    let valid = true;
    for (let prop in validation) {
      if (!validation[prop]) valid = false;
    }
    return valid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const body = {
      newPassword: form.newPassword,
      confirmNewPassword: form.confirmNewPassword,
      oldPassword: form.oldPassword,
    };
    userChangePassword(body, props.history);
  };

  return (
    <Box style={{ marginTop: -120 }}>
      <Background image={BackgroundImage}>
        <Container component="main" className={classes.container}>
          <Box mt={"160px"} style={{ textAlign: "center" }}>
            <KronosLogo
              width={"180px"}
              style={{ marginLeft: -30 }}
              color={logoColor}
            />
          </Box>
          <CssBaseline />
          <Paper elevation={20} className={classes.paperForm}>
            <Title>Change password</Title>
            <form className={classes.form} onSubmit={onSubmit}>
              {Boolean(error) && <Alert severity="error">{error}</Alert>}
              <InputPassword
                name="oldPassword"
                label={"Old Password"}
                placeholder={"Enter old Password"}
                onChange={handleChange("oldPassword")}
                value={form.oldPassword}
              />
              <InputPassword
                name="newPassword"
                label={"New Password"}
                placeholder={"Enter new password"}
                onChange={handleChange("newPassword")}
                value={form.newPassword}
              />
              <InputPassword
                label={"Confirm new password"}
                placeholder={"Enter new password"}
                name="confirmNewPassword"
                onChange={handleChange("confirmNewPassword")}
                value={form.confirmNewPassword}
              />
              <PasswordPolicy validation={validation} />
              <Box mt={8} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid() || loading}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Save changes
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Background>
    </Box>
  );
}

const mapStatetoProps = (state) => {
  const { error, loading } = state.ChangePassword;
  return { error, loading };
};

export default connect(mapStatetoProps, { userChangePassword })(ChangePassword);