import { parseJwt } from "reactcoregk/utils";
import { getToken } from "../helpers/utils";
import { AUTHORITY } from "../constants/enums";

export class Auth {

  static getRoles = () => {
    try {
      const decoded = parseJwt(getToken());
      return decoded.authorities;
    } catch (ex) {
      return [];
    }
  };

  static hasRole = (role) => {
    const roles = this.getRoles();
    return roles.includes(role);
  };

  static isSuperAdmin = () => {
    return this.hasRole("ROLE_SUPER_ADMIN");
  };

  static isAdmin = () => {
    return this.hasRole(AUTHORITY.ADMIN);
  };

  static getUserName() {
    try {
      const decoded = parseJwt(getToken());
      return decoded.sub;
    } catch (ex) {
      return null;
    }
  }
}
