import {
    ADD_NOTIFICATION,
    GET_UNREAD_COUNT_SUCCESS,
    LOAD_NOTIFICATIONS,
    LOAD_NOTIFICATIONS_SUCCESS,
    NOTIFICATION_ERROR,
    READ_NOTIFICATION_SUCCESS,
} from "./actionTypes";

const initialState = {
  all: [],
  unreadCount: 0,
  triggerSound: 0,
  notificationPaging: {
    content: [],
    last: true,
    totalElements: 6,
    totalPages: 1,
    number: 1,
    sort: { sorted: false, unsorted: true, empty: true },
    size: 10,
    first: false,
    numberOfElements: 0,
    empty: true,
  },
  page: 0,
  loading: false,
  error: "",
};

const Notification = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        notificationPaging: action.payload,
        all: [...state.all, ...action.payload.content],
      };
      break;
    case ADD_NOTIFICATION:
      state = {
        ...state,
        all: [action.payload, ...state.all],
        unreadCount: state.unreadCount + 1,
        triggerSound: state.triggerSound + 1
      };
      break;
    case READ_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        all: state.all.map((notification) => {
          const read = action.payload.notificationIds.includes(notification.id);
          if (read) {
            return {
              ...notification,
              read: true,
            };
          }
          return notification;
        }),
        unreadCount:
          state.unreadCount >= action.payload.notificationIds.length
            ? state.unreadCount - action.payload.notificationIds.length
            : 0,
      };
      break;
    case GET_UNREAD_COUNT_SUCCESS:
      state = {
        ...state,
        unreadCount: action.payload,
      };
      break;
    case NOTIFICATION_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Notification;
