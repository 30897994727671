import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useEntityProgress, useSortHandler } from "reactcoregk/hooks";
import { Paging } from "reactcoregk/models";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { fetchData } from "../../helpers/utils";
import { useDefaultTable } from "../../helpers/hooks";
import BaseDialog from "../../components/Common/BaseDialog";
import Title from "../../components/Common/Title";
import DefaultTable from "../../components/Common/DefaultTable";
import { makeStyles } from "@material-ui/styles";
import SearchBox from "../../components/Common/SearchBox";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useCallback } from "react";
import { debounce } from "lodash";

const defaultCells = [
  {
    id: "id",
    numeric: false,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    label: "Name",
  },
  {
    id: "format",
    numeric: false,
    label: "Format",
  },
  {
    id: "type",
    numeric: false,
    label: "Type",
  },
];

const url = ApiEndpoint[EntityType.Attack];

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});
function ManageAttacksDialog(props) {
  const classes = useStyles();

  const { collection, updateCollection, handleClose, open, context } = props;

  const [page, setPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const [attacks, setAttacks] = useState(new Paging());
  const [query, setQuery] = useState("");
  const [showSelected, setShowSelected] = useState(false);
  const [initialAttacks, setInitialAttacks] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      if (query) {
        setQuery(query);
      } else {
        setQuery("");
      }
    }, 500),
    []
  );

  const sortHandler = useSortHandler();
  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(attacks.content, sortHandler);

  useEffect(() => {
    if (open) {
      setBusy(true);
      const APIURL = `${url}?page=${page}&size=20&sort=id,desc&s=${query}`;
      const params = showSelected ? `&attackIds=${selected.join(",")}` : "";
      fetchData(`${APIURL}${params}`)
        .then((data) => {
          setAttacks(data);
          setInitialAttacks(data);
          setBusy(false);
        })
        .catch((ex) => setBusy(false));
    }
  }, [open, page, query, showSelected]);

  useEffect(() => {
    setSelected(collection.attackIds);
  }, [collection.attackIds, setSelected]);

  useEntityProgress(context, handleClose);

  const handleSave = () => {
    const { uuid, name, description, collectionType } = collection;
    updateCollection({
      uuid,
      name,
      description,
      collectionType,
      attackIds: selected,
    });
  };

  const saving = context.update.isLoading;
  const errorMessage = context.update.error;

  return (
    <BaseDialog
      title={"Manage attacks"}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleSave}
      inProgress={busy || saving}
      errorMessage={errorMessage}
      showProgress={saving}
      disableBackdropClick
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>Select attacks to update</Title>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showSelected}
                    onChange={() => {
                      setShowSelected((s) => !s);
                      setPage(0);
                    }}
                    name="selected"
                    color="primary"
                  />
                }
                label="Show Selected"
              />
            </Grid>
            <Grid item>
              <SearchBox
                placeholder={"name"}
                handleChange={(e) => {
                  debouncedHandleSearch(e.target.value);
                  setSearchInput(e.target.value);
                  setPage(0);
                }}
                query={searchInput}
              />
            </Grid>
          </Grid>
        </Grid>

        {!showSelected ? (
          <>
            <Grid item>
              <DefaultTable
                rows={attacks.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={attacks.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        ) : (
          <>
            <Grid item>
              <DefaultTable
                rows={initialAttacks.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={initialAttacks.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        )}
      </Grid>
    </BaseDialog>
  );
}

export default ManageAttacksDialog;
