import Entity from "./Entity";

class Client extends Entity {
  constructor() {
    super();
    this.id = null
    this.name = "";
    this.vertical = [];
    this.intent = "";
    this.compliance = "";
    this.country = [];
    this.region = "";
    this.contactName = "";
    this.contactEmail = "";
    this.kronosVersionId = "";
  }
}

export default Client