import React, { useEffect, useMemo, useState } from "react";
import { fetchData } from "../helpers/utils";
import { API_URL } from "../config";
import BaseDialog from "./Common/BaseDialog";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { loginUser } from "../store/auth/login/actions";
import { useCommonStyles } from "./Common/styles";
import { useSnackbar } from "notistack";
import { lockoutMsg } from "../pages/Auth/sign-in";
import TextField from "@material-ui/core/TextField";
import InputPassword from "./Inputs/InputPassword";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

function ExpirationAlert(props) {
  const [expiration, setExpiration] = useState({ });
  const [open, setOpen] = useState(false);
  const classes = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { loginUser, error, loading } = props;

  useEffect(() => {
    fetchData(`${API_URL}/api/me/info`)
      .then((data) => {
        setExpiration(data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, []);

  useEffect(() => {
    const sessionInfo = async () => {
      const res = await fetchData(`${API_URL}/api/me/info`);
      setExpiration(res);
    };

    const interval = setInterval(sessionInfo, 180000);

    return () => clearInterval(interval);
  }, []);

  const expirationTime = useMemo(() => {
    return Math.floor(expiration.expiryInSeconds / 60);
  }, [expiration]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (expirationTime <= 5) setOpen(true);
  }, [expirationTime]);

  return (
    <BaseDialog
      onClose={undefined}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      title={"Session timeout warning"}
      maxWidth={"sm"}
      disableBackdropClick
    >
      <Typography variant={"subtitle1"} color={"textSecondary"}> Your session is about to expire within the next 5 minutes.</Typography>
      <Typography variant={"subtitle1"} color={"textSecondary"}> Please enter your credentials to re-login and continue the session!</Typography>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={(values) => {
          loginUser(values, props.history, () => {
            enqueueSnackbar('Successful re-login !', { variant: "info" })
            handleClose()
          })
        }}
        validate={(values) => {
          const errors = [];
          for (let value in values) {
            if (!values[value]) {
              errors.push(['Invalid']);
            }
          }
          return errors;
        }}
      >
        {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            /* and other goodies */
          }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="standard"
              margin="normal"
              placeholder={'Enter username'}
              fullWidth
              id="username"
              label="Username"
              error={error}
              name="username"
              autoComplete="username"
              autoFocus
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
            />
            <InputPassword
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={error}
              value={values.password}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {error && error !== lockoutMsg && (
                <Typography color={'error'}>
                  Invalid username or password
                </Typography>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || errors[0]}
                className={classes.submit}
              >
                Login
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </BaseDialog>
  );
}

const mapStatetoProps = (state) => {
  const { error, loading, isAuth } = state.Login;
  return { error, loading, isAuth };
};

export default connect(mapStatetoProps, { loginUser })(ExpirationAlert);
