import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 4,
    width: "100%",
    border: "none!Important",
    borderBottom: "1px solid rgba(255, 255, 255, 0.8)!Important",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    paddingLeft: 0,
    paddingRight: 4,
    color: theme.palette.text.secondary,
    marginTop: 8,
    "&:hover": {
      borderColor: theme.palette.text.primary,
      transition: "0.3ms",
    },
  },
  active: {
    color: theme.palette.text.primary,
  }
}));

function ButtonChoseList(props) {
  const classes = useStyles();

  const title = props.title + (props.required ? "*" : "");
  return (
      <Button
          fullWidth
          disabled={props.disabled}
          onClick={props.onClick}
          variant={"outlined"}
          className={classes.button}
          disableFocusRipple
          disableRipple
          endIcon={<ArrowDropDown style={{ fontSize: 24 }} />}
      >
        <span style={{ marginRight: "auto"}}>{title} {props.selected.length > 0 && <span className={classes.active}>{`(${props.selected.length})`}</span>}</span>
      </Button>
  );
}

export default ButtonChoseList;
