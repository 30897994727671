import React from "react";
import Grid from "@material-ui/core/Grid";
import Counter from "../../components/Common/Counter";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";

const Counters = ({ context }) => {
  const totalVersion = context.Version.getAll.result.length;
  const totalClients = context.Client.getAll.result.length;
  const totalUsers = context.User.getAll.result.length;
  return (
    <Grid container alignItems={"center"}>
      <Grid item style={{ flex: 1 }}>
        <Counter color={"#fff"} label={"Versions"} value={totalVersion} />
      </Grid>
      <Grid item>
        <Divider orientation={"vertical"} style={{ height: 53 }} />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Counter color={"#fff"} label={"Clients"} value={totalClients} />
      </Grid>
      <Grid item>
        <Divider orientation={"vertical"} style={{ height: 53 }} />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Counter color={"#F05E13"} label={"Users"} value={totalUsers} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {})(Counters);
