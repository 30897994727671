import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Title from "../../components/Common/Title";
import {Box} from "@material-ui/core";
import {useCommonStyles} from "../../components/Common/styles";
import Background from "components/Common/Background";
import InputPassword from "../../components/Inputs/InputPassword";
import {connect} from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {BackgroundImage} from "../../assets/images";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {API_URL} from "../../config";
import {useForm} from "reactcoregk/hooks";
import {useAppTheme} from "../../constants/theme";
import {ReactComponent as KronosLogo} from "../../assets/images/logo.svg";
import {postData} from "../../helpers/utils";

const PolicyItem = ({ _key, validation }) => {
  const wordings = {
    useChars: "Use 6 to 30 characters.",
    passwordsMatch: "Confirm password.",
    includesNumberOrSymbol:
      "Use a combination of upper case and lower case letters, numbers and symbols (!,@,#,$,%,^,&,*,_,+,=).",
    isCaseSensitive: "Password is case sensitive.",
  };
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: 4 }}>
      {validation[_key] ? (
        <CheckIcon style={{ color: "#43DD43" }} />
      ) : (
        <CloseIcon style={{ color: "#FA8072" }} />
      )}

      <Typography
        variant={"caption"}
        color={"textSecondary"}
        style={{ marginLeft: 8 }}
      >
        {wordings[_key]}
      </Typography>
    </div>
  );
};

function validateStr(str) {
  var regex = new RegExp("[A-Z]+"); // Check for uppercase first
  if (regex.test(str) === true) {
    regex = new RegExp("[0-9]+"); // Now we check for numbers
    if (regex.test(str) === true) {
      regex = new RegExp("[a-z]+"); // checking now for lowercase
      if (regex.test(str) === true) {
        return 1;
      } else return 2;
    } else return 3;
  } else return 4;
}

function hasLowerCase(str) {
  return str.toUpperCase() !== str;
}

function hasUpperCase(str) {
  return str.toLowerCase() !== str;
}

function hasCaseSensitive(str) {
  return hasLowerCase(str) && hasUpperCase(str);
}

function ResetPassword(props) {
  const theme = useAppTheme()
  const logoColor =
      theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black;
  const classes = useCommonStyles();
  const [form, handleChange] = useForm({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const params = props.location.search;

  const validatePassword = () => {
    const passwordsMatch =
      form.newPassword && form.newPassword === form.confirmNewPassword;
    const useChars =
      form.newPassword &&
      form.newPassword.length >= 6 &&
      form.newPassword.length <= 30;
    const includesNumberOrSymbol =
      form.newPassword && validateStr(form.newPassword) === 1;
    const isCaseSensitive =
      form.newPassword && hasCaseSensitive(form.newPassword);
    return {
      useChars: useChars,
      passwordsMatch: passwordsMatch,
      includesNumberOrSymbol: includesNumberOrSymbol,
      isCaseSensitive: isCaseSensitive,
    };
  };

  const validation = validatePassword();

  const isValid = () => {
    if (!params) {
      return Boolean(form.username);
    }
    let valid = true;
    for (let prop in validation) {
      if (!validation[prop]) valid = false;
    }
    return valid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const username = urlParams.get("username");
    const token = urlParams.get("token");
    const url = API_URL + "/token/update-reset-password";
    setSuccess("");
    setError("");
    try {
      await postData(url, { username, token, newPassword: form.newPassword });
      setSuccess("Your password has been reset successfully. You will be redirected to login page.");
      setTimeout(() => {
        props.history.push("/sign-in")
        setLoading(false)
      }, 3000);
    } catch (ex) {
      setError(ex.message);
      setLoading(false);
    }
  };

  return (
    <Background image={BackgroundImage}>
      <Container component="main" className={classes.container}>
        <Box mt={"160px"} style={{ textAlign: "center" }}>
          <KronosLogo width={"180px"} style={{ marginLeft: -30 }} color={logoColor} />
        </Box>
        <CssBaseline />
        <Paper elevation={20} className={classes.paperForm}>
          <Title>Reset password</Title>
          <Typography color={"textSecondary"}>
            For security reasons, default password is one-time use only. Set a
            password
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            {Boolean(error) && <Alert severity="error">{error}</Alert>}
            {Boolean(success) && <Alert severity="success">{success}</Alert>}
            {params && (
              <InputPassword
                name="newPassword"
                placeholder={"Enter new password"}
                onChange={handleChange("newPassword")}
                value={form.password}
              />
            )}
            <InputPassword
              label={"Confirm new password"}
              placeholder={"Enter new password"}
              name="confirmNewPassword"
              onChange={handleChange("confirmNewPassword")}
              value={form.confirmNewPassword}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PolicyItem _key={"useChars"} validation={validation} />
              <PolicyItem _key={"includesNumberOrSymbol"} validation={validation}/>
              <PolicyItem _key={"passwordsMatch"} validation={validation} />
              <PolicyItem _key={"isCaseSensitive"} validation={validation} />
            </div>
            <Box mt={8} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                fullWidth
                disabled={!isValid() || loading}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change password
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Background>
  );
}

const mapStatetoProps = (state) => {
  const { error, loading } = state.ChangePassword;
  return { error, loading };
};

export default connect(mapStatetoProps, {})(ResetPassword);
