import React, { useCallback, useEffect, useMemo, useState } from "react";
import EntityDialog from "../../components/EntityDialog";
import { EntityType } from "../../store/@core/entityType";
import { useForm } from "reactcoregk/hooks";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Schema } from "../../store/@core/schema";
import Select from "../../components/Inputs/Select";
import { ATTACK_SUPPORTED_SYSTEMS } from "../../constants/enums";
import ButtonChoseList from "../../components/Common/ButtonChoseList";
import MitigationDialog from "./MitigationDialog";
import MultipleFreeText from "../../components/Inputs/MultipleFreeText";

function TechniqueDialog(props) {
  const { entity, open } = props;
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Technique]);
  const [openMitigations, setOpenMitigations] = useState(false);

  useEffect(() => {
    if (entity) setForm(entity);
  }, [entity, setForm, open]);

  const type = useMemo(() => {
    if (entity.techniqueParentId) return "Sub-technique";
    return "Technique";
  }, [entity]);

  const onSelect = useCallback(
    (prop) => (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        [prop]: selected,
      }));
    },
    [setForm]
  );

  const {tacticId, ...rest} = form
  const validForm = entity.techniqueParentId && entity.id ? rest : form

  return (
    <EntityDialog {...props} form={validForm} titleOverride={type} maxWidth={"sm"}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter ID"}
            fullWidth
            multiline
            rowsMax={10}
            value={form.mitreId}
            onChange={handleChange("mitreId")}
            label={
              entity.techniqueParentId ? "Sub-Technique ID" : "Technique ID"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter name"}
            fullWidth
            value={form.name}
            onChange={handleChange("name")}
            label="Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter description"}
            multiline
            rowsMax={10}
            fullWidth
            value={form.description}
            onChange={handleChange("description")}
            label="Description"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter detection"}
            multiline
            rowsMax={10}
            fullWidth
            value={form.detection}
            onChange={handleChange("detection")}
            label="Detection"
          />
        </Grid>
        {!entity.techniqueParentId && (
          <>
            <Grid item xs={6}>
              <Select
                options={ATTACK_SUPPORTED_SYSTEMS}
                variant="standard"
                margin="none"
                placeholder={"Select platform"}
                fullWidth
                value={form.platform}
                onChange={handleChange("platform")}
                label="Platform"
                controlId={"platform"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                margin="none"
                placeholder={"Enter datasource"}
                fullWidth
                value={form.datasource}
                onChange={handleChange("datasource")}
                label="Datasource"
              />
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            type="datetime-local"
            value={form.mitreCreatedAt}
            onChange={handleChange("mitreCreatedAt")}
            label="Created At"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            type="datetime-local"
            value={form.mitreLastUpdatedAt}
            onChange={handleChange("mitreLastUpdatedAt")}
            label="Modified At"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonChoseList
            // disabled={busy}
            fullWidth
            onClick={() => setOpenMitigations(true)}
            title={"Mitigations"}
            selected={form.mitigations?.filter((x) => !x.shouldDelete)}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleFreeText
            placeholder={"Enter References"}
            value={form.references || []}
            onChange={handleChange("references")}
            label="References"
          />
        </Grid>
      </Grid>
      <MitigationDialog
        open={openMitigations}
        handleClose={() => setOpenMitigations(false)}
        callback={onSelect("mitigations")}
        technique={form}
      />
    </EntityDialog>
  );
}

export default TechniqueDialog;
