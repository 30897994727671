import React, {Fragment} from "react";
import SubMenu from "components/Header/Submenu";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const SubmenuLayout = (props) => {
  return (
    <Fragment>
      <SubMenu {...props} />
      <Box mt={"105px"} pb={2}>{props.children}</Box>
    </Fragment>
  );
};

export default SubmenuLayout;


SubmenuLayout.propTypes = {
  title: PropTypes.string,
  backLink: PropTypes.string,
  MenuItemsComponent: PropTypes.element,
  navLink: PropTypes.string,
};
