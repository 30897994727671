import { EntityType } from "./entityType";
import { Client, KronosVersion, User, SupportTicket } from "../../models/model";
import Alert from "../../models/Alert";
import Attack from "../../models/Attack";
import Patch from "../../models/Patch";
import Technique from "../../models/Technique";
import moment from "moment";
import Actor from "../../models/Actor";
import Product from "../../models/Product";

export const Schema = {
  [EntityType.User]: new User(),
  [EntityType.Client]: new Client(),
  [EntityType.Version]: new KronosVersion(),
  [EntityType.SupportTicket]: new SupportTicket(),
  [EntityType.Alert]: new Alert(),
  [EntityType.Attack]: new Attack(),
  [EntityType.Technique]: new Technique(),
  [EntityType.Patch]: new Patch(),
  [EntityType.Actor]: new Actor(),
  [EntityType.Product]: new Product(),
  [EntityType.Tactic]: {
    description: "",
    name: "",
    type: "",
    mitreId: "",
    mitreCreatedAt: moment().format("YYYY-MM-DDThh:mm:ss"),
    mitreLastUpdatedAt: moment().format("YYYY-MM-DDThh:mm:ss"),
  },
  [EntityType.Collection]: {
    description: "",
    name: "",
    id: null,
    attackIds: [],
  },
  [EntityType.CveDefinition]: {
    codeName: "",
    isZeroDay: false,
    cve: "",
  },
  [EntityType.Country]: {
    id: "",
    name: "",
  },
  [EntityType.CompanyVertical]: {
    id: "",
    name: "",
  },
};
