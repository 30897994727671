import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { useDefaultTable } from "../../../helpers/hooks";
import BaseDialog from "../../../components/Common/BaseDialog";
import DefaultTable from "../../../components/Common/DefaultTable";
import Title from "../../../components/Common/Title";
import { ApiEndpoint } from "../../../store/@core/endpoint";
import { EntityType } from "../../../store/@core/entityType";
import { fetchData } from "../../../helpers/utils";
import { Paging } from "reactcoregk/models";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import SearchBox from "../../../components/Common/SearchBox";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useCallback } from "react";
import { debounce } from "lodash";

const defaultCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Name",
    default: true,
    isSortable: false,
  },
  {
    id: "short_description",
    disablePadding: false,
    label: "Description",
    default: true,
    isSortable: false,
  },
  {
    id: "known_as",
    disablePadding: false,
    label: "Known As",
    default: true,
    isSortable: false,
  },
];

const url = ApiEndpoint[EntityType.Actor];

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});

function ManageActorsDialog(props) {
  const classes = useStyles();

  const { attack, handleClose, open, callback } = props;

  const [page, setPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const [actors, setActors] = useState(new Paging());
  const [errorMessage, setErrorMessage] = useState(null);
  const [query, setQuery] = useState("");
  const [showSelected, setShowSelected] = useState(false);
  const [initialActors, setInitialActors] = useState([]);

  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      if (query) {
        setQuery(query);
      } else {
        setQuery("");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (open) {
      setBusy(true);
      const APIURL = `${url}?page=${page}&sort=actorId,desc&size=20&s=${query}`;
      const params = showSelected ? `&actorIds=${selected.join(",")}` : "";
      fetchData(`${APIURL}${params}`)
        .then((data) => {
          setActors(data);
          setInitialActors(data);
          setBusy(false);
        })
        .catch((ex) => setBusy(false));
    }
  }, [open, page, query, showSelected]);

  const sortHandler = useSortHandler();
  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(actors.content, sortHandler, "actorId");

  useEffect(() => {
    if (open && attack) {
      setSelected(attack.actorIds || []);
      setInitialActors(attack.actorIds);
    }
  }, [attack, open, setSelected]);

  const handleConfirm = () => {
    callback(selected);
    handleClose();
    setPage(0);
    setErrorMessage(null);
  };

  return (
    <BaseDialog
      title={"Manage actors"}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      inProgress={busy}
      errorMessage={errorMessage}
      disableBackdropClick
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>Select actors to add</Title>
            </Grid>
            <Grid style={{ display: "flex" }} item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showSelected}
                    onChange={() => {
                      setShowSelected((s) => !s);
                      setPage(0);
                    }}
                    name="selected"
                    color="primary"
                  />
                }
                label="Show Selected"
              />
              <SearchBox
                placeholder={"name, description or known as"}
                handleChange={(e) => {
                  debouncedHandleSearch(e.target.value);
                  setQuery(e.target.value);
                }}
                query={query}
                syncQuery={(e) => {
                  setPage(0);
                  setQuery(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!showSelected ? (
          <>
            <Grid item>
              <DefaultTable
                rows={actors.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={actors.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        ) : (
          <>
            <Grid item>
              <DefaultTable
                rows={initialActors.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={initialActors.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        )}
      </Grid>
    </BaseDialog>
  );
}

export default ManageActorsDialog;
