import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        alignSelf: "center",
        color: props => props.color ? props.color : theme.palette.primary.main,
        fontSize: 12,
        "&:hover": {
            color: "#18C7B6",
            backgroundColor: "transparent",
            opacity: 1,
        },
    },
}));

const MenuButton = (props) => {
    const classes = useStyles(props);
    return (
        <Button
            className={classes.button}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export default MenuButton;
