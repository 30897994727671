import React, { useCallback, useEffect } from "react";
import { Chip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { cloneDeep, invokeMap, compact, uniq, isEqual } from "lodash";

function MultipleFreeText(props) {
  const {
    onChange,
    options = [],
    placeholder,
    label,
    value,
    controlId,
    fullWidth,
    disabled,
    margin,
    variant = "standard",
    csAutotags = false,
  } = props;

  const [freeTextValue, setFreeTextValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = useCallback(
    (e, newArray) => {
      onChange(newArray);
    },
    [onChange]
  );

  useEffect(() => {
    setFreeTextValue(value);
  }, [value]);

  return (
    <>
      {csAutotags ? (
        <Autocomplete
          multiple
          freeSolo
          limitTags={4}
          fullWidth={fullWidth}
          id={controlId}
          disabled={disabled}
          options={options}
          defaultValue={""}
          value={freeTextValue}
          inputValue={inputValue}
          onChange={(event, newValue) => {
            setFreeTextValue(newValue);
            handleChange(event, newValue);
          }}
          onInputChange={(event, newInputValue) => {
            const options = invokeMap(newInputValue.split(","), "trim");
            if (options.length > 1) {
              const uniqValues = uniq(freeTextValue.concat(options))
                .map((x) => x.trim())
                .filter((x) => x);
              setFreeTextValue(uniqValues);
              handleChange(event, uniqValues);
            } else {
              setInputValue(newInputValue);
            }
          }}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              margin={margin}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      ) : (
        <Autocomplete
          multiple
          id={controlId}
          options={options}
          limitTags={4}
          defaultValue={""}
          disabled={disabled}
          freeSolo
          fullWidth={fullWidth}
          value={value}
          onChange={handleChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              margin={margin}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      )}
    </>
  );
}

export default MultipleFreeText;
