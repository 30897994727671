import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  clearLicenses,
  createLicense,
  getAllLicensesByClient,
} from "../../store/license/actions";
import DefaultTable from "../../components/Common/DefaultTable";
import { useDefaultTable } from "../../helpers/hooks";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { downloadFile, postData } from "../../helpers/utils";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import moment from "moment";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import { API_URL } from "../../config";
import { Tooltip } from "@material-ui/core";

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "activatedAt",
    label: "Activated At",
  },
  {
    id: "createdAt",
    label: "Created At",
  },
  {
    id: "expiresAt",
    label: "Expires At",
  },
  {
    id: "lastCheckedAt",
    label: "Last Checked At",
  },
  {
    id: "startsAt",
    label: "Starts At",
  },
  {
    id: "terminate",
    label: "",
  },
  {
    id: "download",
    label: "",
  },
];
function LicenseList(props) {
  const { context, client, getAllLicensesByClient, clearLicenses } = props;
  const licenses = context.License.getAll.result;

  const { renderHeadCell, renderBodyCell } = useDefaultTable(licenses);

  useEffect(() => {
    if (client) {
      getAllLicensesByClient(client.id);
    }
    return () => {
      clearLicenses();
    };
  }, [client, getAllLicensesByClient, clearLicenses]);

  const handleDownload = (licenseId) => {
    const url = `${ApiEndpoint[EntityType.Client]}/${
      client.id
    }/license/${licenseId}/download`;
    downloadFile(url, `license_${client.name}`, "key").catch((ex) =>
      console.log(ex)
    );
  };

  const handleTerminate = (token) => {
    const url = `${API_URL}/api/licenses/terminate`;
    postData(url, { licenseToken: token })
      .then(() => getAllLicensesByClient(client.id))
      .catch((ex) => console.log(ex));
  };
  const currentDate = new Date();
  const renderDefaultBody = () => {
    return licenses.map((license) => {
      const expirationDate = new Date(license.expiresAt);
      const isActive = license.activatedAt && expirationDate > currentDate;
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={license.id}>
          {renderBodyCell(license.id)}
          {renderBodyCell(moment(license.activatedAt).format("lll"))}
          {renderBodyCell(moment(license.createdAt).format("lll"))}
          {renderBodyCell(moment(license.expiresAt).format("lll"))}
          {renderBodyCell(moment(license.lastCheckedAt).format("lll"))}
          {renderBodyCell(moment(license.startsAt).format("lll"))}
          <TableCell align="right">
            <Tooltip title={"Terminate license"}>
              <IconButton
                aria-label="options"
                size="small"
                onClick={() => handleTerminate(license.token)}
                disabled={!isActive}
              >
                <IndeterminateCheckBoxOutlinedIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Tooltip title={"Download license"}>
              <IconButton
                aria-label="options"
                size="small"
                onClick={() => handleDownload(license.id)}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <DefaultTable
      headCells={headCells}
      rows={licenses}
      renderHeadCell={renderHeadCell}
      renderDefaultBody={renderDefaultBody}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  clearLicenses,
  getAllLicensesByClient,
  createLicense,
})(LicenseList);
