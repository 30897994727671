import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Title from "../../components/Common/Title";
import {Box} from "@material-ui/core";
import {useCommonStyles} from "../../components/Common/styles";
import Background from "components/Common/Background";
import {BackgroundImage} from "../../assets/images";
import {API_URL} from "../../config";
import TextField from "@material-ui/core/TextField";
import BusyIndicator from "../../components/Common/BusyIndicator";
import {useForm} from "reactcoregk/hooks";
import {ReactComponent as KronosLogo} from "../../assets/images/logo.svg";
import {useAppTheme} from "../../constants/theme";
import {useSnackbar} from "notistack";
import {postData} from "../../helpers/utils";

export default function ForgotPassword(props) {
  const theme = useAppTheme()
  const logoColor =
      theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black;
  const classes = useCommonStyles();
  const [form, handleChange] = useForm({username: ""});
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar()

  const isValid = () => {
    return Boolean(form.username)
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postData(API_URL + "/token/reset-password-request", {
        username: form.username,
      })
      enqueueSnackbar("An email has been sent to your account", { variant: "success"})
    } catch (ex) {
      enqueueSnackbar(ex.message, { variant: "error"})
    }
    setLoading(false);
  };

  return (
    <Background image={BackgroundImage}>
      <Container component="main" className={classes.container}>
        <Box mt={"160px"} style={{ textAlign: "center" }}>
          <KronosLogo width={"180px"} color={logoColor} />
        </Box>
        <CssBaseline />
        <Paper elevation={20} className={classes.paperForm}>
          <BusyIndicator busy={loading}/>
          <Title>Reset password</Title>
          <Typography color={"textSecondary"}>
            Enter your username to reset your password.
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              variant="standard"
              margin="normal"
              placeholder={"Enter username"}
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              type="text"
              onChange={handleChange("username")}
              value={form.username}
            />
            <Box mt={8} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                fullWidth
                disabled={!isValid() || loading}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change password
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Background>
  );
}

