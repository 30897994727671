import React, { useMemo } from "react";
import Input from "./Input";

const CommaSeparatedInput = (props) => {
  const { value, onChange, label, margin, variant = "standard", placeholder } = props;

  const val = useMemo(() => {
    return value.length > 0 ? value.join(",") : "";
  }, [value]);

  const handleChange = (e) => {
    const value = e.target.value ? e.target.value.split(',').map(v => v.trim()) : [];
    onChange(value);
  };

  return (
    <Input
      margin={margin}
      label={label}
      variant={variant}
      placeholder={placeholder}
      onChange={handleChange}
      value={val}
    />
  );
};

export default CommaSeparatedInput;