import React from "react";
import FilterBase from "../../components/Common/FilterBase";
import TableFilters from "../../components/Common/TableFilters";
import { useTableSearch } from "../../helpers/hooks";
import SearchBox from "../../components/Common/SearchBox";
import SubmenuItems from "../../components/Header/SubmenuItems";
import RankButton from "../../components/Common/Ranking/RankButton";
import { useSelector } from "react-redux";
import { createMap } from "reactcoregk/utils";
import { RANK_ENTITY } from "../../constants/enums";
import {HiveIcons} from "../../assets/icons";

function getActors(state) {
  return createMap(state.Actor.getAllPageable.all, "actorId");
}

const Menu = ({ selected, handleCreateNew }) => {
  const [query, handleChange, syncQuery] = useTableSearch();

  // get all the loaded actors
  const allActors = useSelector(getActors);

  const items = selected.map((id) => ({
    id,
    entityName: RANK_ENTITY.ACTORS,
    name: allActors.get(id)?.data?.name,
  }));

  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <RankButton
          selected={items}
          entityName={"Actor"}
          rankEntity={RANK_ENTITY.ACTORS}
        />
      ),
    },
    {
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },
    {
      customEl: (
        <FilterBase>
          <TableFilters filterOptions={[]} />
        </FilterBase>
      ),
    },
    {
      divider: true,
    },
    {
      title: "New Actor",
      icon: <HiveIcons.AddOutlined />,
      onClick: handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Menu;
