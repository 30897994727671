import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import ButtonLink from "../Common/ButtonLink";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import MenuButton from "components/Common/MenuButton";

function SubmenuItems(props) {
  const renderItem = (menuItem) => {
    if (menuItem.path) {
      return (
        <ButtonLink to={menuItem.path} color={menuItem.color}>
          {menuItem.icon}
          {menuItem.title}
        </ButtonLink>
      );
    } else if (menuItem.onClick) {
      return (
        <MenuButton onClick={menuItem.onClick} color={menuItem.color}>
          {menuItem.icon}
          {menuItem.title}
        </MenuButton>
      );
    } else if (menuItem.customEl) {
      return menuItem.customEl;
    } else if (menuItem.divider) {
      return <Divider style={{ width: 1, height: 24, margin: "0 15px" }} />;
    }
  };

  return props.items.map((menuItem, index) => (
    <Fragment key={index}>
      {menuItem.pushRight && <div style={{ marginLeft: "auto" }} />}
      <Grid item>{renderItem(menuItem)}</Grid>
    </Fragment>
  ));
}

export default SubmenuItems;

SubmenuItems.propTypes = {
  items: PropTypes.array.isRequired,
};
