import { API_URL } from "../../config";
import { EntityType } from "./entityType";

export const ApiEndpoint = {
  [EntityType.Notification]: API_URL + "/api/notifications",
  [EntityType.Client]: API_URL + "/api/clients",
  [EntityType.Country]: API_URL + "/api/countries",
  [EntityType.CompanyVertical]: API_URL + "/api/companyVerticals",
  [EntityType.Role]: API_URL + "/api/roles",
  [EntityType.Version]: API_URL + "/api/kronosVersions",
  [EntityType.License]: API_URL + "/api/licenses",
  [EntityType.User]: API_URL + "/api/users",
  [EntityType.SupportTicket]: API_URL + "/api/support-tickets",
  [EntityType.CveDefinition]: API_URL + "/api/cverisk",
  [EntityType.Alert]: API_URL + "/api/alerts",
  [EntityType.Policy]: API_URL + "/api/policies",
  [EntityType.Attack]: API_URL + "/api/attacks",
  [EntityType.Actor]: API_URL + "/api/actors",
  [EntityType.SubTechnique]: API_URL + "/api/sub-techniques",
  [EntityType.Technique]: API_URL + "/api/techniques",
  [EntityType.Tactic]: API_URL + "/api/tactics",
  [EntityType.Collection]: API_URL + "/api/attacks/collections",
  [EntityType.Patch]: API_URL + "/api/patches",
  [EntityType.Product]: API_URL + "/api/icons",
  [EntityType.Job]: API_URL + "/api/cverisk/upload-definitions/cyrecon/jobs",
};
