import React from "react";
import { HiveIcons } from "assets/icons";
import SubmenuItems from "../../components/Header/SubmenuItems";
import SearchBox from "../../components/Common/SearchBox";

const UserListMenu = (props) => {
  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <SearchBox value={props.query} onChange={props.handleChangeQuery} />
      ),
    },
    {
      divider: true,
    },
    {
      title: "New User",
      icon: <HiveIcons.AddOutlined />,
      onClick: props.handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default UserListMenu;
