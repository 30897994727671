import {MULTI_TENANT_MODES, PROXY_MODES} from "../constants/enums";

class License {
  constructor() {
    this.startsAt = "";
    this.expiresAt = "";
    this.proxyMode = PROXY_MODES[0].value
    this.multiTenantMode = MULTI_TENANT_MODES[0].value
    this.numberOfTenants = 1
  }
}
export default License
