import Entity from "./Entity";
import moment from "moment";

class Patch extends Entity {
  constructor() {
    super();
    this.surrogateId = "";
    this.bulletinId = "";
    this.cve = "";
    this.name = "";
    this.description = "";
    this.type = "";
    this.sources = [];
    this.links = [];
    this.reference = [];
    this.applicabilities = [];
    this.isPublishedByVendor = false;
    this.publishedAt = moment().format("YYYY-MM-DDThh:mm:ss");
    this.hasValidatedMetadata = false
    this.mepmpPatchId = ""
    this.ivantiPatchId = ""
    this.medcPatchId = ""
    this.sccmPatchId = ""
    this.workarounds = []
  }
}
export default Patch;
