import React, { useEffect, useMemo, useState } from "react";
import BaseDialog from "../../../components/Common/BaseDialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { SIGNATURES } from "../../../constants/enums";
import { Button, Collapse, IconButton, Typography } from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import Select from "../../../components/Inputs/Select";
import Alert from "@material-ui/lab/Alert";

function SignatureDialog({ handleClose, open, form, setForm }) {
  const [signatures, setSignatures] = useState([]);

  const isValid = useMemo(() => {
    return (
      signatures.length > 0 &&
      signatures.filter((signature) =>
          signature.signatureType &&
          signature.hashValues.filter((x) => !!x).length === signature.hashValues.length)
        .length === signatures.length
    );
  }, [signatures]);

  useEffect(() => {
    if (open) {
      const signatures = form.signatures || [];
      if (signatures.length === 0) {
        setSignatures([{ hashValues: [""], signatureType: "" }]);
      } else {
        setSignatures(form.signatures);
      }
    }
  }, [form.signatures, open]);

  const handleConfirm = () => {
    setForm((prevForm) => ({ ...prevForm, signatures }));
    handleClose();
  };

  const handleAddNew = (e) => {
    setSignatures((prevState) => [
      ...prevState,
      { hashValues: [""], signatureType: "" },
    ]);
  };

  const handleAddNewHash = (index) => {
    setSignatures((prevState) => {
      return prevState.map((signature, idx) => {
        if (idx === index) {
          return {
            ...signature,
            hashValues: [...signature.hashValues, ""],
          };
        }
        return signature;
      });
    });
  };

  const handleRemoveHash = (signatureIndex, hashIndex) => {
    setSignatures((prevState) => {
      return prevState.map((signature, idx) => {
        if (idx === signatureIndex) {
          return {
            ...signature,
            hashValues: [
              ...signature.hashValues.slice(0, hashIndex),
              ...signature.hashValues.slice(hashIndex + 1),
            ],
          };
        }
        return signature;
      });
    });
  };

  const handleRemoveSignature = (index) => {
    setSignatures((prevState) => {
      return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
    });
  };

  const handleChangeSignature = (index) => (e) => {
    setSignatures((prevState) => {
      return prevState.map((signature, idx) => {
        if (idx === index) {
          return {
            ...signature,
            signatureType: e.target.value,
          };
        }
        return signature;
      });
    });
  };

  const handleChangeSignatureHash = (signatureIndex, hashIndex) => (e) => {
    const value = e.target.value;
    setSignatures((prevState) => {
      return prevState.map((signature, idx) => {
        if (idx === signatureIndex) {
          return {
            ...signature,
            hashValues: signature.hashValues.map((hash, idx) => {
              if (idx === hashIndex) {
                return value;
              }
              return hash;
            }),
          };
        }
        return signature;
      });
    });
  };

  return (
    <BaseDialog
      onClose={handleClose}
      open={open}
      title={"Signatures"}
      primaryAction={handleConfirm}
      maxWidth={"md"}
      isValid={isValid}
      fullWidth
    >
      <Collapse in={!isValid}>
        <Alert severity="warning" style={{ marginBottom: 16 }}>
          Please fill all the required field
        </Alert>
      </Collapse>
      <Grid container spacing={2}>
        {signatures.map((signature, index) => (
          <Grid item container spacing={1}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography style={{ marginRight: 8 }}>
                Signature #{index + 1}
              </Typography>
              <IconButton onClick={() => handleRemoveSignature(index)}>
                <RemoveCircleOutline color={"error"} />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Select
                placeholder={"Select"}
                label={"Type"}
                controlId={"signatureType"}
                margin={"none"}
                required
                value={signature.signatureType}
                options={SIGNATURES}
                onChange={handleChangeSignature(index)}
              />
            </Grid>
            <Grid item xs={8} container direction={"column"} spacing={2}>
              {signature.hashValues.map((hash, idx) => (
                <Grid item>
                  <TextField
                    variant="standard"
                    margin="none"
                    placeholder={"Enter signature hash"}
                    fullWidth
                    autoFocus
                    value={hash}
                    required
                    onChange={handleChangeSignatureHash(index, idx)}
                    label="Signature Hash"
                  />
                </Grid>
              ))}
              <Grid
                item
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {signature.hashValues.length > 1 && (
                  <Button
                    size={"small"}
                    variant={"outlined"}
                    style={{ marginRight: 8 }}
                    onClick={() =>
                      handleRemoveHash(index, signature.hashValues.length - 1)
                    }
                    startIcon={<RemoveCircleOutline color={"error"} />}
                  >
                    Remove Hash
                  </Button>
                )}
                <Button
                  size={"small"}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => handleAddNewHash(index)}
                  startIcon={<AddCircleOutline />}
                >
                  Add Hash
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 24 }}>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={handleAddNew}
            startIcon={<AddCircleOutline />}
          >
            Add Signature
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

export default SignatureDialog;