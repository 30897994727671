import React from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import Box from "@material-ui/core/Box";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDropzone } from "reactcoregk/hooks";
import { makeStyles } from "@material-ui/core/styles";
import { downloadFile, postData } from "../../helpers/utils";
import { API_URL } from "../../config";
import Title from "../../components/Common/Title";
import Typography from "@material-ui/core/Typography";
import HiDrop from "../../components/HiDrop";
import ErrorBoundary from "../../components/ErrorBoundary";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles({
  link: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
  },
});

const UploadCvesDialog = (props) => {
  const { open, handleClose, refreshJobs } = props;

  const classes = useStyles();

  const [inProgress, setInProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { handleAcceptedFiles, setSelectedFiles, selectedFiles } =
    useDropzone();

  useEffect(() => {
    if (open) {
      setSelectedFiles([]);
    }
  }, [open, setSelectedFiles]);

  const handleCsvDownload = async () => {
    try {
      await downloadFile(
        API_URL + "/api/cverisk/download-template/cyrecon-sync",
        "CVE Definition Template",
        "csv"
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);

    setInProgress(true);
    postData(API_URL + "/api/cverisk/upload-definitions/cyrecon", formData)
      .then(() => {
        setInProgress(false);
        refreshJobs();
        handleClose();
      })
      .catch((ex) => {
        setInProgress(false);
        enqueueSnackbar(ex.message, { variant: "error" });
      });
  };

  return (
    <BaseDialog
      form={{ id: "upload-definitions" }}
      title={"Upload Cves"}
      showProgress
      maxWidth={"sm"}
      primaryAction={handleUpload}
      primaryActionTitle={"Upload"}
      open={open}
      inProgress={inProgress}
      onClose={handleClose}
    >
      <ErrorBoundary>
        <Grid container direction={"column"} spacing={1}>
          <Grid item>
            <Title>Add CVEs from csv</Title>
          </Grid>
          <Box mt={2} />
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography
                  color={"textSecondary"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Upload CSV{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation={"vertical"} style={{ height: 14 }} />
              </Grid>
              <Grid item>
                <Button
                  color={"primary"}
                  size={"small"}
                  className={classes.link}
                  onClick={handleCsvDownload}
                >
                  <GetAppIcon /> Download template
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ height: 250 }}>
            <HiDrop
              selectedFiles={selectedFiles}
              handleAcceptedFiles={handleAcceptedFiles}
              title={"Drag and drop CSV"}
              setSelectedFiles={setSelectedFiles}
              link={"Upload file"}
              accept=".csv, application/vnd.ms-excel, text/csv"
            />
          </div>
        </Grid>
      </ErrorBoundary>
    </BaseDialog>
  );
};

export default UploadCvesDialog;
