import React, {Fragment, useContext, useEffect} from 'react';
import {Toolbar} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {KeyboardArrowLeft} from "@material-ui/icons";
import Title from "../Common/Title";
import Divider from "@material-ui/core/Divider";
import {ExtraTitle} from 'components/Layouts/AuthLayout'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: theme.spacing(1),
    borderBottom: "1px solid #3B3B3B",
    width: "100%",
  },
  rightMenu: {
    width: "800px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  createDashboardBtn: {
    width: "20px",
  },
  actionButton: {
    width: "20px",
  },
  appBar: {
    marginTop: "50px",
    height: 60,
    zIndex: 2,
  },
  backButton: {
    cursor: "pointer",
    textDecoration: "none",
    background: "transparent",
    "&:hover": {
      textDecoration: "none",
      background: "transparent",
      color: "white",
    },
  },
}));
const SubMenu = (props) => {
  const classes = useStyles();
  const { MenuItemsComponent } = props;
  const {setExtraTitle} = useContext(ExtraTitle)

  useEffect(()=>{
    if (props.title && props.backLink) {
      setExtraTitle(`- ${props.title}`)
    }
    else
      setExtraTitle('')
    // eslint-disable-next-line
  },[props.title,props.backLink])
  const renderTitle = () => {
    const link = props.backLink ? props.backLink : props.navLink;
    return (
      props.title && (
        <Box
          component={props.backLink || props.navLink ? Link : Fragment}
          to={link}
          display="flex"
          alignItems="center"
          alignContent="center"
          mr={1}
          className={classes.backButton}
        >
          {props.backLink && (
            <KeyboardArrowLeft color={"primary"} fontSize={"large"} />
          )}
          <Title
            style={{
              color: props.backLink ? "white" : "#A7A7A7",
              whiteSpace: "nowrap",
            }}
          >
            {props.title}
          </Title>
          {props.MenuItemsComponent && !props.noDivider && (
            <Divider
              style={{ width: 1, height: 24, marginLeft: 16, marginRight: 16 }}
            />
          )}
        </Box>
      )
    );
  };

  return (
    <>
      <AppBar
        color="none"
        position="fixed"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.root} variant="dense">
          <div style={{ width: "100%" }}>
            <Box display="flex" style={{ alignItems: "center" }}>
              {renderTitle()}
              {MenuItemsComponent}
            </Box>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default SubMenu;

SubMenu.propTypes = {
  title: PropTypes.string,
  backLink: PropTypes.string,
  MenuItemsComponent: PropTypes.element,
  navLink: PropTypes.string,
  noDivider: PropTypes.bool
};
