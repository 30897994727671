import React, {useState,createContext} from 'react';
import Navigation from '../Header/Navigation';
import Background from 'components/Common/Background';
import Helmet from 'react-helmet'
import {PRODUCT_NAME} from "../../config";

export const ExtraTitle = createContext(null);
export const AuthLayout = (props) => {
  const [extraTitle, setExtraTitle] = useState('');

  return (
    <>
      <ExtraTitle.Provider value={{extraTitle, setExtraTitle}}>
        <Helmet>
          <title>{`${PRODUCT_NAME} Hive Cloud - ${props.title} ${extraTitle}`}</title>
        </Helmet>
        <Background>
          <Navigation websocketRef={props.websocketRef}/>
          <div style={{marginTop: 120, height: '-webkit-fill-available'}}>
            {props.children}
          </div>
        </Background>
      </ExtraTitle.Provider>
    </>
  );
};

