import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ErrorBoundary from "../../components/ErrorBoundary";
import { useDropzone, useForm } from "reactcoregk/hooks";
import Alert from "../../models/Alert";
import MultiSelect from "../../components/Inputs/MultiSelect";
import Select from "../../components/Inputs/Select";
import { ALERT_CATEGORY, THREAT_LEVEL_OPTIONS } from "../../constants/enums";
import MultipleFreeText from "../../components/Inputs/MultipleFreeText";
import ButtonChoseList from "../../components/Common/ButtonChoseList";
import ManageCvesDialog from "./ManageCvesDialog";
import { createOptions } from "reactcoregk/utils";
import {
  deleteData,
  downloadFile,
  fetchData,
  postData,
  updateData,
} from "../../helpers/utils";
import { API_URL } from "../../config";
import ManageActorsDialog from "./ManageActorsDialog";
import Title from "../../components/Common/Title";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import ManageAttacksDialog from "./ManageAttacksDialog";
import SectionGroupDescription from "components/SectionGroupDescription";
import { HiveIcons } from "assets/icons";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import FileCard from "components/Common/FileCard";
import BaseDialog from "components/Common/BaseDialog";
import HiDrop from "components/HiDrop";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
import CommaSeparatedInput from "../../components/Inputs/CommaSeparatedInput";
import { cloneDeep } from "lodash";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import IocTable from "./ioc-table";
import SearchBox from "components/Common/SearchBox";
import { debounce } from "lodash";
import { Paging } from "reactcoregk/models";
import AddEditIocDialog from "./AddEditIocDialog";

const ALERT_PDF = "ALERT_PDF";
const ALERT_IOC = "ALERT_IOC";

const useStyles = makeStyles({
  hidropTitle: {
    fontSize: 14,
    fontWeight: 300,
  },
  link: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
  },
});

function flatList(list, prop = "id") {
  return list.map((x) => x[prop]);
}

const setSortedOptions = (formField) => (options) => {
  return [
    ...options
      .sort((opt) => (formField.includes(opt.value) ? -1 : 1))
      .sort((a, b) => {
        if (formField.includes(a.value) && formField.includes(b.value)) {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
        }
        return 0;
      }),
  ];
};

const EditDialog = (props) => {
  const {
    entity,
    countryOptions,
    verticalOptions,
    techs,
    context,
    handleClose,
    open,
    deleteEntity,
  } = props;

  const classes = useStyles();

  const [form, handleChange, setForm] = useForm(new Alert());

  const [openCves, setOpenCves] = useState(false);
  const [openActors, setOpenActors] = useState(false);
  const [openAttacks, setOpenAttacks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addIocDialogOpen, setAddIocDialogOpen] = useState(false);

  const subTechniques = techs["subTechniques"] || [];
  const techniques = techs["techniques"] || [];
  const tactics = techs["tactics"] || [];

  const [tacticOptions, setTacticOptions] = useState([]);
  const [techniqueOptions, setTechniqueOptions] = useState([]);
  const [subTechniqueOptions, setSubTechniqueOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);

  const recommendations = useMemo(() => {
    return form?.recommendations ? form?.recommendations.split("<br/>") : [""];
  }, [form?.recommendations]);

  const insights = useMemo(() => form?.insights ?? [""], [form?.insights]);

  const [isSaving, setIsSaving] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    setTacticOptions(() =>
      setSortedOptions(form.mitreTacticIds)(createOptions(tactics, "name"))
    );
    setTechniqueOptions(() =>
      setSortedOptions(form.mitreTechniqueIds)(
        createOptions(techniques, "name")
      )
    );
    setSubTechniqueOptions(() =>
      setSortedOptions(form.mitreSubtechniqueIds)(
        createOptions(subTechniques, "name")
      )
    );
    setLocationOptions(() =>
      setSortedOptions(form.targetedLocations)(countryOptions)
    );
    setIndustryOptions(() =>
      setSortedOptions(form.targetedIndustries)(verticalOptions)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techs, loading]);

  const handleCreateNewAlert = async () => {
    setIsSaving(true);
    try {
      const res = await postData(`${API_URL}/api/alerts`, form);
      await handleUpload(res.id);
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
      setIsSaving(false);
    }
  };

  const handleUpdateAlert = async () => {
    setIsSaving(true);
    try {
      await updateData(`${API_URL}/api/alerts`, form);
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
      setIsSaving(false);
    }
  };

  const handleDeleteAlert = () => {
    deleteEntity(form);
    setOpenDelete(false);
    onClose();
  };

  const onDelete = () => {
    setOpenDelete(true);
  };

  const fetchAlertData = useCallback(async () => {
    if (entity?.id) {
      setForm(new Alert());
      setLoading(true);
      fetchData(`${API_URL}/api/alerts/${entity.id}`)
        .then((data) => {
          setForm({
            ...new Alert(),
            ...data,
            actorIds: flatList(data.actors, "actorId"),
            mitreTacticIds: flatList(data.mitreTactics),
            mitreTechniqueIds: flatList(data.mitreTechniques),
            mitreSubtechniqueIds: flatList(data.mitreSubtechniques),
            attackIds: flatList(data.attacks),
            attackCollectionIds: flatList(data.attackCollections, "uuid"),
            recommendations: data.recommendations ?? "",
            insights: data.insights ?? [""],
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setForm(new Alert());
    }
  }, [entity, setForm]);

  const [isOpenFileUpload, setOpenFileUpload] = useState({
    from: "",
    status: false,
  });
  const { handleDownloadFile, handleDeleteFile, busyFiles } = useFiles(
    entity?.id,
    fetchAlertData,
    isOpenFileUpload.from
  );

  useEffect(() => {
    fetchAlertData();
  }, [entity, setForm, fetchAlertData]);

  const onSelect = useCallback(
    (prop) => (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        [prop]: selected,
      }));
    },
    [setForm]
  );

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    handleAcceptedFiles: handleAcceptedAlertPdfFiles,
    setSelectedFiles: setSelectedAlertPdfFile,
    selectedFiles: selectedAlertPdfFile,
  } = useDropzone();

  const {
    handleAcceptedFiles: handleAcceptedAlertIocFile,
    setSelectedFiles: setSelectedAlertIocFile,
    selectedFiles: selectedAlertIocFile,
  } = useDropzone();

  const handleUpload = async (id) => {
    if (entity?.id || (id && typeof id === "number")) {
      const url = `${API_URL}/api/alerts/${entity?.id || id}/`;
      try {
        if (selectedAlertIocFile[0]) {
          const params = "iocs";
          const iocUrl = `${url}${params}`;
          const iocFormData = new FormData();
          iocFormData.append("file", selectedAlertIocFile[0]);
          await postData(iocUrl, iocFormData);
          setForm((form) => ({
            ...form,
          }));
          await Promise.all([fetchAlertData(), fetchAlertIocs()]);
        }
        if (selectedAlertPdfFile[0]) {
          const pdfUrl = `${API_URL}/api/alerts/${
            entity?.id || id
          }/file/upload`;
          const pdfFormData = new FormData();
          pdfFormData.append("file", selectedAlertPdfFile[0]);
          await postData(pdfUrl, pdfFormData);
          setForm((form) => ({
            ...form,
            hasPdfFile: true,
          }));
        }
        setOpenFileUpload({ from: "", status: false });
      } catch (e) {
        setUploadError(e.message);
      }
    } else {
      setOpenFileUpload({ from: "", status: false });
    }
  };

  const isValid = useMemo(() => {
    let isValid = false;
    isValid =
      Boolean(
        form.name &&
          form.shortName &&
          form.description &&
          form.threatLevel &&
          form.category
      ) && (form.cisaAvailability ? Boolean(form.cisaId) : true);
    return isValid;
  }, [
    form.cisaAvailability,
    form.cisaId,
    form.description,
    form.name,
    form.shortName,
    form.threatLevel,
    form.category,
  ]);

  const onClose = () => {
    handleClose();
    setSelectedAlertIocFile([]);
    setSelectedAlertPdfFile([]);
    setIsSaving(false);
    setIsUploading(false);
    setErrorMessage("");
    setForm(new Alert());
    setSearchInput("");
    setSearchIocQuery("");
    setPage(0);
  };

  const handleRemoveRecommendation = (index) => {
    const rec = cloneDeep(recommendations);
    rec.splice(index, 1);
    if (rec.length === 0) {
      rec.push("");
    }
    setForm((prevForm) => ({
      ...prevForm,
      recommendations: rec.join("<br/>"),
    }));
  };

  const handleUpdateRecommendation = (value, index) => {
    const rec = cloneDeep(recommendations);
    rec[index] = value;

    setForm((prevForm) => ({
      ...prevForm,
      recommendations: rec.join("<br/>"),
    }));
  };

  const handleAddRecommendation = () => {
    const rec = cloneDeep(recommendations);
    rec.push("");
    setForm((prevForm) => ({
      ...prevForm,
      recommendations: rec.join("<br/>"),
    }));
  };

  const handleRemoveInsights = (index) => {
    const rec = cloneDeep(insights);
    rec.splice(index, 1);
    if (rec.length === 0) {
      rec.push("");
    }
    setForm((prevForm) => ({
      ...prevForm,
      insights: rec,
    }));
  };

  const handleUpdateInsights = (value, index) => {
    const rec = cloneDeep(insights);
    rec[index] = value;

    setForm((prevForm) => ({
      ...prevForm,
      insights: rec,
    }));
  };

  const handleAddInsights = () => {
    const rec = cloneDeep(insights);
    rec.push("");
    setForm((prevForm) => ({
      ...prevForm,
      insights: rec,
    }));
  };

  const defaultCells = [
    {
      id: "iocType",
      label: "IOC Type",
    },
    {
      id: "iocValue",
      label: "IOC Value",
    },
    {
      id: "action",
      label: " ",
    },
    {
      id: "action",
      label: " ",
    },
  ];

  const [searchIocQuery, setSearchIocQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [iocList, setIocList] = useState(new Paging());

  const [page, setPage] = useState(0);

  const fetchAlertIocs = async () => {
    if (entity?.id) {
      setLoading(true);
      fetchData(
        API_URL +
          `/api/iocs?size=10&page=${page}&alertId=${entity.id}&s=${encodeURI(
            searchIocQuery
          )}`
      )
        .then((res) => {
          setLoading(false);
          setIocList(res);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchAlertIocs();
  }, [searchIocQuery, entity, page]);

  const handleUpdateIoc = async (updatedIocs = [], deletedIocs = []) => {
    const requests = [];
    setLoading(true);
    let modifiedIocs = [];
    let newIocs = [];

    updatedIocs.forEach((x) => {
      if (x.uuid === undefined) {
        newIocs.push({ ...x, alertId: form.id, modified: undefined });
      } else if (x.uuid !== undefined && x.modified === true) {
        modifiedIocs.push({ ...x, alertId: form.id, modified: undefined });
      }
    });
    if (newIocs.length > 0) {
      requests.push(postData(API_URL + "/api/iocs", { iocs: newIocs }));
    }

    if (modifiedIocs.length > 0)
      requests.push(updateData(API_URL + "/api/iocs", { iocs: modifiedIocs }));

    if (deletedIocs.length > 0) {
      let stringUuids = deletedIocs.map((x) => x.uuid).join(",");
      requests.push(
        deleteData(API_URL + `/api/iocs/${form.id}?stringUuids=${stringUuids}`)
      );
    }
    await Promise.all(requests);
    await Promise.all([fetchAlertData(), fetchAlertIocs()]);
  };

  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      setPage(0);
      setSearchIocQuery(query);
    }, 300),
    []
  );

  return (
    <BaseDialog
      title={`${form.id ? "Edit" : "Create new"} Alert`}
      primaryAction={form?.id ? handleUpdateAlert : handleCreateNewAlert}
      primaryActionTitle={form?.id ? "Update" : "Save"}
      inProgress={isSaving}
      open={open}
      onClose={onClose}
      scrollable={true}
      isValid={isValid}
      errorMessage={errorMessage}
      dismissError={() => setErrorMessage("")}
      showProgress
      maxWidth={"lg"}
      onDelete={form.id && deleteEntity ? onDelete : null}
      {...props}
    >
      <ErrorBoundary>
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Title>Enter Alert details</Title>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              margin="none"
              placeholder={"Enter Alert Name"}
              fullWidth
              value={form.name}
              onChange={handleChange("name")}
              id="name"
              label="Alert Name"
              autoFocus
            />
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <TextField
              required
              variant="standard"
              margin="none"
              placeholder={"Enter Short Name"}
              fullWidth
              value={form.shortName}
              onChange={handleChange("shortName")}
              id="shortName"
              label="Short Name"
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Select
              required
              value={form.threatLevel}
              onChange={handleChange("threatLevel")}
              options={THREAT_LEVEL_OPTIONS}
              fullWidth
              margin={"none"}
              controlId={"threatLevel"}
              label={"Threat Level"}
              placeholder={"Select Threat Level"}
              hasNone={false}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Select
              required
              value={form.category}
              onChange={handleChange("category")}
              options={ALERT_CATEGORY}
              fullWidth
              margin={"none"}
              controlId={"category"}
              label={"Category"}
              placeholder={"Select Category"}
              hasNone={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              margin="none"
              placeholder={"Enter Description"}
              fullWidth
              multiline
              value={form.description}
              onChange={handleChange("description")}
              id="description"
              label="Description"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ButtonChoseList
              // disabled={busy}
              fullWidth
              onClick={() => setOpenCves(true)}
              title={"CVES"}
              selected={form.cves}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ButtonChoseList
              fullWidth
              onClick={() => setOpenActors(true)}
              title={"Actors"}
              selected={form.actorIds}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ButtonChoseList
              fullWidth
              onClick={() => setOpenAttacks(true)}
              title={"Attacks"}
              selected={form.attackIds}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <MultiSelect
              placeholder={"Select Tactic"}
              label={"Tactic"}
              controlId={"mitreTacticIds"}
              disabled={isSaving}
              onChange={handleChange("mitreTacticIds")}
              values={form.mitreTacticIds}
              margin={"none"}
              options={tacticOptions}
              labelId={"mitreTacticIds"}
              onClose={() =>
                setTacticOptions(setSortedOptions(form.mitreTacticIds))
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <MultiSelect
              placeholder={"Select Technique"}
              label={"Technique"}
              controlId={"mitreTechniqueIds"}
              disabled={isSaving}
              onChange={handleChange("mitreTechniqueIds")}
              values={form.mitreTechniqueIds}
              margin={"none"}
              options={techniqueOptions}
              labelId={"mitreTechniqueIds"}
              onClose={() =>
                setTechniqueOptions(setSortedOptions(form.mitreTechniqueIds))
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <MultiSelect
              placeholder={"Select Sub-Technique"}
              label={"Sub-Technique"}
              controlId={"mitreSubtechniqueIds"}
              disabled={isSaving}
              onChange={handleChange("mitreSubtechniqueIds")}
              values={form.mitreSubtechniqueIds}
              margin={"none"}
              options={subTechniqueOptions}
              labelId={"mitreSubtechniqueIds"}
              onClose={() =>
                setSubTechniqueOptions(
                  setSortedOptions(form.mitreSubtechniqueIds)
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <MultiSelect
              fullWidth
              margin={"none"}
              placeholder={"Targeted Locations"}
              options={locationOptions}
              label="Targeted Locations"
              onChange={handleChange("targetedLocations")}
              showSelectAll={true}
              values={form.targetedLocations}
              labelId={"targetedLocations"}
              onClose={() =>
                setLocationOptions(setSortedOptions(form.targetedLocations))
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <MultiSelect
              fullWidth
              margin={"none"}
              placeholder={"Targeted Industries"}
              options={industryOptions}
              label="Targeted Industries"
              showSelectAll={true}
              onChange={handleChange("targetedIndustries")}
              values={form.targetedIndustries}
              labelId={"targetedIndustries"}
              onClose={() =>
                setIndustryOptions(setSortedOptions(form.targetedIndustries))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CommaSeparatedInput
              placeholder={"Enter comma separated Tenable Plugins"}
              value={form.tenablePlugins}
              onChange={handleChange("tenablePlugins")}
              label="Tenable Plugins"
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleFreeText
              csAutotags={true}
              value={form.recentBreaches}
              onChange={handleChange("recentBreaches")}
              label={"Recent Breaches"}
              placeholder={"Add Recent Breaches"}
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleFreeText
              csAutotags={true}
              value={form.references}
              onChange={handleChange("references")}
              label={"References"}
              placeholder={"Add References"}
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleFreeText
              csAutotags={true}
              value={form.patchLinks}
              onChange={handleChange("patchLinks")}
              label={"Patch Links"}
              placeholder={"Enter Patch Links"}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} direction="row" spacing={3} md={4} sm={6}>
          <Grid item style={{ marginTop: "1rem" }}>
            <FormControlLabel
              label="CISA"
              control={
                <Checkbox
                  checked={form.cisaAvailability}
                  onChange={handleChange("cisaAvailability")}
                  name="cisaAvailability"
                  color="primary"
                />
              }
            />
          </Grid>
          <Grid item style={{ marginTop: "0.3rem" }}>
            <TextField
              required={form.cisaAvailability}
              variant="standard"
              margin="none"
              placeholder={"Enter CISA ID"}
              fullWidth
              value={form.cisaId}
              onChange={handleChange("cisaId")}
              id="cisaId"
              label="CISA ID"
              autoFocus
              disabled={!form.cisaAvailability}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: "1rem" }}>
          <Grid item md={2} sm={6} xs={12} style={{ marginTop: "0.5rem" }}>
            <TextField
              variant="standard"
              margin="none"
              type="number"
              placeholder={"Enter Attacks Executed"}
              fullWidth
              value={form.attacksExecuted}
              onChange={handleChange("attacksExecuted")}
              id="attacksExecuted"
              label="Attacks Executed"
            />
          </Grid>
          <Grid item md={5} sm={6} xs={12} style={{ marginTop: "1rem" }}>
            <Paper variant={"outlined"} style={{ padding: 16 }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  color={"textPrimary"}
                  style={{ fontWeight: "bold" }}
                >
                  Recommendations
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleAddRecommendation}
                    style={{ marginLeft: 16 }}
                    size={"small"}
                  >
                    <AddCircleOutline
                      style={{ marginRight: 4 }}
                      color={"primary"}
                    />
                    Add Recommendations
                  </Button>
                </Box>
              </Box>
              {recommendations.map((rec, index) => {
                return (
                  <>
                    <Grid item container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <TextField
                          id="name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          placeholder={"Enter Recommendation"}
                          value={rec}
                          disabled={isSaving}
                          onChange={(e) => {
                            handleUpdateRecommendation(e.target.value, index);
                          }}
                          label={`Recommendation #${index + 1}`}
                        />
                        <IconButton
                          onClick={() => {
                            handleRemoveRecommendation(index);
                          }}
                        >
                          <RemoveCircleOutline color={"error"} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Paper>
          </Grid>
          <Grid item md={5} sm={6} xs={12} style={{ marginTop: "1rem" }}>
            <Paper variant={"outlined"} style={{ padding: 16 }}>
              <Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    color={"textPrimary"}
                    style={{ fontWeight: "bold" }}
                  >
                    Insights
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleAddInsights}
                      style={{ marginLeft: 16 }}
                      size={"small"}
                    >
                      <AddCircleOutline
                        style={{ marginRight: 4 }}
                        color={"primary"}
                      />
                      Add Insights
                    </Button>
                  </Box>
                </Box>

                {insights?.map((rec, index) => {
                  return (
                    <>
                      <Grid item container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            id="name"
                            variant="standard"
                            margin="normal"
                            fullWidth
                            placeholder={"Enter Insight"}
                            value={rec}
                            disabled={isSaving}
                            onChange={(e) => {
                              handleUpdateInsights(e.target.value, index);
                            }}
                            label={`Insight #${index + 1}`}
                          />
                          <IconButton
                            onClick={() => {
                              handleRemoveInsights(index);
                            }}
                          >
                            <RemoveCircleOutline color={"error"} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid item style={{ marginTop: "1rem" }}>
          <Paper variant={"outlined"} style={{ padding: 16 }}>
            <Grid container>
              <Grid item style={{ flex: 1 }}>
                <SectionGroupDescription
                  title={"Alert PDF"}
                  IconComponent={FolderOpenIcon}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={isSaving || form?.hasPdfFile || busyFiles}
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() =>
                    setOpenFileUpload({ from: ALERT_PDF, status: true })
                  }
                >
                  <HiveIcons.AddOutlined />
                  Add Pdf File
                </Button>
              </Grid>
            </Grid>
            {form?.hasPdfFile && (
              <Grid container>
                <Grid item sm={4}>
                  <FileCard
                    file="Alert PDF"
                    busy={busyFiles}
                    handleDelete={() => handleDeleteFile()}
                    handleDownload={() => handleDownloadFile(form.name)}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item style={{ marginTop: "1rem" }}>
          <Paper variant={"outlined"} style={{ padding: 16 }}>
            <Grid container>
              <Grid item style={{ flex: 1, overflowY: "visible" }}>
                <Typography>IOCs</Typography>
              </Grid>
              <Grid
                item
                style={{
                  marginRight: 3,
                }}
              >
                <SearchBox
                  placeholder={"IOCs"}
                  handleChange={(e) => {
                    debouncedHandleSearch(e.target.value);
                    setSearchInput(e.target.value);
                  }}
                  query={searchInput}
                />
              </Grid>
              <Grid
                item
                style={{
                  marginRight: 5,
                }}
              >
                <Button
                  disabled={isSaving}
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() => {
                    setAddIocDialogOpen(true);
                  }}
                >
                  <HiveIcons.AddOutlined />
                  Add IOCs
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isSaving}
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() =>
                    setOpenFileUpload({ from: ALERT_IOC, status: true })
                  }
                >
                  <HiveIcons.AddOutlined />
                  Upload IOCs
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{ flex: 1, overflowY: "visible" }}>
                <IocTable
                  headCells={defaultCells}
                  alert={entity}
                  iocs={iocList}
                  page={page}
                  setPage={setPage}
                  busy={loading}
                  handleUpdateIoc={handleUpdateIoc}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </ErrorBoundary>
      <ManageCvesDialog
        open={openCves}
        handleClose={() => setOpenCves(false)}
        callback={onSelect("cves")}
        alert={form}
      />
      <ManageActorsDialog
        open={openActors}
        handleClose={() => setOpenActors(false)}
        callback={onSelect("actorIds")}
        alert={form}
      />
      <ManageAttacksDialog
        open={openAttacks}
        handleClose={() => setOpenAttacks(false)}
        callback={onSelect("attackIds")}
        alert={form}
      />
      <BaseDialog
        title={
          isOpenFileUpload.from == ALERT_PDF
            ? "Upload Alert PDF"
            : "Upload IOC CSV"
        }
        showProgress
        inProgress={isUploading}
        primaryActionTitle={"Upload"}
        primaryAction={handleUpload}
        onClose={() => {
          isOpenFileUpload.from === ALERT_PDF
            ? setSelectedAlertPdfFile([])
            : setSelectedAlertIocFile([]);
          setOpenFileUpload({ from: "", status: false });
        }}
        open={isOpenFileUpload.status}
        errorMessage={uploadError}
        isValid={
          isOpenFileUpload.from == ALERT_PDF
            ? selectedAlertPdfFile[0]
            : selectedAlertIocFile[0]
        }
        dismissError={() => setUploadError(null)}
      >
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography
                  color={"textSecondary"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {isOpenFileUpload.from == ALERT_PDF
                    ? "Upload Alert PDF"
                    : "Upload IOC CSV"}{" "}
                </Typography>
              </Grid>
              {isOpenFileUpload.from !== ALERT_PDF && (
                <>
                  <Grid item>
                    <Divider orientation={"vertical"} style={{ height: 14 }} />
                  </Grid>
                  <Grid item>
                    <Button
                      color={"primary"}
                      size={"small"}
                      className={classes.link}
                      onClick={() => {
                        setOpenFileUpload({ from: ALERT_IOC, status: false });
                        handleDownloadFile("IOCTemplate");
                      }}
                    >
                      <GetAppIcon /> Download template
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <div style={{ height: 250 }}>
              {isOpenFileUpload.from == ALERT_PDF ? (
                <HiDrop
                  selectedFiles={selectedAlertPdfFile}
                  handleAcceptedFiles={handleAcceptedAlertPdfFiles}
                  title={"Drag and drop here"}
                  setSelectedFiles={setSelectedAlertPdfFile}
                  link={"Upload file"}
                  accept={".pdf"}
                />
              ) : (
                <HiDrop
                  selectedFiles={selectedAlertIocFile}
                  handleAcceptedFiles={handleAcceptedAlertIocFile}
                  title={"Drag and drop here"}
                  setSelectedFiles={setSelectedAlertIocFile}
                  link={"Upload file"}
                  accept={".csv"}
                  isMultiple={false}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </BaseDialog>
      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        title="Delete Alert"
        description="Are you sure you want to delete this Alert?"
        primaryActionTitle={"Delete"}
        secondaryActionTitle={"Cancel"}
        primaryAction={handleDeleteAlert}
        secondaryAction={() => setOpenDelete(false)}
      />
      {addIocDialogOpen && (
        <AddEditIocDialog
          open={addIocDialogOpen}
          isEdit={false}
          onClose={() => setAddIocDialogOpen(false)}
          maxWidth={"md"}
          title="Add IOCs"
          primaryActionTitle={"Add"}
          secondaryActionTitle={"Cancel"}
          secondaryAction={() => setAddIocDialogOpen(false)}
          formIocs={[
            {
              iocType: "",
              iocValue: "",
              uuid: undefined,
            },
          ]}
          handleUpdateIoc={handleUpdateIoc}
          showProgress
          inProgress={loading}
          alertId={form.id}
        />
      )}
    </BaseDialog>
  );
};

export default EditDialog;

const useFiles = (id, fetchAlertData, from) => {
  const [busyFiles, setBusyFiles] = useState(false);

  const handleDownloadFile = (filename) => {
    setBusyFiles(true);
    const urlAlertPdf = `${API_URL}/api/alerts/${id}/file/download`;
    const urlIocCsv = `${API_URL}/api/iocs/upload-template`;
    downloadFile(
      `${from === ALERT_IOC ? urlIocCsv : urlAlertPdf}`,
      filename,
      `${from === ALERT_IOC ? "csv" : "pdf"}`
    )
      .then(() => setBusyFiles(false))
      .catch(() => setBusyFiles(false));
  };

  const handleDeleteFile = () => {
    setBusyFiles(true);
    deleteData(`${API_URL}/api/alerts/${id}/file`)
      .then(() => {
        fetchAlertData();
        setBusyFiles(false);
      })
      .catch(() => setBusyFiles(false));
  };

  return {
    busyFiles,
    handleDeleteFile,
    handleDownloadFile,
  };
};
