import BaseDialog from "../BaseDialog";
import Title from "../Title";
import Pluralize from "pluralize";
import { Box, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { fetchData } from "../../../helpers/utils";
import { API_URL } from "../../../config";
import RankEditDialog from "./RankEditDialog";

function RankViewDialog({ open, handleClose, entityName, rankEntity }) {
  const [busy, setBusy] = useState(false);
  const [items, setItems] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (open) {
      setItems([]);
      setBusy(true);
      fetchData(`${API_URL}/api/rankings/${rankEntity}`)
        .then((data) => {
          setItems(
            data.map((item) => ({
              ...item,
              id: item.rankEntityId,
              entityName: item.rankEntityName,
            }))
          );
          setBusy(false);
          handleClose();
          setOpenEdit(true);
        })
        .catch((ex) => {
          setBusy(false);
          setItems([]);
        });
    }
  }, [handleClose, open, rankEntity]);

  return (
    <Fragment>
      <RankEditDialog
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        selected={items}
        entityName={entityName}
      />
      <BaseDialog
        onClose={handleClose}
        title={`View ${entityName} Ranking`}
        inProgress={busy}
        showProgress
        open={open}
        primaryAction={handleClose}
        primaryActionTitle={"Dismiss"}
      >
        {!busy ? (
          <Fragment>
            <Title>No ranked {Pluralize(entityName, 2)} found.</Title>
            <Box mt={1} />
            <Typography>
              Please make a selection from the list and then press Rank
              Selection.
            </Typography>
          </Fragment>
        ) : (
          <Title>Fetching {Pluralize(entityName, 2)}.</Title>
        )}
      </BaseDialog>
    </Fragment>
  );
}

export default RankViewDialog;
