import {entityType} from "./module";
import {
    createDeleteMethod,
    createGetAllMethod,
    createGetMethod,
    createPostMethod,
    createPutMethod
} from "reactcoregk/store/actions";
import {getDefaultApiHandler} from "../../helpers/utils";

export const getAllClients = (params) => createGetAllMethod(entityType, params, getDefaultApiHandler())
export const getClient = (params) => createGetMethod(entityType, params, getDefaultApiHandler())
export const createClient = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateClient = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const deleteClient = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())
