import React, { useCallback } from "react";
import {
  createGenerateClassName,
  makeStyles,
  StylesProvider,
  withStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Box, Tabs, Toolbar } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ReactComponent as KronosLogo } from "assets/images/logo.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { HeaderTab } from "./HeaderTab";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: theme.spacing(1),
    borderBottom: `1px solid ${
      theme.palette.type === "dark" ? "#3B3B3B" : "#E5E6E9"
    }`,
  },
  rightMenu: {
    width: "800px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  createDashboardBtn: {
    width: "20px",
  },
  actionButton: {
    width: "20px",
  },
  appBar: {
    // marginBottom: "12px",
    height: 50,
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main} !Important`,
    color: theme.palette.primary.main + " !Important",
    fontWeight: "bold !Important",
  },
}));

const HeaderTabs = withStyles({
  root: {
    width: "100%",
    padding: 0,
    alignItems: "center",
    display: "flex",
  },
})(Tabs);

const generateClassName = createGenerateClassName({
  seed: "Navigation",
});

const PrimaryNavigation = (props) => {
  const classes = useStyles();
  const { theme, navigationLinks } = props;

  const logoColor =
    theme.palette.type === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black;

  const matches = useMediaQuery("(min-width:1360px)");

  const getView = useCallback((link) => {
    if (link.customEl) {
      return link.customEl();
    }
    return (
      <HeaderTab
        label={link.label}
        component={NavLink}
        id={link.path}
        exact={false}
        activeClassName={classes.activeTab}
        to={link.path}
      />
    );
  }, [classes.activeTab]);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <AppBar
        color="none"
        position="fixed"
        elevation={2}
        component={Box}
        boxShadow={20}
        className={classes.appBar}
      >
        <Toolbar className={classes.root} variant="dense">
          <NavLink
            to={"/"}
            style={{
              backgroundColor: "transparent",
              width: matches ? "auto" : "50px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            <KronosLogo width={"120px"} color={logoColor} />
          </NavLink>
          <HeaderTabs>
            {navigationLinks.map((link, index) => (
              <>
                {getView(link)}
                {link.divider && (
                  <Divider
                    style={{
                      width: 1,
                      height: 24,
                      margin: "12px",
                    }}
                  />
                )}
              </>
            ))}
          </HeaderTabs>
        </Toolbar>
      </AppBar>
    </StylesProvider>
  );
};

export default PrimaryNavigation;
