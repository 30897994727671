// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "components/Inputs/Select";
import Input from "components/Inputs/Input";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Box } from "@material-ui/core";
import { useApi } from "../../../helpers/hooks";
import { format } from 'date-fns'

const INITIAL_RULE = {
  uuid: "",
  name: "",
  description: "",
  categoryUuid: "",
  enabled: false,
  parameters: [],
};

const INITIAL_PARAMETER = {
  parameter: "",
  condition: "",
  conditionValue: "",
  operator: null,
  priority: 0,
};

const useVulnerabilityClassificationRules = (selectedRuleId) => {
  const API_URL = useMemo(() => {
    return selectedRuleId && `/classifications/rules/${selectedRuleId}`;
  }, [selectedRuleId]);

  const [ruleDetails, ruleIsLoading] = useApi(API_URL, INITIAL_RULE);
  const [rule, handleChangeRule, clearRuleForm] = useForm(
    ruleDetails ? ruleDetails : INITIAL_RULE
  );
  const [parameters, setParameters] = useState([]);
  const [errors, setErrors] = useState({ ruleErrors: "", parameterErrors: [] });

  useEffect(() => {
    setParameters(
      ruleDetails.parameters.sort((a, b) => (a.priority > b.priority ? 1 : -1))
    );
  }, [ruleDetails, setParameters]);

  useEffect(() => {
    if (!selectedRuleId) {
      clearRuleForm(INITIAL_RULE);
      setParameters([]);
    }
  }, [clearRuleForm, selectedRuleId]);

  const [CATEGORIES, , refreshCategories] = useApi(
    "/classifications/categories",
    []
  );

  const CATEGORIES_OPTIONS = useMemo(() => {
    return CATEGORIES.map?.((category) => ({
      value: category.uuid,
      label: category.name,
    }));
  }, [CATEGORIES]);

  const [PARAMETERS] = useApi("/classifications/rules/parameters", []);
  const PARAMETERS_OPTIONS = useMemo(() => {
    return PARAMETERS.map?.((parameter) => ({
      value: parameter.name,
      label: parameter.label,
    }));
  }, [PARAMETERS]);

  const handleAddNewParameter = useCallback(() => {
    setParameters((parameters) => [...parameters, INITIAL_PARAMETER]);
  }, []);

  const handleEditParameter = useCallback(
    (index, field) => (e) => {
      e.persist && e.persist();
      setParameters((params) =>
        params.map((param, i) => {
          if (index === i) {
            return {
              ...param,
              [field]: e.target?.value,
            };
          }
          return param;
        })
      );
    },
    []
  );

  const handleRemoveParameter = useCallback((index) => {
    setParameters((parameters) => {
      const tempArray = [...parameters];
      tempArray.splice(index, 1);
      return tempArray;
    });
  }, []);

  const handleValidateForm = useCallback(() => {
    let ruleErrors = "";
    for (const key of Object.keys(rule)) {
      if (key === "name" && !rule[key]) {
        ruleErrors = "Name is required";
        break;
      }
      if (key === "description" && !rule[key]) {
        ruleErrors = "Description is required";
        break;
      }
      if (key === "categoryUuid" && !rule[key]) {
        ruleErrors = "Category is required";
        break;
      }
    }

    const parameterErrors = parameters.map((param, index) => {
      if (!param.parameter) {
        return "Parameter is required";
      }
      if (!param.condition) {
        return "Condition is required";
      }
      if (!param.conditionValue) {
        return "Condition Value is required";
      }
      if (index !== parameters.length - 1 && !param.operator) {
        return "Operator is required";
      }
      return "";
    });
    setErrors({ ruleErrors, parameterErrors });
    return (
      !Boolean(ruleErrors) &&
      parameterErrors.every((parameterError) => !Boolean(parameterError))
    );
  }, [rule, parameters]);

  const isValidRule = useMemo(() => {
    let isValid = false;
    if (
      !Boolean(errors.ruleErrors) &&
      errors.parameterErrors.every((parameterError) => !Boolean(parameterError))
    ) {
      isValid = true;
    }
    return isValid;
  }, [errors]);

  return {
    CATEGORIES_OPTIONS,
    CATEGORIES,
    PARAMETERS_OPTIONS,
    PARAMETERS,
    handleAddNewParameter,
    parameters,
    handleRemoveParameter,
    handleEditParameter,
    rule,
    handleChangeRule,
    handleValidateForm,
    errors,
    isValidRule,
    refreshCategories,
    ruleIsLoading,
  };
};

const useVulnerabilityClassificationRulesHelpers = () => {
  const getConditionValuesFields = useCallback(
    (parameter, handleEditConditionValue) => {
      switch (parameter.parameter) {
        case "EXPLOITABLE":
        case "WORMABLE":
        case "ATTACK_AVAILABLE":
        case "ATTACKS_SIMULATED":
        case "SIMULATION_BLOCKED":
        case "ZERO_DAY":
        case "ACTORS_ASSOCIATED":
        case "ASSOCIATED_MALWARE_RANSOMWARE":
        case "PATCH_PUBLISHED":
          return (
            <Select
              options={[
                { label: "TRUE", value: "TRUE" },
                { label: "FALSE", value: "FALSE" },
              ]}
              value={parameter.conditionValue}
              margin="none"
              fullWidth
              controlId={`conditionValue-${parameter.uuid}`}
              placeholder="Select"
              label="Select"
              onChange={handleEditConditionValue}
            />
          );
        case "MENTION_LANGUAGES":
          return (
            <Select
              options={[
                { label: "English", value: "english" },
                { label: "Chinese", value: "chinese" },
                { label: "Russian", value: "russian" },
              ]}
              value={parameter.conditionValue}
              margin="none"
              fullWidth
              controlId={`conditionValue-${parameter.uuid}`}
              placeholder="Select"
              label="Select"
              onChange={handleEditConditionValue}
            />
          );
        case "STATUS":
          return (
            <Select
              options={[
                { label: "Open", value: "open" },
                { label: "Closed", value: "closed" },
                { label: "Reopened", value: "reopened" },
              ]}
              value={parameter.conditionValue}
              margin="none"
              fullWidth
              controlId={`conditionValue-${parameter.uuid}`}
              placeholder="Select"
              label="Select"
              onChange={handleEditConditionValue}
            />
          );
        case "FIRST_SEEN":
        case "LAST_SEEN":
          const changeFirstDateHandler = (e) => {
            const parsedDate = format(e, "yyyy/MM/dd");
            e = `${parsedDate},${parameter.conditionValue.split(",")[1]}`;
            handleEditConditionValue({ target: {value: e} });
          };
          const changeSecondDateHandler = (e) => {
            const parsedDate = format(e, "yyyy/MM/dd");
            e = `${parameter.conditionValue.split(",")[0]},${parsedDate}`;
            handleEditConditionValue({ target: {value: e} });
          };
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box>
                <KeyboardDatePicker
                  disableToolbar
                  style={{paddingTop:0}}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  fullWidth
                  value={parameter?.conditionValue?.split(",")[0]}
                  onChange={changeFirstDateHandler}
                />
                {parameter.condition === "BETWEEN" && (
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    fullWidth
                    value={parameter?.conditionValue?.split(",")[1]}
                    onChange={changeSecondDateHandler}
                  />
                )}
              </Box>
            </MuiPickersUtilsProvider>
          );
        default:
          return (
            <Input
              value={parameter.conditionValue}
              placeholder="Enter value"
              onChange={handleEditConditionValue}
              margin="none"
              style={{ marginTop: 16 }}
            />
          );
      }
    },
    []
  );

  const formatPayload = (rule, parameters) => {
    return {
      ...rule,
      parameters: parameters.map((param, i) => {
        let conditionValue = param.conditionValue;
        if (
          param.parameter === "FIRST_SEEN" ||
          param.parameter === "LAST_SEEN"
        ) {
          if (!(param.condition === "BETWEEN")) {
            conditionValue = conditionValue.split(",")[0];
          }
        }
        return {
          ...param,
          conditionValue,
          priority: i,
        };
      }),
    };
  };

  return { getConditionValuesFields, formatPayload };
};
const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (prop) => (e) => {
    const value = e && e.target ? e.target.value : e ? e.value || e : null;
    const safeValue =
      e && e.target && e.target.type === "checkbox" ? e.target.checked : value;
    setValues({
      ...values,
      [prop]: safeValue,
    });
  };
  const handleChangeArray = (prop, array) => {
    setValues({
      ...values,
      [prop]: array,
    });
  };
  const updateInitial = useCallback((values) => {
    setValues(values);
  }, []);

  return [values, handleChange, updateInitial, handleChangeArray];
};

export {
  useVulnerabilityClassificationRules,
  useVulnerabilityClassificationRulesHelpers,
};
