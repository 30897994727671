import React, {useEffect, useState} from "react";
import BaseDialog from "./Common/BaseDialog";
import Grid from "@material-ui/core/Grid";
import {Card, CardActionArea, CardContent, styled} from "@material-ui/core";

function importAll(r) {
  return r.keys().map(r);
}

export const loadBasIconLibrary = () => {
  const images = importAll(require.context("../assets/icons/bas", false, /\.(svg)$/));
  return images.map((src) => {
    const key = src
      .substr(src.indexOf("icon_"))
      .replace(/ *\.[^)]*\.svg */g, "")
      .replace("icon_", "")
    return { src, key: parseInt(key) };
  });
};
const images = loadBasIconLibrary();

const StyledCard = styled(Card)((props) => ({
  backgroundColor: props.selected ? props.theme.palette.primary.main : "transparent"
}))

function CardIcon({ image, selected, setSelected }) {
  return (
    <Grid item>
      <StyledCard variant={"outlined"} selected={selected === image.key}>
        <CardActionArea
          onClick={() => setSelected(image.key)}
        >
          <CardContent>
            <img src={image.src} width={80} height={80}  alt={image.key}/>
          </CardContent>
        </CardActionArea>
      </StyledCard>
    </Grid>
  );
}

function BasIconLibraryDialog({ open, handleClose, onChange, value  }) {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (open) setSelected(value)
  }, [open, value])

  const handleConfirm = () => {
    onChange(selected)
    handleClose()
  }

  return (
    <BaseDialog
      onClose={handleClose}
      open={open}
      title={"Bas Icon Library"}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      primaryActionTitle={"Confirm"}
    >
      <Grid container spacing={2} justify={"center"}>
        {images.map((image) => {
          return (
            <CardIcon
              key={image.key}
              image={image}
              selected={selected}
              setSelected={setSelected}
            />
          );
        })}
      </Grid>
    </BaseDialog>
  );
}

export default BasIconLibraryDialog;
