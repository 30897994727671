import React, { useCallback, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "../../components/Inputs/Select";
import ErrorBoundary from "../../components/ErrorBoundary";
import MultipleFreeText from "../../components/Inputs/MultipleFreeText";
import ButtonChoseList from "../../components/Common/ButtonChoseList";
import {
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { AddPhotoAlternate, ExpandLess, ExpandMore } from "@material-ui/icons";
import EntitySelectDialog from "../../components/Common/EntitySelectDialog";
import { EntityType } from "../../store/@core/entityType";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { createOptions } from "../../helpers/utils";
import MultiSelect from "../../components/Inputs/MultiSelect";
import BasIconLibraryDialog, {
  loadBasIconLibrary,
} from "../../components/BasIconLibraryDialog";
import { createMap } from "reactcoregk/utils";

const CAPABILITIES = [
  {
    label: "Average",
    value: "AVERAGE",
  },
  {
    label: "Below Average",
    value: "BELOW_AVERAGE",
  },
  {
    label: "Above Average",
    value: "ABOVE_AVERAGE",
  },
];

const images = loadBasIconLibrary();
const imageMap = createMap(images, "key");

function Avatar({ avatar, onClick, disabled }) {
  return (
    <Card
      variant={"outlined"}
      style={{ width: "max-content", position: "relative" }}
    >
      <CardActionArea disabled={disabled} onClick={onClick}>
        <CardContent style={{ minWidth: 80, minHeight: 80 }}>
          {avatar && <img src={avatar.src} width={80} height={80} alt={""} />}
          {!avatar && (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddPhotoAlternate />
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function ActorForm(props) {
  const { form, handleChange, setForm, disabled, countries, verticals } = props;

  const [openCves, setOpenCves] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [openBasGallery, setOpenBasGallery] = useState(false);

  const verticalOptions = createOptions(verticals, "name", "name");
  const countryOptions = createOptions(countries, "name", "name");

  const onSelect = useCallback(
    (prop) => (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        [prop]: selected,
      }));
    },
    [setForm]
  );

  const avatar = imageMap.get(form.mediaGalleryImageId);

  return (
    <ErrorBoundary>
      <Grid container direction={"column"} spacing={2} item md={12} xs>
        <Grid item container spacing={2} alignItems={"flex-end"}>
          <Grid item>
            <Avatar
              disabled={disabled}
              avatar={avatar}
              onClick={() => setOpenBasGallery(true)}
            />
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <TextField
              variant="standard"
              margin="none"
              placeholder={"Enter Name"}
              fullWidth
              value={form.name}
              disabled={disabled}
              onChange={handleChange("name")}
              label="Name"
              autoFocus
            />
          </Grid>
        </Grid>

        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter Description"}
            fullWidth
            value={form.description}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <IconButton
                  size={"small"}
                  onClick={() => setOpenMore(!openMore)}
                >
                  {openMore ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              ),
            }}
            onChange={handleChange("description")}
            label="Description"
          />
        </Grid>

        <Grid item>
          <Collapse in={openMore}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <TextField
                  variant="standard"
                  margin="none"
                  placeholder={"Enter Short Description"}
                  fullWidth
                  multiline
                  rows={4}
                  value={form.shortDescription}
                  disabled={disabled}
                  onChange={handleChange("shortDescription")}
                  label="Short Description"
                />
              </Grid>

              <Grid item>
                <TextField
                  variant="standard"
                  margin="none"
                  placeholder={"Enter Complete Description"}
                  fullWidth
                  multiline
                  rows={8}
                  value={form.completeDescription}
                  disabled={disabled}
                  onChange={handleChange("completeDescription")}
                  label="Complete Description"
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="none"
              placeholder={"Select First Activity Date "}
              fullWidth
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={form.firstActivityDate}
              disabled={disabled}
              onChange={handleChange("firstActivityDate")}
              label="First Activity Date"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="none"
              placeholder={"Select Last Activity Date"}
              fullWidth
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={form.lastActivityDate}
              disabled={disabled}
              onChange={handleChange("lastActivityDate")}
              label="Last Activity Date"
            />
          </Grid>
        </Grid>

        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter Actor Type"}
            fullWidth
            value={form.actorType}
            disabled={disabled}
            onChange={handleChange("actorType")}
            label="Actor Type"
          />
        </Grid>

        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter Known As"}
            fullWidth
            value={form.knownAs}
            disabled={disabled}
            onChange={handleChange("knownAs")}
            label="Known As"
          />
        </Grid>

        <Grid item>
          <Select
            onChange={handleChange("capability")}
            value={form.capability}
            disabled={disabled}
            hasNone
            controlId={"capability"}
            placeholder={"Select Capability"}
            label={"Capability"}
            options={CAPABILITIES}
          />
        </Grid>

        <Grid item>
          <MultipleFreeText
            value={form.motivations}
            onChange={handleChange("motivations")}
            label={"Motivations"}
            placeholder={"Add Motivations"}
          />
        </Grid>

        <Grid item>
          <ButtonChoseList
            // disabled={busy}
            fullWidth
            onClick={() => setOpenCves(true)}
            title={"CVES"}
            selected={form.cves}
          />
        </Grid>

        <Grid item>
          <MultiSelect
            placeholder={"Select Origins"}
            label={"Origins"}
            controlId={"origins"}
            disabled={disabled}
            onChange={handleChange("origins")}
            values={form.origins}
            margin={"none"}
            options={countryOptions}
            labelId={"origins"}
          />
        </Grid>

        <Grid item>
          <MultiSelect
            placeholder={"Select Target Locations"}
            label={"Target Locations"}
            controlId={"targetCountries"}
            disabled={disabled}
            onChange={handleChange("targetCountries")}
            values={form.targetCountries}
            margin={"none"}
            showSelectAll={true}
            options={countryOptions}
            labelId={"targetCountries"}
          />
        </Grid>

        <Grid item>
          <MultiSelect
            placeholder={"Select Target Industries"}
            label={"Target Industries"}
            controlId={"targetCountries"}
            disabled={disabled}
            onChange={handleChange("targetIndustries")}
            values={form.targetIndustries}
            margin={"none"}
            showSelectAll={true}
            options={verticalOptions}
            labelId={"targetIndustries"}
          />
        </Grid>

        <Grid item>
          <TextField
            variant="standard"
            margin="none"
            placeholder={"Enter Kill Chain"}
            fullWidth
            multiline
            value={form.killChain}
            disabled={disabled}
            onChange={handleChange("killChain")}
            label="Kill Chain"
          />
        </Grid>
      </Grid>

      <EntitySelectDialog
        title={"CVEs"}
        open={openCves}
        handleClose={() => setOpenCves(false)}
        callback={onSelect("cves")}
        entitySelections={form.cves}
        nameProp={"cve"}
        valueProp={"cve"}
        url={ApiEndpoint[EntityType.CveDefinition]}
      />
      <BasIconLibraryDialog
        open={openBasGallery}
        handleClose={() => setOpenBasGallery(false)}
        value={form.mediaGalleryImageId}
        onChange={handleChange("mediaGalleryImageId")}
      />
    </ErrorBoundary>
  );
}

export default ActorForm;
