import React from "react";
import SubmenuItems from "../../../components/Header/SubmenuItems";
import {AccountTreeOutlined} from "@material-ui/icons";
const AttackDetailsSubheader = (props) => {

  const menuItems = [
    {
      pushRight: true,
      title: "Add Mitre TTP",
      icon: <AccountTreeOutlined style={{ marginRight: 4 }}/>,
      path: "/mitre-ttp",
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default AttackDetailsSubheader;
