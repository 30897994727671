import React, { useMemo, useState } from "react";
import { useForm } from "reactcoregk/hooks";
import Product from "../../../models/Product";
import ErrorBoundary from "../../../components/ErrorBoundary";
import Grid from "@material-ui/core/Grid";
import ButtonChoseList from "../../../components/Common/ButtonChoseList";
import VendorsDialog from "./VendorsDialog";
import ProductsDialog from "./ProductsDialog";
import { createMap } from "reactcoregk/utils";
import ProductIconLibraryDialog, {
  loadProductIconLibrary,
} from "../../../components/ProductIconLibraryDialog";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { AddPhotoAlternate } from "@material-ui/icons";
import BaseDialog from "../../../components/Common/BaseDialog";
import { API_URL } from "../../../config";
import { postData } from "../../../helpers/utils";
const images = loadProductIconLibrary();
const imageMap = createMap(images, "src");

function Avatar({ avatar, onClick, disabled }) {
  return (
    <Card
      variant={"outlined"}
      style={{ width: "max-content", position: "relative" }}
    >
      <CardActionArea disabled={disabled} onClick={onClick}>
        <CardContent style={{ minWidth: 120, minHeight: 120 }}>
          {avatar && (
            <img
              src={avatar.src}
              style={{ width: 120, height: 120, objectFit: "scale-down" }}
              alt={""}
            />
          )}
          {!avatar && (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddPhotoAlternate />
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function AddProductDialog(props) {
  const { open, handleClose, onAdd } = props;
  const [form, handleChange, setForm] = useForm(new Product());
  const [openVendors, setOpenVendors] = useState(false);
  const [busy, setBusy] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const avatar = imageMap.get(form.icon);

  const submitProduct = async (file) => {
    const url = `${API_URL}/api/icons`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "details",
      new Blob(
        [JSON.stringify({ vendor: form.vendor, products: form.products })],
        { type: "application/json" }
      )
    );
    setBusy(true);
    try {
      await postData(url, formData);
      onAdd();
      setForm(new Product());
      handleClose();
    } catch (e) {
      console.log(e.message);
    }
    setBusy(false);
  };

  const handleAdd = async () => {
    fetch(form.icon)
      .then((res) => res.blob())
      .then((myBlob) => {
        const file = new File([myBlob], "logo.png", {
          type: "image/png",
        });
        submitProduct(file);
      });
  };

  const isValid = useMemo(() => {
    return !!(form.vendor && form.products.length && form.icon);
  }, [form]);

  const onClose = () => {
    setForm(new Product());
    handleClose();
  };

  return (
    <BaseDialog
      title={"Add Product"}
      primaryAction={handleAdd}
      inProgress={busy}
      open={open}
      maxWidth={"md"}
      onClose={onClose}
      scrollable={true}
      isValid={isValid}
      showProgress
      {...props}
    >
      <ErrorBoundary>
        <Grid container direction={"column"} spacing={3}>
          <Grid item>
            <ButtonChoseList
              selected={form.vendor ? [form.vendor] : []}
              fullWidth
              onClick={() => setOpenVendors(true)}
              title={"Vendor"}
            />
          </Grid>
          <Grid item>
            <ButtonChoseList
              selected={form.products}
              disabled={!form.vendor}
              fullWidth
              onClick={() => setOpenProducts(true)}
              title={"Products"}
            />
          </Grid>
          <Grid item>
            <Avatar avatar={avatar} onClick={() => setOpenGallery(true)} />
          </Grid>
        </Grid>
      </ErrorBoundary>
      <VendorsDialog
        open={openVendors}
        handleClose={() => setOpenVendors(false)}
        selectedVendors={form.vendor ? [form.vendor] : []}
        callback={(value) =>
          setForm((prevState) => {
            return {
              vendor: value[0],
              products: [],
              icon: prevState.icon,
            };
          })
        }
        setProducts={setProducts}
      />
      <ProductsDialog
        open={openProducts}
        handleClose={() => setOpenProducts(false)}
        selectedProducts={form.products}
        callback={(value) => handleChange("products")(value)}
        products={products}
      />
      <ProductIconLibraryDialog
        open={openGallery}
        handleClose={() => setOpenGallery(false)}
        value={form.icon}
        onChange={handleChange("icon")}
      />
    </BaseDialog>
  );
}
