import React, {useEffect} from "react";
import EntityDialog from "../../components/EntityDialog";
import {EntityType} from "../../store/@core/entityType";
import ClientForm from "./ClientForm";
import {useForm} from "reactcoregk/hooks";
import {Schema} from "../../store/@core/schema";

function EditDialog(props) {
  const {
    entity,
    open,
    countryOptions,
    verticalOptions,
    versionOptions,
  } = props;
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Client]);

  useEffect(() => {
    if (entity) setForm(entity);
  }, [entity, setForm, open]);

  return (
    <EntityDialog {...props} form={form} maxWidth={"sm"}>
      <ClientForm
        form={form}
        handleChange={handleChange}
        countryOptions={countryOptions}
        verticalOptions={verticalOptions}
        versionOptions={versionOptions}
      />
    </EntityDialog>
  );
}

export default EditDialog;
