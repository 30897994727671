import React from "react";
import Dropzone from "react-dropzone";
import { Publish } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  form: {
    height: "100%",
  },
  dropzone: {
    border: "2px dashed #313843",
    borderRadius: 4,
    height: "100%",
    display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
  },
  dropzoneMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  dropzonePreview: {
    border: "2px dashed #313843",
    borderRadius: 4,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const HiDrop = (props) => {
  const classes = useStyles();
  const {
    selectedFiles,
    handleAcceptedFiles,
    title,
    description,
    link,
    setSelectedFiles,
    accept,
    isMultiple = true
  } = props;

  return (
    <form className={classes.form} noValidate>
      {selectedFiles.length === 0 && (
        <Dropzone
          accept={accept}
          onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
          multiple={isMultiple}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={classes.dropzone}>
              <div {...getRootProps()} style={{ width: "100%" }}>
                <input {...getInputProps()} />
                <div className={classes.dropzoneMessage}>
                  <Publish color={"primary"} />
                  <Typography>{title}</Typography>
                  <Typography color={"textSecondary"}>
                    {description} <Link to={"#"}>{link}</Link>
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      {selectedFiles.map((f, i) => {
        return (
          <Box className={classes.dropzonePreview}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DescriptionIcon color={"primary"} />
              <Box
                style={{ display: "flex", alignItems: "center", marginTop: 8 }}
              >
                <Typography
                  color={"primary"}
                  style={{ fontWeight: "bold", marginRight: 8 }}
                >
                  {f.name}
                </Typography>
                <IconButton
                  size={"small"}
                  onClick={() => setSelectedFiles([])}
                  href={"#"}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        );
      })}
    </form>
  );
};

export default HiDrop;

HiDrop.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string,
  selectedFiles: PropTypes.array.isRequired,
  handleAcceptedFiles: PropTypes.func.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
};
