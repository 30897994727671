import {all, call, fork, put, takeEvery} from "redux-saga/effects";
// Login Redux States
import {LOGIN_USER, LOGOUT_USER} from "./actionTypes";
import {apiError, loginSuccess} from "./actions";
//Include Both Helper File with needed methods
import {postLogin} from "./api";
import {getToken} from "../../../helpers/utils";
import axios from "axios";

function* loginUser({ payload: { user, history, callBack } }) {
  try {
    const response = yield call(postLogin, user);
    localStorage.setItem("authUser", JSON.stringify(response));
    axios.defaults.headers.common = { Authorization: "Bearer " + getToken() };
    if (callBack) {
      callBack();
    } else {
      history.push("/dashboard");
    }
    yield put(loginSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(apiError(error.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history.push("/sign-in");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
