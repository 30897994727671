import React, { useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchSelect from "../../components/Inputs/SearchSelect";
import {
  AFFECTED_PRODUCTS,
  ATTACK_EXECUTORS,
  ATTACK_FORMAT_TYPES,
  ATTACK_SOURCE_TYPES,
  ATTACK_SUPPORTED_SYSTEMS,
  ATTACK_TYPES,
  AttackTypes,
  TARGET_TYPES,
} from "../../constants/enums";
import { createMap, createOptions } from "reactcoregk/utils";
import MultiSelect from "../../components/Inputs/MultiSelect";
import ButtonChoseList from "../../components/Common/ButtonChoseList";
import FreeSolo from "../../components/Inputs/FreeSolo";
import SignatureDialog from "./AttackDetails/SignatureDialog";
import Select from "../../components/Inputs/Select";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { cloneDeep } from "lodash";


function AttackForm({
  form,
  handleChange,
  setForm,
  techs,
  busy,
  openActors,
  openCves,
  setAttackFormCharConstraint,
}) {
  const [openSignatures, setOpenSignatures] = useState(false);
  const subTechniques = techs["subTechniques"] || [];
  const techniques = techs["techniques"] || [];
  const tactics = techs["tactics"] || [];
  const techMap = createMap(techniques);
  const subTechniquesMap = createMap(subTechniques);


  const filteredTechniques = techniques.filter((x) => {
    const tactIds = form.tacticIds || [];
    return tactIds[0] ? tactIds.includes(x.tacticId) : true;
  });

  const filteredSubTechniques = subTechniques.filter((x) => {
    const techIds = form.techniqueIds || [];
    return techIds[0] ? techIds.includes(x.techniqueParentId) : !form.tacticId;
  });

  const tacticOptions = createOptions(tactics, "name");
  const techniqueOptions = createOptions(filteredTechniques, "name");
  const subTechniqueOptions = createOptions(filteredSubTechniques, "name");

  const handleChangeAdversaryTools = (e, newval) => {
    setForm((prevForm) => ({
      ...prevForm,
      adversaryTools: newval,
    }));
  };

  const handleChangeAffectedProducts = (e, affectedPrograms) => {
    setForm((prevForm) => ({
      ...prevForm,
      affectedPrograms,
    }));
  };

  const handleReferences = (e, references) => {
    setForm((prevForm) => ({
      ...prevForm,
      references,
    }));
  };

  const handleChangeTactic = (tacticIds) => {
    const techIds = form.techniqueIds || [];
    const subTechIds = form.subTechniqueIds || [];

    const techniqueIds = techIds.filter((id) => {
      const technique = techMap.get(id);
      return tacticIds.includes(technique?.tacticId);
    });

    const subTechniqueIds = subTechIds.filter((id) => {
      const technique = subTechniquesMap.get(id);
      return techniqueIds.includes(technique?.techniqueParentId);
    });

    setForm((prevForm) => ({
      ...prevForm,
      tacticIds,
      techniqueIds,
      subTechniqueIds,
    }));
  };

  const handleChangeTechnique = (techniqueIds) => {
    const subTechIds = form.subTechniqueIds || [];
    const tactIds = form.tacticIds || [];

    const subTechniqueIds = subTechIds.filter((id) => {
      const technique = subTechniquesMap.get(id);
      return (
        tactIds.includes(technique.tacticId) ||
        techniqueIds.includes(technique?.techniqueParentId)
      );
    });

    setForm((prevForm) => ({
      ...prevForm,
      techniqueIds,
      subTechniqueIds,
    }));
  };

  useEffect(() => {
    if (setAttackFormCharConstraint) {
      if (form?.name.length > 128 || form?.description.length > 2048)
        setAttackFormCharConstraint(false);
      else setAttackFormCharConstraint(true);
    }
  }, [form?.name, form?.description]);

  const tacticIds = form.tacticIds || [];
  const techniqueIds = form.techniqueIds || [];
  const subTechniqueIds = form.subTechniqueIds || [];

  const recommendations = useMemo(() => {
    return form?.recommendation ? form?.recommendation.split("<br/>") : [""];
  }, [form?.recommendation]);

  const handleRemoveRecommendation = (index) => {
    const recommendation = cloneDeep(recommendations);
    recommendation.splice(index, 1);
    if (recommendation.length === 0) {
      recommendation.push("");
    }
    setForm((prevForm) => ({
      ...prevForm,
      recommendation: recommendation.join("<br/>"),
    }));
  };

  const handleUpdateRecommendation = (value, index) => {
    const recommendation = cloneDeep(recommendations);
    recommendation[index] = value;

    setForm((prevForm) => ({
      ...prevForm,
      recommendation: recommendation.join("<br/>"),
    }));
  };

  const handleAddRecommendation = () => {
    const recommendation = cloneDeep(recommendations);
    recommendation.push("");
    setForm((prevForm) => ({
      ...prevForm,
      recommendation: recommendation.join("<br/>"),
    }));
  };

  return (
    <Grid container  direction={"column"} spacing={2}>
      <Grid style={{width:"100%"}} item>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter name"}
          disabled={busy}
          fullWidth
          required={true}
          value={form.name}
          onChange={handleChange("name")}
          label="Name"
          autoFocus
          helperText={
            form?.name.length > 128 && (
              <p style={{ color: "#EF4444" }}>
                Attack name should not exceed 128 characters
              </p>
            )
          }
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter description"}
          fullWidth
          required={true}
          value={form.description}
          multiline
          rowsMax={10}
          disabled={busy}
          onChange={handleChange("description")}
          label="Description"
          helperText={
            form?.description.length > 2048 && (
              <p style={{ color: "#EF4444" }}>
                Attack description should not exceed 2048 characters
              </p>
            )
          }
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <SearchSelect
          placeholder={"Select"}
          label={"Type"}
          controlId={"format"}
          onChange={handleChange("type")}
          required={true}
          value={form.type}
          disabled={busy || form.id}
          margin={"none"}
          options={ATTACK_TYPES}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <MultiSelect
          required={
            form.type === AttackTypes.MITRE_ATTACK ||
            form.type === AttackTypes.ADVERSARY
          }
          placeholder={"Select"}
          label={"Tactic"}
          controlId={"tacticIds"}
          disabled={busy}
          onChange={handleChangeTactic}
          values={tacticIds}
          margin={"none"}
          options={tacticOptions}
          labelId={"tacticIds"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <MultiSelect
          required={
            form.type === AttackTypes.MITRE_ATTACK ||
            form.type === AttackTypes.ADVERSARY
          }
          placeholder={"Select"}
          label={"Technique"}
          controlId={"techniqueIds"}
          disabled={busy}
          onChange={handleChangeTechnique}
          values={techniqueIds}
          margin={"none"}
          options={techniqueOptions}
          labelId={"techniqueIds"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <MultiSelect
          required={
            form.type === AttackTypes.MITRE_ATTACK ||
            form.type === AttackTypes.ADVERSARY
          }
          placeholder={"Select"}
          label={"Sub-technique"}
          controlId={"subTechniqueIds"}
          disabled={busy}
          onChange={handleChange("subTechniqueIds")}
          values={subTechniqueIds}
          margin={"none"}
          options={subTechniqueOptions}
          labelId={"subTechniqueIds"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <ButtonChoseList
          disabled={busy}
          onClick={openActors}
          title={"Actors"}
          required={form.type === AttackTypes.ADVERSARY}
          selected={form.actorIds}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <ButtonChoseList
          disabled={busy}
          onClick={openCves}
          title={"CVES"}
          required={form.type === AttackTypes.VULNERABILITY}
          selected={form.cves}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <FreeSolo
          value={form.adversaryTools}
          onChange={handleChangeAdversaryTools}
          label={"Adversary Tools"}
          required={form.type === AttackTypes.ADVERSARY}
          placeholder={"Enter adversary tool"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <ButtonChoseList
          selected={form.signatures || []}
          title={"Signatures"}
          required
          onClick={() => setOpenSignatures(true)}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <SearchSelect
          placeholder={"Select"}
          label={"Format"}
          controlId={"format"}
          margin={"none"}
          onChange={handleChange("format")}
          value={form.format}
          disabled={busy}
          required
          options={ATTACK_FORMAT_TYPES}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <SearchSelect
          placeholder={"Select"}
          label={"Source Type"}
          controlId={"sourceType"}
          margin={"none"}
          onChange={handleChange("sourceType")}
          value={form.sourceType}
          disabled={busy}
          required
          options={ATTACK_SOURCE_TYPES}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <Select
          onChange={handleChange("target")}
          value={`${form.target}`}
          controlId={"target"}
          label={"Target"}
          required
          options={TARGET_TYPES}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <MultiSelect
          fullWidth
          options={ATTACK_SUPPORTED_SYSTEMS}
          label={"Supported Platforms"}
          onChange={handleChange("supportedPlatforms")}
          values={form.supportedPlatforms || []}
          labelId={"supportedPlatforms"}
          disabled={busy}
          required
          margin={"none"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <MultiSelect
          fullWidth
          margin={"none"}
          options={ATTACK_EXECUTORS}
          label={"Executors"}
          disabled={busy}
          onChange={handleChange("executors")}
          required
          values={form.executors || []}
          labelId={"executors"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <FreeSolo
          value={form.affectedPrograms || []}
          onChange={handleChangeAffectedProducts}
          label={"Affected Programs"}
          options={AFFECTED_PRODUCTS.map((x) => x.value)}
          required
          placeholder={"Enter Affected Programs"}
          labelId={"affectedProducts"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <FreeSolo
          value={form.references || []}
          onChange={handleReferences}
          label={"References"}
          required
          placeholder={"Enter References"}
          labelId={"references"}
        />
      </Grid>
      <Grid style={{width:"100%"}} item>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color={"textPrimary"} style={{ fontWeight: "bold" }}>
            Recommendation *
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleAddRecommendation}
              style={{ marginLeft: 16 }}
              size={"small"}
            >
              <AddCircleOutline style={{ marginRight: 4 }} color={"primary"} />
              Add Recommendation
            </Button>
          </Box>
        </Box>

        {recommendations.map((rec, index) => {
          return (
            <>
              <Grid item container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TextField
                    id="name"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    placeholder={"Enter Recommendation"}
                    value={rec}
                    disabled={busy}
                    onChange={(e) => {
                      handleUpdateRecommendation(e.target.value, index);
                    }}
                    label={`Recommendation #${index + 1}`}
                  />
                  <IconButton
                    onClick={() => {
                      handleRemoveRecommendation(index);
                    }}
                  >
                    <RemoveCircleOutline color={"error"} />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
      <SignatureDialog
        open={openSignatures}
        handleClose={() => setOpenSignatures(false)}
        form={form}
        setForm={setForm}
      />
    </Grid>
  );
}

export default AttackForm;
