import {entityType} from "./module";
import {
    createDeleteMethod,
    createGetAllPageableMethod, createGetMethod, createGetSuccessMethod,
    createPostMethod,
    createPutMethod
} from "reactcoregk/store/actions";
import {getDefaultApiHandler, getDefaultHeaders} from "../../helpers/utils";
import Attack from "../../models/Attack";
import ApiHandler from "reactcoregk/models/apiHandler";

const updateApiHandler = () =>
    new ApiHandler(false, false, getDefaultHeaders());

export const getAllCollections = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler())
export const createCollection = (payload) => createPostMethod(entityType, payload, updateApiHandler())
export const updateCollection = (payload) => createPutMethod(entityType, payload, updateApiHandler())
export const deleteCollection = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())
export const getCollection = (params) => createGetMethod(entityType, params, getDefaultApiHandler())
export const clearCollection = () => createGetSuccessMethod(entityType, new Attack())
