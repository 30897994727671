import React from "react";
import { useDefaultTable } from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import { threatLevel } from "../../constants/enums";

const getColor = (level) => {
  switch (level) {
    case threatLevel.AMBER:
      return "#E3792D";
    case threatLevel.RED:
      return "#EE2D2A";
    case threatLevel.WHITE:
      return "#FFFFFF";
    case threatLevel.GREEN:
      return "#43DD43";
    default:
      return "#FFFFFF";
  }
};

function AlertTable(props) {
  const { alerts, defaultTable, headCells, handleEntityClick, isLoading } =
    props;

  const { renderHeadCell, renderDefaultBody } = defaultTable;

  function styleCell(cell, value) {
    if (cell === "threatLevel") {
      return {
        color: getColor(value),
      };
    }
    return null;
  }

  return (
    <DefaultTable
      headCells={headCells}
      onRowClick={handleEntityClick}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      styleCell={styleCell}
      rows={alerts}
      isLoading={isLoading}
    />
  );
}

export default AlertTable;
