import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { Paging } from "reactcoregk/models";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import { fetchData } from "../../helpers/utils";
import { useDefaultTable } from "../../helpers/hooks";
import BaseDialog from "../../components/Common/BaseDialog";
import Title from "../../components/Common/Title";
import DefaultTable from "../../components/Common/DefaultTable";
import { makeStyles } from "@material-ui/styles";
import SearchBox from "../../components/Common/SearchBox";
import { FormControlLabel, Switch } from "@material-ui/core";
import { debounce } from "lodash";

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});
function EntitySelectDialog(props) {
  const classes = useStyles();

  const {
    entitySelections = [],
    handleClose,
    open,
    callback,
    url,
    title,
    queryProp = "s",
    nameProp = "name",
    valueProp = "id",
  } = props;

  const defaultCells = [
    {
      id: valueProp,
      numeric: false,
      label: "ID",
    },
    {
      id: nameProp,
      numeric: false,
      label: "Name",
    },
  ];

  const [page, setPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(new Paging());
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSelected, setShowSelected] = useState(false);
  const [initialCves, setInitialCves] = useState([]);

  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      if (query) {
        setQuery(query);
      } else {
        setQuery("");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (open) {
      setBusy(true);
      const APIURL = `${url}?page=${page}&sort=publishedDate,desc&size=20&${queryProp}=${query}`;
      const params = showSelected ? `&cves=${selected.join(",")}` : "";
      fetchData(`${APIURL}${params}`)
        .then((data) => {
          setData(data);
          setInitialCves(data);
          setBusy(false);
        })
        .catch((ex) => {
          setBusy(false);
          setErrorMessage(ex.message);
        });
    }
  }, [open, page, query, queryProp, url, showSelected]);

  const sortHandler = useSortHandler();
  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(data.content, sortHandler, valueProp);

  useEffect(() => {
    setSelected(entitySelections);
  }, [entitySelections, setSelected]);

  const handleConfirm = () => {
    callback(selected);
    handleClose();
    setPage(0);
    setErrorMessage(null);
  };

  return (
    <BaseDialog
      title={`Select ${title}`}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      disableBackdropClick
      errorMessage={errorMessage}
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>Select {title} to update your selection</Title>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showSelected}
                    onChange={() => {
                      setShowSelected((s) => !s);
                      setPage(0);
                    }}
                    name="selected"
                    color="primary"
                  />
                }
                label="Show Selected"
              />
            </Grid>
            <Grid item>
              <SearchBox
                placeholder={"name"}
                handleChange={(e) => {
                  debouncedHandleSearch(e.target.value);
                  setQuery(e.target.value);
                }}
                query={query}
              />
            </Grid>
          </Grid>
        </Grid>

        {!showSelected ? (
          <>
            <Grid item>
              <DefaultTable
                rows={data.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={data.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        ) : (
          <>
            <Grid item>
              <DefaultTable
                rows={initialCves.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={initialCves.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        )}
      </Grid>
    </BaseDialog>
  );
}

export default EntitySelectDialog;
