import React from "react";
import BaseDialog from "./BaseDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import PropTypes from "prop-types";
import Title from "./Title";

const ConfirmationDialog = (props) => {
  const { description, ...rest } = props;
  return (
    <BaseDialog
      {...rest}
      secondaryAction={props.secondaryAction || props.onClose}
    >
      <DialogContentText id="alert-dialog-description">
        <Title>{description}</Title>
      </DialogContentText>
    </BaseDialog>
  );
};

export default ConfirmationDialog;

ConfirmationDialog.defaultProps = {
  primaryActionTitle: "Confirm",
  secondaryActionTitle: "Cancel",
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  fullWidth: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  primaryAction: PropTypes.func,
  primaryActionTitle: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryActionTitle: PropTypes.string,
  inProgress: PropTypes.bool,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
};
