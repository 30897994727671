import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { postData } from "../../../helpers/utils";
import { API_URL } from "../../../config";
import BaseDialog from "../BaseDialog";
import Pluralize from "pluralize";
import Title from "../Title";
import { Box } from "@material-ui/core";
import RankEditList from "./RankEditList";

function RankEditDialog({ open, handleClose, selected, entityName }) {
  const [items, setItems] = useState(selected);
  const { enqueueSnackbar } = useSnackbar();
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (open) {
      setItems(selected);
    }
  }, [open, selected]);

  const handleSubmit = async () => {
    setBusy(true);
    try {
      await postData(`${API_URL}/api/rankings`, { rankings: items });
      enqueueSnackbar("Ranking updated successfully", { variant: "success" });
      handleClose();
    } catch (ex) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setBusy(false);
  };

  return (
    <BaseDialog
      onClose={handleClose}
      title={`Rank ${Pluralize(entityName, 2)}`}
      open={open}
      inProgress={busy}
      showProgress
      primaryAction={handleSubmit}
      primaryActionTitle={"Submit"}
    >
      <Title>
        Reorder {Pluralize(entityName, 2)} and press submit to apply ranking.
      </Title>
      <Box mt={3} />
      <RankEditList items={items} setItems={setItems} entityName={entityName} />
    </BaseDialog>
  );
}

export default RankEditDialog;
