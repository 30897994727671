import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { HiveIcons } from "../../assets/icons";
import { useTableSearch } from "../../helpers/hooks";
import SearchBox from "../../components/Common/SearchBox";
import {Publish} from "@material-ui/icons";

const Subheader = (props) => {
  const [query, handleChange, syncQuery] = useTableSearch("search");
  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },

    { divider: true },
    {
      title: "Upload Patch",
      icon: <Publish color={"primary"} />,
      onClick: props.handleAddPatch,
    },
    {
      title: "New Patch",
      icon: <HiveIcons.AddOutlined />,
      onClick: props.handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Subheader;
