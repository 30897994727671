import { withStyles } from "@material-ui/core/styles";
import { Tab } from "@material-ui/core";
import React from "react";

export const HeaderTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    height: "30px",
    minWidth: "20px",
    width: "auto",
    opacity: 1,
    color: theme.palette.text.secondary,
    fontWeight: "inherit",
    maxWidth: "120px",
    // marginLeft: theme.spacing(2),
    borderBottom: "2px solid transparent",
    borderTop: "2px solid transparent",
    fontFamily: "Roboto, sans-serif",
    padding: "0px 6px",
    // fontWeight: "400",
    fontSize: "12px",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.primary.main + "!important",
      fontWeight: 500,
    },
    "&:focus": {
      color: theme.palette.primary.main + "!Important",
      opacity: 1,
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} value={true} />);
