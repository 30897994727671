import React from "react";
import { Redirect } from "react-router-dom";
// Authentication related pages
// Dashboard
import SignIn from "pages/Auth/sign-in";
import { AuthLayout } from "components/Layouts/AuthLayout";
import Dashboard from "../pages/Dashboard/dashboard";
import ClientList from "../pages/Clients/client-list";
import ClientView from "../pages/Clients/client-view";
import UserList from "../pages/Users/user-list";
import MyTickets from "../pages/SupportTickets";
import Jobs from "../pages/Jobs";
import TicketDetails from "../pages/SupportTickets/TicketDetails";
import Alerts from "../pages/Alerts/alerts";
import CveDefinitions from "../pages/CveDefinitions";
import Policies from "../pages/Policies/policies";
import Attacks from "../pages/Attacks";
import Techniques from "../pages/Techniques";
import AttackDetails from "../pages/Attacks/AttackDetails/AttackDetails";
import Collections from "../pages/Collections";
import CollectionDetails from "../pages/Collections/CollectionDetails";
import PatchIntelligence from "pages/PatchIntelligence";
import PatchDetails from "pages/PatchIntelligence/PatchDetails/PatchDetails";
import Actors from "../pages/Actors";
import ActorDetails from "../pages/Actors/ActorDetails";
import Countries from "../pages/Countries";
import Industries from "../pages/Industries";
import { AUTHORITY } from "../constants/enums";
import ChangePassword from "../pages/Auth/change-password";
import ForgotPassword from "../pages/Auth/forgot-password";
import ResetPassword from "../pages/Auth/reset-password";
import Rules from "../pages/Rules";
import Products from "../pages/Products";

const roleAuthority = (role) => {
  return `ROLE_${role}`
}

const authProtectedRoutes = [
  //  Auth routes
  //Dashboard Routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  {
    path: "/dashboard",
    component: Dashboard,
    layout: AuthLayout,
    title: "Dashboard",
    authorities: [
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/clients",
    exact: true,
    component: ClientList,
    layout: AuthLayout,
    title: "Clients",
    authorities: [
      roleAuthority(AUTHORITY.LICENSE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/clients/:id",
    component: ClientView,
    layout: AuthLayout,
    title: "Clients",
    authorities: [
      roleAuthority(AUTHORITY.LICENSE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/users",
    exact: true,
    component: UserList,
    layout: AuthLayout,
    title: "Users",
    authorities: [
      roleAuthority(AUTHORITY.USER_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/alerts",
    exact: true,
    component: Alerts,
    layout: AuthLayout,
    title: "Alerts",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.ALERT_ADMIN)
    ]
  },
  {
    path: "/cve-definitions",
    exact: true,
    component: CveDefinitions,
    layout: AuthLayout,
    title: "CVE Definitions",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.ALERT_ADMIN)
    ]
  },
  {
    path: "/policies",
    component: Policies,
    layout: AuthLayout,
    title: "Policies",
    authorities: [
      roleAuthority(AUTHORITY.POLICY_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
    ]
  },
  {
    path: "/collections",
    exact: true,
    component: Collections,
    layout: AuthLayout,
    title: "Collections",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.BAS_ADMIN)
    ]
  },
  {
    path: "/collections/:id",
    component: CollectionDetails,
    layout: AuthLayout,
    title: "Collection Details",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.BAS_ADMIN)
    ]
  },
  {
    path: "/attacks",
    exact: true,
    component: Attacks,
    layout: AuthLayout,
    title: "Attacks",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.BAS_ADMIN)
    ]
  },
  {
    path: "/attacks/:id",
    component: AttackDetails,
    layout: AuthLayout,
    title: "Attack",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.BAS_ADMIN)
    ]
  },
  {
    path: "/patches",
    exact: true,
    component: PatchIntelligence,
    layout: AuthLayout,
    title: "Patches",
    authorities: [
      roleAuthority(AUTHORITY.PATCH_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/patches/:id",
    component: PatchDetails,
    layout: AuthLayout,
    title: "Patch",
    authorities: [
      roleAuthority(AUTHORITY.PATCH_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/mitre-ttp",
    component: Techniques,
    layout: AuthLayout,
    title: "Techniques",
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.BAS_ADMIN)
    ]
  },
  {
    path: "/support-tickets",
    exact: true,
    component: MyTickets,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/jobs",
    exact: true,
    component: Jobs,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/support-tickets/:id",
    component: TicketDetails,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/actors",
    component: Actors,
    exact: true,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.ACTOR_ADMIN)
    ]
  },
  {
    path: "/actors/:id",
    exact: true,
    component: ActorDetails,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.THREAT_INTELLIGENCE_ADMIN),
      roleAuthority(AUTHORITY.ADMIN),
      roleAuthority(AUTHORITY.ACTOR_ADMIN)
    ]
  },
  {
    path: "/countries",
    exact: true,
    component: Countries,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.OPTIONS_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/industries",
    exact: true,
    component: Industries,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.OPTIONS_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/rules",
    exact: true,
    component: Rules,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.OPTIONS_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/products",
    exact: true,
    component: Products,
    layout: AuthLayout,
    authorities: [
      roleAuthority(AUTHORITY.OPTIONS_ADMIN),
      roleAuthority(AUTHORITY.ADMIN)
    ]
  },
  {
    path: "/change-password",
    component: ChangePassword,
    layout: AuthLayout,
    authorities: []
  },
];

const publicRoutes = [
  { path: "/sign-in", component: SignIn },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password", component: ResetPassword }
];

export { authProtectedRoutes, publicRoutes };
