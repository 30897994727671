import React, {useEffect, useMemo} from "react";
import EntityDialog from "../../components/EntityDialog";
import {useForm} from "reactcoregk/hooks";
import Actor from "../../models/Actor";
import ActorForm from "./ActorForm";
import moment from "moment";

function EditDialog(props) {
	const {
		entity,
		open,
		countries,
		verticals
	} = props;
	const [form, handleChange, setForm] = useForm(new Actor());

	useEffect(() => {
		if (entity) setForm(entity);
	}, [entity, setForm, open]);

	const finalForm = useMemo(() => {
		return {
			...form,
			firstActivityDate: form.firstActivityDate ? moment(form.firstActivityDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
			lastActivityDate: form.lastActivityDate ? moment(form.lastActivityDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
		};
	}, [form]);

	return (
		<EntityDialog {...props} form={finalForm} maxWidth={"md"}>
			<ActorForm
				form={form}
				handleChange={handleChange}
				setForm={setForm}
				countries={countries}
				verticals={verticals}
			/>
		</EntityDialog>
	);
}

export default EditDialog;
