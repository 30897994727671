import { EntityType } from "../@core/entityType";
import { Operation } from "reactcoregk";

export const entityType = EntityType.Collection;
export const entityOperations = [
  Operation.getAllPageable,
  Operation.create,
  Operation.update,
  Operation.delete,
  Operation.get,
];
