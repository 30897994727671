import {entityType} from "./module";
import {getCommonState, handleCommonState} from "reactcoregk/store/reducer";
import * as ActionTypes from "reactcoregk/store/actionTypes";

const initialState = getCommonState();


const User = (state = initialState, action) => {
  const actionEntity = action.type.split(".")[0]

  if (actionEntity !== entityType) return state
  const actionType = action.type.split(".")[1]

  switch (actionType) {
    case ActionTypes.POST_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          isLoading: false,
        }
      };
    default:
      break;
  }

  return  handleCommonState(state, actionType, action, "id");
};

export default User;
