import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() => ({
  header: {
    padding: "20px 0",
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
}));

function Loader() {
  return (
    <Grid>
      <Box style={{ display: "flex", flexDirection: "column" }} p={2}>
        <Skeleton variant="rect" height={20} width={"100%"} />
        <Box mt={2} />
        <Skeleton variant="rect" height={20} width={"80%"} />
        <Box mt={2} />
        <Skeleton variant="rect" height={20} width={"20%"} />
      </Box>
    </Grid>
  );
}

function SectionFlatPanel(props) {
  const classes = useStyles();
  const { title, children, subtitle, renderToolbar, icon, busy, backgroundTransparent, noHeader, ...restProps } = props

  return (
    <Card style={{ backgroundColor: backgroundTransparent && 'transparent' }} {...restProps} variant={"elevation"} square elevation={0}>
      {!noHeader &&
        <div className={classes.header}>
          {icon && <span style={{ marginRight: 4, marginBottom: -4 }}>{icon}</span>}
          {title && <Typography color={"textSecondary"} className={classes.title}>{title}</Typography>}
          {subtitle && <Typography color={"textSecondary"} className={classes.subtitle}>{subtitle}</Typography>}
          <div style={{ flex: 1 }} />
          {renderToolbar && renderToolbar()}
        </div>
      }
      <div style={{ padding: "12px 0" }}>
        {busy ? <Loader /> : children}
      </div>
    </Card>
  );
}

export default SectionFlatPanel;