import {deleteData, updateData, postData} from "../../helpers/utils";
import {ApiEndpoint} from "../@core/endpoint";
import {EntityType} from "../@core/entityType";

const url = ApiEndpoint[EntityType.Technique]

export function deleteMitigations(techId, mitigations) {
    const requests = mitigations.map(x => {
        const api = `${url}/${techId}/mitigations/${x.id}`
        return deleteData(api)
    })
    return Promise.all(requests)
}

export function updateMitigations(techId, mitigations) {
    const requests = mitigations.map(x => {
        const api = `${url}/${techId}/mitigations/${x.id}`
        return updateData(api, x)
    })
    return Promise.all(requests)
}

export function createMitigations(techId, mitigations) {
    const requests = mitigations.map(x => {
        const api = `${url}/${techId}/mitigations`
        return postData(api, x)
    })
    return Promise.all(requests)
}
