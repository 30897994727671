import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as SpinnerWarning } from "assets/spinners/warning.svg";
import BaseDialog from "./Common/BaseDialog";
import Title from "./Common/Title";
import Subheader from "./Common/Subheader";

function DeleteConfirmation(props) {
  const {
    busy,
    handleDelete,
    errorMessage,
    open,
    handleClose,
    label,
  } = props;

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      primaryAction={handleDelete}
      inProgress={busy}
      title={`Delete ${label}`}
      maxWidth={"md"}
      errorMessage={errorMessage}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Title>Delete confirmation</Title>
        </Grid>
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item>
            <SpinnerWarning height={300} width={300} />
          </Grid>
          <Grid item>
            <Subheader style={{ fontSize: 20 }}>
              Are you sure you want to delete {label}?
            </Subheader>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Typography
              color={"textSecondary"}
              style={{ fontWeight: 400 }}
              variant={"caption"}
            >
              This action is irreversible and cannot be undone
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

export default DeleteConfirmation;
