import Entity from "./Entity";

class KronosVersion extends Entity {
  constructor() {
    super();
    this.id = null;
    this.version = "";
    this.date = "";
    this.description = "";
    this.changes = "";
  }
}

export default KronosVersion;
