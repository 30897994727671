import {CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS} from "./actionTypes";

export const userChangePassword = (form, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { form, history }
  };
};

export const userChangePasswordSuccess = message => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userChangePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message
  };
};
