import { PRODUCT_NAME } from "../config";
import { ReactComponent as WindowsIcon } from "assets/icons/windows.svg";
import { ReactComponent as MacosIcon } from "assets/icons/macos.svg";
import { ReactComponent as LinuxIcon } from "assets/icons/linux.svg";
import { ReactComponent as SafariIcon } from "assets/icons/safari.svg";
import { ReactComponent as VLCIcon } from "assets/icons/vlc.svg";
import { ReactComponent as ChromeIcon } from "assets/icons/chrome.svg";

const OnboardingStatus = {
  organization: 0,
  infrastructure: 1,
  userManagement: 2,
  integration: 3,
  assetDiscovery: 4,
};

const jobStatus = {
  QUEUED: "QUEUED",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
  ERROR: "ERROR",
};

const jobType = {
  ASSET_SCAN: "ASSET_SCAN",
};

const SUPPORT_TICKET_STATUS = {
  RESOLVED: "RESOLVED",
  UNRESOLVED: "UNRESOLVED",
};

const SUPPORT_TICKET_STATUS_COLORS = {
  RESOLVED: "#02903E",
  UNRESOLVED: "#F05E13",
};

const SUPPORT_TICKET_PRIORITY = {
  CRITICAL: "CRITICAL",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  INFORMATION: "INFORMATION",
};

const SUPPORT_TICKET_PRIORITY_COLORS = {
  CRITICAL: "#FF2E2E",
  HIGH: "#FA8072",
  MEDIUM: "#15A1F4",
  LOW: "#00FFF9",
  INFORMATION: "#FFFFFF",
};

const SUPPORT_TICKET_ESCALATION = {
  L1: "L1",
  L2: "L2",
  L3: "L3",
};

const SUPPORT_TICKET_STATE = {
  OPEN: "OPEN",
  IN_PROGRESS: "IN PROGRESS",
  CLOSED: "CLOSED",
};

const FILETYPES = {
  IMAGE: "IMAGE",
  PDF: "PDF",
};

const ATTACK_FORMAT_TYPES = [
  { label: "YAML", value: "YAML" },
  { label: "JSON", value: "JSON" },
];

const TARGET_TYPES = [
  { label: "Remote", value: "REMOTE" },
  { label: "Local", value: "LOCAL" },
];

const CollectionTypes = {
  VULNERABILITY: "VULNERABILITY",
  MITRE_ATTACK: "MITRE_ATTACK",
  ADVERSARY: "ADVERSARY",
  MALWARE: "MALWARE",
  RANSOMWARE: "RANSOMWARE",
  CERT_ADVISORY: "CERT_ADVISORY",
  GTI_REPORTS: "GTI_REPORTS",
};

const AttackTypes = {
  VULNERABILITY: "VULNERABILITY",
  MITRE_ATTACK: "MITRE_ATTACK",
  ADVERSARY: "ADVERSARY",
  MALWARE: "MALWARE",
  RANSOMWARE: "RANSOMWARE",
};

const ATTACK_TYPES = [
  { label: "VULNERABILITY EXPLOIT", value: AttackTypes.VULNERABILITY },
  { label: "MALWARE", value: AttackTypes.MALWARE },
  { label: "RANSOMWARE", value: AttackTypes.RANSOMWARE },
  { label: "MITRE ATTACK", value: AttackTypes.MITRE_ATTACK },
  { label: "ADVERSARY", value: AttackTypes.ADVERSARY },
];

export const COLLECTION_TYPES = [
  { label: "VULNERABILITY EXPLOIT", value: CollectionTypes.VULNERABILITY },
  { label: "MALWARE", value: CollectionTypes.MALWARE },
  { label: "RANSOMWARE", value: CollectionTypes.RANSOMWARE },
  { label: "MITRE ATTACK", value: CollectionTypes.MITRE_ATTACK },
  { label: "ADVERSARY", value: CollectionTypes.ADVERSARY },
  { label: "CERT ADVISORY", value: CollectionTypes.CERT_ADVISORY },
  { label: "GTI REPORTS", value: CollectionTypes.GTI_REPORTS },
];

const ATTACK_SOURCE_TYPES = [
  { label: PRODUCT_NAME, value: "KRONOS" },
  { label: "MITRE", value: "MITRE" },
];

const ATTACK_EXECUTOR_TYPES = [
  { label: "CMD", value: "CMD" },
  { label: "SH", value: "SH" },
  { label: "PSH", value: "PSH" },
  { label: "PYTHON", value: "PY" },
];

const ATTACK_SUPPORTED_SYSTEMS = [
  { label: "MACOS", value: "MACOS", icon: MacosIcon },
  { label: "LINUX", value: "LINUX", icon: LinuxIcon },
  { label: "WINDOWS", value: "WINDOWS", icon: WindowsIcon },
];

export const AFFECTED_PRODUCTS = [
  { label: "MACOS", value: "MACOS", icon: MacosIcon },
  { label: "LINUX", value: "LINUX", icon: LinuxIcon },
  { label: "WINDOWS", value: "WINDOWS", icon: WindowsIcon },
  { label: "VLC", value: "VLC", icon: VLCIcon },
  { label: "SAFARI", value: "SAFARI", icon: SafariIcon },
  { label: "GOOGLE CHROME", value: "GOOGLE_CHROME", icon: ChromeIcon },
];

const PROXY_MODES = [
  { label: "Offline", value: "proxied_offline" },
  { label: "Online", value: "proxied_online" },
  { label: "Direct", value: "direct" },
];

const MULTI_TENANT_MODES = [
  { label: "Single Tenant", value: "single_tenant" },
  { label: "Multi Tenant", value: "multi_tenant" },
];

const ATTACK_EXECUTORS = [
  { label: "CMD", value: "CMD" },
  { label: "SH", value: "SH" },
  { label: "PSH", value: "PSH" },
  { label: "PYTHON", value: "PY" },
];

const TACTIC_TYPE_OPTIONS = [
  { label: "ENTERPRISE", value: "ENTERPRISE" },
  { label: "MOBILE", value: "MOBILE" },
];

const SIGNATURES = [
  { label: "MD5", value: "MD5" },
  { label: "SHA1", value: "SHA1" },
  {
    label: "SHA256",
    value: "SHA256",
  },
  {
    label: "SHA512",
    value: "SHA512",
  },
];

const threatLevel = {
  RED: "RED",
  GREEN: "GREEN",
  WHITE: "WHITE",
  AMBER: "AMBER",
};

const alertCategory = {
  ADVERSARY_ADVISORY: "ADVERSARY_ADVISORY",
  ATTACK_ADVISORY: "ATTACK_ADVISORY",
  VULNERABILITY_ADVISORY: "VULNERABILITY_ADVISORY",
  WEEKLY_DIGEST: "WEEKLY_DIGEST",
  MONTHLY_DIGEST: "MONTHLY_DIGEST",
  CELEBRITY_VULNERABILITIES: "CELEBRITY_VULNERABILITIES",
  CISA_KEVS: "CISA_KEVS",
  MICROSOFT_PATCH_TUESDAY: "MICROSOFT_PATCH_TUESDAY",
};

const RANK_ENTITY = {
  ACTORS: "ACTORS",
  THREATS: "THREATS",
  BAS_ATTACKS: "BAS_ATTACKS",
  BAS_ATTACK_COLLECTIONS: "BAS_ATTACK_COLLECTIONS",
  BAS_TACTICS: "BAS_TACTICS",
  BAS_TECHNIQUES: "BAS_TECHNIQUES",
  TTP: "TTP",
};

const ALERT_CATEGORY = [
  {
    label: "Adversary Advisory",
    value: alertCategory.ADVERSARY_ADVISORY,
  },
  {
    label: "Attack Advisory",
    value: alertCategory.ATTACK_ADVISORY,
  },
  {
    label: "Vulnerability Advisory",
    value: alertCategory.VULNERABILITY_ADVISORY,
  },
  {
    label: "Weekly Digest",
    value: alertCategory.WEEKLY_DIGEST,
  },
  {
    label: "Monthly Digest",
    value: alertCategory.MONTHLY_DIGEST,
  },
  {
    label: "Celebrity Vulnerabilities",
    value: alertCategory.CELEBRITY_VULNERABILITIES,
  },
  {
    label: "CISA KEVs",
    value: alertCategory.CISA_KEVS,
  },
  {
    label: "Microsoft Patch Tuesday",
    value: alertCategory.MICROSOFT_PATCH_TUESDAY,
  },
];

const THREAT_LEVEL_OPTIONS = [
  {
    label: "Red",
    value: threatLevel.RED,
  },
  {
    label: "Green",
    value: threatLevel.GREEN,
  },
  {
    label: "White",
    value: threatLevel.WHITE,
  },
  {
    label: "Amber",
    value: threatLevel.AMBER,
  },
];

const TYPE_OF_PATCHES = [
  {
    label: "Version Update",
    value: "version_update",
  },
  {
    label: "Configuration",
    value: "configuration",
  },
  { label: "Change", value: "change" },
  { label: "Others", value: "others" },
  { label: "Security", value: "security" },
  { label: "Update", value: "update" },
  { label: "Monthly Update", value: "monthly_update" },
];

const AUTHORITY = {
  ADMIN: "ADMIN",
  LICENSE_ADMIN: "LICENSE_ADMIN",
  PATCH_INTELLIGENCE_ADMIN: "PATCH_INTELLIGENCE_ADMIN",
  THREAT_INTELLIGENCE_ADMIN: "THREAT_INTELLIGENCE_ADMIN",
  USER_ADMIN: "USER_ADMIN",
  POLICY_ADMIN: "POLICY_ADMIN",
  OPTIONS_ADMIN: "OPTIONS_ADMIN",
  BAS_ADMIN: "BAS_ADMIN",
  ALERT_ADMIN: "ALERT_ADMIN",
  ACTOR_ADMIN: "ACTOR_ADMIN",
};

const MONTH_OPTIONS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const YES_NO_OPTIONS = [
  { label: "YES", value: "true" },
  { label: "NO", value: "false" },
];

export {
  OnboardingStatus,
  jobStatus,
  jobType,
  SUPPORT_TICKET_STATUS,
  SUPPORT_TICKET_PRIORITY,
  SUPPORT_TICKET_ESCALATION,
  SUPPORT_TICKET_STATE,
  FILETYPES,
  SUPPORT_TICKET_PRIORITY_COLORS,
  SUPPORT_TICKET_STATUS_COLORS,
  ATTACK_FORMAT_TYPES,
  ATTACK_TYPES,
  ATTACK_EXECUTOR_TYPES,
  ATTACK_SUPPORTED_SYSTEMS,
  ATTACK_EXECUTORS,
  PROXY_MODES,
  MULTI_TENANT_MODES,
  TACTIC_TYPE_OPTIONS,
  AttackTypes,
  ATTACK_SOURCE_TYPES,
  SIGNATURES,
  threatLevel,
  THREAT_LEVEL_OPTIONS,
  TYPE_OF_PATCHES,
  RANK_ENTITY,
  AUTHORITY,
  TARGET_TYPES,
  ALERT_CATEGORY,
  MONTH_OPTIONS,
  YES_NO_OPTIONS,
};
