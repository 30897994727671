import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import {kFormatter} from "../../helpers/utils";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // maxWidth: "max-content"
  },
  counter: {
    fontSize: 45,
    fontWeight: 300,
    color: (props) => props.color,
    // color: (props) => props.color,
  },
  label: {
    fontWeight: 300,
    fontSize: 14,
    color: "#FFFFFF",
  },
});

export default function Counter(props) {
  const classes = useStyles(props);
  const { value, label } = props;

  return (
    <Box className={classes.root}>
      <Typography className={classes.counter}>{kFormatter(value)}</Typography>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
}
Counter.propTypes = {
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
