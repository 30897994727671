import * as colors from "./colors";
import {errorColor} from "./colors";
import {createMuiTheme} from "@material-ui/core/styles";
// import { useMediaQuery } from "@material-ui/@core";
import React from "react";

const overrides = (prefersDarkMode) => {
  // const btnDefault = prefersDarkMode
  //   ? colors.defaultColorDark
  //   : colors.defaultColorLight;

  const btnPrimary = prefersDarkMode ? colors.primaryDark : colors.primaryLight;
  return {
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiFilledInput: {
      underline: {
        // borderRadius: "10px 10px 0 0",
        borderColor: "#5D5E72",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36
      }
    },
    MuiListItem: {
      root: {
        backgroundColor: "#171717",
        "&$selected": {
          backgroundColor: "#222222",
          "&:hover": {
            backgroundColor: btnPrimary.dark,
          },
        },
      },
    },
    MuiPaginationItem: {
      selected: {
        backgroundColor: "#000",
      }
    },
    MuiButton: {
      root: {
        borderRadius: 30,
      },
      // contained: {
      //   backgroundColor: btnDefault.main,
      //   color: btnDefault.contrastText,
      //   "&:hover": {
      //     backgroundColor: btnDefault.dark,
      //   },
      // },
      label: {
        // fontFamily: "Roboto Regular, sans-serif",
        fontWeight: 400,
        textTransform: "none",
      },
    },
    MuiTypography: {
      h6: {
        fontSize: "20px",
      }
    },
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiAppBar: {
      root: {
        height: "45px",
      },
      colorPrimary: {
        background: "none",
      },
    },
    MuiToolbar: {
      root: {
        background: "#1A1C1C",
        color: "white",
        padding: 0,
        width: "100%",
        height: '25px'
      },
    },
    MuiTooltip:{
      tooltip: {
        backgroundColor: '#2F2F2F'
      },
      arrow: {
        color: '#2F2F2F'
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: 0,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: 13
      }
    }
  };
};

const typography = () => {
  return {
    root: {
      fontFamily: "Roboto Regular",
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
    h5: {
      fontWeight: 300,
      fontSize: 28,
    },
    h6: {
      fontSize: 16,
    },
  };
};

function useAppTheme() {
  const prefersDarkMode = true;
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: prefersDarkMode ? colors.primaryDark : colors.primaryLight,
          error: errorColor,
          secondary: prefersDarkMode
            ? colors.secondaryDark
            : colors.secondaryLight,
          background: prefersDarkMode
            ? colors.backgroundDark
            : colors.backgroundLight,
        },
        overrides: overrides(prefersDarkMode),
        typography: typography(prefersDarkMode),
      }),
    [prefersDarkMode]
  );
}

export { colors, useAppTheme };
