import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    color: "#ffffff",
    fontSize: 14,
  },
});

const Subheader = (props) => {
  const classes = useStyles();
  return (
    <Typography {...props} className={classes.header}>
      {props.children}
    </Typography>
  );
};

export default Subheader;
