import Entity from "./Entity";

class Product extends Entity {
	constructor() {
		super();
		this.id = null
		this.vendor = ""
		this.products = []
		this.icon = ""
	}
}
export default Product
