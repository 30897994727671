import React, {useEffect, useState} from 'react';
import Title from "../../components/Common/Title";
import {Box} from "@material-ui/core";
import RankEditList from "../../components/Common/Ranking/RankEditList";
import BaseDialog from "../../components/Common/BaseDialog";
import {postData} from "../../helpers/utils";
import {API_URL} from "../../config";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {clearTechniques} from "../../store/technique/actions";

function createPayload(entity, list) {
	const items = list.map((item, index) => ({
		id: item.id,
		order: index
	}))

	// handle tactics sorting payload
	if (!entity.objectType) return items

	// handle techniques sorting payload under a tactic
	return {
		tacticId: entity.id,
		techniques: items
	}
}

function SortDialog({ open, handleClose, entity, getAllTechniques }) {
	
	const [items, setItems] = useState([])
	const [busy, setBusy] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()

	useEffect(() => {
		if (open) {
			setItems(entity.children)
		}
	}, [entity, open])

	const renderTitle = () => {
		if (!entity.objectType) return "Sort Tactics"
		if (entity.objectType === "TACTIC") return "Sort Techniques"
		// if (entity.objectType === "TECHNIQUE") return "Sort Sub-techniques"
	}

	const handleSubmit = async () => {
		const payload = createPayload(entity, items)

		const endpoint = entity.objectType ? "/api/techniques/order" : "/api/tactics/order"
		setBusy(true)
		try {
			await postData(`${API_URL}${endpoint}`, payload)
			dispatch(clearTechniques())
			getAllTechniques()
			handleClose()
		} catch (e) {
			enqueueSnackbar(e.message, { variant: "error" })
		}
		setBusy(false)
	}

	return (
		<BaseDialog
			onClose={handleClose}
			title={renderTitle()}
			open={open}
			inProgress={busy}
			showProgress
			primaryAction={handleSubmit}
			primaryActionTitle={"Save"}
		>
			<Title>
				Reorder items {entity.name ? `under ${entity.name}` : ""} and press submit to apply sorting.
			</Title>
			<Box mt={3} />
			<RankEditList items={items} setItems={setItems} entityName={"Mitre"} />
		</BaseDialog>
	);
}

export default SortDialog;