import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { Publish } from "@material-ui/icons";

const Menu = ({ handleAddNew }) => {

  const menuItems = [
    {
      pushRight: true,
      divider: true,
    },
    {
      title: "Upload CVEs",
      icon: <Publish color={"primary"} />,
      onClick: handleAddNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Menu;
