import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import EntityDialog from "../../components/EntityDialog";
import ErrorBoundary from "../../components/ErrorBoundary";
import { useDropzone, useFetch, useForm } from "reactcoregk/hooks";
import HiDrop from "../../components/HiDrop";
import Title from "../../components/Common/Title";
import Select from "../../components/Inputs/Select";
import XMLViewer from "react-xml-viewer";
import { API_URL } from "config";
import { getDefaultHeaders } from "helpers/utils";

const UploadPolicyDialog = (props) => {
  const { open, entity, handleClose } = props;
  const [form, handleChange, setForm] = useForm({
    type: "",
    id: "",
    content: "",
  });

  const options = usePolicyTypes();

  useEffect(() => {
    if (open) {
      setForm(entity);
    }
  }, [entity, open, setForm]);

  const {
    handleAcceptedFiles,
    setSelectedFiles,
    selectedFiles,
  } = useDropzone();

  useEffect(() => {
    if (open) {
      setSelectedFiles([]);
    }
  }, [open, setSelectedFiles]);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("policy", selectedFiles[0]);
    formData.append("type", form.type);
    props.uploadPolicy(formData);
  };

  const handleAction = () => {
    form.id ? handleClose() : handleUpload();
  };

  return (
    <EntityDialog
      form={{ id: true }}
      titleOverride={"Policy"}
      showProgress
      maxWidth={form.id ? "md" : "sm"}
      isValid={!form.id && !!form.type && !!selectedFiles[0]}
      {...props}
      primaryActionTitle={form.id ? "Dismiss" : "Upload"}
      updateEntity={handleAction}
      createEntity={handleAction}
    >
      <ErrorBoundary>
        <Grid container direction={"column"} spacing={1}>
          <Grid item>
            <Title>{form.id ? "Policy details" : "Upload policy"}</Title>
          </Grid>
          <Grid item>
            <Select
              onChange={handleChange("type")}
              value={form.type}
              disabled={form.id}
              controlId={"typeId"}
              placeholder={"Select policy type"}
              label={"Policy type"}
              options={options}
            />
          </Grid>

          {form.id && (
            <Grid item>
              <XMLViewer xml={form.content} />
            </Grid>
          )}
          {!form.id && (
            <Grid item>
              <div style={{ height: 250 }}>
                <HiDrop
                  selectedFiles={selectedFiles}
                  handleAcceptedFiles={handleAcceptedFiles}
                  title={"Drag and drop CSV"}
                  setSelectedFiles={setSelectedFiles}
                  link={"Upload file"}
                  // accept=".xml, text/csv"
                />
              </div>
            </Grid>
          )}
        </Grid>
      </ErrorBoundary>
    </EntityDialog>
  );
};

export default UploadPolicyDialog;

const usePolicyTypes = () => {
  const [, data] = useFetch(API_URL + "/api/policies/types", getDefaultHeaders(),[]);
  return data.map((item) => ({
    label: item,
    value: item,
  }));
};
