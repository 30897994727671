import React from "react";
import {useDefaultTable} from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import {
    SUPPORT_TICKET_STATUS,
    SUPPORT_TICKET_STATUS_COLORS
} from "../../constants/enums";

const statusColor = status => {
    switch (status) {
        case SUPPORT_TICKET_STATUS.RESOLVED:
            return SUPPORT_TICKET_STATUS_COLORS.RESOLVED
        case SUPPORT_TICKET_STATUS.UNRESOLVED:
            return SUPPORT_TICKET_STATUS_COLORS.UNRESOLVED
        default:
            return 'inherit'
    }
}

function TicketsTable(props) {
    const { tickets, sortHandler, headCells, handleEntityClick, isLoading } = props;

    const styleCell = (colId, value) => {
        if (colId === "status") {
            return {
                color: statusColor(value)
            }
        }
    }

    const {
        renderHeadCell,
        renderCheckAllCell,
        renderDefaultBody,
    } = useDefaultTable(tickets, sortHandler);

    return (
        <DefaultTable
            headCells={headCells}
            isLoading={isLoading}
            onRowClick={handleEntityClick}
            renderCheckAllCell={renderCheckAllCell}
            renderDefaultBody={renderDefaultBody}
            renderHeadCell={renderHeadCell}
            rows={tickets}
            styleCell={styleCell}
        />
    );
}

export default TicketsTable;
