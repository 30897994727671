import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Title from "../../../components/Common/Title";
import HiDrop from "../../../components/HiDrop";
import { useDropzone } from "reactcoregk/hooks";
import BaseDialog from "../../../components/Common/BaseDialog";
import { postData } from "../../../helpers/utils";
import { ApiEndpoint } from "../../../store/@core/endpoint";
import { EntityType } from "../../../store/@core/entityType";
import TextField from "@material-ui/core/TextField";

function UploadDialog(props) {
  const { entity, open, handleClose, getAttack, type, hasKey, accept } = props;
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const [key, setKey] = useState("");

  const {
    handleAcceptedFiles,
    setSelectedFiles,
    selectedFiles,
  } = useDropzone();

  const handleUpload = async () => {
    const url = `${ApiEndpoint[EntityType.Attack]}/${entity.id}/upload/${type}`;
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    if (hasKey) {
      formData.append(
        "details",
        new Blob([JSON.stringify({ key })], {
          type: "application/json",
        })
      );
    }

    setBusy(true);
    try {
      await postData(url, formData);
      getAttack(entity.id);
      handleClose();
    } catch (e) {
      setError(e.message);
    }
    setBusy(false);
  };

  return (
    <BaseDialog
      title={`Upload ${type}`}
      showProgress
      inProgress={busy}
      primaryActionTitle={"Upload"}
      primaryAction={handleUpload}
      onClose={handleClose}
      open={open}
      errorMessage={error}
      isValid={!!key || !hasKey}
      dismissError={() => setError(null)}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <Title>Upload {type}</Title>
        </Grid>
        {hasKey && (
          <Grid item>
            <TextField
              variant="standard"
              margin="none"
              placeholder={"Enter key"}
              disabled={busy}
              fullWidth
              value={key}
              onChange={(e) => setKey(e.target.value)}
              label="Key"
              autoFocus
            />
          </Grid>
        )}
        <Grid item>
          <div style={{ height: 250 }}>
            <HiDrop
              selectedFiles={selectedFiles}
              handleAcceptedFiles={handleAcceptedFiles}
              title={"Drag and drop here"}
              setSelectedFiles={setSelectedFiles}
              link={"Upload file"}
              accept={accept}
            />
          </div>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

export default UploadDialog;
