import React from "react";
import DefaultTable from "../../components/Common/DefaultTable";
import TableRow from "@material-ui/core/TableRow";
import { formatDatesOnList } from "../../helpers/utils";

const headCells = [
  {
    id: "cve",
    numeric: false,
    label: "CVE",
  },
  {
    id: "publishedDate",
    numeric: false,
    label: "Published At",
  },
  {
    id: "cveName",
    numeric: false,
    label: "CVE Name",
  },
  {
    id: "pluginId",
    numeric: false,
    label: "Plugin ID",
  },
  {
    id: "qualysId",
    numeric: false,
    label: "Qualys QID",
  },
  {
    id: "nvtId",
    numeric: false,
    label: "NVT",
  },
  {
    id: "lastModifiedDate",
    numeric: false,
    label: "Last Update",
  },
];

function CveTable(props) {
  const { cves, isLoading, defaultTable } = props;

  const {
    renderHeadCell,
    renderCheckRowCell,
    renderCheckAllCell,
    isSelected,
    handleClick,
    renderBodyCell,
  } = defaultTable;

  const formatted = formatDatesOnList(
    cves,
    "LLL",
    "lastModifiedDate",
    "publishedDate"
  );

  const renderDefaultBody = () => {
    return formatted.map((cve) => {
      const { id } = cve;
      const isItemSelected = isSelected(id);
      return (
        <TableRow
          hover
          role="checkbox"
          onClick={(e) => handleClick(e, id)}
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={id}
          selected={isItemSelected}
        >
          {renderCheckRowCell(isItemSelected, id, (e) => {
            handleClick(e, id);
          })}
          {renderBodyCell(id)}
          {renderBodyCell(cve.publishedDate)}
          {renderBodyCell(cve.cveName)}
          {renderBodyCell(cve.pluginId)}
          {renderBodyCell(cve.qualysId)}
          {renderBodyCell(cve.nvt)}
          {renderBodyCell(cve.lastModifiedDate)}
        </TableRow>
      );
    });
  };

  return (
    <DefaultTable
      headCells={headCells}
      isLoading={isLoading}
      renderCheckAllCell={renderCheckAllCell}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={cves}
      selectableRows
    />
  );
}

export default CveTable;
