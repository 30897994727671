import {ReactComponent as Malwares} from 'assets/icons/malwares.svg'
import {ReactComponent as Mitre} from 'assets/icons/mitreattack.svg'
import {ReactComponent as Vulnerability} from 'assets/icons/vulnerability.svg'
import {ReactComponent as AdversaryBehaviour} from 'assets/icons/adversary-behaviour.svg'
import {ReactComponent as AdversaryTools} from 'assets/icons/adversary-tools.svg'
import {ReactComponent as Simulation} from 'assets/icons/simulation.svg'
import {ReactComponent as Collection} from 'assets/icons/collection.svg'
import {AttackTypes} from "../../constants/enums";


export function getCollectionImage(arsenal) {
    switch (arsenal.arsenalType) {
        case AttackTypes.MALWARE: return Malwares
        case AttackTypes.MITRE_ATTACK: return Mitre
        case AttackTypes.VULNERABILITY: return Vulnerability
        case AttackTypes.ADVERSARY: return AdversaryBehaviour
        case "simulation": return Simulation
        case "collection": return Collection
        default: return Malwares
    }
}
