import {all, call, fork, put, takeEvery} from "redux-saga/effects";
//Include Both Helper File with needed methods
import {GET_UNREAD_COUNT, LOAD_NOTIFICATIONS, READ_NOTIFICATION} from "./actionTypes";
import {
    getUnreadNotificationsCountSuccess,
    loadNotificationsSuccess,
    notificationError,
    readNotificationsSuccess,
} from "./actions";
import {fetchData} from "../../helpers/utils";
import {ApiEndpoint} from "../@core/endpoint";
import {EntityType} from "../@core/entityType";
import {postNotifications} from "./api";

// Login Redux States

function* getNotifications({ payload: { page } }) {
  try {
    const url = ApiEndpoint[EntityType.Notification] + `?page=${page}&size=5&sort=createdAt,desc`
    const appResult = yield call(fetchData, url);
    yield put(loadNotificationsSuccess(appResult));
  } catch (error) {
    yield put(notificationError(error.message));
  }
}

function* readNotifications({ payload: { notificationIds } }) {
  try {
    yield call(postNotifications, notificationIds);
    yield put(readNotificationsSuccess(notificationIds));
  } catch (error) {
    yield put(notificationError(error.message));
  }
}
function* getUnreadCount() {
  try {
    const url = ApiEndpoint[EntityType.Notification] + "/unread"
    const appResult = yield call(fetchData, url);
    yield put(getUnreadNotificationsCountSuccess(appResult));
  } catch (error) {
    yield put(notificationError(error.message));
  }
}

export function* watchNotificationsFetch() {
  yield takeEvery(LOAD_NOTIFICATIONS, getNotifications);
}

export function* watchNotificationsRed() {
  yield takeEvery(READ_NOTIFICATION, readNotifications);
}

export function* watchNotificationsCount() {
  yield takeEvery(GET_UNREAD_COUNT, getUnreadCount);
}

function* authSaga() {
  yield all([fork(watchNotificationsFetch), fork(watchNotificationsRed), fork(watchNotificationsCount)]);
}

export default authSaga;
