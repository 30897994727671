import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { getCollectionImage } from "./views";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { DotOptions } from "../../assets/icons/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: 120,
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: "smaller",
    marginRight: theme.spacing(1),
  },
  value: {
    color: theme.palette.text.primary,
  },
  image: {
    width: 96,
    height: 96,
    borderRadius: 50,
  },
}));

function ArsenalCard(props) {
  const classes = useStyles();
  const {
    arsenal,
    isLoading,
    isSelected,
    handleSelect,
    handleViewDetails,
    isSelectable,
  } = props;

  const Image = getCollectionImage(arsenal);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Card variant={"outlined"}>
        <CardContent className={classes.root}>
          <Box display={"flex"}>
            <div style={{ minWidth: "40px" }}>
              {isSelectable && (
                <Checkbox
                  disabled={isLoading}
                  // onChange={handleToggle}
                  onClick={() => handleSelect(arsenal)}
                  checked={isSelected}
                  name={arsenal.id}
                  color="primary"
                />
              )}
            </div>
            <Box mx={1} />
            <Box
              flex={1}
              display={"flex"}
              justifyContent={"center"}
              marginRight={"40px"}
            >
              {isLoading ? (
                <Skeleton variant="rect" className={classes.image} />
              ) : (
                <Image className={classes.image} />
              )}
            </Box>
          </Box>
          <Box mt={1} />
          <Box display={"flex"} mt={1} flexDirection={"column"}>
            {isLoading ? (
              <Skeleton variant="text" height={20} width={120} />
            ) : (
              <Typography color={"textPrimary"}>{arsenal.name}</Typography>
            )}
            <Box mt={1} />
            <Box display={"flex"} alignItems={"center"}>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  height={14}
                  width={60}
                  className={classes.label}
                />
              ) : (
                <Typography className={classes.label}>Created On:</Typography>
              )}
              {isLoading ? (
                <Skeleton
                  variant="text"
                  height={18}
                  width={80}
                  className={classes.value}
                />
              ) : (
                <Typography className={classes.value}>
                  {arsenal.createdAt}
                </Typography>
              )}
            </Box>
            <Box mt={1} />
            <Box display={"flex"} alignItems={"center"}>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  height={14}
                  width={60}
                  className={classes.label}
                />
              ) : (
                <Typography className={classes.label}>Created by:</Typography>
              )}
              {isLoading ? (
                <Skeleton
                  variant="text"
                  height={18}
                  width={85}
                  className={classes.value}
                />
              ) : (
                <Typography className={classes.value}>
                  {arsenal.createdBy}
                </Typography>
              )}
            </Box>
            <Box mt={1} />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {isLoading ? (
                <Skeleton variant="text" height={20} width={120} />
              ) : (
                <Typography color={"primary"}>{arsenal.type}</Typography>
              )}
              {!isLoading && arsenal.arsenalType === "collection" && (
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  aria-label="options"
                  size="small"
                >
                  <DotOptions color={"textSecondary"} />
                </IconButton>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleViewDetails(arsenal);
          }}
        >
          View Details
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default ArsenalCard;
