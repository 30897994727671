import { AttackTypes } from "../../constants/enums";

export const handleAttackPreSubmit = (form, setError, callback) => {
  if (!form.name) {
    setError("Name is required");
    return;
  } else if (!form.description) {
    setError("Description is required");
    return;
  } else if (!form.type) {
    setError("Type is required");
    return;
  } else if (!form.signatures || form.signatures.length === 0) {
    setError("Signatures cannot be empty");
    return;
  } else if (!form.format) {
    setError("Format is required");
    return;
  } else if (!form.sourceType) {
    setError("Source Type is required");
    return;
  } else if (form.executors.length === 0) {
    setError("Executors cannot be empty");
    return;
  } else if (form.supportedPlatforms.length === 0) {
    setError("Supported Platforms cannot be empty");
    return;
  } else if (!form.target) {
    setError("Target is required");
    return;
  } else if (
    (() => {
      if (form.recommendation.length > 0) {
        const val = form.recommendation
          .split("<br/>")
          .every((x) => x.length > 0);
        return !val;
      } else {
        return true;
      }
    })()
  ) {
    setError("Recommendation is required");
    return;
  } else if (form.references.length === 0) {
    setError("References cannot be empty");
    return;
  }

  const techniqueIds =
    (!form.subTechniqueIds?.length && form.techniqueIds) || null;
  const tacticIds =
    (!form.subTechniqueIds?.length &&
      !form.techniqueIds?.length &&
      form.tacticIds) ||
    null;
  const payload = { ...form, techniqueIds, tacticIds };

  switch (form.type) {
    case AttackTypes.MITRE_ATTACK: {
      const hasTactic = Boolean(
        form.tacticIds?.length ||
          form.techniqueIds?.length ||
          form.subTechniqueIds?.length
      );
      if (!hasTactic) return setError("Tactic is required");
      setError("");
      callback(payload);
      break;
    }
    case AttackTypes.VULNERABILITY: {
      const cves = form.cves.length === 1;
      if (!cves) return setError("Attack should have one CVE");
      setError("");
      callback(payload);
      break;
    }
    case AttackTypes.ADVERSARY: {
      const actorAgents = form.actorIds.length === 1;
      if (!actorAgents) return setError("Attack should have one actor");
      const adversaryTools = form.adversaryTools.length === 1;
      if (!adversaryTools)
        return setError("Attack should have one adversary tool");
      const hasTactic = Boolean(
        form.tacticIds?.length ||
          form.techniqueIds?.length ||
          form.subTechniqueIds?.length
      );
      if (!hasTactic) return setError("Tactic is required");
      setError("");
      callback(payload);
      break;
    }
    default:
      setError("");
      callback(payload);
      break;
  }
};
