import React from "react";
import { Tab, Tabs } from "@material-ui/core";

const RulesSubheader = ({ activeTab, handleChangeTab, options }) => {
  return (
    <Tabs
      value={activeTab}
      onChange={handleChangeTab}
      indicatorColor="primary"
      textColor="primary"
      variant="standard"
      style={{ borderBottom: "0.05rem solid #151F1E" }}
    >
      {options.map((option) => (
        <Tab label={option.label} />
      ))}
    </Tabs>
  );
};

export default RulesSubheader;
