import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { useDefaultTable } from "../../helpers/hooks";
import BaseDialog from "../../components/Common/BaseDialog";
import DefaultTable from "../../components/Common/DefaultTable";
import Title from "../../components/Common/Title";
import TableRow from "@material-ui/core/TableRow";
import {
  Button,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

const defaultCells = [
  {
    id: "mitreId",
    disablePadding: false,
    label: "Mitre ID",
    default: true,
    isSortable: false,
  },
  {
    id: "name",
    disablePadding: false,
    label: "Name",
    default: true,
    isSortable: false,
  },
  {
    id: "description",
    disablePadding: false,
    label: "Description",
    default: true,
    isSortable: false,
  },
  {
    id: "actions",
    disablePadding: false,
  },
];

function MitigationDialog(props) {
  const { technique, handleClose, callback } = props;
  const [mitigations, setMitigations] = useState([]);

  const sortHandler = useSortHandler();

  const handleChange = useCallback(
    (prop, index) => (e) => {
      const value = e.target.value;
      setMitigations((prevState) => {
        return prevState.map((x, i) => {
          if (i === index) {
            return {
              ...x,
              [prop]: value,
              shouldUpdate: true
            };
          }
          return x;
        });
      });
    },
    []
  );

  const handleDelete = useCallback((index) => {
    setMitigations((prevState) => {
      return prevState.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            shouldDelete: true,
          };
        }
        return x;
      });
    });
  }, []);

  const handleNew = useCallback(() => {
    setMitigations((prevState) => [
      ...prevState,
      { name: "", description: "" },
    ]);
  }, []);

  const { renderHeadCell } = useDefaultTable(mitigations, sortHandler);

  useEffect(() => {
    setMitigations(technique.mitigations);
  }, [technique.mitigations]);

  const handleConfirm = () => {
    callback(mitigations);
    handleClose();
  };

  const visibleMitigations = mitigations.filter((x) => !x.shouldDelete);

  const renderDefaultBody = () => {
    return visibleMitigations.map((mit, index) => {
      return (
        <TableRow hover tabIndex={-1} key={index}>
          <TableCell style={{ padding: 4 }}>
            <TextField
              fullWidth
              value={mit.mitreId}
              onChange={handleChange("mitreId", index)}
            />
          </TableCell>
          <TableCell style={{ padding: 4 }}>
            <TextField
              fullWidth
              value={mit.name}
              onChange={handleChange("name", index)}
            />
          </TableCell>
          <TableCell style={{ padding: 4 }} width={"400px"}>
            <TextField
              fullWidth
              value={mit.description}
              onChange={handleChange("description", index)}
            />
          </TableCell>
          <TableCell style={{ padding: 4 }}>
            <Tooltip title={"Publish"}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(index);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <BaseDialog
      title={"Mitigations"}
      fullWidth={true}
      maxWidth={"md"}
      primaryAction={handleConfirm}
      disableBackdropClick
      onClose={handleClose}
      {...props}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>Manage technique mitigations</Title>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DefaultTable
            rows={visibleMitigations}
            headCells={defaultCells}
            renderHeadCell={renderHeadCell}
            renderDefaultBody={renderDefaultBody}
          />
        </Grid>
        <Grid item>
          <Button color={"primary"} endIcon={<Add />} onClick={handleNew}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

export default MitigationDialog;
