import React, { useCallback } from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { HiveIcons } from "../../assets/icons";
import FilterBase from "../../components/Common/FilterBase";
import TableFilters from "../../components/Common/TableFilters";
import {
  AFFECTED_PRODUCTS,
  ATTACK_EXECUTORS,
  ATTACK_SUPPORTED_SYSTEMS,
  ATTACK_TYPES, MONTH_OPTIONS,
  RANK_ENTITY
} from "../../constants/enums";
import { useTableSearch } from "../../helpers/hooks";
import SearchBox from "../../components/Common/SearchBox";
import { AccountTreeOutlined } from "@material-ui/icons";
import RankButton from "../../components/Common/Ranking/RankButton";
import { useSelector } from "react-redux";
import { createMap } from "reactcoregk/utils";
import { getParameterByName, updateURLParameter } from "../../helpers/utils";
import { TextField } from "@material-ui/core";
import MultiSelect from "../../components/Inputs/MultiSelect";

const year = (new Date()).getFullYear();
const year_options = Array.from(new Array(20),( val, index) => {
  return {
    label: year-index,
    value: year-index
  }
});

const filterOptions = [
  {
    label: "Type",
    value: "type",
    options: ATTACK_TYPES,
  },
  {
    customEl: (locationSearch, setLocationSearch) => (
      <CreatedDate
        locationSearch={locationSearch}
        setLocationSearch={setLocationSearch}
      />
    ),
  },
  {
    label: "Created Month",
    value: "createdAtMonth",
    options: MONTH_OPTIONS
  },
  {
    label: "Created Year",
    value: "createdAtYear",
    options: year_options
  },
  {
    customEl: (locationSearch, setLocationSearch) => (
      <UpdatedDate
        locationSearch={locationSearch}
        setLocationSearch={setLocationSearch}
      />
    ),
  },
  {
    label: "Updated Month",
    value: "updatedAtMonth",
    options: MONTH_OPTIONS
  },
  {
    label: "Updated Year",
    value: "updatedAtYear",
    options: year_options
  },
  {
    customEl: (locationSearch, setLocationSearch) => (
      <SupportedPlatforms
        locationSearch={locationSearch}
        setLocationSearch={setLocationSearch}
      />
    ),
  },
  {
    customEl: (locationSearch, setLocationSearch) => (
      <Executors
        locationSearch={locationSearch}
        setLocationSearch={setLocationSearch}
      />
    ),
  },
  {
    customEl: (locationSearch, setLocationSearch) => (
      <AffectedPrograms
        locationSearch={locationSearch}
        setLocationSearch={setLocationSearch}
      />
    ),
  },
];

function getAttacks(state) {
  return createMap(state.Attack.getAllPageable.all);
}

const Subheader = ({ handleCreateNew, selected }) => {
  const [query, handleChange, syncQuery] = useTableSearch();

  const allAttacks = useSelector(getAttacks);

  const items = selected.map((id) => ({
    id,
    entityName: RANK_ENTITY.BAS_ATTACKS,
    name: allAttacks.get(id)?.name,
  }));

  const menuItems = [
    {
      title: "Add Mitre TTP",
      icon: <AccountTreeOutlined style={{ marginRight: 4 }} />,
      path: "/mitre-ttp",
    },
    {
      pushRight: true,
      customEl: <RankButton selected={items} entityName={"Attack"}  rankEntity={RANK_ENTITY.BAS_ATTACKS}/>,
    },
    {
      customEl: (
        <SearchBox
          query={query}
          handleChange={handleChange}
          syncQuery={syncQuery}
        />
      ),
    },
    {
      customEl: (
        <FilterBase>
          <TableFilters filterOptions={filterOptions} />
        </FilterBase>
      ),
    },
    { divider: true },
    {
      title: "New Attack",
      icon: <HiveIcons.AddOutlined />,
      onClick: handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

function CreatedDate({ locationSearch, setLocationSearch}) {

  const handleChange = useCallback((value) => {
    const safeValue = value.target.value.toString()
    const searchDate = updateURLParameter(locationSearch, "createdAt", safeValue);
    setLocationSearch(searchDate);
  }, [locationSearch, setLocationSearch])

  return (
        <TextField
          variant="standard"
          margin={"normal"}
          type="date"
          fullWidth
          sx={{width: "300px"}}
          value={getParameterByName("createdAt", locationSearch) || ""}
          onChange={handleChange}
          label={"Created Date"}
          color={"primary"}
          InputLabelProps={{
            shrink: true,
          }}
        />
  );
}

function UpdatedDate({ locationSearch, setLocationSearch}) {

  const handleChange = useCallback((value) => {
    const safeValue = value.target.value.toString()
    const searchDate = updateURLParameter(locationSearch, "updatedAt", safeValue);
    setLocationSearch(searchDate);
  }, [locationSearch, setLocationSearch])

  return (
    <TextField
      variant="standard"
      margin={"normal"}
      type="date"
      fullWidth
      sx={{width: "300px"}}
      value={getParameterByName("updatedAt", locationSearch) || ""}
      onChange={handleChange}
      label={"Updated Date"}
      color={"primary"}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

function SupportedPlatforms({ locationSearch, setLocationSearch }) {

  const handleChangeForm = useCallback((value) => {
    const safeValue = value.toString()
    const search = updateURLParameter(locationSearch, "supportedPlatform", safeValue);
    setLocationSearch(search);
  }, [locationSearch, setLocationSearch])

  const supported = getParameterByName("supportedPlatform", locationSearch) || ""
  const formatted = supported.split(",").filter(x => !!x)

  return (
    <MultiSelect
      fullWidth
      margin={"dense"}
      onChange={handleChangeForm}
      values={formatted || []}
      label={"Supported Platforms"}
      placeholder={"Select Platforms"}
      controlId={"platforms"}
      options={ATTACK_SUPPORTED_SYSTEMS}
      labelId={"platforms"}
    />
  );
}

function Executors({ locationSearch, setLocationSearch }) {

  const handleChangeForm = useCallback((value) => {
    const safeValue = value.toString()
    const search = updateURLParameter(locationSearch, "executors", safeValue);
    setLocationSearch(search);
  }, [locationSearch, setLocationSearch])

  const executors = getParameterByName("executors", locationSearch) || ""
  const formatted = executors.split(",").filter(x => !!x)

  return (
    <MultiSelect
      fullWidth
      margin={"dense"}
      onChange={handleChangeForm}
      values={formatted || []}
      label={"Executors"}
      placeholder={"Select executors"}
      controlId={"executors"}
      options={ATTACK_EXECUTORS}
      labelId={"executors"}
    />
  );
}

function AffectedPrograms({ locationSearch, setLocationSearch }) {

  const handleChangeForm = useCallback((value) => {
    const safeValue = value.toString()
    const search = updateURLParameter(locationSearch, "affectedPrograms", safeValue);
    setLocationSearch(search);
  }, [locationSearch, setLocationSearch])

  const executors = getParameterByName("affectedPrograms", locationSearch) || ""
  const formatted = executors.split(",").filter(x => !!x)

  return (
    <MultiSelect
      fullWidth
      margin={"dense"}
      onChange={handleChangeForm}
      values={formatted || []}
      label={"Affected Programs"}
      placeholder={"Select programs"}
      controlId={"programs"}
      options={AFFECTED_PRODUCTS}
      labelId={"programs"}
    />
  );
}

export default Subheader;
