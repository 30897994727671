import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import EntityDialog from "../../components/EntityDialog";
import User from "../../models/User";
import ErrorBoundary from "../../components/ErrorBoundary";
import { useForm } from "reactcoregk/hooks";
import MultiSelect from "../../components/Inputs/MultiSelect";
import Button from "@material-ui/core/Button";
import {ErrorButtonOutlined} from "../../components/Common/ErrorButton";
import { postData } from "../../helpers/utils";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { useSnackbar } from "notistack";
import {getAllUsers} from "../../store/user/actions";
import {useDispatch} from "react-redux";

const EditDialog = (props) => {
  const { open, entity, roleOptions, handleClose } = props;
  const [form, handleChange, updateInitial] = useForm(new User());
  const [inProgress, setInProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      const user = new User();
      if (entity.id) {
        user.initFullUser(entity, roleOptions);
        user.roleIds = entity.authorities
          .map((authority) => {
            return roleOptions.find((x) => x.label === authority)?.value;
          })
          .filter((x) => Boolean(x));
      }
      updateInitial(user);
    }
  }, [roleOptions, entity, open, updateInitial]);

  const primaryActionTitle = entity.id ? "Update User" : "Add User";

  const onCreateOrUpdate = () => {
    props.createEntity([form]);
  };

  const isValid = Boolean(form.username && form.email && form.roleIds[0]);

  const handleUpdateStatus = async (status) => {
    setInProgress(true);
    try {
      await postData(`${ApiEndpoint[EntityType.User]}/${entity.id}/${status}`, {});
      const message = status === "enable" ? "activated" : "deactivated";
      enqueueSnackbar(`${entity.username} has been ${message}`, {
        variant: "success",
      });
      dispatch(getAllUsers())
      handleClose()
    } catch (ex) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setInProgress(false);
  };

  return (
    <EntityDialog
      primaryActionTitle={primaryActionTitle}
      form={form}
      showProgress
      maxWidth={"sm"}
      {...props}
      isValid={isValid}
      createEntity={onCreateOrUpdate}
      updateEntity={onCreateOrUpdate}
    >
      <ErrorBoundary>
        <Grid container direction={"column"}>
          <Grid item>
            <TextField
              variant="standard"
              margin="none"
              placeholder={"Enter username"}
              fullWidth
              value={form.username}
              onChange={handleChange("username")}
              id="name"
              label="Username"
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              margin="normal"
              placeholder={"First name"}
              fullWidth
              value={form.firstName}
              onChange={handleChange("firstName")}
              id="firstName"
              label="First name"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              margin="normal"
              placeholder={"Last name"}
              fullWidth
              value={form.lastName}
              onChange={handleChange("lastName")}
              id="lastName"
              label="Last name"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              margin="normal"
              placeholder={"Enter email ID"}
              fullWidth
              value={form.email}
              onChange={handleChange("email")}
              id="email-id"
              label="Email"
              type={"email"}
            />
          </Grid>
          <Grid item>
            <MultiSelect
              placeholder={"Select roles"}
              fullWidth
              values={form.roleIds}
              onChange={handleChange("roleIds")}
              label="Role"
              controlId={"roleId"}
              options={roleOptions}
              labelId={"roleId"}
            />
          </Grid>
        </Grid>
        {entity.id && (
          <Grid item style={{ marginTop: 32 }}>
            {entity.enabled ? (
              <ErrorButtonOutlined
                variant={"outlined"}
                disabled={inProgress}
                onClick={() => handleUpdateStatus("disable")}
              >
                Deactivate
              </ErrorButtonOutlined>
            ) : (
              <Button
                disabled={inProgress}
                onClick={() => handleUpdateStatus("enable")}
                variant={"outlined"}
                color={"primary"}
              >
                Activate
              </Button>
            )}
          </Grid>
        )}
      </ErrorBoundary>
    </EntityDialog>
  );
};

export default EditDialog;
