import React from "react";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import Container from "@material-ui/core/Container";
import Menu from "./client-list-menu";
import {useDefaultTable, useSortHandler, useSubheaderLogic,} from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import {connect} from "react-redux";
import {createClient, updateClient} from "../../store/client/actions";
import {EntityType} from "../../store/@core/entityType";
import EditDialog from "./EditDialog";
import {createOptions} from "../../helpers/utils";
import {useFiltered, useMasterDetailWithDialog} from "reactcoregk/hooks";
import {Schema} from "../../store/@core/schema";

const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "contactEmail",
    label: "email",
  },
  {
    id: "country",
    label: "Country",
  },
  {
    id: "vertical",
    label: "Vertical",
  },
];

const ClientList = (props) => {
  const { context, createClient, updateClient } = props;
  const { query, handleChangeQuery } = useSubheaderLogic();
  const sortHandler = useSortHandler();

  const verticals = context.CompanyVertical.getAll.result;
  const countries = context.Country.getAll.result;
  const versions = context.Version.getAll.result;
  const clients = context.Client.getAll.result;

  const verticalOptions = createOptions(verticals);
  const countryOptions = createOptions(countries);
  const versionOptions = createOptions(versions);

  const results = useFiltered(clients, query, ['name', 'email', 'country', 'vertical'])

  const {
    openModal,
    handleCreateNew,
    handleCloseModal,
    entity,
  } = useMasterDetailWithDialog(Schema[EntityType.Client]);

  const {
    renderCheckAllCell,
    renderHeadCell,
    renderDefaultBody,
  } = useDefaultTable([], sortHandler);

  const MenuItemsComponent = <Menu
      query={query}
      handleChangeQuery={handleChangeQuery}
      handleCreateNew={handleCreateNew}
    />

  const onRowClick = (client) => {
    props.history.push(`/clients/${client.id}`);
  };

  return (
    <SubmenuLayout noDivider MenuItemsComponent={MenuItemsComponent} title={"Clients"}>
      <Container maxWidth={false}>
        <DefaultTable
          renderCheckAllCell={renderCheckAllCell}
          headCells={headCells}
          rows={results}
          onRowClick={onRowClick}
          renderHeadCell={renderHeadCell}
          renderDefaultBody={renderDefaultBody}
        />
      </Container>
      <EditDialog
        entity={entity}
        context={context.Client}
        createEntity={createClient}
        updateEntity={updateClient}
        open={openModal}
        entityType={EntityType.Client}
        handleClose={handleCloseModal}
        countryOptions={countryOptions}
        verticalOptions={verticalOptions}
        versionOptions={versionOptions}
      />
    </SubmenuLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  createClient,
  updateClient,
})(ClientList);
