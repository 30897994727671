import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  optionalLabel: {
    marginLeft: 8,
    fontStyle: "italic",
  },
  required: {
    color: "#FA8072",
    marginLeft: 4,
    marginTop: -8,
  },
  rightContent: {
    marginLeft: 8,
  },
});

const Type = {
  required: "required",
  none: "non",
  optional: "optional",
};

const SectionGroupDescription = (props) => {
  const classes = useStyles();
  const { title, description, IconComponent, type, rightContent } = props;
  return (
    <Grid container direction={"column"} spacing={1}>
      <Grid item container alignItems={"center"}>
        <IconComponent color={"primary"} style={{ marginRight: 4 }} />
        <Typography variant={"h6"} component={"h2"}>
          {title}
        </Typography>
        {type === Type.optional && (
          <Typography
            color={"textSecondary"}
            variant={"caption"}
            className={classes.optionalLabel}
          >
            (Optional)
          </Typography>
        )}
        {type === Type.required && (
          <Typography className={classes.required}>*</Typography>
        )}
        {rightContent && (
          <Typography className={classes.rightContent} color={"textSecondary"}>{rightContent}</Typography>
        )}
      </Grid>
      {description && (
        <Grid item xs={12}>
          <Typography color={"textSecondary"}>{description}</Typography>
        </Grid>
      )}
      {props.children && (
        <Grid item xs={12}>
          {props.children}
        </Grid>
      )}
    </Grid>
  );
};

export default SectionGroupDescription;

SectionGroupDescription.defaultProps = {
  type: Type.none,
};

SectionGroupDescription.propTypes = {
  IconComponent: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.oneOf(["required", "optional", "none"]),
};
