import { motion } from "framer-motion";
import { slideVariants } from "../../constants/constants";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import Grid from "@material-ui/core/Grid";
import Title from "../../components/Common/Title";
import FormControl from "@material-ui/core/FormControl";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useEntityProgress, useForm } from "reactcoregk/hooks";
import TextField from "@material-ui/core/TextField";
import Select from "../../components/Inputs/Select";
import { COLLECTION_TYPES } from "../../constants/enums";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1 }}
      {...other}
      className="wrapper"
    >
      {value === index && (
        <motion.div
          variants={slideVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function NewCollectionDialog(props) {
  const {
    open,
    handleClose,
    history,
    context,
    createCollection,
    collection,
    step,
    setStep,
  } = props;
  const [form, handleChange] = useForm({
    shouldCreate: "true",
    shouldCopy: "true",
    name: "",
    description: "",
    visible: "false",
    attackIds: [],
  });

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open, setStep]);

  const handleNext = () => {
    if (step === 0) {
      if (form.shouldCreate === "false") {
        handleClose();
        history.push("/collections?addToExistingCollection=true");
      } else {
        setStep(step + 1);
      }
    } else if (step === 1) {
      if (form.shouldCopy === "true") {
        history.push("/collections?copyFromCollection=true");
        handleClose();
      } else {
        setStep(step + 1);
      }
    } else {
      let attackIds = [];
      if (collection?.attackIds?.length > 0 && form.shouldCopy === "true") {
        attackIds = collection.attackIds;
      }
      createCollection({ ...form, attackIds });
    }
  };

  const handleRedirect = useCallback(() => {
    const id = context.create.result?.uuid;
    history.push(`/collections/${id}`);
  }, [context.create.result, history]);

  const [busy, error] = useEntityProgress(context, handleRedirect);

  return (
    <BaseDialog
      open={open}
      primaryAction={handleNext}
      title={"Create Selection"}
      inProgress={busy}
      errorMessage={error}
      primaryActionTitle={step === 2 ? "Save" : "Continue"}
      onClose={handleClose}
      showProgress
      isValid={
        (step > 1 && (form.name.length <= 0 || form.name.length > 128)) ||
        form.description.length > 2048
          ? false
          : true
      }
    >
      <TabPanel index={step} value={0}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Title>Create new Collection or add to existing collection?</Title>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="shouldCreate"
                name="shouldCreate"
                value={form.shouldCreate}
                onChange={handleChange("shouldCreate")}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio />}
                  label="Create new Collection"
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio />}
                  label="Add to existing Collection"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel index={step} value={1}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Title>Do you want to copy an existing collection?</Title>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="shouldCopy"
                name="shouldCopy"
                value={form.shouldCopy}
                onChange={handleChange("shouldCopy")}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel index={step} value={2}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Title>Enter details</Title>
          </Grid>
          <Grid item>
            <Grid item>
              <TextField
                variant="standard"
                margin="none"
                placeholder={"Enter name"}
                disabled={busy}
                fullWidth
                value={form.name}
                onChange={handleChange("name")}
                label="Name"
                autoFocus
                helperText={
                  form?.name.length > 128 && (
                    <p style={{ color: "#EF4444" }}>
                      Collection name should not exceed 128 characters.
                    </p>
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <TextField
                variant="standard"
                margin="none"
                placeholder={"Enter Description"}
                disabled={busy}
                fullWidth
                multiline
                rowsMax={10}
                value={form.description}
                onChange={handleChange("description")}
                label="Description"
                helperText={
                  form?.description.length > 2048 && (
                    <p style={{ color: "#EF4444" }}>
                      Collection description should not exceed 2048 characters.
                    </p>
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Select
                variant="standard"
                margin="none"
                placeholder={"Enter Type"}
                disabled={busy}
                fullWidth
                value={form.collectionType}
                onChange={handleChange("collectionType")}
                options={COLLECTION_TYPES}
                label="Type"
              />
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </BaseDialog>
  );
}

export default NewCollectionDialog;
