import EntityDialog from "components/EntityDialog";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "reactcoregk/hooks";
import { createPatch } from "store/actions";

import PatchForm from "./PatchForm";

export default function EditDialog(props) {
  const dispatch = useDispatch();
  const { entity, open } = props;

  const [form, handleChange, setForm] = useForm(entity);

  const handleCreate = useCallback(
    (payload) => {
      dispatch(createPatch(payload));
    },
    [dispatch]
  );

  const isValid = () => {
    return !!form.cve & form.bulletinId;
  };

  useEffect(() => {
    if (open) {
      setForm(entity);
    }
  }, [entity, setForm, open]);

  return (
    <EntityDialog
      {...props}
      isValid={isValid()}
      createEntity={handleCreate}
      form={form}
      maxWidth={"md"}
    >
      <PatchForm form={form} setForm={setForm} handleChange={handleChange} />
    </EntityDialog>
  );
}
