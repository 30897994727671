import React from "react";
import Typography from "@material-ui/core/Typography";

const Title = (props) => {
  return (
    <Typography component="h1" variant="h5" color={"textSecondary"} {...props}>
      {props.children}
    </Typography>
  );
};

export default Title;
