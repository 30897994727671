import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import { HiveIcons } from "../../assets/icons";
import RankButton from "../../components/Common/Ranking/RankButton";
import { RANK_ENTITY } from "../../constants/enums";
import { useMap } from "../../helpers/hooks";

const Subheader = ({
  handleCreateNew,
  selectedTechs,
  selectedTactics,
  selectedSubTechs,
  tactics,
  techniques,
  subTechniques,
}) => {
  const tacticMap = useMap(tactics);
  const techniquesMap = useMap(techniques);
  const subTechniquesMap = useMap(subTechniques);

  const tacticItems = selectedTactics.map((id) => ({
    id,
    entityName: RANK_ENTITY.BAS_TACTICS,
    name: tacticMap.get(id)?.name,
  }));

  const techItems = selectedTechs.map((id) => ({
    id,
    entityName: RANK_ENTITY.BAS_TECHNIQUES,
    name: techniquesMap.get(id)?.name,
  }));

  const subTechItems = selectedSubTechs.map((id) => ({
    id,
    entityName: RANK_ENTITY.BAS_TECHNIQUES,
    name: subTechniquesMap.get(id)?.name,
  }));

  const items = [...tacticItems, ...techItems, ...subTechItems];

  const menuItems = [
    {
      pushRight: true,
      customEl: (
        <RankButton
          selected={items}
          entityName={"TTP"}
          rankEntity={RANK_ENTITY.TTP}
        />
      ),
    },
    {
      divider: true,
    },
    {
      title: "New Mitre TTP",
      icon: <HiveIcons.AddOutlined />,
      onClick: handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Subheader;
