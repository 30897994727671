import React, { useEffect, useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { useDefaultTable } from "../../helpers/hooks";
import BaseDialog from "../../components/Common/BaseDialog";
import DefaultTable from "../../components/Common/DefaultTable";
import Title from "../../components/Common/Title";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import { fetchData } from "../../helpers/utils";
import { Paging } from "reactcoregk/models";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import SearchBox from "../../components/Common/SearchBox";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Switch } from "@material-ui/core";
import { debounce } from "lodash";

const defaultCells = [
  {
    id: "actorId",
    disablePadding: false,
    label: "Name",
    default: true,
    isSortable: false,
  },
  {
    id: "name",
    disablePadding: false,
    label: "Name",
    default: true,
    isSortable: false,
  },
  {
    id: "actorType",
    disablePadding: false,
    label: "Type",
    default: true,
    isSortable: false,
  },
];

const url = ApiEndpoint[EntityType.Actor];

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
  },
});

function ManageActorsDialog(props) {
  const classes = useStyles();

  const { alert, handleClose, open, callback } = props;

  const [page, setPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const [actors, setActors] = useState(new Paging());
  const [errorMessage, setErrorMessage] = useState(null);
  const [query, setQuery] = useState("");
  const [showSelected, setShowSelected] = useState(false);
  const [initialActors, setInitialActors] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      if (query) {
        setQuery(query);
      } else {
        setQuery("");
      }
    }, 500),
    []
  );

  const sortHandler = useSortHandler();
  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
    selected,
    setSelected,
  } = useDefaultTable(actors.content, sortHandler, "actorId");

  useEffect(() => {
    if (open) {
      setBusy(true);
      const apiUrl = `${url}?page=${page}&sort=actorId,asc&size=20&s=${query}`;
      const params = showSelected ? `&actorIds=${selected.join(",")}` : "";
      fetchData(`${apiUrl}${params}`)
        .then((data) => {
          setActors(data);
          setInitialActors(data);
          setBusy(false);
        })
        .catch((ex) => {
          setBusy(false);
          if (showSelected && selected.length === 0) {
            setActors(new Paging());
            setInitialActors(new Paging());
          } else {
            setErrorMessage(ex.message);
          }
        });
    }
  }, [open, page, query, showSelected]);

  useEffect(() => {
    if (open) {
      setSelected(alert.actorIds || []);
      setInitialActors(alert.actorIds);
    }
  }, [alert.actorIds, setSelected, open]);

  const handleConfirm = () => {
    callback(selected);
    handleClose();
    setPage(0);
    setErrorMessage(null);
  };

  return (
    <BaseDialog
      title={"Manage actors"}
      fullWidth={true}
      maxWidth={"lg"}
      primaryAction={handleConfirm}
      inProgress={busy}
      errorMessage={errorMessage}
      disableBackdropClick
      onClose={handleClose}
      {...props}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container>
            <Grid item style={{ flex: 1 }}>
              <Title>Select actors to add</Title>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showSelected}
                    onChange={() => {
                      setShowSelected((s) => !s);
                      setPage(0);
                    }}
                    name="selected"
                    color="primary"
                  />
                }
                label="Show Selected"
              />
            </Grid>
            <Grid item>
              <SearchBox
                placeholder={"name, description or known as"}
                handleChange={(e) => {
                  debouncedHandleSearch(e.target.value);
                  setSearchInput(e.target.value);
                  setPage(0);
                }}
                query={searchInput}
              />
            </Grid>
          </Grid>
        </Grid>

        {!showSelected && (
          <>
            <Grid item>
              <DefaultTable
                rows={actors.content}
                isLoading={busy}
                headCells={defaultCells}
                renderHeadCell={renderHeadCell}
                renderCheckAllCell={renderCheckAllCell}
                renderDefaultBody={renderDefaultBody}
                selectableRows
              />
            </Grid>
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={actors.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </>
        )}

        {showSelected && (
          <Grid item>
            <DefaultTable
              rows={initialActors.content}
              isLoading={busy}
              headCells={defaultCells}
              renderHeadCell={renderHeadCell}
              renderCheckAllCell={renderCheckAllCell}
              renderDefaultBody={renderDefaultBody}
              selectableRows
            />
            <Box mt={2} component={Grid} container justify={"center"}>
              <Pagination
                onChange={(e, newPage) => setPage(newPage - 1)}
                page={page + 1}
                count={initialActors.totalPages}
                showFirstButton
                showLastButton
                size={"small"}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </BaseDialog>
  );
}

export default ManageActorsDialog;
