import {entityType} from "./module";
import {
    createAsyncAction,
    createDeleteMethod, createGetAllSuccessMethod,
    createPostMethod,
} from "reactcoregk/store/actions";
import {getDefaultApiHandler, getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

export const getAllTechniqueActions = createAsyncAction(entityType, "GET_ALL")
export const updateTechniqueActions = createAsyncAction(entityType, "UPDATE")
export const api = () => new ApiHandler(false, false, getDefaultHeaders())

export const getAllTechniques = () => getAllTechniqueActions.request()
export const createTechnique = (payload) => createPostMethod(entityType, payload, api())
export const updateTechnique = (payload) =>  updateTechniqueActions.request(payload)
export const deleteTechnique = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())
export const clearTechniques = () => createGetAllSuccessMethod(entityType, [], getDefaultApiHandler())
