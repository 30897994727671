import {entityType} from "./module";
import {createDeleteMethod, createGetAllMethod, createPostMethod, createPutMethod} from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

export const getDefaultApiHandler = () => new ApiHandler(false, false, getDefaultHeaders(), "POST");

export const getAllCountries = (params) => createGetAllMethod(entityType, params, getDefaultApiHandler())
export const createCountry = (payload) => createPostMethod(entityType, payload, getDefaultApiHandler())
export const updateCountry = (payload) => createPutMethod(entityType, payload, getDefaultApiHandler())
export const deleteCountry = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler())

