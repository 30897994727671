import {API_ERROR, LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS} from './actionTypes';
import {AUTH_ENABLED} from "../../../config";

const initialState = {
    error: "",
    loading: false,
    isAuth: !AUTH_ENABLED,
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            }
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                isAuth: true
            }
            break;
        case LOGOUT_USER:
            state = { ...state, isAuth: false };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state, isAuth: false };
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;
