import React from "react";
import {HiveIcons} from "assets/icons";
import SubmenuItems from "../../components/Header/SubmenuItems";

const DashboardMenu = (props) => {
  const menuItems = [
    {
      pushRight: true,

      title: "New version",
      icon: <HiveIcons.AddOutlined />,
      onClick: props.handleCreateNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default DashboardMenu;
