import axios from "axios";
import {API_URL} from "../../../config";
import {handleError} from "reactcoregk/store/api";

// import md5 from 'md5';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = async (data) => {
  // const config = {
  //   headers: {
  //     Authorization: `${data.username}:${md5(data.password)}`
  //   }
  // }
  const url = API_URL + "/token/jwt";
  return axios
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};


//Logout Method
const postLogout = async () => {
  const url = API_URL + "/token/logout";
  return axios
      .post(url, {})
      .then(() => {
        localStorage.removeItem('authUser')
        window.location.reload()
      })
      .catch(handleError);
};


// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postForgetPwd,
  postLogout
};
