import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSortHandler } from "reactcoregk/hooks";
import { useDefaultTable } from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";
import Box from "@material-ui/core/Box";
import { Pagination } from "@material-ui/lab";
import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import ConfirmationDialog from "components/Common/ConfirmationDialog";
import AddEditIocDialog from "./AddEditIocDialog";

function IocTable(props) {
  const { iocs, alert, headCells, busy, page, setPage, handleUpdateIoc } =
    props;

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [editIocDialogOpen, setEditIocDialogOpen] = useState(false);

  const sortHandler = useSortHandler();
  const { renderHeadCell } = useDefaultTable(
    iocs.content,
    sortHandler,
    "alertId"
  );

  const [selectedIoc, setSelectedIoc] = useState(null);

  const handleDelete = () => {
    handleUpdateIoc([], [selectedIoc]);
  };

  const customizedRenderDefaultBody = (
    array,
    headerColumns,
    selectableRows,
    onClick,
    styleCell
  ) => {
    return array.map((row, index) => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
          <TableCell align="left">
            <Typography style={{ fontSize: 12 }} color={"textSecondary"}>
              {row.iocType}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{ fontSize: 12 }} color={"textSecondary"}>
              {row.iocValue}
            </Typography>
          </TableCell>
          <TableCell style={{ padding: 4 }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setEditIocDialogOpen(true);
                setSelectedIoc(row);
              }}
            >
              <Edit />
            </IconButton>
          </TableCell>
          <TableCell style={{ padding: 4 }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                // handleDelete(index);
                setSelectedIoc(row);
                setOpenConfirmation(true);
              }}
            >
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <DefaultTable
        rows={iocs.content}
        isLoading={busy}
        headCells={headCells}
        renderHeadCell={renderHeadCell}
        renderDefaultBody={customizedRenderDefaultBody}
      />
      <Box mt={2} component={Grid} container justify={"center"}>
        <Pagination
          onChange={(e, newPage) => setPage(newPage - 1)}
          page={page + 1}
          count={iocs.totalPages}
          showFirstButton
          showLastButton
          size={"small"}
        />
      </Box>
      {openConfirmation && (
        <ConfirmationDialog
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          title="Delete IOC"
          description="Are you sure you want to delete these IOC?"
          primaryActionTitle={"Delete"}
          secondaryActionTitle={"Cancel"}
          primaryAction={() => {
            setOpenConfirmation(false);
            handleDelete();
          }}
          secondaryAction={() => setOpenConfirmation(false)}
        />
      )}

      {editIocDialogOpen && (
        <AddEditIocDialog
          open={editIocDialogOpen}
          onClose={() => setEditIocDialogOpen(false)}
          maxWidth={"md"}
          title="Edit IOCs"
          primaryActionTitle={"Update"}
          secondaryActionTitle={"Cancel"}
          secondaryAction={() => setEditIocDialogOpen(false)}
          formIocs={[selectedIoc]}
          handleUpdateIoc={handleUpdateIoc}
          showProgress
          inProgress={busy}
          alertId={alert.id}
        />
      )}
    </>
  );
}

export default IocTable;
