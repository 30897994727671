import React, { useEffect } from "react";
import "./App.css";
import { authProtectedRoutes, publicRoutes } from "./routes";
import { BrowserRouter, Switch } from "react-router-dom";
import { HOME_PAGE } from "./config";
import AppRoute from "./routes/route";
import * as theme from "./constants/theme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useAuthValidation } from "./helpers/hooks";
import { connect } from "react-redux";
import { loginSuccess, logoutUser } from "./store/auth/login/actions";
import Background from "components/Common/Background";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getAllVersions } from "./store/version/actions";
import { getAllClients } from "./store/client/actions";
import { getAllUsers } from "./store/user/actions";
import { getAllCompanyVerticals } from "./store/company-vertical/actions";
import { getAllCountries } from "./store/country/actions";
import { getAllRoles } from "./store/role/actions";
import { getAllJobs } from "./store/job/actions";
import BusyIndicator from "./components/Common/BusyIndicator";
import NotFound from "./pages/NotFound";
import { SnackbarProvider } from "notistack";
import {Slide} from "@material-ui/core";
import ExpirationAlert from "./components/ExpirationAlert";

function App(props) {
  const {
    loginSuccess,
    logoutUser,
    context,
    getAllVersions,
    getAllClients,
    getAllUsers,
    getAllCountries,
    getAllCompanyVerticals,
    getAllRoles,
    getAllJobs
  } = props;

  const appTheme = theme.useAppTheme();
  const isValidating = useAuthValidation(loginSuccess, logoutUser);
  const isAuth = context.Login.isAuth;

  useEffect(() => {
    if (isAuth) {
      getAllVersions();
      getAllClients();
      getAllUsers();
      getAllCountries();
      getAllCompanyVerticals();
      getAllRoles();
      getAllJobs();
    }
  }, [
    getAllUsers,
    getAllVersions,
    getAllClients,
    getAllCountries,
    getAllCompanyVerticals,
    getAllRoles,
    getAllJobs,
    isAuth,
  ]);

  if (isValidating) {
    return (
      <Background>
        <MuiThemeProvider theme={appTheme}>
          <BusyIndicator busy={isValidating} />
        </MuiThemeProvider>
      </Background>
    );
  }

  return (
    <MuiThemeProvider theme={appTheme}>
      <SnackbarProvider
        autoHideDuration={2000}
        maxSnack={3}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <CssBaseline />
        <BrowserRouter basename={HOME_PAGE}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                exact={route.exact}
                path={route.path}
                layout={route.layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                // websocketRef={clientRef}
                exact={route.exact}
                path={route.path}
                layout={route.layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                title={route.title}
                authorities={route.authorities}
              />
            ))}
            <AppRoute component={NotFound} />
          </Switch>
          <ExpirationAlert />
        </BrowserRouter>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  loginSuccess,
  logoutUser,
  getAllVersions,
  getAllClients,
  getAllUsers,
  getAllCompanyVerticals,
  getAllCountries,
  getAllRoles,
  getAllJobs,
})(App);
