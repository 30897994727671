import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Input from "components/Inputs/Input";
import Select from "components/Inputs/Select";
import BaseDialog from "components/Common/BaseDialog";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import HiDrop from "components/HiDrop";
import { useDropzone, useEntityProgress, useForm } from "reactcoregk/hooks";
import { Schema } from "store/@core/schema";
import { EntityType } from "store/@core/entityType";
import { SUPPORT_TICKET_ESCALATION, SUPPORT_TICKET_PRIORITY, SUPPORT_TICKET_STATUS } from "constants/enums";
import { HiveIcons } from "assets/icons";
import { API_URL } from "config";
import { postData } from "helpers/utils";


const useStyles = makeStyles((theme) => ({
    inputTitle: {
        fontSize: 14,
        color: '#C5BFBF',

    },
    title: {
        fontSize: 28,
        color: '#D1D1D1',
    },
    dropText: {
        color: theme.palette.primary.main
    },
    dropArea: {
        height: 200,
        margin: '20px 0'
    },
    mainContent: {
        height: '40vh'
    },
    successMsg: {
        color: '#43DD43',
        fontSize: 20,
    },
}));

const CreateNewTicketDialog = (props) => {
    const classes = useStyles();
    const { open, handleClose, createEntity, updateEntity, context } = props;
    const [form, handleChange, updateInitial] = useForm(Schema[EntityType.SupportTicket]);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [ticketActionCompleted, setTicketActionCompleted] = React.useState(false)
    const [isUploading, setIsUploading] = React.useState(false);

    useEffect(() => {
        if (open) {
            const editedSchema = {
                ...Schema[EntityType.SupportTicket],
                tags: Schema[EntityType.SupportTicket]['tags'].join(" "),
                status: SUPPORT_TICKET_STATUS.OPEN,
                priority: SUPPORT_TICKET_PRIORITY.MEDIUM,
                escalation: SUPPORT_TICKET_ESCALATION.L1,
                name: "TSI-TW-HP"
            }
            updateInitial(editedSchema)
        } else {
            setErrorMessage(null);
            setTicketActionCompleted(false);
        }
    }, [open, updateInitial])




    const handleUpload = async (supportTicketId) => {
        setIsUploading(true);
        try {
            const url = API_URL + "/api/support-tickets/attachments";
            const requests = []
            selectedFiles.forEach(file => {
                const formData = new FormData();
                formData.append("attachment", file);
                formData.append(
                    "details",
                    new Blob([JSON.stringify({ supportTicketId: supportTicketId })], {
                        type: "application/json",
                    })
                );
                requests.push(postData(url, formData))
            })
            await Promise.all(requests)
            setIsUploading(false);
            setTicketActionCompleted(true)
            setSelectedFiles([])
        } catch (e) {
            setErrorMessage(e.message);
            setIsUploading(false);
            setTicketActionCompleted(true)
            setSelectedFiles([])
        }
    };

    const handleProgressComplete = _ => {
        if (context.SupportTicket.create.result) {
            if (selectedFiles.length) {
                const ticketId = context.SupportTicket.create.result.id
                handleUpload(ticketId);
            } else setTicketActionCompleted(true)

        }
    }
    const [busy, error] = useEntityProgress(context.SupportTicket, handleProgressComplete);

    useEffect(() => {
        setErrorMessage(error)
        if (error) {
            setTicketActionCompleted(true)
        }
    }, [error]);


    const handleCreate = () => {
        return form.id ? updateEntity({ ...form, tags: form.tags?.split(" ") }) : createEntity({ ...form, tags: form.tags?.split(" ") });
    };


    const { handleAcceptedFiles, setSelectedFiles, selectedFiles } = useDropzone();

    return (
        <BaseDialog
            className={classes.mainContent}
            maxWidth={"md"}
            inProgress={busy || isUploading}
            open={open}
            onClose={handleClose}
            title={"Create Ticket"}
            maxContentHeight="40vh"
            primaryActionTitle={ticketActionCompleted ? "Done" : "Create ticket"}
            primaryAction={ticketActionCompleted ? handleClose : handleCreate}
        //errorMessage={errorMessage}
        //dismissError={() => setErrorMessage(null)}
        >
            <Grid container direction={"column"} spacing={0} >
                {!ticketActionCompleted ? <><Typography className={classes.title}>Enter Details</Typography>
                    <Grid item style={{ paddingBottom: 0, marginTop: 25 }}>
                        <Typography className={classes.inputTitle}>Category</Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <Input
                            value={form.category}
                            onChange={handleChange("category")}
                            placeholder={"Enter Category"}
                        />
                    </Grid>
                    <Grid item style={{ paddingBottom: 0, marginTop: 25 }}>
                        <Typography className={classes.inputTitle}>Subject</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            value={form.subject}
                            onChange={handleChange("subject")}
                            placeholder={"Enter Subject"}
                        />
                    </Grid>
                    <Grid item style={{ paddingBottom: 0, marginTop: 25 }}>
                        <Typography className={classes.inputTitle}>Source Channel</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            value={form.sourceChannel}
                            onChange={handleChange("sourceChannel")}
                            placeholder={"Enter Source Channel"}
                        />
                    </Grid>
                    <Grid item style={{ paddingBottom: 0, marginTop: 25 }}>
                        <Typography className={classes.inputTitle}>Tags</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            value={form.tags}
                            onChange={handleChange("tags")}
                            placeholder={"Enter Tags"}
                        />
                    </Grid>
                    <Grid item style={{ paddingBottom: 0, marginTop: 25 }}>
                        <Typography className={classes.inputTitle}>Contact Type</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            label={"Select"}
                            value={form.contactType}
                            onChange={handleChange("contactType")}
                            controlId={"contactType"}
                            options={[]}
                        />
                    </Grid>
                    <Grid item xs={8} className={classes.dropArea}>
                        <HiDrop
                            selectedFiles={selectedFiles}
                            handleAcceptedFiles={handleAcceptedFiles}
                            title={<Typography className={classes.dropText}>Drag and drop file to upload attachment</Typography>}
                            //scription={"Or find it on your disk"}
                            setSelectedFiles={setSelectedFiles}
                        //link={"Browse"}
                        />
                    </Grid>
                    {/*<Grid item style={{ paddingBottom: 0, marginTop: 25 }}>
                        <Typography className={classes.inputTitle}>Assign to</Typography>
                    </Grid>
                     <Grid item xs={8}>
                        <Select
                            label={"Priority"}
                            value={form.priority}
                            onChange={handleChange("priority")}
                            controlId={"priority"}
                            options={[]} //add SUPPORT_TICKET_PRIORITY?
                        />
                    </Grid>*/}
                </>
                    : <>
                        <Typography className={classes.title}>{errorMessage ? 'There has been a problem' : 'Ticket Created'}</Typography>
                        {!errorMessage ? <><Grid item style={{ alignSelf: 'center' }}>
                            <HiveIcons.Completed />
                        </Grid>
                            <Grid item style={{ alignSelf: 'center', marginTop: 30 }}>
                                <Typography className={classes.successMsg}>Ticket Created Successfully!</Typography>
                            </Grid></>

                            : <Grid item style={{ alignSelf: 'center' }}>
                                <HiveIcons.IconWarningKronosOutlined />
                            </Grid>}
                    </>

                }

            </Grid>
        </BaseDialog>
    );
};

export default CreateNewTicketDialog;
