import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import { useCommonDetailedPage } from "../../components/Common/styles";
import Title from "../../components/Common/Title";
import {ReactComponent as Collection} from 'assets/icons/collection.svg'

const useStyles = makeStyles((theme) => ({
  image: {
    width: 96,
    height: 96,
    marginRight: theme.spacing(3),
  },
  root: {
    display: "flex",
    alignItems: "center",
    minHeight: 120,
  },
}));

function Details({ collection, busy }) {
  const classes = useCommonDetailedPage();
  const classes2 = useStyles();

  return (
    <Grid item spacing={3} container direction="row" alignItems="center">
      <Grid item xs={12} className={classes2.root}>
        {busy ? (
          <Skeleton variant="rect" className={classes2.image} />
        ) : (
          <Collection className={classes2.image} />
        )}
        {busy ? (
          <Skeleton variant="text" width={120} height={32} />
        ) : (
          <Title>{collection.name}</Title>
        )}
      </Grid>
      <Grid item>
        <div className={classes.riskText}>Created on: </div>
        <div className={classes.riskDataText}>{collection.createdAt}</div>
      </Grid>
      <Grid item>
        <div className={classes.riskText}>Created By on: </div>
        <div className={classes.riskDataText}>{collection.createdByUsername}</div>
      </Grid>
      <Grid item>
        <div className={classes.riskText}>Type: </div>
        <div className={classes.riskDataText}>{collection.collectionType}</div>
      </Grid>
    </Grid>
  );
}

export default Details;
