import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const PolicyItem = ({ _key, validation }) => {
  const wordings = {
    useChars: "Use 6 to 30 characters.",
    passwordsMatch: "Confirm password.",
    avoidUsingTheSame: "Avoid using the same password.",
    includesNumberOrSymbol:
      "Use a combination of upper case and lower case letters, numbers and symbols (!,@,#,$,%,^,&,*,_,+,=).",
    isCaseSensitive: "Password is case sensitive.",
  };
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: 4 }}>
      {validation[_key] ? (
        <CheckIcon style={{ color: "#43DD43" }} />
      ) : (
        <CloseIcon style={{ color: "#FA8072" }} />
      )}

      <Typography
        variant={"caption"}
        color={"textSecondary"}
        style={{ marginLeft: 8 }}
      >
        {wordings[_key]}
      </Typography>
    </div>
  );
};

function PasswordPolicy(props) {
  const { validation } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PolicyItem _key={"useChars"} validation={validation} />
        <PolicyItem _key={"includesNumberOrSymbol"} validation={validation} />
        <PolicyItem _key={"isCaseSensitive"} validation={validation} />
        <PolicyItem _key={"avoidUsingTheSame"} validation={validation} />
        <PolicyItem _key={"passwordsMatch"} validation={validation} />
    </div>
  );
}

export default PasswordPolicy;
