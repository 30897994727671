import {Checkbox, Divider, FormControlLabel, Grid, TextField} from "@material-ui/core";
import FetchSelect from "components/Inputs/FetchSelect";
import MultipleFreeText from "components/Inputs/MultipleFreeText";
import SearchSelect from "components/Inputs/SearchSelect";
import React, { useCallback } from "react";
import { ApiEndpoint } from "store/@core/endpoint";
import { EntityType } from "store/@core/entityType";
import { TYPE_OF_PATCHES } from "../../constants/enums";

export default function PatchForm({ form, handleChange, busy }) {
  const cveMap = useCallback((data) => {
    return data.content.map((cve) => ({
      label: cve.cve,
      value: cve.cve,
    }));
  }, []);

  return (
    <Grid container direction={"row"} spacing={3}>
      {Boolean(form.id) && (
        <Grid item xs={12}>
          <TextField
            variant="standard"
            margin="none"
            fullWidth
            value={form.cve}
            disabled
            label={"Cve"}
          />
        </Grid>
      )}
      {!Boolean(form.id) && (
        <Grid item xs={12}>
          <FetchSelect
            url={ApiEndpoint[EntityType.CveDefinition] + `?size=20&s=`}
            dataMap={cveMap}
            label={"Select Cve"}
            onChange={handleChange("cve")}
            required={true}
            placeholder={"Enter Cve Id"}
            disabled={busy}
            initialValue={form.cve}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter patch name"}
          fullWidth
          value={form.name}
          onChange={handleChange("name")}
          label="Patch Name"
          disabled={busy}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter patch id"}
          fullWidth
          required={true}
          value={form.bulletinId}
          onChange={handleChange("bulletinId")}
          label="Patch ID"
          autoFocus
          disabled={busy || Boolean(form.id)}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          fullWidth
          type="datetime-local"
          value={form.publishedAt}
          onChange={handleChange("publishedAt")}
          label="Published At"
          disabled={busy}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter MEPMP Patch ID"}
          fullWidth
          value={form.mepmpPatchId}
          onChange={handleChange("mepmpPatchId")}
          label="MEPMP Patch ID"
          disabled={busy}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter Ivanti Patch ID"}
          fullWidth
          value={form.ivantiPatchId}
          onChange={handleChange("ivantiPatchId")}
          label="Ivanti Patch ID"
          disabled={busy}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter MEDC Patch ID"}
          fullWidth
          value={form.medcPatchId}
          onChange={handleChange("medcPatchId")}
          label="MEDC Patch ID"
          disabled={busy}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter SCCM Patch ID"}
          fullWidth
          value={form.sccmPatchId}
          onChange={handleChange("sccmPatchId")}
          label="SCCM Patch ID"
          disabled={busy}
        />
      </Grid>

      <Grid item xs={6}>
        <SearchSelect
          placeholder={"Choose type of patch"}
          label={"Type of patch"}
          controlId={"type"}
          margin={"none"}
          onChange={handleChange("type")}
          value={form.type}
          disabled={busy}
          required
          options={TYPE_OF_PATCHES}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider/>
      </Grid>

      <Grid item xs={12}>
        <MultipleFreeText
          value={form.workarounds || []}
          onChange={handleChange("workarounds")}
          label={"Workarounds"}
          placeholder={"Add Workarounds"}
          disabled={busy}
        />
      </Grid>

      <Grid item xs={12}>
        <MultipleFreeText
          value={form.sources || []}
          onChange={handleChange("sources")}
          label={"Source of Patch"}
          placeholder={"Add source of Patch"}
          disabled={busy}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          margin="none"
          placeholder={"Enter desceription of patch"}
          fullWidth
          value={form.description}
          onChange={handleChange("description")}
          label="Description of Patch"
          disabled={busy}
        />
      </Grid>
      <Grid item xs={12}>
        <MultipleFreeText
          value={form.links || []}
          onChange={handleChange("links")}
          label={"Patch link"}
          placeholder={"Add patch link"}
          disabled={busy}
        />
      </Grid>
      <Grid item xs={12}>
        <MultipleFreeText
          value={form.reference || []}
          onChange={handleChange("reference")}
          label={"Reference"}
          placeholder={"Add Reference"}
          disabled={busy}
        />
      </Grid>
      <Grid item xs={12}>
        <MultipleFreeText
          placeholder={"Enter Applicabilities"}
          value={form.applicabilities || []}
          onChange={handleChange("applicabilities")}
          label="Applicabilities"
          disabled={busy}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={form.isPublishedByVendor}
              onChange={handleChange("isPublishedByVendor")}
            />
          }
          label={"Patch Published by Vendor"}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={form.hasValidatedMetadata}
              onChange={handleChange("hasValidatedMetadata")}
            />
          }
          label={"Has Validated Metadata"}
        />
      </Grid>
    </Grid>
  );
}
