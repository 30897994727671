import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
        borderRadius: 6,
        backgroundColor: '#1E1D1D',
        display: 'flex',
        alignItems: 'center',
        height: 60
    },
    secondaryTail: {
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
        border: '1px dashed #36468F',
        position: 'absolute',
        height: 100 + '%',
        top: props => props.type === 'contensed' ? 41 : 25,
    },
    customDot: {
        backgroundColor: "#1F63FA",
        border: '4px solid #1F63FA',
        borderRadius: 50 + '%',
        zIndex: 1
    },
    customDotOutlined: {
        backgroundColor: "#1F63FA",
        border: '4px solid #242D5C',
        borderRadius: 50 + '%',
    },
    alignContentLeft: {
        '&:before': {
            display: 'contents'
        }
    },
    dateText: {
        color: '#6796FF',
        fontFamily: 'Helvetica',
        fontSize: 12,
        letterSpacing: 0,
        lineHeight: '14px',
        fontWeight: 'bold',
        marginRight: 10,
        marginTop: props => props.type === 'contensed' ? 0 : 4
    },
    descText: {
        color: '#AAAAAA',
        fontSize: 12,
        letterSpacing: 0,
        lineHeight: '14px',
    },
    sectionTitle: {
        fontSize: 16,
        color: '#E0E0E0',
    },
    list: {
        width: "91%",
        backgroundColor: "transparent",
        marginTop: '-19px',
        boxShadow: 'inset 0 -1px 0 0 rgba(151,151,151,0.12)',
    },
    listItem: {
        boxShadow: 'inset 0 -1px 0 0 rgba(151,151,151,0.12)',
    },
    nested: {
        // paddingLeft: theme.spacing(4),
    },
}));

const Option = (props) => {
    const { label, subItems } = props
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const subItemsLength = subItems ? subItems.length : 0
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button onClick={handleClick} style={{ backgroundColor: "transparent" }}>
                <ListItemText primary={label} />
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {subItems.map((item, index) => (
                        <ListItem key={index} button className={index < subItemsLength - 1 && classes.listItem} style={{ backgroundColor: "transparent", height: 45 }}>
                            <Typography style={{ fontSize: 12 }} color={"textSecondary"}>{item}</Typography>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export const CustomizedTimeline = (props) => {
    const { data, type } = props
    const classes = useStyles(props);

    return (<div style={{ marginTop: type === 'contensed' ? 0 : '2vh' }}>
        <Timeline >
            {data.map((item, i) => (
                <TimelineItem classes={{ missingOppositeContent: classes.alignContentLeft, }}>
                    <TimelineSeparator style={{ alignSelf: type === 'contensed' ? 'center' : 'auto' }} >
                        <TimelineDot className={i > 0 ? classes.customDotOutlined : classes.customDot} />
                        {i < data.length - 1 && <TimelineConnector className={classes.secondaryTail} />}
                    </TimelineSeparator>
                    <TimelineContent style={{ marginBottom: type === 'contensed' ? 'auto' : 20 }}>
                        {type === 'contensed'
                            ? <Paper elevation={2} className={classes.paper} >
                                <Typography component='span' className={classes.dateText}>{moment(item.createdAt).format('Do MMM')}</Typography><Typography component='span' className={classes.descText}>{item.title}</Typography>
                            </Paper>
                            : <Grid item container direction='row' justify="space-between">
                                <Typography className={classes.dateText}>{moment(item.createdAt).format('Do MMM')}</Typography>
                                <List
                                    aria-labelledby="nested-list-timeline"
                                    className={classes.list}
                                    style={{ borderLeft: item.color ? `2px solid ${item.color}` : '2px solid #9E4BF3' }}
                                >
                                    <Option label={item.title} subItems={item.subItems} />
                                </List>
                            </Grid>}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    </div>);
}

CustomizedTimeline.defaultProps = {
    type: "contensed",
};

CustomizedTimeline.propTypes = {
    type: PropTypes.oneOf(["contensed", "fullWidth"]),
};

