import {entityType} from "./module";
import { createCustomAction, createGetAllMethod } from "reactcoregk/store/actions";
import {getDefaultHeaders} from "../../helpers/utils";
import ApiHandler from "reactcoregk/models/apiHandler";

export const getDefaultApiHandler = () => new ApiHandler(false, false, getDefaultHeaders(), "POST");

export const getAllJobs = (params) => createGetAllMethod(entityType, params, getDefaultApiHandler())
export const resetJob = (payload) => createCustomAction(entityType, {actionType: "UPDATE_JOB", result: payload})

