import { entityType } from "./module";
import {
  createDeleteMethod,
  createGetAllPageableMethod,
  createGetMethod,
  createPostMethod,
  createPutMethod,
  createGetSuccessMethod,
} from "reactcoregk/store/actions";
import {getDefaultApiHandler, getDefaultHeaders} from "../../helpers/utils";
import Patch from "models/Patch";
import ApiHandler from "reactcoregk/models/apiHandler";

const putApi = () => new ApiHandler(false, true, getDefaultHeaders(), "POST")
const postApi = () => new ApiHandler(false, false, getDefaultHeaders())

export const getAllPatches = (params) => createGetAllPageableMethod(entityType, params, getDefaultApiHandler());
export const createPatch = (payload) => createPostMethod(entityType, payload, postApi());
export const updatePatch = (payload) => createPutMethod(entityType, payload, putApi());
export const deletePatch = (payload) => createDeleteMethod(entityType, payload, getDefaultApiHandler());
export const getPatch = (params) => createGetMethod(entityType, params, getDefaultApiHandler());
export const clearPatch = () => createGetSuccessMethod(entityType, new Patch());
