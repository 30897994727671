import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ApiEndpoint } from "../@core/endpoint";
import { EntityType } from "../@core/entityType";
import { fetchData, postData } from "../../helpers/utils";
import { CREATE_LICENSE, GET_ALL_LICENSES_BY_CLIENT } from "./actionTypes";
import { createLicenseActions, getAllLicensesByClientActions } from "./actions";
import { API_URL } from "../../config";
import {ActionStates, buildAsyncActionType} from "reactcoregk/store/actions";

const entityType = EntityType.License

function* getLicensesByClient({ payload  }) {
  try {
    const url = `${API_URL}/api/clients/${payload}/licenses`;
    const result = yield call(fetchData, url);
    yield put(getAllLicensesByClientActions.success(result));
  } catch (error) {
    yield put(getAllLicensesByClientActions.failure(error.message));
  }
}

function* createLicense({ payload }) {
  try {
    const url = ApiEndpoint[entityType];
    const result = yield call(postData, url, payload);
    yield put(createLicenseActions.success(result));
    yield put(getAllLicensesByClientActions.request(payload.clientId));
  } catch (error) {
    yield put(createLicenseActions.failure(error.message));
  }
}

export function* watchLicenseByClientFetch() {
  const actionType = buildAsyncActionType(entityType, GET_ALL_LICENSES_BY_CLIENT, ActionStates.request)
  yield takeEvery(actionType, getLicensesByClient);
}

export function* watchLicenseCreation() {
  const actionType = buildAsyncActionType(entityType, CREATE_LICENSE, ActionStates.request)
  yield takeEvery(actionType, createLicense);
}

function* sagas() {
  yield all([fork(watchLicenseByClientFetch), fork(watchLicenseCreation)]);
}

export default sagas;
