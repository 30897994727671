import React from "react";
import SubmenuItems from "../../components/Header/SubmenuItems";
import {HiveIcons} from "../../assets/icons";

const Menu = ({ handleAddNew }) => {

  const menuItems = [
    {
      pushRight: true,
      divider: true,
    },
    {
      title: "Add Product",
      icon: <HiveIcons.AddOutlined />,
      onClick: handleAddNew,
    },
  ];

  return <SubmenuItems items={menuItems} />;
};

export default Menu;
