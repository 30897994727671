import {all, call, fork, put, takeEvery} from "redux-saga/effects";
// Login Redux States
import {CHANGE_PASSWORD} from "./actionTypes";
import {userChangePasswordError, userChangePasswordSuccess} from "./actions";
import {API_URL} from "../../../config";
import {postLogin} from "../login/api";
import axios from "axios";
import {getToken, getUsername, postData} from "../../../helpers/utils";
//Include Both Helper File with needed methods

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changeUserPassword({ payload: { form, history } }) {
  try {
    const resetUrl = API_URL + "/api/users/update-password";
    yield call(postData, resetUrl, form);

    const user = {
      username: getUsername(),
      password: form.newPassword,
    };
    const response = yield call(postLogin, user);
    localStorage.setItem("authUser", JSON.stringify(response));
    axios.defaults.headers.common = { Authorization: "Bearer " + getToken() };
    yield put(userChangePasswordSuccess("Password successfully changed"));
    if (history) {
      history.push("/");
    }
  } catch (error) {
    yield put(userChangePasswordError(error.message));
  }
}

export function* watchUserPasswordChange() {
  yield takeEvery(CHANGE_PASSWORD, changeUserPassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordChange)]);
}

export default forgetPasswordSaga;
