import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PropTypes from "prop-types";

// Value will come from different source with a pre-populated initial value.

const SearchSelect = (props) => {
  const {
    options,
    value,
    onChange,
    label,
    busy,
    disabled,
    fullWidth,
    margin,
    placeholder,
    required,
    helperText,
    error,
  } = props;

  const selected = options.find((x) => x.value === value);
  const defaultValue = { value: 0, label: "" };
  return (
    <Autocomplete
      fullWidth={fullWidth}
      disabled={busy || disabled}
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      value={selected || defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          placeholder={placeholder}
          margin={margin}
          label={label}
          color={"primary"}
          variant="standard"
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

export default SearchSelect;

SearchSelect.defaultProps = {
  fullWidth: true,
  margin: "normal",
};

SearchSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  controlId: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  margin: PropTypes.oneOf(["normal", "dense", "none"]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};
