import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { fetchData } from "../../helpers/utils";
import Grid from "@material-ui/core/Grid";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SectionGroupDescription from "../../components/SectionGroupDescription";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { Delete, Save } from "@material-ui/icons";
import { useEntityProgress, useForm, useProgress } from "reactcoregk/hooks";
import { deleteActor, updateActor } from "../../store/actor/actions";
import ActorForm from "./ActorForm";
import Actor from "../../models/Actor";
import { ApiEndpoint } from "../../store/@core/endpoint";
import { EntityType } from "../../store/@core/entityType";
import moment from "moment";
import { useSnackbar } from "notistack";
import ErrorButton from "../../components/Common/ErrorButton";
import BaseDialog from "../../components/Common/BaseDialog";
import Title from "../../components/Common/Title";

const useStyles = makeStyles({
  paper: {
    padding: 16,
  },
});

const ActorDetails = (props) => {
  const classes = useStyles();
  const { context, updateActor, history, deleteActor } = props;
  const id = props.match.params.id;
  const [loading, setLoading] = useState(false)
  const [actor, setActor] = useState(new Actor())
  const [form, handleChange, setForm] = useForm(new Actor());
  const [busy, error] = useEntityProgress(context.Actor, () => {});
  const [openDelete, setOpenDelete] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const verticals = context.CompanyVertical.getAll.result;
  const countries = context.Country.getAll.result;

  const fetchActor = useCallback((id) => {
    setLoading(true)
    fetchData(`${ApiEndpoint[EntityType.Actor]}/${id}`)
      .then(data => {
        const res = { ...data, id: data.actorId, killChain: JSON.stringify(data.killChain) }
        setActor(res)
        setForm(res)
        setLoading(false)
      }).catch(ex => {
        enqueueSnackbar(ex.message, { variant: "error" })
        setLoading(false)
      })
  }, [setForm, enqueueSnackbar])


  const handleRedirect = () => history.push("/actors")
  useProgress(null, handleRedirect, null, context.Actor.delete)
  useProgress(null, () => fetchActor(id), null, context.Actor.update)

  useEffect(() => {
    if (error) enqueueSnackbar(error, {variant: "error"})
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (id) fetchActor(id)
  }, [fetchActor, id]);

  const finalForm = useMemo(() => {
    return {
      ...form,
      firstActivityDate: form.firstActivityDate ? moment(form.firstActivityDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
      lastActivityDate: form.lastActivityDate ? moment(form.lastActivityDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
    };
  }, [form]);

  const handleDelete = () => {
    setOpenDelete(false)
    deleteActor(actor)
  }

  return (
    <SubmenuLayout
      MenuItemsComponent={Fragment}
      title={actor?.name || "Actor"}
      backLink={"/actors"}
    >
      <Container maxWidth={false}>
        <Grid container style={{ maxWidth: 920 }}>
          <Grid xs={12}>
            <Paper variant={"outlined"} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item style={{ flex: 1 }}>
                  <SectionGroupDescription
                    title={"Actor info"}
                    IconComponent={AssignmentIndIcon}
                  />
                </Grid>
                <Grid item>
                  <ErrorButton
                    style={{ marginLeft: "auto" }}
                    disabled={busy || loading || !actor?.id}
                    color={"error"}
                    startIcon={<Delete/>}
                    variant={"outlined"}
                    onClick={() => setOpenDelete(true)}
                  >
                    Delete
                  </ErrorButton>
                </Grid>
                <Grid item>
                  <Button
                    disabled={busy || loading || !actor?.id}
                    color={"primary"}
                    variant={"outlined"}
                    startIcon={<Save/>}
                    onClick={() => updateActor(finalForm)}
                  >
                    Update info
                  </Button>
                </Grid>
              </Grid>

              <Box my={3} />
              <ActorForm
                disabled={busy || loading || !actor?.id}
                form={form}
                handleChange={handleChange}
                setForm={setForm}
                verticals={verticals}
                countries={countries}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <BaseDialog
        onClose={() => setOpenDelete(false)}
        open={openDelete} title={"Delete actor"}
        primaryActionTitle={"Delete"}
        secondaryActionTitle={"Cancel"}
        inProgress={busy}
        primaryAction={handleDelete}
        secondaryAction={() => setOpenDelete(false)}
      >
        <Title>Are you sure you want to delete {actor?.name}</Title>
      </BaseDialog>
    </SubmenuLayout>
  );
};

const mapStatetoProps = (state) => {
  return {
    context: {
      Actor: state.Actor,
      Country: state.Country,
      CompanyVertical: state.CompanyVertical
    },
  };
};

export default connect(mapStatetoProps, { updateActor, deleteActor })(ActorDetails);
