import React from "react";
import {useDefaultTable} from "../../helpers/hooks";
import DefaultTable from "../../components/Common/DefaultTable";

function PoliciesTable(props) {
  const { definitions, sortHandler, headCells, handleEntityClick } = props;

  const {
    renderHeadCell,
    renderCheckAllCell,
    renderDefaultBody,
  } = useDefaultTable(definitions, sortHandler);

  return (
    <DefaultTable
      headCells={headCells}
      onRowClick={handleEntityClick}
      renderCheckAllCell={renderCheckAllCell}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={definitions}
    />
  );
}

export default PoliciesTable;
