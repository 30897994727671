import React from "react";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => {
  const bgColor =
    theme.palette.type === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black;
  return {
    search: {
      position: "relative",
      marginRight: 8,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(bgColor, 0.05),
      "&:hover": {
        backgroundColor: fade(bgColor, 0.15),
      },
      // backgroundColor: "transparent",
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      caretColor: theme.palette.primary.main,
      color:
        theme.palette.type === "dark"
          ? theme.palette.text.secondary
          : theme.palette.text.primary,
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: theme.palette.text.secondary,
      },
    },
  };
});

export default function SearchBox({
  query,
  handleChange,
  syncQuery,
  placeholder,
}) {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon style={{ color: "#A7A7A7" }} />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          syncQuery && syncQuery();
        }}
      >
        <InputBase
          placeholder={`Search${placeholder ? " by " + placeholder : "..."}`}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={query}
          onChange={handleChange}
          inputProps={{ "aria-label": "search" }}
        />
      </form>
    </div>
  );
}
