import React from "react";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import {
  useDependency,
  useEntityProgress,
  useMasterDetailWithDialog,
  useSortHandler,
} from "reactcoregk/hooks";
import PoliciesTable from "./policies-table";
import PoliciesSubheader from "./policies-subheader";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import { EntityType } from "../../store/@core/entityType";
import UploadPolicyDialog from "./UploadPolicyDialog";
import { getAllPolicies, uploadPolicy } from "../../store/policy/actions";
import BusyIndicator from "../../components/Common/BusyIndicator";
const defaultCells = [
  {
    id: "type",
    numeric: false,
    label: "Type",
  },
  {
    id: "createdAt",
    numeric: false,
    label: "Created",
  },
];

function Policies(props) {
  const { context, getAllPolicies, uploadPolicy } = props;

  // Handlers for new or existing entity to show in dialog
  const {
    openModal,
    entity,
    handleEntityClick,
    handleCloseModal,
    handleCreateNew,
  } = useMasterDetailWithDialog({ type: "" });

  // Create the subheader options
  const subheader = <PoliciesSubheader handleCreateNew={handleCreateNew} />;

  // Sort handler used by default table
  const sortHandler = useSortHandler();

  // Initialize dependency
  useDependency(context.Policy, getAllPolicies);

  // Refresh table after a successful post
  useEntityProgress(context.Policy, () => getAllPolicies());

  return (
    <SubmenuLayout noDivider MenuItemsComponent={subheader} title={"Policies"}>
      <BusyIndicator busy={context.Policy.getAll.isLoading} />
      <Container maxWidth={false}>
        <PoliciesTable
          headCells={defaultCells}
          definitions={context.Policy.getAll.result}
          handleEntityClick={handleEntityClick}
          sortHandler={sortHandler}
        />
      </Container>
      <UploadPolicyDialog
        open={openModal}
        entity={entity}
        context={context.Policy}
        entityType={EntityType.Policy}
        handleClose={handleCloseModal}
        uploadPolicy={uploadPolicy}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {
  getAllPolicies,
  uploadPolicy,
})(Policies);


